import AppInfoProvider from '@providers/AppInfoProvider';
import { IAppInfo } from '@models/interfaces/entities/IAppInfo';
import { IAppInfoDto } from '@models/interfaces/dtos/IAppInfoDto';
import mapper from '@models/mapper';

const appInfoProvider = new AppInfoProvider();

export const getAppInfo = async () => {
  const result = await appInfoProvider.getAppInfo();
  return mapper.map<IAppInfoDto, IAppInfo>(result, 'IAppInfoDto', 'IAppInfo');
};

export const getAppAlert = async (url: string) => {
  const result = await appInfoProvider.getAppAlert(url);
  return result;
};
