import { Mapper, createMap } from '@automapper/core';

import { IWhitelistRecord } from '@models/interfaces/entities/IWhitelistRecord';
import { IWhitelistRecordDto } from '@models/interfaces/dtos/IWhitelistRecordDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createWhitelistRecordMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IWhitelistRecordDto>('IWhitelistRecordDto', {
    id: String,
    name: String,
    template: String,
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IWhitelistRecord>('IWhitelistRecord', {
    id: String,
    name: String,
    template: String,
    links: 'ILinks',
  });

  createMap<IWhitelistRecordDto, IWhitelistRecord>(
    mapper,
    'IWhitelistRecordDto',
    'IWhitelistRecord',
  );
};
