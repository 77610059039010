import { Button, Menu, MenuItem } from '@mui/material';

import { Box } from '@mui/system';
import ChevronDownGrayIcon from '@assets/icons/dropdown/chevron-down-gray.svg';
import ChevronDownWhiteIcon from '@assets/icons/dropdown/chevron-down-white.svg';
import { useState } from 'react';
import useStyles from './styles';
import { v4 as uuidv4 } from 'uuid';

export interface IDropdownOption {
  value: string;
  label: string;
}

export interface IProps {
  value: string;
  options: IDropdownOption[];
  onChanged: (value: string) => void;
  variant: 'default' | 'pagination' | 'sorting';
  id: string;
  disabled?: boolean;
}

export const Dropdown = ({ id, variant, value, options, onChanged, disabled }: IProps) => {
  const { classes } = useStyles({ variant });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (value?: string) => {
    setAnchorEl(null);
    if (value) onChanged(value);
  };

  const buttonId = `${id}-button`;
  const menuId = `${id}-menu`;

  const getLabel = (value: string) => {
    return options.find((x) => x.value === value)?.label || 'Unknown';
  };

  return (
    <Box>
      <Button
        className={classes.button}
        variant='text'
        id={buttonId}
        aria-controls={open ? menuId : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={
          <img
            alt='arrow down'
            src={variant === 'sorting' ? ChevronDownWhiteIcon : ChevronDownGrayIcon}
          />
        }
        disabled={disabled}
      >
        {getLabel(value)}
      </Button>
      <Menu
        id={menuId}
        MenuListProps={{
          'aria-labelledby': buttonId,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        className={classes.paper}
      >
        {options.map((item) => (
          <MenuItem key={item.value} onClick={() => handleClose(item.value)} disableRipple>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

Dropdown.defaultProps = { variant: 'default', id: uuidv4(), disabled: false };
