import { Box, Typography } from '@mui/material';

import { ActivityEventTypes } from '@models/enums/ActivityEventTypes';
import ColumnsIcon from '@assets/icons/activity/columns.svg';
import Edit2FilledIcon from '@assets/icons/activity/edit-2-filled.svg';
import FileIcon from '@assets/icons/activity/file.svg';
import GridIcon from '@assets/icons/activity/grid.svg';
import { IActivityEvent } from '@models/interfaces/entities/IActivityEvent';
import { IClientCreatedActivityData } from '@models/interfaces/activityData/IClientCreatedActivityData';
import { IClientDeletedActivityData } from '@models/interfaces/activityData/IClientDeletedActivityData';
import { IClientRolesUpdatedActivityData } from '@models/interfaces/activityData/IClientRolesUpdatedActivityData';
import { IFeatureAddedToClientActivityData } from '@models/interfaces/activityData/IFeatureAddedToClientActivityData';
import { IFileCategoryChangedActivityData } from '@models/interfaces/activityData/IFileCategoryChangedActivityData';
import { IFileCategoryCreatedActivityData } from '@models/interfaces/activityData/IFileCategoryCreatedActivityData';
import { IFileCategoryDeletedActivityData } from '@models/interfaces/activityData/IFileCategoryDeletedActivityData';
import { IFileCategoryFieldCreatedActivityData } from '@models/interfaces/activityData/IFileCategoryFieldCreatedActivityData';
import { IFileCategoryFieldDeletedActivityData } from '@models/interfaces/activityData/IFileCategoryFieldDeletedActivityData';
import { IFileCategoryFieldUpdatedActivityData } from '@models/interfaces/activityData/IFileCategoryFieldUpdatedActivityData';
import { IFileCategoryUpdatedActivityData } from '@models/interfaces/activityData/IFileCategoryUpdatedActivityData';
import { IFileContentDeletedActivityData } from '@models/interfaces/activityData/IFileContentDeletedActivityData';
import { IFileCreatedActivityData } from '@models/interfaces/activityData/IFileCreatedActivityData';
import { IFileDeletedActivityData } from '@models/interfaces/activityData/IFileDeletedActivityData';
import { IFileIgnoreStatusUpdatedActivityData } from '@models/interfaces/activityData/IFileIgnoreStatusUpdatedActivityData';
import { IFileMappingChangedActivityData } from '@models/interfaces/activityData/IFileMappingChangedActivityData';
import { IFileTransformationScriptChangedActivityData } from '@models/interfaces/activityData/IFileTransformationScriptChangedActivityData';
import { IFileUserStatusUpdatedActivityData } from '@models/interfaces/activityData/IFileUserStatusUpdatedActivityData';
import { IGroupCreatedActivityData } from '@models/interfaces/activityData/IGroupCreatedActivityData';
import { IGroupDeletedActivityData } from '@models/interfaces/activityData/IGroupDeletedActivityData';
import { IPiiIncidentCategoryCreatedActivityData } from '@models/interfaces/activityData/IPiiIncidentCategoryCreatedActivityData';
import { IPiiIncidentCategoryDeletedActivityData } from '@models/interfaces/activityData/IPiiIncidentCategoryDeletedActivityData';
import { IPiiIncidentCategoryUpdatedActivityData } from '@models/interfaces/activityData/IPiiIncidentCategoryUpdatedActivityData';
import { IProductAddedToClientActivityData } from '@models/interfaces/activityData/IProductAddedToClientActivityData';
import { IProductCreatedActivityData } from '@models/interfaces/activityData/IProductCreatedActivityData';
import { IProductDeletedActivityData } from '@models/interfaces/activityData/IProductDeletedActivityData';
import { IProductUpdatedActivityData } from '@models/interfaces/activityData/IProductUpdatedActivityData';
import { IProjectAccountsUpdatedActivityData } from '@models/interfaces/activityData/IProjectAccountsUpdatedActivityData';
import { IProjectCreatedActivityData } from '@models/interfaces/activityData/IProjectCreatedActivityData';
import { IProjectDeletedActivityData } from '@models/interfaces/activityData/IProjectDeletedActivityData';
import { IProjectUpdatedActivityData } from '@models/interfaces/activityData/IProjectUpdatedActivityData';
import { IStandardPiiIncidentCategoryUpdatedActivityData } from '@models/interfaces/activityData/IStandardPiiIncidentCategoryUpdatedActivityData';
import { IUserAddedToGroupActivityData } from '@models/interfaces/activityData/IUserAddedToGroupActivityData';
import { IUserCreatedActivityData } from '@models/interfaces/activityData/IUserCreatedActivityData';
import { IUserDeletedActivityData } from '@models/interfaces/activityData/IUserDeletedActivityData';
import { IUserRemovedFromGroupActivityData } from '@models/interfaces/activityData/IUserRemovedFromGroupActivityData';
import { IWhitelistRecordCreatedActivityData } from '@models/interfaces/activityData/IWhitelistRecordCreatedActivityData';
import { IWhitelistRecordDeletedActivityData } from '@models/interfaces/activityData/IWhitelistRecordDeletedActivityData';
import { IWhitelistRecordUpdatedActivityData } from '@models/interfaces/activityData/IWhitelistRecordUpdatedActivityData';
import LayersIcon from '@assets/icons/activity/layers.svg';
import ListIcon from '@assets/icons/activity/list.svg';
import RowsIcon from '@assets/icons/activity/rows.svg';
import ShuffleIcon from '@assets/icons/activity/shuffle.svg';
import UserIcon from '@assets/icons/activity/user.svg';
import UsersIcon from '@assets/icons/activity/users.svg';
import { clsx } from 'clsx';
import moment from 'moment';
import { useMemo } from 'react';
import useStyles from './styles';

interface IProps {
  item: IActivityEvent;
}

export const ActivityEventCard = ({ item }: IProps) => {
  const { classes } = useStyles();

  const data = useMemo(() => {
    switch (item.name) {
      case ActivityEventTypes.clientCreated: {
        return {
          icon: UserIcon,
          alt: 'Client',
          title: (item.data as IClientCreatedActivityData).name,
          details: 'Client was created',
        };
      }
      case ActivityEventTypes.clientUpdated: {
        return {
          icon: UserIcon,
          alt: 'Client',
          title: (item.data as IClientCreatedActivityData).name,
          details: 'Client was updated',
        };
      }
      case ActivityEventTypes.clientDeleted: {
        return {
          icon: UserIcon,
          alt: 'Client',
          title: (item.data as IClientDeletedActivityData).name,
          details: 'Client was deleted',
        };
      }
      case ActivityEventTypes.clientRolesUpdated: {
        return {
          icon: UserIcon,
          alt: 'Client',
          title: (item.data as IClientRolesUpdatedActivityData).client.name,
          details: 'Roles were updated',
        };
      }
      case ActivityEventTypes.featureAddedToClient: {
        return {
          icon: UserIcon,
          alt: 'Client',
          title: (item.data as IFeatureAddedToClientActivityData).client.name,
          details: `Feature "${
            (item.data as IFeatureAddedToClientActivityData).feature.name
          }" was added`,
        };
      }
      case ActivityEventTypes.fileCreated: {
        return {
          icon: FileIcon,
          alt: 'File',
          title: (item.data as IFileCreatedActivityData).name,
          details: 'File was created',
        };
      }
      case ActivityEventTypes.fileDeleted: {
        return {
          icon: FileIcon,
          alt: 'File',
          title: (item.data as IFileDeletedActivityData).name,
          details: 'File was deleted',
        };
      }
      case ActivityEventTypes.fileContentDeleted: {
        return {
          icon: FileIcon,
          alt: 'File',
          title: (item.data as IFileContentDeletedActivityData).name,
          details: 'File content was deleted',
        };
      }
      case ActivityEventTypes.fileUserStatusUpdated: {
        return {
          icon: FileIcon,
          alt: 'File',
          title: (item.data as IFileUserStatusUpdatedActivityData).name,
          details: 'User status was updated',
        };
      }
      case ActivityEventTypes.fileIgnoreStatusUpdated: {
        return {
          icon: FileIcon,
          alt: 'File',
          title: (item.data as IFileIgnoreStatusUpdatedActivityData).name,
          details: 'Ignore status was updated',
        };
      }
      case ActivityEventTypes.fileCategoryChanged: {
        return {
          icon: FileIcon,
          alt: 'File',
          title: (item.data as IFileCategoryChangedActivityData).name,
          details: 'File category was updated',
        };
      }
      case ActivityEventTypes.fileCategoryCreated: {
        return {
          icon: ColumnsIcon,
          alt: 'File Category',
          title: (item.data as IFileCategoryCreatedActivityData).name,
          details: 'File category was created',
        };
      }
      case ActivityEventTypes.fileCategoryUpdated: {
        return {
          icon: ColumnsIcon,
          alt: 'File Category',
          title: (item.data as IFileCategoryUpdatedActivityData).name,
          details: 'File category was updated',
        };
      }
      case ActivityEventTypes.fileCategoryDeleted: {
        return {
          icon: ColumnsIcon,
          alt: 'File Category',
          title: (item.data as IFileCategoryDeletedActivityData).name,
          details: 'File category was deleted',
        };
      }
      case ActivityEventTypes.fileCategoryFieldCreated: {
        return {
          icon: Edit2FilledIcon,
          alt: 'File Category Field',
          title: (item.data as IFileCategoryFieldCreatedActivityData).name,
          details: 'File category field was created',
        };
      }
      case ActivityEventTypes.fileCategoryFieldUpdated: {
        return {
          icon: Edit2FilledIcon,
          alt: 'File Category Field',
          title: (item.data as IFileCategoryFieldUpdatedActivityData).name,
          details: 'File category field was updated',
        };
      }
      case ActivityEventTypes.fileCategoryFieldDeleted: {
        return {
          icon: Edit2FilledIcon,
          alt: 'File Category Field',
          title: (item.data as IFileCategoryFieldDeletedActivityData).name,
          details: 'File category field was deleted',
        };
      }
      case ActivityEventTypes.fileMappingChanged: {
        return {
          icon: FileIcon,
          alt: 'File',
          title: (item.data as IFileMappingChangedActivityData).name,
          details: 'File mapping was updated',
        };
      }
      case ActivityEventTypes.fileTransformationScriptChanged: {
        return {
          icon: FileIcon,
          alt: 'File',
          title: (item.data as IFileTransformationScriptChangedActivityData).name,
          details: 'File transformation script was updated',
        };
      }
      case ActivityEventTypes.fileSubCategoryCreated: {
        return {
          icon: ColumnsIcon,
          alt: 'File Sub-category',
          title: 'File sub-category',
          details: 'File sub-category was created',
        };
      }
      case ActivityEventTypes.fileSubCategoryMappingCreated: {
        return {
          icon: ShuffleIcon,
          alt: 'File Mapping',
          title: 'File mapping',
          details: 'File mapping was created',
        };
      }
      case ActivityEventTypes.fileSubCategoryMappingUpdated: {
        return {
          icon: ShuffleIcon,
          alt: 'File Mapping',
          title: 'File mapping',
          details: 'File mapping was updated',
        };
      }
      case ActivityEventTypes.fileSubCategoryTransformationScriptCreated: {
        return {
          icon: ShuffleIcon,
          alt: 'File Transformation Script',
          title: 'File transformation script',
          details: 'File transformation script was created',
        };
      }
      case ActivityEventTypes.fileSubCategoryUpdated: {
        return {
          icon: ColumnsIcon,
          alt: 'File Sub-category',
          title: 'File sub-category',
          details: 'File sub-category was updated',
        };
      }
      case ActivityEventTypes.groupCreated: {
        return {
          icon: GridIcon,
          alt: 'Group',
          title: (item.data as IGroupCreatedActivityData).name,
          details: 'Group was created',
        };
      }
      case ActivityEventTypes.groupDeleted: {
        return {
          icon: GridIcon,
          alt: 'Group',
          title: (item.data as IGroupDeletedActivityData).name,
          details: 'Group was deleted',
        };
      }
      case ActivityEventTypes.piiIncidentCategoryCreated: {
        return {
          icon: RowsIcon,
          alt: 'PII Category (custom)',
          title: (item.data as IPiiIncidentCategoryCreatedActivityData).name,
          details: 'Custom PII category was created',
        };
      }
      case ActivityEventTypes.piiIncidentCategoryUpdated: {
        return {
          icon: RowsIcon,
          alt: 'PII Category (custom)',
          title: (item.data as IPiiIncidentCategoryUpdatedActivityData).name,
          details: 'Custom PII category was updated',
        };
      }
      case ActivityEventTypes.piiIncidentCategoryDeleted: {
        return {
          icon: RowsIcon,
          alt: 'PII Category (custom)',
          title: (item.data as IPiiIncidentCategoryDeletedActivityData).name,
          details: 'Custom PII category was deleted',
        };
      }
      case ActivityEventTypes.whitelistRecordCreated: {
        return {
          icon: RowsIcon,
          alt: 'Whitelist Record',
          title: (item.data as IWhitelistRecordCreatedActivityData).name,
          details: 'Whitelist record was created',
        };
      }
      case ActivityEventTypes.whitelistRecordUpdated: {
        return {
          icon: RowsIcon,
          alt: 'Whitelist Record',
          title: (item.data as IWhitelistRecordUpdatedActivityData).name,
          details: 'Whitelist record was updated',
        };
      }
      case ActivityEventTypes.whitelistRecordDeleted: {
        return {
          icon: RowsIcon,
          alt: 'Whitelist Record',
          title: (item.data as IWhitelistRecordDeletedActivityData).name,
          details: 'Whitelist record was deleted',
        };
      }
      case ActivityEventTypes.productCreated: {
        return {
          icon: LayersIcon,
          alt: 'Product',
          title: (item.data as IProductCreatedActivityData).name,
          details: 'Product was created',
        };
      }
      case ActivityEventTypes.productUpdated: {
        return {
          icon: LayersIcon,
          alt: 'Product',
          title: (item.data as IProductUpdatedActivityData).name,
          details: 'Product was updated',
        };
      }
      case ActivityEventTypes.productDeleted: {
        return {
          icon: LayersIcon,
          alt: 'Product',
          title: (item.data as IProductDeletedActivityData).name,
          details: 'Product was deleted',
        };
      }
      case ActivityEventTypes.productAddedToClient: {
        return {
          icon: UserIcon,
          alt: 'Client',
          title: (item.data as IProductAddedToClientActivityData).client.name,
          details: `Product "${
            (item.data as IProductAddedToClientActivityData).product.name
          }" was added`,
        };
      }
      case ActivityEventTypes.projectCreated: {
        return {
          icon: ListIcon,
          alt: 'Project',
          title: (item.data as IProjectCreatedActivityData).name,
          details: 'Project was created',
        };
      }
      case ActivityEventTypes.projectUpdated: {
        return {
          icon: ListIcon,
          alt: 'Project',
          title: (item.data as IProjectUpdatedActivityData).name,
          details: 'Project was updated',
        };
      }
      case ActivityEventTypes.projectDeleted: {
        return {
          icon: ListIcon,
          alt: 'Project',
          title: (item.data as IProjectDeletedActivityData).name,
          details: 'Project was deleted',
        };
      }
      case ActivityEventTypes.projectAccountsUpdated: {
        return {
          icon: ListIcon,
          alt: 'Project',
          title: (item.data as IProjectAccountsUpdatedActivityData).project.name,
          details: 'Project accounts were updated',
        };
      }
      case ActivityEventTypes.standardPiiIncidentCategoryUpdated: {
        return {
          icon: RowsIcon,
          alt: 'PII Category (basic)',
          title: (item.data as IStandardPiiIncidentCategoryUpdatedActivityData).name,
          details: 'Basic PII category was updated',
        };
      }
      case ActivityEventTypes.userCreated: {
        return {
          icon: UsersIcon,
          alt: 'User',
          title: `${(item.data as IUserCreatedActivityData).firstName} ${
            (item.data as IUserCreatedActivityData).lastName
          }`.trim(),
          details: 'User was created',
        };
      }
      case ActivityEventTypes.userDeleted: {
        return {
          icon: UsersIcon,
          alt: 'User',
          title: `${(item.data as IUserDeletedActivityData).firstName} ${
            (item.data as IUserDeletedActivityData).lastName
          }`.trim(),
          details: 'User was deleted',
        };
      }
      case ActivityEventTypes.userAddedToGroup: {
        return {
          icon: GridIcon,
          alt: 'Group',
          title: (item.data as IUserAddedToGroupActivityData).group.name,
          details: `User "${`${(item.data as IUserAddedToGroupActivityData).user.firstName} ${
            (item.data as IUserAddedToGroupActivityData).user.lastName
          }`.trim()}" was added`,
        };
      }
      case ActivityEventTypes.userRemovedFromGroup: {
        return {
          icon: GridIcon,
          alt: 'Group',
          title: (item.data as IUserRemovedFromGroupActivityData).group.name,
          details: `User "${`${(item.data as IUserRemovedFromGroupActivityData).user.firstName} ${
            (item.data as IUserRemovedFromGroupActivityData).user.lastName
          }`.trim()}" was removed`,
        };
      }
      default: {
        return {
          icon: '',
          alt: 'Unknown',
          title: 'Unknown action',
          details: 'Unknown action has been done',
        };
      }
    }
  }, [item]);

  return (
    <Box key={item.id} className={clsx([classes.container])}>
      <Box className={classes.title}>
        <img src={data.icon} alt={data.alt} />
        <Typography variant='subtitle2'>{data.title}</Typography>
      </Box>
      <Box data-testid='details'>
        <Typography className={classes.textGrey} variant='caption'>
          {data.details}
        </Typography>{' '}
        <Typography className={classes.textGrey} variant='caption'>
          by
        </Typography>{' '}
        <Typography className={classes.textBold} variant='caption'>
          {item.initiator}
        </Typography>{' '}
        <Typography className={classes.textGrey} variant='caption'>
          at
        </Typography>{' '}
        <Typography className={classes.textBold} variant='caption'>
          {moment(item.timestamp).format('hh:mma, MM.DD.YYYY')}
        </Typography>
      </Box>
    </Box>
  );
};
