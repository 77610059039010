import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  container: {
    marginTop: 24,
    paddingTop: 24,
    borderTopWidth: 1,
    borderTopColor: t.palette.divider,
    borderTopStyle: 'solid',
    position: 'relative',
  },
  date: {
    fontWeight: 600,
    color: t.palette.custom.dark[60],
    position: 'absolute',
    top: -8,
    left: '50%',
    transform: 'translate(-50%, 0)',
    backgroundColor: t.palette.background.default,
    paddingLeft: 16,
    paddingRight: 16,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
}));

export default useStyles;
