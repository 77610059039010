import { Button, IconButton, Tooltip } from '@mui/material';
import { deleteWhitelistRecord, getClient, getWhitelistRecords } from '@services/api';
import { useApi, useConfirm, useLoader, useUpdateEffect } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { CustomDialog } from '@components/CustomDialog';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { IProject } from '@models/interfaces/entities/IProject';
import { IWhitelistRecord } from '@models/interfaces/entities/IWhitelistRecord';
import { Loader } from '@components/Loader';
import TrashFilledIcon from '@assets/icons/item-view/trash-filled.svg';
import { toast } from 'react-toastify';
import useStyles from './styles';

interface IProps {
  project: IProject;
  open: boolean;
  onClose: () => void;
}

export const WhitelistRecordsDialog = ({ project, open, onClose }: IProps) => {
  const [whitelistRecords, setWhitelistRecords] = useState<IWhitelistRecord[]>([]);
  const confirm = useConfirm();
  const { classes } = useStyles();

  const onCancel = () => {
    onClose();
  };

  const {
    request: getWhitelistRecordsRequest,
    loading: getWhitelistRecordsLoading,
    data: getWhitelistRecordsData,
  } = useApi(getWhitelistRecords, null, { handleErrors: true });

  const {
    request: getClientRequest,
    loading: getClientLoading,
    data: getClientData,
  } = useApi(getClient, null, { handleErrors: true });

  const {
    request: deleteWhitelistRecordRequest,
    loading: deleteWhitelistRecordLoading,
    data: deleteWhitelistRecordData,
  } = useApi(
    async (url: string, id: string) => {
      await deleteWhitelistRecord(url);
      return id;
    },
    null,
    {
      handleErrors: true,
    },
  );

  useEffect(() => {
    if (open && project.links[Actions.getClient]?.href) {
      getClientRequest(project.links[Actions.getClient].href);
    }
  }, [open, project]);

  useUpdateEffect(() => {
    if (getWhitelistRecordsData) {
      setWhitelistRecords(getWhitelistRecordsData.items);
    }
  }, [getWhitelistRecordsData]);

  useUpdateEffect(() => {
    if (getClientData?.links[Actions.getWhitelistRecords]?.href) {
      getWhitelistRecordsRequest(getClientData?.links[Actions.getWhitelistRecords].href);
    }
  }, [getClientData]);

  useUpdateEffect(() => {
    if (deleteWhitelistRecordData) {
      toast.info('Successfully deleted the whitelist record');
      setWhitelistRecords((prev) => prev.filter((x) => x.id !== deleteWhitelistRecordData));
    }
  }, [deleteWhitelistRecordData]);

  const onDeleteWhitelistRecord = useCallback(
    async (record: IWhitelistRecord) => {
      if (record?.links[Actions.delete]) {
        await confirm({
          title: 'Delete Whitelist Record',
          description: 'Are you sure that you want to delete this whitelist record?',
          confirmationText: 'Delete',
        });

        deleteWhitelistRecordRequest(record.links[Actions.delete].href, record.id);
      }
    },
    [getWhitelistRecordsData],
  );

  const columns = useMemo(
    () => [
      {
        field: 'template',
        headerName: 'Content',
        type: 'string',
        width: 430,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        renderCell: ({ row }: { row: IWhitelistRecord }) => {
          return (
            <Tooltip title='Remove from the whitelist'>
              <IconButton onClick={() => onDeleteWhitelistRecord(row)}>
                <img src={TrashFilledIcon} alt='Remove' />
              </IconButton>
            </Tooltip>
          );
        },
      },
    ],
    [],
  );

  const showLoader = useLoader(
    getClientLoading,
    getWhitelistRecordsLoading,
    deleteWhitelistRecordLoading,
  );

  return (
    <>
      <CustomDialog
        title='Whitelisted Values'
        onClose={onCancel}
        open={open}
        maxWidth='sm'
        fullWidth
        actions={
          <>
            <div />
            <Button size='medium' variant='contained' color='primary' onClick={onCancel}>
              Ok
            </Button>
          </>
        }
      >
        <DataGridPro
          rows={whitelistRecords}
          columns={columns}
          density='standard'
          className={classes.table}
        />
        <Loader show={showLoader} fixed={false} />
      </CustomDialog>
    </>
  );
};
