import { useEffect, useRef } from 'react';

export const useUpdateEffect = (
  effect: () => void,
  dependencies: React.DependencyList | undefined,
) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      return effect();
    }
  }, dependencies);
};
