import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { selectClientClientsCount, setClientsCount } from '@reducers/clientSlice';
import { useApi, useAppDispatch, useAppSelector, useLoader, useUpdateEffect } from '@hooks';

import { Box } from '@mui/material';
import { Loader } from '@components/Loader';
import ProjectFilesCacheProvider from '@components/providers/ProjectFilesCacheProvider';
import { getClients } from '@services/api';
import { selectAuthGetClientsActionLink } from '@reducers/authSlice';
import { useEffect } from 'react';
import useStyles from './styles';

export const DashboardLayout = () => {
  const { classes } = useStyles();
  const getClientsActionLink = useAppSelector(selectAuthGetClientsActionLink);
  const clientsCount = useAppSelector(selectClientClientsCount);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // TODO: should be replaced with get clients count and if 1 get clients
  const {
    request: getClientsRequest,
    data: getClientsData,
    loading: getClientsLoading,
  } = useApi(getClients, null, { handleErrors: true });

  useEffect(() => {
    if (getClientsActionLink) getClientsRequest(getClientsActionLink.href);
  }, [getClientsActionLink]);

  useUpdateEffect(() => {
    if (getClientsData) {
      dispatch(setClientsCount(getClientsData.length));
      if (pathname === '/' && getClientsData.length === 1) {
        const [client] = getClientsData;
        navigate(`/clients/${client.id}`);
      }
    }
  }, [getClientsData]);

  const showLoader = useLoader(getClientsLoading);

  return (
    <ProjectFilesCacheProvider>
      <Box className={classes.root}>
        <Box className={classes.mainSection}>{clientsCount !== undefined && <Outlet />}</Box>
        <Loader show={showLoader} />
      </Box>
    </ProjectFilesCacheProvider>
  );
};
