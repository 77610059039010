import {
  IProjectFilesCacheAction,
  IProjectFilesCacheItem,
} from '@reducers/projectFilesCacheReducer';

import { createContext } from 'react';

export default createContext<{
  cache: IProjectFilesCacheItem[];
  dispatch: React.Dispatch<IProjectFilesCacheAction>;
}>({
  cache: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
});
