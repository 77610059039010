import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    gap: 16,
    display: 'flex',
    alignItems: 'center',
    minHeight: 51,
    padding: 8,
  },
}));

export default useStyles;
