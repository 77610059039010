import BaseProvider from './BaseProvider';
import { IGetClientRolesResponse } from '@models/interfaces/responses/IGetClientRolesResponse';
import { IUpdateClientRolesRequest } from '@models/interfaces/requests/IUpdateClientRolesRequest';
import { IUpdateClientRolesResponse } from '@models/interfaces/responses/IUpdateClientRolesResponse';

export default class ClientRolesProvider extends BaseProvider {
  public getClientRoles = (url: string) => {
    return this.get<IGetClientRolesResponse>(url);
  };

  public updateClientRoles = (url: string, data: IUpdateClientRolesRequest) => {
    return this.put<IUpdateClientRolesRequest, IUpdateClientRolesResponse>(url, data);
  };
}
