import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    borderBottom: `1px solid ${t.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 16,
    paddingRight: 16,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  removeControl: {
    fontWeight: 600,
    fontSize: t.typography.caption.fontSize,
    lineHeight: t.typography.caption.lineHeight,
    letterSpacing: t.typography.caption.letterSpacing,
    padding: 8,
  },
}));

export default useStyles;
