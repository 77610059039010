import { Box, Switch, Typography } from '@mui/material';

import { IProduct } from '@models/interfaces/entities/IProduct';
import clsx from 'clsx';
import { useMemo } from 'react';
import useStyles from './styles';

export interface IProps {
  product: IProduct & {
    isEnabled: boolean;
  };
  onActivate: () => void;
  isActivationEnabled: boolean;
}

export const ProductCard = ({ product, onActivate, isActivationEnabled }: IProps) => {
  const { classes } = useStyles();

  const switchChecked = useMemo(() => product.isEnabled, [product.isEnabled]);
  const switchEnabled = useMemo(
    () => !product.isEnabled && isActivationEnabled,
    [product.isEnabled, isActivationEnabled],
  );

  return (
    <Box className={clsx([classes.root, product.isEnabled ? classes.active : classes.inactive])}>
      <Switch
        className={classes.switch}
        disabled={!switchEnabled}
        checked={switchChecked}
        inputProps={{ role: 'switch' }}
        onChange={(_, value) => {
          if (value) {
            onActivate();
          }
        }}
      />
      <Typography variant='subtitle1'>{product.name}</Typography>
    </Box>
  );
};
