import BaseProvider from './BaseProvider';
import { IGetStandardPiiIncidentCategoriesResponse } from '@models/interfaces/responses/IGetStandardPiiIncidentCategoriesResponse';
import { IGetStandardPiiIncidentCategoryResponse } from '@models/interfaces/responses/IGetStandardPiiIncidentCategoryResponse';
import { IUpdateStandardPiiIncidentCategoryRequest } from '@models/interfaces/requests/IUpdateStandardPiiIncidentCategoryRequest';
import { IUpdateStandardPiiIncidentCategoryResponse } from '@models/interfaces/responses/IUpdateStandardPiiIncidentCategoryResponse';

export default class StandardPiiIncidentCategoriesProvider extends BaseProvider {
  public getStandardPiiIncidentCategories = (url: string) => {
    return this.get<IGetStandardPiiIncidentCategoriesResponse>(url);
  };

  public getStandardPiiIncidentCategory = (url: string) => {
    return this.get<IGetStandardPiiIncidentCategoryResponse>(url);
  };

  public updateStandardPiiIncidentCategory = (
    url: string,
    data: IUpdateStandardPiiIncidentCategoryRequest,
  ) => {
    return this.patch<
      IUpdateStandardPiiIncidentCategoryRequest,
      IUpdateStandardPiiIncidentCategoryResponse
    >(url, data);
  };
}
