import { ISectionMenuItem, SectionMenu } from '@components/SectionMenu';

import { IFeature } from '@models/interfaces/entities/IFeature';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

interface IProps {
  features: IFeature[];
  activeFeatureId?: string;
}

export const FeaturesMenu = ({ features, activeFeatureId }: IProps) => {
  const navigate = useNavigate();

  const onFeatureSelected = (id: string) => {
    navigate(`/admin/features/${id}`);
  };

  const menuItems = useMemo<ISectionMenuItem[]>(() => {
    return features.map((x) => ({
      id: x.id,
      title: x.name,
    }));
  }, [features]);

  return (
    <>
      <SectionMenu
        title='Features'
        activeItemId={activeFeatureId}
        items={menuItems}
        showAddButton={false}
        onItemSelected={onFeatureSelected}
      />
    </>
  );
};
