import { Box, Button, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';

import { CustomDialog } from '@components/CustomDialog';
import { IFeature } from '@models/interfaces/entities/IFeature';
import { IUpdateFeatureData } from '@models/interfaces/additional/IUpdateFeatureData';
import SaveFilledIcon from '@assets/icons/dialog/save-filled.svg';

interface IProps {
  feature: IFeature;
  open: boolean;
  onClose: (data?: IUpdateFeatureData) => void;
}

type IFormData = IUpdateFeatureData;

export const EditFeatureDialog = ({ feature, open, onClose }: IProps) => {
  const formDefaultData = useMemo(
    () => ({
      description: feature.description,
    }),
    [feature],
  );

  const form = useForm<IFormData>({
    defaultValues: formDefaultData,
  });

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const data = form.getValues();

    onClose(data);
  };

  useEffect(() => {
    form.reset(formDefaultData);
  }, [feature, open]);

  return (
    <CustomDialog
      title='Edit Feature'
      onClose={onCancel}
      open={open}
      maxWidth='xs'
      fullWidth
      actions={
        <>
          <div />
          <Button
            type='submit'
            form='form'
            variant='contained'
            color='secondary'
            size='large'
            startIcon={<img alt='add' src={SaveFilledIcon} />}
          >
            Save
          </Button>
          <div />
        </>
      }
    >
      <form
        id='form'
        onSubmit={form.handleSubmit(() => {
          onSave();
        })}
        noValidate
      >
        <Box>
          <Controller
            name={'description'}
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Please enter the description',
              },
            }}
            render={({ field: { onChange, value }, formState }) => (
              <TextField
                fullWidth
                multiline
                rows={4}
                label='Description'
                variant='standard'
                error={!!formState.errors.description}
                helperText={formState.errors.description?.message}
                onChange={onChange}
                value={value}
                autoComplete='off'
              />
            )}
          />
        </Box>
      </form>
    </CustomDialog>
  );
};
