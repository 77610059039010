import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ centered: boolean }>()((t, { centered }) => ({
  title: {
    display: 'flex',
    justifyContent: centered ? 'center' : 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  infoContainer: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  stepsContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  activeStep: {
    color: t.palette.secondary.main,
    border: `1px solid ${t.palette.secondary.main}`,
    borderRadius: '50%',
    height: 24,
    width: 24,
    textAlign: 'center',
    marginRight: 4,
    marginLeft: 4,
  },
  steps: {
    color: '#fff',
    border: `1px solid ${t.palette.secondary.main}`,
    backgroundColor: t.palette.secondary.main,
    borderRadius: '50%',
    height: 24,
    width: 24,
    textAlign: 'center',
    marginRight: 4,
    marginLeft: 4,
  },
  contentContainer: {
    textAlign: centered ? 'center' : 'left',

    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: t.palette.custom.dark[67],
      borderRadius: 4,
    },
  },
  actionsContainer: {
    justifyContent: 'space-between',
  },
  additionalContainer: {
    justifyContent: 'center',
    display: 'flex',
  },
}));

export default useStyles;
