import { Box, Menu, MenuItem, Typography } from '@mui/material';
import { useMemo, useState } from 'react';

import ChevronRightIcon from '@assets/icons/resources-menu/chevron-right.svg';
import ExternalLinkIcon from '@assets/icons/external-link.svg';
import useStyles from './styles';

interface IProps {
  id: string;
  title: string;
  links: {
    text: string;
    href: string;
  }[];
}

export const ResourcesMenu = ({ id, title, links }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { classes } = useStyles();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  return (
    <Box>
      <Box className={classes.trigger} onClick={handleMenu}>
        <img src={ChevronRightIcon} alt='chevron-right' />
        <Typography variant='subtitle2'>{title}</Typography>
      </Box>
      <Menu
        id={id}
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={open}
        onClose={handleClose}
        PaperProps={{
          className: classes.menuPaper,
        }}
      >
        {links.map((item) => (
          <MenuItem
            key={item.href}
            component='a'
            href={item.href}
            target='_blank'
            rel='noopener noreferrer'
            onClick={handleClose}
            className={classes.menuItem}
          >
            <img src={ExternalLinkIcon} alt='external-link' />
            <Typography variant='subtitle2' className={classes.menuItemText}>
              {item.text}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
