import { selectAuthAppInfo, selectAuthIsAdmin, selectAuthIsNonAdmin } from '@reducers/authSlice';

import { Actions } from '@models/enums/Actions';
import { useAppSelector } from '@hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface IProps {
  action?: Actions;
  admin?: boolean;
  children: JSX.Element;
  redirect?: string;
}

export const SecureContainer = ({ action, children, admin, redirect }: IProps) => {
  const appInfo = useAppSelector(selectAuthAppInfo);
  const hasAdminGroup = useAppSelector(selectAuthIsAdmin);
  const hasNonAdminGroup = useAppSelector(selectAuthIsNonAdmin);
  const navigate = useNavigate();

  const conditionsFailed =
    (action && (!appInfo || !appInfo.links[action])) ||
    (admin && !hasAdminGroup) ||
    (admin === false && !hasNonAdminGroup);

  const appInfoLoaded = appInfo !== null;

  useEffect(() => {
    if (appInfoLoaded && redirect && conditionsFailed) {
      navigate(redirect);
    }
  }, [conditionsFailed, appInfoLoaded, redirect]);

  return !conditionsFailed ? <>{children}</> : null;
};
