import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  mt24: {
    marginTop: 24,
  },
  mt40: {
    marginTop: 40,
  },
  root: {
    height: 'calc(100vh - 272px)',
    maxHeight: 500,
    overflow: 'hidden',
  },
  scrollableContainer: {
    overflowY: 'auto',
    height: '100%',

    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: t.palette.custom.dark[67],
      borderRadius: 4,
    },
  },
  controlsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    overflowY: 'auto',
  },
  controlRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    width: '100%',
    justifyContent: 'space-between',
  },
  autocomplete: {
    flexGrow: 1,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
