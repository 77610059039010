import FileCategoryFieldsProvider from '@providers/FileCategoryFieldsProvider';
import { ICreateFileCategoryFieldData } from '@models/interfaces/additional/ICreateFileCategoryFieldData';
import { ICreateFileCategoryFieldRequest } from '@models/interfaces/requests/ICreateFileCategoryFieldRequest';
import { IFileCategoryField } from '@models/interfaces/entities/IFileCategoryField';
import { IFileCategoryFieldDto } from '@models/interfaces/dtos/IFileCategoryFieldDto';
import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import { IUpdateFileCategoryFieldData } from '@models/interfaces/additional/IUpdateFileCategoryFieldData';
import { IUpdateFileCategoryFieldRequest } from '@models/interfaces/requests/IUpdateFileCategoryFieldRequest';
import mapper from '@models/mapper';

const fileCategoryFieldsProvider = new FileCategoryFieldsProvider();

export const getFileCategoryFields = async (url: string) => {
  const result = await fileCategoryFieldsProvider.getFileCategoryFields(url);
  return {
    items: mapper.mapArray<IFileCategoryFieldDto, IFileCategoryField>(
      result.items,
      'IFileCategoryFieldDto',
      'IFileCategoryField',
    ),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const createFileCategoryField = async (url: string, data: ICreateFileCategoryFieldData) => {
  const requestData = mapper.map<ICreateFileCategoryFieldData, ICreateFileCategoryFieldRequest>(
    data,
    'ICreateFileCategoryFieldData',
    'ICreateFileCategoryFieldRequest',
  );
  const result = await fileCategoryFieldsProvider.createFileCategoryField(url, requestData);
  return mapper.map<IFileCategoryFieldDto, IFileCategoryField>(
    result,
    'IFileCategoryFieldDto',
    'IFileCategoryField',
  );
};

export const updateFileCategoryField = async (url: string, data: IUpdateFileCategoryFieldData) => {
  const requestData = mapper.map<IUpdateFileCategoryFieldData, IUpdateFileCategoryFieldRequest>(
    data,
    'IUpdateFileCategoryFieldData',
    'IUpdateFileCategoryFieldRequest',
  );
  const result = await fileCategoryFieldsProvider.updateFileCategoryField(url, requestData);
  return mapper.map<IFileCategoryFieldDto, IFileCategoryField>(
    result,
    'IFileCategoryFieldDto',
    'IFileCategoryField',
  );
};

export const getFileCategoryField = async (url: string) => {
  const result = await fileCategoryFieldsProvider.getFileCategoryField(url);
  return mapper.map<IFileCategoryFieldDto, IFileCategoryField>(
    result,
    'IFileCategoryFieldDto',
    'IFileCategoryField',
  );
};

export const deleteFileCategoryField = async (url: string) => {
  await fileCategoryFieldsProvider.deleteFileCategoryField(url);
  return true;
};
