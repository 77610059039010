import { Box, Container, Typography } from '@mui/material';
import { SearchInput, SearchInputColors } from '@components/SearchInput';
import { useApi, useAppSelector, useInput, useLoader, useUpdateEffect } from '@hooks';
import { useEffect, useMemo, useState } from 'react';

import { ClientCard } from './components/ClientCard';
import { DashboardBreadcrumbs } from '@components/DashboardBreadcrumbs';
import { ExternalResources } from '@components/ExternalResources';
import { IClient } from '@models/interfaces/entities/IClient';
import { IssuesAlert } from '@components/IssuesAlert';
import { Loader } from '@components/Loader';
import clsx from 'clsx';
import { getClients } from '@services/api';
import { selectAuthGetClientsActionLink } from '@reducers/authSlice';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';

export const ClientsPage = () => {
  const { classes } = useStyles();
  const getClientsActionLink = useAppSelector(selectAuthGetClientsActionLink);
  const [clients, setClients] = useState<IClient[]>([]);
  const searchInput = useInput<string>('');
  const navigate = useNavigate();

  const {
    request: getClientsRequest,
    data: getClientsData,
    loading: getClientsLoading,
  } = useApi(getClients, null, { handleErrors: true });

  useEffect(() => {
    if (getClientsActionLink) getClientsRequest(getClientsActionLink.href);
  }, [getClientsActionLink]);

  useUpdateEffect(() => {
    if (getClientsData) {
      setClients(getClientsData);
    }
  }, [getClientsData]);

  const onClientSelected = (item: IClient) => {
    navigate(`/clients/${item.id}`);
  };

  const filteredItems = useMemo(() => {
    const regexp = new RegExp(searchInput.value, 'i');
    return regexp ? clients.filter((x) => regexp.test(x.name)) : clients;
  }, [clients, searchInput.value]);

  const showLoader = useLoader(getClientsLoading);

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.container}>
          <Box className={classes.hiddenSection} />
          <Box className={classes.topSection}>
            <Container maxWidth='xl'>
              <Box className={classes.topSectionContainer}>
                <DashboardBreadcrumbs />
                <IssuesAlert />
                <Box className={clsx([classes.flexRow, classes.flexGap60])}>
                  <ExternalResources />
                  <SearchInput
                    onChange={(value) => searchInput.onChange({ target: { value } })}
                    color={SearchInputColors.white}
                    maxWidth={120}
                  />
                </Box>
              </Box>
            </Container>
          </Box>
          <Box className={classes.mainSection}>
            <Box className={classes.title}>
              <Container maxWidth='xl'>
                <Typography variant='h6'>All Clients</Typography>
              </Container>
            </Box>
            <Container className={classes.overflowHidden} maxWidth='lg'>
              <Box className={classes.clientsSection}>
                <Box className={classes.clientsList}>
                  {filteredItems.map((item) => (
                    <ClientCard
                      key={item.id}
                      client={item}
                      onSelected={() => onClientSelected(item)}
                    />
                  ))}
                </Box>
              </Box>
            </Container>
          </Box>
        </Box>
      </Box>
      <Loader show={showLoader} />
    </>
  );
};
