import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  info: {
    marginBottom: 8,
    textAlign: 'center',
  },
  description: {
    marginBottom: 32,
    textAlign: 'center',
    color: t.palette.custom.dark[60],
  },
  controlContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 8,
    maxWidth: 330,
  },
}));

export default useStyles;
