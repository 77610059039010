import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { Actions } from '@models/enums/Actions';
import { ActivityEventsPage } from '@pages/activityEvents';
import { AdminLayout } from '@components/AdminLayout';
import { ClientsPage } from '@pages/clients';
import ConfirmProvider from '@components/providers/ConfirmProvider';
import { CssBaseline } from '@mui/material';
import { ClientsPage as DashboardClientsPage } from '@pages/dashboard/clients';
import { DashboardLayout } from '@components/DashboardLayout';
import { ProductsPage as DashboardProductsPage } from '@pages/dashboard/products';
import { ProjectPage as DashboardProjectPage } from '@pages/dashboard/project';
import { ProjectsPage as DashboardProjectsPage } from '@pages/dashboard/projects';
import { FeaturesPage } from '@pages/features';
import { GroupsPage } from '@pages/groups';
import { IPublicClientApplication } from '@azure/msal-browser';
import { LicenseInfo } from '@mui/x-license-pro';
import { Loader } from '@components/Loader';
import { MaintenancePage } from '@pages/maintenance';
import { MsalProvider } from '@azure/msal-react';
import { PageLayout } from '@components/PageLayout';
import { PiiCategoriesPage } from '@pages/piiCategories';
import { ProductsPage } from '@pages/products';
import { SecureContainer } from '@components/SecureContainer';
import { Suspense } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { UsersPage } from '@pages/users';
import { env } from '@config/env';
import { theme } from '@styles/theme';

LicenseInfo.setLicenseKey(env.REACT_APP_MUI_LICENSE_KEY);

interface IProps {
  msalInstance: IPublicClientApplication;
}

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more,
 * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export default function App({ msalInstance }: IProps) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ConfirmProvider>
        <MsalProvider instance={msalInstance}>
          <Suspense fallback={<Loader show />}>
            <Router>
              <Routes>
                <Route path='/' element={<PageLayout />}>
                  <Route path='/' element={<DashboardLayout />}>
                    <Route
                      path='/'
                      element={
                        <SecureContainer action={Actions.getClients}>
                          <DashboardClientsPage />
                        </SecureContainer>
                      }
                    />

                    <Route
                      path='/clients/:clientId'
                      element={
                        <SecureContainer action={Actions.getClients}>
                          <DashboardProductsPage />
                        </SecureContainer>
                      }
                    />
                    <Route
                      path='/clients/:clientId/products/:productId'
                      element={
                        <SecureContainer action={Actions.getClients}>
                          <DashboardProjectsPage />
                        </SecureContainer>
                      }
                    />
                    <Route
                      path='/clients/:clientId/products/:productId/projects/:projectId'
                      element={
                        <SecureContainer action={Actions.getClients}>
                          <DashboardProjectPage />
                        </SecureContainer>
                      }
                    >
                      <Route path=':type' />
                    </Route>
                  </Route>
                  <Route
                    path='/admin'
                    element={
                      <SecureContainer admin redirect='/'>
                        <AdminLayout />
                      </SecureContainer>
                    }
                  >
                    <Route
                      path='clients'
                      element={
                        <SecureContainer action={Actions.getClients} admin redirect='/'>
                          <ClientsPage />
                        </SecureContainer>
                      }
                    >
                      <Route path=':clientId' />
                    </Route>
                    <Route
                      path='products'
                      element={
                        <SecureContainer action={Actions.getProducts} admin redirect='/'>
                          <ProductsPage />
                        </SecureContainer>
                      }
                    >
                      <Route path=':productId' />
                    </Route>
                    <Route
                      path='features'
                      element={
                        <SecureContainer action={Actions.getFeatures} admin redirect='/'>
                          <FeaturesPage />
                        </SecureContainer>
                      }
                    >
                      <Route path=':featureId' />
                    </Route>
                    <Route
                      path='groups'
                      element={
                        <SecureContainer action={Actions.getGroups} admin redirect='/'>
                          <GroupsPage />
                        </SecureContainer>
                      }
                    >
                      <Route path=':groupId' />
                    </Route>
                    <Route
                      path='users'
                      element={
                        <SecureContainer action={Actions.getUsers} admin redirect='/'>
                          <UsersPage />
                        </SecureContainer>
                      }
                    >
                      <Route path=':userId' />
                    </Route>
                    <Route
                      path='pii-categories'
                      element={
                        <SecureContainer
                          action={Actions.getStandardPiiCategories}
                          admin
                          redirect='/'
                        >
                          <SecureContainer action={Actions.getPiiCategories} admin redirect='/'>
                            <PiiCategoriesPage />
                          </SecureContainer>
                        </SecureContainer>
                      }
                    >
                      <Route path=':categoryId' />
                    </Route>
                    <Route
                      path='activity'
                      element={
                        <SecureContainer action={Actions.getActivities} admin redirect='/'>
                          <ActivityEventsPage />
                        </SecureContainer>
                      }
                    />
                  </Route>
                </Route>
                <Route path='/maintenance' element={<MaintenancePage />} />
              </Routes>
            </Router>
          </Suspense>
        </MsalProvider>
      </ConfirmProvider>
    </ThemeProvider>
  );
}
