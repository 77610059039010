import BaseProvider from './BaseProvider';
import { ICreateFileSubCategoryRequest } from '@models/interfaces/requests/ICreateFileSubCategoryRequest';
import { ICreateFileSubCategoryResponse } from '@models/interfaces/responses/ICreateFileSubCategoryResponse';
import { IGetFileSubCategoriesResponse } from '@models/interfaces/responses/IGetFileSubCategoriesResponse';
import { IGetFileSubCategoryResponse } from '@models/interfaces/responses/IGetFileSubCategoryResponse';
import { IUpdateFileSubCategoryRequest } from '@models/interfaces/requests/IUpdateFileSubCategoryRequest';
import { IUpdateFileSubCategoryResponse } from '@models/interfaces/responses/IUpdateFileSubCategoryResponse';

export default class FileSubCategoriesProvider extends BaseProvider {
  public getFileSubCategories = (url: string) => {
    return this.get<IGetFileSubCategoriesResponse>(url);
  };

  public getFileSubCategory = (url: string) => {
    return this.get<IGetFileSubCategoryResponse>(url);
  };

  public createFileSubCategory = (url: string, data: ICreateFileSubCategoryRequest) => {
    return this.post<ICreateFileSubCategoryRequest, ICreateFileSubCategoryResponse>(url, data);
  };

  public updateFileSubCategory = (url: string, data: IUpdateFileSubCategoryRequest) => {
    return this.patch<IUpdateFileSubCategoryRequest, IUpdateFileSubCategoryResponse>(url, data);
  };
}
