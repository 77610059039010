import { Box, IconButton, Typography } from '@mui/material';

import TrashFilledIcon from '@assets/icons/uploader/trash-filled.svg';
import UploadCloudIcon from '@assets/icons/uploader/upload-cloud.svg';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import useStyles from './styles';

export interface IProps {
  imageUrl?: string;
  onImageUploaded: (file: File) => void;
  onImageRemoved: () => void;
  maxFileSize: number;
}

export const ImageUploader = ({
  imageUrl,
  onImageUploaded,
  onImageRemoved,
  maxFileSize,
}: IProps) => {
  const { classes } = useStyles();

  const onDropFiles = useCallback(async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) return;
    const [file] = acceptedFiles;
    onImageUploaded(file);
  }, []);

  const dropzone = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    multiple: false,
    onDrop: onDropFiles,
    onDropRejected() {
      toast.info(`file skipped due to exceeding max size (${maxFileSize} mb)`);
    },
    maxSize: maxFileSize * 1024 * 1024,
  });

  return (
    <>
      {imageUrl === undefined && (
        <Box className={classes.dropzoneContainer}>
          <Box
            {...dropzone.getRootProps()}
            className={clsx([classes.dropzone, dropzone.isDragActive && 'active'])}
            data-testid='dropzone'
          >
            <input data-testid='dropzone-input' {...dropzone.getInputProps()} />
            <img className={classes.dropzoneControlsIcon} alt='upload' src={UploadCloudIcon} />
            <Box className={classes.dropzoneControlsTextContent}>
              <Typography className={classes.textWhite} variant='subtitle2'>
                Click to upload
              </Typography>
              <Typography className={classes.textWhite80} variant='body2'>
                or drag & drop
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.textWhite60} variant='overline'>
                png, jpeg. (Max. file size {maxFileSize} MB.)
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.textWhite60} variant='overline'>
                Recommended resolution 540 * 256
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {!!imageUrl && (
        <Box className={classes.previewContainer}>
          <Box className={classes.imageContainer}>
            <img className={classes.previewImage} src={imageUrl} alt='product image' />
            <IconButton className={classes.removeButton} onClick={onImageRemoved}>
              <img src={TrashFilledIcon} alt='Remove' />
            </IconButton>
          </Box>
        </Box>
      )}
    </>
  );
};

ImageUploader.defaultProps = {
  maxFileSize: 2,
};
