import BaseProvider from './BaseProvider';
import { ICreateClientProductRequest } from '@models/interfaces/requests/ICreateClientProductRequest';
import { ICreateClientProductResponse } from '@models/interfaces/responses/ICreateClientProductResponse';
import { IGetClientProductResponse } from '@models/interfaces/responses/IGetClientProductResponse';
import { IGetClientProductsResponse } from '@models/interfaces/responses/IGetClientProductsResponse';

export default class ClientProductsProvider extends BaseProvider {
  public getClientProduct = (url: string) => {
    return this.get<IGetClientProductResponse>(url);
  };

  public getClientProducts = (url: string) => {
    return this.get<IGetClientProductsResponse>(url);
  };

  public createClientProduct = (url: string, data: ICreateClientProductRequest) => {
    return this.post<ICreateClientProductRequest, ICreateClientProductResponse>(url, data);
  };
}
