import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../store';

export interface GeneralState {
  acceptableFilesCount: number;
  issuesDrawerOpened: boolean;
  issuesDrawerSelectedFileId: string | null;
  issuesDrawerSelectedClientId: string | null;
  issuesDrawerSelectedProductId: string | null;
  issuesDrawerSelectedProjectId: string | null;
  autoCloseScanResultsDialog: boolean;
  uploadingDialogOpened: boolean;
  completedTours: string[];
  currentTours: string[];
  markedTours: string[];
}

const initialState: GeneralState = {
  acceptableFilesCount: 0,
  issuesDrawerOpened: false,
  issuesDrawerSelectedFileId: null,
  issuesDrawerSelectedClientId: null,
  issuesDrawerSelectedProductId: null,
  issuesDrawerSelectedProjectId: null,
  autoCloseScanResultsDialog: false,
  uploadingDialogOpened: false,
  completedTours: [],
  currentTours: [],
  markedTours: [],
};

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setAcceptableFilesCount: (state, action: PayloadAction<number>) => {
      state.acceptableFilesCount = action.payload;
    },
    setIssuesDrawerData: (
      state,
      action: PayloadAction<{
        open: boolean;
        fileId?: string;
        clientId?: string;
        productId?: string;
        projectId?: string;
      }>,
    ) => {
      state.issuesDrawerOpened = action.payload.open;
      state.issuesDrawerSelectedFileId = action.payload.fileId || null;
      state.issuesDrawerSelectedClientId = action.payload.clientId || null;
      state.issuesDrawerSelectedProductId = action.payload.productId || null;
      state.issuesDrawerSelectedProjectId = action.payload.projectId || null;
    },
    setUploadingDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.uploadingDialogOpened = action.payload;
    },
    setAutoCloseScanResultsDialog: (state, action: PayloadAction<boolean>) => {
      state.autoCloseScanResultsDialog = action.payload;
    },
    setCompletedTours: (state, action: PayloadAction<string[]>) => {
      state.completedTours = action.payload;
    },
    addCompletedTour: (state, action: PayloadAction<string>) => {
      const completedTours = state.completedTours || [];
      if (!completedTours.includes(action.payload)) {
        state.completedTours = [...completedTours, action.payload];
      }
    },
    setCurrentTours: (state, action: PayloadAction<string[]>) => {
      state.currentTours = action.payload;
    },
    addCurrentTour: (state, action: PayloadAction<string>) => {
      const currentTours = state.currentTours || [];
      if (!currentTours.includes(action.payload)) {
        state.currentTours = [...currentTours, action.payload];
      }
    },
    removeCurrentTour: (state, action: PayloadAction<string>) => {
      const currentTours = state.currentTours || [];
      state.currentTours = currentTours.filter((x) => x !== action.payload);
    },
    addMarkedTour: (state, action: PayloadAction<string>) => {
      const markedTours = state.markedTours || [];
      if (!markedTours.includes(action.payload)) {
        state.markedTours = [...markedTours, action.payload];
      }
    },
    removeMarkedTour: (state, action: PayloadAction<string>) => {
      const markedTours = state.markedTours || [];
      state.markedTours = markedTours.filter((x) => x !== action.payload);
    },
  },
});

export const {
  setAcceptableFilesCount,
  setIssuesDrawerData,
  setUploadingDialogOpened,
  setAutoCloseScanResultsDialog,
  setCompletedTours,
  addCompletedTour,
  setCurrentTours,
  addCurrentTour,
  removeCurrentTour,
  addMarkedTour,
  removeMarkedTour,
} = generalSlice.actions;

export const selectAcceptableFilesCount = (state: RootState) => state.general.acceptableFilesCount;
export const selectGeneralIssuesDrawerOpened = (state: RootState) =>
  state.general.issuesDrawerOpened;
export const selectGeneralAutoCloseScanResultsDialog = (state: RootState) =>
  state.general.autoCloseScanResultsDialog;
export const selectGeneralIssuesDrawerSelectedFileId = (state: RootState) =>
  state.general.issuesDrawerSelectedFileId;
export const selectGeneralIssuesDrawerSelectedFilters = (state: RootState) => ({
  clientId: state.general.issuesDrawerSelectedClientId,
  productId: state.general.issuesDrawerSelectedProductId,
  projectId: state.general.issuesDrawerSelectedProjectId,
});
export const selectGeneralUploadingDialogOpened = (state: RootState) =>
  state.general.uploadingDialogOpened;
export const selectGeneralCompletedTours = (state: RootState) => state.general.completedTours || [];
export const selectGeneralCurrentTours = (state: RootState) => state.general.currentTours || [];
export const selectGeneralMarkedTours = (state: RootState) => state.general.markedTours || [];

export default generalSlice.reducer;
