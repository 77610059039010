import React, { Fragment, useReducer } from 'react';

import ProjectFilesCacheContext from '@contexts/ProjectFilesCacheContext';
import { projectFilesCacheReducer } from '@reducers/projectFilesCacheReducer';

interface IProps {
  children: React.ReactNode;
}

const ProjectFilesCacheProvider = ({ children }: IProps) => {
  const [cache, dispatch] = useReducer(projectFilesCacheReducer, []);

  return (
    <Fragment>
      <ProjectFilesCacheContext.Provider value={{ cache, dispatch }}>
        {children}
      </ProjectFilesCacheContext.Provider>
    </Fragment>
  );
};

export default ProjectFilesCacheProvider;
