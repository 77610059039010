import { Box, Collapse, IconButton, Tooltip, Typography } from '@mui/material';
import { useMemo, useState } from 'react';

import { DataGridPro } from '@mui/x-data-grid-pro';
import EyeIcon from '@assets/icons/dashboard/eye.svg';
import EyeOffIcon from '@assets/icons/dashboard/eye-off.svg';
import { IWhitelistRecord } from '@models/interfaces/entities/IWhitelistRecord';
import TrashFilledIcon from '@assets/icons/item-view/trash-filled.svg';
import useStyles from './styles';

interface IProps {
  records: IWhitelistRecord[];
  onDeleteWhitelistRecord: (record: IWhitelistRecord) => void;
}

export const WhitelistRecordsView = ({ records, onDeleteWhitelistRecord }: IProps) => {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState(false);

  const columns = useMemo(
    () => [
      {
        field: 'template',
        headerName: 'Content',
        type: 'string',
        width: 700,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        renderCell: ({ row }: { row: IWhitelistRecord }) => {
          return (
            <Tooltip title='Remove from the whitelist'>
              <IconButton onClick={() => onDeleteWhitelistRecord(row)}>
                <img src={TrashFilledIcon} alt='Remove' />
              </IconButton>
            </Tooltip>
          );
        },
      },
    ],
    [],
  );

  const onToggleExpanded = () => {
    setExpanded((val) => !val);
  };

  return (
    <>
      <Box className={classes.controls}>
        <Typography>
          Whitelist ({records.length} record{records.length !== 1 ? 's' : ''})
        </Typography>
        <Tooltip title={expanded ? 'Hide whitelist' : 'Show whitelist'}>
          <IconButton onClick={onToggleExpanded} data-tour='whitelist-toggle'>
            {expanded ? <img alt='eye-off' src={EyeOffIcon} /> : <img alt='eye' src={EyeIcon} />}
          </IconButton>
        </Tooltip>
      </Box>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <DataGridPro rows={records} columns={columns} density='standard' />
      </Collapse>
    </>
  );
};
