import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    backgroundColor: t.palette.custom.dark[5],
    paddingTop: 8,
    paddingBottom: 8,
  },
  card: {
    padding: 8,
  },
  title: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  balanceLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  accordionSummaryContent: {
    minHeight: 48,
    margin: 0,
    justifyContent: 'space-between',
    paddingRight: 24,

    '&.Mui-expanded': {
      marginTop: 8,
      marginBottom: 8,
    },
  },
  messageContainer: {
    padding: 40,
    textAlign: 'center',
  },
  groupsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
}));

export default useStyles;
