export enum PIIScanResultStatuses {
  inProgress = 'In Progress',
  completedWithoutPii = 'Complete Without PII',
  completedWithPii = 'Complete With PII',
  failure = 'Failure',
  unscanned = 'Unscanned',
  queued = 'Queued',
  skipped = 'Skipped',
  unknown = 'Unknown',
}
