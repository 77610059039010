import { Mapper, createMap, forMember, mapFrom } from '@automapper/core';

import { IFileCategoryField } from '@models/interfaces/entities/IFileCategoryField';
import { IFileCategoryFieldDto } from '@models/interfaces/dtos/IFileCategoryFieldDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createFileCategoryFieldMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IFileCategoryFieldDto>('IFileCategoryFieldDto', {
    id: String,
    name: String,
    displayName: String,
    type: Number,
    typeMessage: String,
    required: Boolean,
    position: Number,
    fileCategoryId: String,
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IFileCategoryField>('IFileCategoryField', {
    id: String,
    name: String,
    displayName: String,
    type: String,
    required: Boolean,
    position: Number,
    fileCategoryId: String,
    links: 'ILinks',
  });

  createMap<IFileCategoryFieldDto, IFileCategoryField>(
    mapper,
    'IFileCategoryFieldDto',
    'IFileCategoryField',
    forMember(
      (destination) => destination.type,
      mapFrom((source) => source.typeMessage),
    ),
  );
};
