import FileSubCategoriesProvider from '@providers/FileSubCategoriesProvider';
import { ICreateFileSubCategoryData } from '@models/interfaces/additional/ICreateFileSubCategoryData';
import { ICreateFileSubCategoryRequest } from '@models/interfaces/requests/ICreateFileSubCategoryRequest';
import { IFileSubCategory } from '@models/interfaces/entities/IFileSubCategory';
import { IFileSubCategoryDto } from '@models/interfaces/dtos/IFileSubCategoryDto';
import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import { IUpdateFileSubCategoryData } from '@models/interfaces/additional/IUpdateFileSubCategoryData';
import { IUpdateFileSubCategoryRequest } from '@models/interfaces/requests/IUpdateFileSubCategoryRequest';
import mapper from '@models/mapper';

const fileSubCategoriesProvider = new FileSubCategoriesProvider();

export const getFileSubCategories = async (url: string) => {
  const result = await fileSubCategoriesProvider.getFileSubCategories(url);
  return {
    items: mapper.mapArray<IFileSubCategoryDto, IFileSubCategory>(
      result.items,
      'IFileSubCategoryDto',
      'IFileSubCategory',
    ),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const createFileSubCategory = async (url: string, data: ICreateFileSubCategoryData) => {
  const requestData = mapper.map<ICreateFileSubCategoryData, ICreateFileSubCategoryRequest>(
    data,
    'ICreateFileSubCategoryData',
    'ICreateFileSubCategoryRequest',
  );
  const result = await fileSubCategoriesProvider.createFileSubCategory(url, requestData);
  return mapper.map<IFileSubCategoryDto, IFileSubCategory>(
    result,
    'IFileSubCategoryDto',
    'IFileSubCategory',
  );
};

export const getFileSubCategory = async (url: string) => {
  const result = await fileSubCategoriesProvider.getFileSubCategory(url);
  return mapper.map<IFileSubCategoryDto, IFileSubCategory>(
    result,
    'IFileSubCategoryDto',
    'IFileSubCategory',
  );
};

export const updateFileSubCategory = async (url: string, data: IUpdateFileSubCategoryData) => {
  const requestData = mapper.map<IUpdateFileSubCategoryData, IUpdateFileSubCategoryRequest>(
    data,
    'IUpdateFileSubCategoryData',
    'IUpdateFileSubCategoryRequest',
  );
  const result = await fileSubCategoriesProvider.updateFileSubCategory(url, requestData);
  return mapper.map<IFileSubCategoryDto, IFileSubCategory>(
    result,
    'IFileSubCategoryDto',
    'IFileSubCategory',
  );
};
