import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  mb24: {
    marginBottom: 24,
  },
  fileItemContainer: {
    borderBottom: `1px solid ${t.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 8,
    paddingBottom: 8,
  },
}));

export default useStyles;
