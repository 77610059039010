import { Mapper, createMap } from '@automapper/core';

import { IClientProduct } from '@models/interfaces/entities/IClientProduct';
import { IClientProductDto } from '@models/interfaces/dtos/IClientProductDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createClientProductMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IClientProductDto>('IClientProductDto', {
    clientId: String,
    productId: String,
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IClientProduct>('IClientProduct', {
    clientId: String,
    productId: String,
    links: 'ILinks',
  });

  createMap<IClientProductDto, IClientProduct>(mapper, 'IClientProductDto', 'IClientProduct');
};
