import { Box, Typography } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';
import useStyles from './styles';

interface IProps {
  show?: boolean;
  label?: string;
  fixed?: boolean;
  size?: number;
}

export const Loader = ({ show, fixed, label, size }: IProps) => {
  const { classes } = useStyles({ fixed: fixed || false });
  return show ? (
    <Box className={classes.backdrop}>
      <CircularProgress color='secondary' size={size} />
      {label && (
        <Typography variant='h5' className={classes.label}>
          {label}
        </Typography>
      )}
    </Box>
  ) : null;
};

Loader.defaultProps = { show: false, fixed: true, size: 100 };
