import { Context, Hub } from '../types';
import { createConnection, isConnectionConnecting } from '../utils';
import { useEffect, useRef, useState } from 'react';

import { ProviderProps } from './types';
import { usePropRef } from '../../utils';

function providerNativeFactory<T extends Hub>(Context: Context<T>) {
  const Provider = ({
    url,
    connectEnabled = true,
    children,
    dependencies = [],
    accessTokenFactory,
    onError,
    onOpen,
    onClosed,
    onReconnect,
    onBeforeClose,
    onConnectionFailed,
    retriesCount,
    retriesDelay,
    ...rest
  }: ProviderProps) => {
    const onErrorRef = usePropRef(onError);
    const accessTokenFactoryRef = usePropRef(accessTokenFactory);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const clear = useRef<(fromRetries?: boolean) => void>(() => {});

    function refreshConnection() {
      if (!connectEnabled) {
        return;
      }

      const connection = createConnection(
        url,
        {
          accessTokenFactory: () => accessTokenFactoryRef.current?.() || '',
          ...rest,
        },
        new Array(retriesCount).fill(retriesDelay),
      );
      connection.onreconnecting((error) => onErrorRef.current?.(error));
      connection.onreconnected(() => onReconnect?.(connection));

      Context.connection = connection;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Context.reOn();

      connection.onclose((error) => {
        onClosed?.(error);
      });

      async function checkForStart() {
        if (!isConnectionConnecting(connection)) {
          try {
            await connection.start();
            onOpen?.(connection);
          } catch (err) {
            console.log(err);
            onErrorRef.current?.(err as Error);
          }
        }
      }

      checkForStart();

      let count = retriesCount;
      const checkInterval = setInterval(() => {
        if (connection.connectionId) {
          count = retriesCount;
          return;
        }
        if (count > 0) {
          checkForStart();
          count--;
        } else {
          clear.current(true);
        }
      }, retriesDelay);

      clear.current = async (fromRetries?: boolean) => {
        clearInterval(checkInterval);
        await onBeforeClose?.(connection);
        if (connection.connectionId) {
          connection.stop();
        } else if (onConnectionFailed && fromRetries) {
          onConnectionFailed();
        }
      };
    }

    useState(() => {
      refreshConnection();
    });

    const isMounted = useRef<boolean>(false);

    useEffect(() => {
      if (isMounted.current) {
        refreshConnection();
      }

      isMounted.current = true;
      return () => {
        clear.current();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectEnabled, url, ...dependencies]);

    return children;
  };
  return Provider;
}

export { providerNativeFactory };
