import { addMarkedTour, removeMarkedTour } from '@reducers/generalSlice';

import { useAppDispatch } from '@hooks';
import { useEffect } from 'react';

interface IProps {
  names: string[];
}

export const GuideTourMarker = ({ names }: IProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (names.length) {
      names.forEach((name) => {
        dispatch(addMarkedTour(name));
      });

      return () => {
        names.forEach((name) => {
          dispatch(removeMarkedTour(name));
        });
      };
    }
  }, [names]);

  return <div />;
};
