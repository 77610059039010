import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistCombineReducers,
  persistStore,
} from 'reduxjs-toolkit-persist';
import authReducer, { AuthState } from '@reducers/authSlice';
import clientReducer, { ClientState } from '@reducers/clientSlice';
import generalReducer, { GeneralState } from '@reducers/generalSlice';

import autoMergeLevel1 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import { createStateSyncMiddleware } from 'redux-state-sync';
import storage from 'reduxjs-toolkit-persist/lib/storage';

const saveSubsetBlacklistFilter = createBlacklistFilter('general', ['currentTours']);

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel1,
  transforms: [saveSubsetBlacklistFilter],
};

const syncConfig = {
  whitelist: ['auth/logout'],
};

const syncStateMiddleware = [createStateSyncMiddleware(syncConfig)];

const _persistedReducer = persistCombineReducers(persistConfig, {
  auth: authReducer,
  client: clientReducer,
  general: generalReducer,
});

export interface RootState {
  auth: AuthState;
  client: ClientState;
  general: GeneralState;
}

export const store = configureStore({
  reducer: _persistedReducer,
  middleware: (getDefaultMiddleware) =>
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getDefaultMiddleware({
      serializableCheck: {
        /* ignore persistance actions */
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(syncStateMiddleware),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
// export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
