import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { deleteWhitelistRecord, updateWhitelistRecord } from '@services/api';
import { useApi, useConfirm, useLoader } from '@hooks';

import { Actions } from '@models/enums/Actions';
import EditIcon from '@assets/icons/item-view/edit.svg';
import { EditWhitelistRecordDialog } from '../EditWhitelistRecordDialog';
import { IUpdateWhitelistRecordData } from '@models/interfaces/additional/IUpdateWhitelistRecordData';
import { IWhitelistRecord } from '@models/interfaces/entities/IWhitelistRecord';
import { Loader } from '@components/Loader';
import TrashFilledIcon from '@assets/icons/item-view/trash-filled.svg';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useState } from 'react';
import useStyles from './styles';

export interface IProps {
  record: IWhitelistRecord;
  onRecordChanged: () => void;
}

export const WhitelistRecordCard = ({ record, onRecordChanged }: IProps) => {
  const { classes } = useStyles();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const confirm = useConfirm();

  const { request: deleteWhitelistRecordRequest, loading: deleteWhitelistRecordLoading } = useApi(
    deleteWhitelistRecord,
    null,
    {
      handleErrors: true,
      onCallback: () => {
        toast.info('Successfully deleted the whitelist record');
        onRecordChanged();
      },
    },
  );

  const { request: updateWhitelistRecordRequest, loading: updateWhitelistRecordLoading } = useApi(
    updateWhitelistRecord,
    null,
    {
      handleErrors: true,
      onCallback: () => {
        setOpenEditDialog(false);
        toast.info('Successfully updated the whitelist record');
        onRecordChanged();
      },
    },
  );

  const onDelete = async () => {
    if (record.links[Actions.delete]?.href) {
      await confirm({
        title: 'Delete Whitelist Record',
        description: 'Are you sure that you want to delete this whitelist record?',
        confirmationText: 'Delete',
      });
      deleteWhitelistRecordRequest(record.links[Actions.delete].href);
    }
  };

  const onEdit = () => {
    setOpenEditDialog(true);
  };

  const onCloseEditDialog = (data?: IUpdateWhitelistRecordData) => {
    if (!data) {
      setOpenEditDialog(false);
    } else {
      const url = record.links[Actions.update]?.href;
      if (url) {
        updateWhitelistRecordRequest(url, data);
      }
    }
  };

  const showLoader = useLoader(deleteWhitelistRecordLoading, updateWhitelistRecordLoading);

  return (
    <>
      <Box className={classes.root}>
        <Box className={clsx([classes.flexRow, classes.justifyContentSpaceBetween, classes.w100])}>
          <Box>
            <Typography variant='subtitle1'>{record.name}</Typography>
            <Typography variant='subtitle2' className={classes.textGray}>
              {record.template}
            </Typography>
          </Box>
          <Box className={clsx([classes.flexRow, classes.gap0])}>
            {record.links[Actions.update]?.href && (
              <Tooltip title='Edit Category'>
                <IconButton data-testid='edit-button' onClick={onEdit}>
                  <img src={EditIcon} alt='Edit' />
                </IconButton>
              </Tooltip>
            )}
            {record.links[Actions.delete]?.href && (
              <Tooltip title='Delete Category'>
                <IconButton data-testid='delete-button' onClick={onDelete}>
                  <img src={TrashFilledIcon} alt='Trash' />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>
      {!!record?.links[Actions.update] && (
        <EditWhitelistRecordDialog
          record={record}
          open={openEditDialog}
          onClose={onCloseEditDialog}
        />
      )}
      <Loader show={showLoader} />
    </>
  );
};
