import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CalendarIcon from '@assets/icons/date-picker/calendar.svg';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import useStyles from './styles';

export const CustomDatePicker = <TInputDate, TDate = TInputDate>(
  props: DatePickerProps<TInputDate, TDate>,
) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        {...props}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        PaperProps={{ ...props.PaperProps, className: classes.datePickerPaper }}
        InputProps={{
          ...props.InputProps,
          startAdornment: (
            <IconButton data-testid='calendar-icon-button' onClick={() => setOpen((o) => !o)}>
              <img src={CalendarIcon} alt='Calendar' />
            </IconButton>
          ),
          endAdornment: null,
        }}
        disableOpenPicker
        inputFormat='MM.DD.YYYY'
      />
    </LocalizationProvider>
  );
};
