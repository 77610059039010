import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  marginTop24: {
    marginTop: 24,
  },
  marginLeft16: {
    marginLeft: 16,
  },
}));

export default useStyles;
