import { IconButton, Tooltip } from '@mui/material';
import { useApi, useLoader, useUpdateEffect } from '@hooks';
import { useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import DownloadIcon from '@assets/icons/dashboard/download.svg';
import { IFile } from '@models/interfaces/entities/IFile';
import { IFileCategory } from '@models/interfaces/entities/IFileCategory';
import { IProject } from '@models/interfaces/entities/IProject';
import JsZip from 'jszip';
import { Loader } from '@components/Loader';
import { getProjectFilesDirectory } from '@services/api';
import moment from 'moment';
import saveAs from 'file-saver';
import { toast } from 'react-toastify';

interface IProps {
  id?: string;
  project: IProject;
  files: IFile[];
  categories: IFileCategory[];
  structured: boolean;
}

interface ILinkData {
  link: string;
  name: string;
  processed?: boolean;
  failed?: boolean;
}

const limit = 1024 * 1024 * 600;

export const FileDownloader = ({ id, project, files, categories, structured }: IProps) => {
  const [countToProcess, setCountToProcess] = useState(0);
  const [countProcessed, setCountProcessed] = useState(0);
  const [zipping, setZipping] = useState(false);

  const [links, setLinks] = useState<ILinkData[]>([]);

  const filteredFiles = useMemo(() => {
    const categorizedSourceFilesIds = files
      .filter((x) => x.isSource && x.fileCategoryId)
      .map((x) => x.id);
    return files.filter(
      (x) =>
        x.links[Actions.getContent]?.href &&
        (!x.sourceFileId || !categorizedSourceFilesIds.includes(x.sourceFileId)) &&
        (!x.isSource || categorizedSourceFilesIds.includes(x.id)),
    );
  }, [files]);

  const getFileName = (file: IFile) => {
    const folder = structured
      ? file.fileCategoryId
        ? categories.find((x) => x.id === file.fileCategoryId)?.name || 'Unknown'
        : 'Uncategorized'
      : '';
    const fileName = file.sourceFileName
      ? `${file.sourceFileName} - ${file.alternativeName || file.name}`
      : file.alternativeName || file.name;
    const fullFileName = folder ? `${folder}/${fileName}` : fileName;
    return fullFileName;
  };

  const getFileContent = async (linkData: ILinkData) => {
    try {
      const response = await fetch(linkData.link);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const arrayBuffer = await response.arrayBuffer();
      const contentType = response.headers.get('content-type');

      const blob = new Blob([arrayBuffer], { type: contentType || undefined });
      const file = new File([blob], linkData.name, { type: contentType || undefined });

      return file;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const { request: getProjectFilesDirectoryRequest, loading: getProjectFilesDirectoryLoading } =
    useApi(getProjectFilesDirectory, null, {
      handleErrors: true,
      onCallback(link) {
        if (link) {
          setCountToProcess(filteredFiles.length);
          setCountProcessed(0);

          const links = filteredFiles.map((x) => ({
            link: buildFileSasLink(x, link),
            name: getFileName(x),
          }));
          setLinks(links);
        }
      },
    });

  const buildFileSasLink = (file: IFile, folderSasLink: string) => {
    const parts = folderSasLink.split('?');
    const link = `${parts[0]}/${file.contentId}?${parts[1]}`;
    return link;
  };

  const onDownloadAllFiles = async () => {
    if (project.links[Actions.getFilesDirectory]?.href) {
      getProjectFilesDirectoryRequest(project.links[Actions.getFilesDirectory].href);
    }
  };

  const zipAndSave = async (zip: JsZip, date: Date, part?: number) => {
    setZipping(true);
    const zipFile = await zip.generateAsync({ type: 'blob' });
    const fileName = `${project.name} (${moment(date).format('hh.mm.ss MM.DD.YYYY')})${
      part ? ` (part ${part})` : ''
    }.zip`;
    saveAs(zipFile, fileName);
    setZipping(false);
  };

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  useUpdateEffect(() => {
    (async () => {
      if (links.length) {
        const updatedLinks = [...links];
        let zip = JsZip();

        let zipSize = 0;
        let zipped = 0;
        const date = new Date();

        for (const link of updatedLinks) {
          const result = await getFileContent(link);

          if (result) {
            link.processed = true;
            if (zipSize + result.size > limit) {
              await zipAndSave(zip, date, zipped + 1);
              zip = JsZip();
              zipSize = 0;
              zipped += 1;
              await delay(2000);
            }

            zipSize += result.size;
            zip.file(result.name, result);
            await delay(500);
          } else {
            link.failed = true;
          }
          setCountProcessed((c) => c + 1);
        }

        await zipAndSave(zip, date, zipped === 0 ? undefined : zipped + 1);

        const withErrors = updatedLinks.filter((x) => x.failed);
        if (withErrors.length) {
          toast.warning(
            `Downloading completed with error. ${withErrors.length} file${
              withErrors.length === 1 ? ' has' : 's have'
            } been not downloaded.`,
          );
        } else {
          toast.success('Successfully zipped and downloaded all the files.');
        }
        setLinks([]);
      }
    })();
  }, [links]);

  const processing = countToProcess > countProcessed;

  const showLoader = useLoader(processing, zipping, getProjectFilesDirectoryLoading);
  const loaderLabel = useMemo(
    () => (zipping ? 'zipping' : `${Math.round((countProcessed * 100) / countToProcess) || 0}%`),
    [countToProcess, countProcessed, zipping],
  );

  if (filteredFiles.length === 0) return null;

  return (
    <>
      {project.links[Actions.getFilesDirectory]?.href && (
        <Tooltip title='Download all'>
          <IconButton onClick={onDownloadAllFiles} id={id}>
            <img alt='download' src={DownloadIcon} />
          </IconButton>
        </Tooltip>
      )}
      <Loader show={showLoader} label={loaderLabel} />
    </>
  );
};
