import { Box, Button, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import moment, { Moment } from 'moment';
import { useEffect, useMemo } from 'react';

import { CustomDatePicker } from '@components/CustomDatePicker';
import { CustomDialog } from '@components/CustomDialog';
import { ICreateProjectData } from '@models/interfaces/additional/ICreateProjectData';
import PlusFilledIcon from '@assets/icons/dialog/plus-filled.svg';
import useStyles from './styles';

interface IProps {
  open: boolean;
  onClose: (data?: ICreateProjectData) => void;
}

type IFormData = ICreateProjectData;

export const CreateProjectDialog = ({ open, onClose }: IProps) => {
  const { classes } = useStyles();

  const toStartDate = (local: Moment) => {
    const year = local.year();
    const month = local.month();
    const date = local.date();
    return new Date(Date.UTC(year, month, date, 12, 0, 0));
  };

  const formDefaultData = useMemo(() => {
    return {
      name: '',
      startDate: toStartDate(moment()),
    };
  }, [toStartDate]);

  const form = useForm<IFormData>({
    defaultValues: formDefaultData,
  });

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const data = form.getValues();

    onClose(data);
  };

  useEffect(() => {
    form.reset(formDefaultData);
    if (open) {
      setTimeout(() => {
        form.setFocus('name');
      }, 0);
    }
  }, [open]);

  return (
    <CustomDialog
      title='Add New Project'
      onClose={onCancel}
      open={open}
      maxWidth='xs'
      fullWidth
      actions={
        <>
          <div />
          <Button
            type='submit'
            form='form'
            variant='contained'
            color='secondary'
            size='large'
            startIcon={<img alt='add' src={PlusFilledIcon} />}
          >
            Add Project
          </Button>
          <div />
        </>
      }
    >
      <form
        id='form'
        onSubmit={form.handleSubmit(() => {
          onSave();
        })}
        noValidate
      >
        <Box>
          <Controller
            name={'name'}
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Please enter the name',
              },
            }}
            render={({ field: { onChange, value, ref }, formState }) => (
              <TextField
                inputRef={ref}
                fullWidth
                label='Name'
                variant='standard'
                error={!!formState.errors.name}
                helperText={formState.errors.name?.message}
                onChange={onChange}
                value={value}
                autoComplete='off'
              />
            )}
          />
        </Box>
        <Box className={classes.mt24}>
          <Controller
            name={'startDate'}
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Please enter the start date',
              },
            }}
            render={({ field: { onChange, value }, formState }) => (
              <CustomDatePicker
                value={value}
                onChange={(newValue) => {
                  onChange({
                    target: {
                      value: newValue ? toStartDate(moment(newValue)) : null,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label='Start Date'
                    variant='standard'
                    error={!!formState.errors.startDate}
                    helperText={formState.errors.startDate?.message}
                    autoComplete='off'
                  />
                )}
              />
            )}
          />
        </Box>
      </form>
    </CustomDialog>
  );
};
