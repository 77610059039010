import { Mapper, createMap } from '@automapper/core';

import { IClientFeature } from '@models/interfaces/entities/IClientFeature';
import { IClientFeatureDto } from '@models/interfaces/dtos/IClientFeatureDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createClientFeatureMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IClientFeatureDto>('IClientFeatureDto', {
    clientId: String,
    featureId: String,
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IClientFeature>('IClientFeature', {
    clientId: String,
    featureId: String,
    links: 'ILinks',
  });

  createMap<IClientFeatureDto, IClientFeature>(mapper, 'IClientFeatureDto', 'IClientFeature');
};
