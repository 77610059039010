import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import { INewTypeCode } from '@models/interfaces/entities/INewTypeCode';
import { INewTypeCodeDto } from '@models/interfaces/dtos/INewTypeCodeDto';
import { ITypeCode } from '@models/interfaces/entities/ITypeCode';
import { ITypeCodeDto } from '@models/interfaces/dtos/ITypeCodeDto';
import { IUpdateNewTypeCodeData } from '@models/interfaces/additional/IUpdateNewTypeCodeData';
import { IUpdateNewTypeCodeRequest } from '@models/interfaces/requests/IUpdateNewTypeCodeRequest';
import TypeCodesProvider from '@providers/TypeCodesProvider';
import mapper from '@models/mapper';

const typeCodesProvider = new TypeCodesProvider();

export const getExistingTypeCodes = async (url: string, categoryId: string, ignored: boolean) => {
  const result = await typeCodesProvider.getExistingTypeCodes(url, categoryId, ignored);
  return {
    ignored: result.ignored,
    items: mapper.mapArray<ITypeCodeDto, ITypeCode>(result.items, 'ITypeCodeDto', 'ITypeCode'),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const getNewTypeCodes = async (url: string, categoryId: string) => {
  const result = await typeCodesProvider.getNewTypeCodes(url, categoryId);
  return {
    items: mapper.mapArray<INewTypeCodeDto, INewTypeCode>(
      result.items,
      'INewTypeCodeDto',
      'INewTypeCode',
    ),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const updateNewTypeCode = async (url: string, data: IUpdateNewTypeCodeData) => {
  const requestData = mapper.map<IUpdateNewTypeCodeData, IUpdateNewTypeCodeRequest>(
    data,
    'IUpdateNewTypeCodeData',
    'IUpdateNewTypeCodeRequest',
  );
  await typeCodesProvider.updateNewTypeCode(url, requestData);
  return true;
};
