import Axios, { AxiosRequestConfig } from 'axios';
import { CacheRequestConfig, setupCache } from 'axios-cache-interceptor';

import { apiConfig } from '@config/apiConfig';
import { store } from '@store';

const axios = setupCache(Axios, {
  methods: ['get'],
  ttl: 3 * 1000, // 3s
});

export default class BaseProvider {
  private getUrl = (url: string) => {
    if (url.includes('https://') || url.includes('http://')) {
      return url;
    }
    return `${apiConfig.webApi}/api${url}`;
  };

  private getAuthToken = () => {
    const state = store.getState();
    const { token } = state.auth;

    return token?.accessToken || '';
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected get = async <T>(url: string, config: CacheRequestConfig<any> = { headers: {} }) => {
    const token = this.getAuthToken();
    const { headers, ...rest } = config;
    const result = await axios.get<T>(this.getUrl(url), {
      ...rest,
      headers: { ...headers, ...(token ? { Authorization: `Bearer ${token}` } : {}) },
    });
    return result.data;
  };

  protected getWithHeaders = async <T>(
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config: CacheRequestConfig<any> = { headers: {} },
  ) => {
    const token = this.getAuthToken();
    const { headers, ...rest } = config;
    const result = await axios.get<T>(this.getUrl(url), {
      ...rest,
      headers: { ...headers, ...(token ? { Authorization: `Bearer ${token}` } : {}) },
    });
    return { data: result.data, headers: result.headers };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected delete = async <T>(url: string, config: AxiosRequestConfig<any> = { headers: {} }) => {
    const token = this.getAuthToken();
    const { headers, ...rest } = config;
    const result = await axios.delete<T>(this.getUrl(url), {
      ...rest,
      headers: { ...headers, ...(token ? { Authorization: `Bearer ${token}` } : {}) },
    });
    return result.data;
  };

  protected post = async <TReq, TRes>(
    url: string,
    data: TReq,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config: AxiosRequestConfig<any> = { headers: {} },
  ) => {
    const token = this.getAuthToken();
    const { headers, ...rest } = config;
    const result = await axios.post<TRes>(this.getUrl(url), data, {
      ...rest,
      headers: { ...headers, ...(token ? { Authorization: `Bearer ${token}` } : {}) },
    });

    return result.data;
  };

  protected put = async <TReq, TRes>(
    url: string,
    data: TReq,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config: AxiosRequestConfig<any> = { headers: {} },
  ) => {
    const token = this.getAuthToken();
    const { headers, ...rest } = config;
    const result = await axios.put<TRes>(this.getUrl(url), data, {
      ...rest,
      headers: { ...headers, ...(token ? { Authorization: `Bearer ${token}` } : {}) },
    });

    return result.data;
  };

  protected patch = async <TReq, TRes>(
    url: string,
    data: TReq,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config: AxiosRequestConfig<any> = { headers: {} },
  ) => {
    const token = this.getAuthToken();
    const { headers, ...rest } = config;
    const result = await axios.patch<TRes>(this.getUrl(url), data, {
      ...rest,
      headers: { ...headers, ...(token ? { Authorization: `Bearer ${token}` } : {}) },
    });

    return result.data;
  };
}
