import { useApi, useAppSelector, useLoader } from '@hooks';
import { useCallback, useEffect } from 'react';

import { Actions } from '@models/enums/Actions';
import { Box } from '@mui/material';
import { GroupView } from './components/GroupView';
import { GroupsMenu } from './components/GroupsMenu';
import { Loader } from '@components/Loader';
import { getGroups } from '@services/api';
import { selectAuthGetGroupsActionLink } from '@reducers/authSlice';
import { useParams } from 'react-router-dom';
import useStyles from './styles';

export const GroupsPage = () => {
  const { classes } = useStyles();
  const getGroupsActionLink = useAppSelector(selectAuthGetGroupsActionLink);
  const { groupId } = useParams();

  const {
    request: getGroupsRequest,
    data: getGroupsData,
    loading: getGroupsLoading,
  } = useApi(getGroups, { items: [], links: {} }, { handleErrors: true });

  useEffect(() => {
    if (getGroupsActionLink) getGroupsRequest(getGroupsActionLink.href);
  }, [getGroupsActionLink]);

  const onChanged = useCallback(() => {
    if (getGroupsActionLink) getGroupsRequest(getGroupsActionLink.href);
  }, [getGroupsActionLink]);

  const showLoader = useLoader(getGroupsLoading);

  return (
    <Box className={classes.container}>
      <GroupsMenu
        onGroupAdded={onChanged}
        groups={getGroupsData.items}
        createGroupActionLink={getGroupsData.links[Actions.createGroup]}
        activeGroupId={groupId}
      />
      {groupId && <GroupView onGroupChanged={onChanged} groupId={groupId} />}
      <Loader show={showLoader} />
    </Box>
  );
};
