import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  firstFormGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 16,
  },
  secondFormGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: 16,
    marginTop: 16,
  },
}));

export default useStyles;
