import BaseProvider from './BaseProvider';
import { IAddUserMembershipRequest } from '@models/interfaces/requests/IAddUserMembershipRequest';
import { IAddUserMembershipResponse } from '@models/interfaces/responses/IAddUserMembershipResponse';
import { ICreateGroupRequest } from '@models/interfaces/requests/ICreateGroupRequest';
import { ICreateGroupResponse } from '@models/interfaces/responses/ICreateGroupResponse';
import { IGetGroupResponse } from '@models/interfaces/responses/IGetGroupResponse';
import { IGetGroupsResponse } from '@models/interfaces/responses/IGetGroupsResponse';
import { IGetUserMembershipsResponse } from '@models/interfaces/responses/IGetUserMembershipsResponse';

export default class GroupsProvider extends BaseProvider {
  public getGroup = (url: string) => {
    return this.get<IGetGroupResponse>(url);
  };

  public getGroups = (url: string) => {
    return this.get<IGetGroupsResponse>(url);
  };

  public createGroup = (url: string, data: ICreateGroupRequest) => {
    return this.post<ICreateGroupRequest, ICreateGroupResponse>(url, data);
  };

  public getUserMemberships = (url: string) => {
    return this.get<IGetUserMembershipsResponse>(url);
  };

  public addUserMembership = (url: string, data: IAddUserMembershipRequest) => {
    return this.post<IAddUserMembershipRequest, IAddUserMembershipResponse>(url, data);
  };

  public deleteGroup = (url: string) => {
    return this.delete<void>(url);
  };
}
