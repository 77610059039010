import GroupsProvider from '@providers/GroupsProvider';
import { IAddUserMembershipData } from '@models/interfaces/additional/IAddUserMembershipData';
import { IAddUserMembershipRequest } from '@models/interfaces/requests/IAddUserMembershipRequest';
import { ICreateGroupData } from '@models/interfaces/additional/ICreateGroupData';
import { ICreateGroupRequest } from '@models/interfaces/requests/ICreateGroupRequest';
import { IGroup } from '@models/interfaces/entities/IGroup';
import { IGroupDto } from '@models/interfaces/dtos/IGroupDto';
import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import mapper from '@models/mapper';

const groupsProvider = new GroupsProvider();

export const getGroup = async (url: string) => {
  const result = await groupsProvider.getGroup(url);
  return mapper.map<IGroupDto, IGroup>(result, 'IGroupDto', 'IGroup');
};

export const getGroups = async (url: string) => {
  const result = await groupsProvider.getGroups(url);
  return {
    items: mapper.mapArray<IGroupDto, IGroup>(result.items, 'IGroupDto', 'IGroup'),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const createGroup = async (url: string, data: ICreateGroupData) => {
  const requestData = mapper.map<ICreateGroupData, ICreateGroupRequest>(
    data,
    'ICreateGroupData',
    'ICreateGroupRequest',
  );
  const result = await groupsProvider.createGroup(url, requestData);
  return mapper.map<IGroupDto, IGroup>(result, 'IGroupDto', 'IGroup');
};

export const getUserMemberships = async (url: string) => {
  const result = await groupsProvider.getUserMemberships(url);
  return mapper.mapArray<IGroupDto, IGroup>(result.items, 'IGroupDto', 'IGroup');
};

export const addUserMembership = async (url: string, data: IAddUserMembershipData) => {
  const requestData = mapper.map<IAddUserMembershipData, IAddUserMembershipRequest>(
    data,
    'IAddUserMembershipData',
    'IAddUserMembershipRequest',
  );
  const result = await groupsProvider.addUserMembership(url, requestData);
  return mapper.mapArray<IGroupDto, IGroup>(result.items, 'IGroupDto', 'IGroup');
};

export const deleteGroup = async (url: string) => {
  await groupsProvider.deleteGroup(url);
  return true;
};
