import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  container: {
    paddingTop: 40,
    paddingLeft: 24,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  mb24: { marginBottom: 24 },
  scrollContainer: {
    overflowY: 'auto',
    paddingBottom: 24,
    paddingRight: 24,

    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: t.palette.custom.dark[67],
      borderRadius: 4,
    },
  },
  controlsSection: {
    borderColor: t.palette.custom.dark[100],
    backgroundColor: t.palette.custom.dark[5],
    borderTopWidth: 2,
    borderBottomWidth: 2,
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: -24,
  },
  dateRangePickerInput: {
    width: 280,
  },
  clientAutocomplete: {
    width: 250,
  },
}));

export default useStyles;
