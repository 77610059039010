import { Mapper, createMap, forMember, mapFrom } from '@automapper/core';

import { IActivityEvent } from '@models/interfaces/entities/IActivityEvent';
import { IActivityEventDto } from '@models/interfaces/dtos/IActivityEventDto';
import { PojosMetadataMap } from '@automapper/pojos';

const updateObjectProps = (obj: object): object => {
  return Object.assign(
    {},
    ...Object.keys(obj).map((k) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const value = (obj as any)[k];
      return {
        [k.charAt(0).toLowerCase() + k.slice(1)]:
          value && typeof value === 'object' ? updateObjectProps(value) : value,
      };
    }),
  );
};

export const createActivityEventMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IActivityEventDto>('IActivityEventDto', {
    id: String,
    name: String,
    initiator: String,
    timestamp: Number,
    serializedData: String,
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IActivityEvent>('IActivityEvent', {
    id: String,
    name: String,
    initiator: String,
    timestamp: Date,
    data: Object,
    links: 'ILinks',
  });

  createMap<IActivityEventDto, IActivityEvent>(
    mapper,
    'IActivityEventDto',
    'IActivityEvent',
    forMember(
      (destination) => destination.timestamp,
      mapFrom((source) => new Date(source.timestamp)),
    ),
    forMember(
      (destination) => destination.data,
      mapFrom((source) => {
        const original = JSON.parse(source.serializedData);
        const updated = updateObjectProps(original);
        return updated;
      }),
    ),
  );
};
