import { Box, Typography } from '@mui/material';

import { IProduct } from '@models/interfaces/entities/IProduct';
import NoImagePlaceholder from '@assets/no-image.png';
import useStyles from './styles';

export interface IProps {
  id: string;
  product: IProduct;
  onSelected: () => void;
}

export const ProductCard = ({ id, product, onSelected }: IProps) => {
  const { classes } = useStyles();

  return (
    <Box id={id} className={classes.root} onClick={() => onSelected()}>
      <Box className={classes.imageContainer}>
        <img
          className={classes.image}
          alt={`${product.id}-image`}
          src={product.image || NoImagePlaceholder}
          data-testid='product-image'
        />
      </Box>
      <Typography variant='subtitle1'>{product.name}</Typography>
    </Box>
  );
};
