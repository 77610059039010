import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ collapsed: boolean }>()((t, { collapsed }) => ({
  button: {
    minWidth: collapsed ? 'auto' : 200,
    height: 54,
    paddingLeft: 0,
    paddingRight: 24,
    alignItems: 'center',
    '.Mui-selected&': {
      backgroundColor: 'transparent',
    },
  },
  marker: {
    marginRight: 16,
    width: 4,
    height: 20,
    '.Mui-selected &': { backgroundColor: t.palette.custom.dark[87] },
  },
  defaultIcon: {
    width: 16,
    height: 16,
    minWidth: 'auto',
    display: 'inline-block',
    '.Mui-selected &': { display: 'none' },
  },
  activeIcon: {
    width: 16,
    height: 16,
    minWidth: 'auto',
    display: 'none',
    '.Mui-selected &': { display: 'inline-block' },
  },
  textContainer: {
    paddingLeft: collapsed ? 0 : 12,
    visibility: collapsed ? 'collapse' : 'visible',
    width: collapsed ? 0 : 'auto',
    transition: t.transitions.create(['padding', 'visibility', 'width'], {
      easing: t.transitions.easing.sharp,
      duration: t.transitions.duration.enteringScreen,
    }),
  },
  text: {
    color: t.palette.custom.dark[60],
    '.Mui-selected &': { color: t.palette.custom.dark[87] },
  },
}));

export default useStyles;
