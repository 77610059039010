import BaseProvider from './BaseProvider';
import { ICreateFileCategoryRequest } from '@models/interfaces/requests/ICreateFileCategoryRequest';
import { ICreateFileCategoryResponse } from '@models/interfaces/responses/ICreateFileCategoryResponse';
import { IGetFileCategoriesResponse } from '@models/interfaces/responses/IGetFileCategoriesResponse';
import { IGetFileCategoryResponse } from '@models/interfaces/responses/IGetFileCategoryResponse';
import { IUpdateFileCategoriesOrderRequest } from '@models/interfaces/requests/IUpdateFileCategoriesOrderRequest';
import { IUpdateFileCategoriesOrderResponse } from '@models/interfaces/responses/IUpdateFileCategoriesOrderResponse';
import { IUpdateFileCategoryRequest } from '@models/interfaces/requests/IUpdateFileCategoryRequest';
import { IUpdateFileCategoryResponse } from '@models/interfaces/responses/IUpdateFileCategoryResponse';

export default class FileCategoriesProvider extends BaseProvider {
  public getFileCategories = (url: string) => {
    return this.get<IGetFileCategoriesResponse>(url);
  };

  public getFileCategory = (url: string) => {
    return this.get<IGetFileCategoryResponse>(url);
  };

  public createFileCategory = (url: string, data: ICreateFileCategoryRequest) => {
    return this.post<ICreateFileCategoryRequest, ICreateFileCategoryResponse>(url, data);
  };

  public deleteFileCategory = (url: string) => {
    return this.delete<void>(url);
  };

  public updateFileCategory = (url: string, data: IUpdateFileCategoryRequest) => {
    return this.patch<IUpdateFileCategoryRequest, IUpdateFileCategoryResponse>(url, data);
  };

  public updateFileCategoriesOrder = (url: string, data: IUpdateFileCategoriesOrderRequest) => {
    return this.patch<IUpdateFileCategoriesOrderRequest, IUpdateFileCategoriesOrderResponse>(
      url,
      data,
    );
  };
}
