import BaseProvider from './BaseProvider';
import { ICloneFileSubCategoryMappingResponse } from '@models/interfaces/responses/ICloneFileSubCategoryMappingResponse';
import { ICreateFileSubCategoryMappingRequest } from '@models/interfaces/requests/ICreateFileSubCategoryMappingRequest';
import { ICreateFileSubCategoryMappingResponse } from '@models/interfaces/responses/ICreateFileSubCategoryMappingResponse';
import { IGetFileSubCategoryMappingResponse } from '@models/interfaces/responses/IGetFileSubCategoryMappingResponse';
import { IGetFileSubCategoryMappingsResponse } from '@models/interfaces/responses/IGetFileSubCategoryMappingsResponse';
import { IUpdateFileSubCategoryMappingRequest } from '@models/interfaces/requests/IUpdateFileSubCategoryMappingRequest';
import { IUpdateFileSubCategoryMappingResponse } from '@models/interfaces/responses/IUpdateFileSubCategoryMappingResponse';

export default class FileSubCategoryMappingsProvider extends BaseProvider {
  public getFileSubCategoryMappings = (url: string) => {
    return this.get<IGetFileSubCategoryMappingsResponse>(url);
  };

  public getFileSubCategoryMapping = (url: string) => {
    return this.get<IGetFileSubCategoryMappingResponse>(url);
  };

  public createFileSubCategoryMapping = (
    url: string,
    data: ICreateFileSubCategoryMappingRequest,
  ) => {
    return this.post<ICreateFileSubCategoryMappingRequest, ICreateFileSubCategoryMappingResponse>(
      url,
      data,
    );
  };

  public updateFileSubCategoryMapping = (
    url: string,
    data: IUpdateFileSubCategoryMappingRequest,
  ) => {
    return this.patch<IUpdateFileSubCategoryMappingRequest, IUpdateFileSubCategoryMappingResponse>(
      url,
      data,
    );
  };

  public cloneFileSubCategoryMapping = (url: string) => {
    return this.post<null, ICloneFileSubCategoryMappingResponse>(url, null);
  };
}
