import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  container: {
    height: 'calc(100vh - 221px)',
    maxHeight: 500,

    '& .Mui-error': {
      backgroundColor: t.palette.custom.error[10],
      color: t.palette.custom.error[100],
    },

    '& button.MuiIconButton-root.Mui-disabled': {
      filter: 'grayscale(1)',
      cursor: 'not-allowed',
    },
  },
}));

export default useStyles;
