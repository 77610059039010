import { Box, Button } from '@mui/material';
import { useApi, useLoader } from '@hooks';

import { Loader } from '@components/Loader';
import MaintenancePlaceholder from '@assets/maintenance.png';
import { getAppInfo } from '@services/api';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';

export const MaintenancePage = () => {
  const { classes } = useStyles();

  const { request: getAppInfoRequest, loading } = useApi(getAppInfo, null, {
    onCallback: () => {
      navigate('/');
    },
  });

  const navigate = useNavigate();

  const onGoHome = () => {
    getAppInfoRequest();
  };

  const showLoader = useLoader(loading);

  return (
    <Box className={classes.container}>
      <img className={classes.image} src={MaintenancePlaceholder} />
      <Button color='secondary' variant='outlined' className='wide' onClick={onGoHome}>
        Reload
      </Button>
      <Loader show={showLoader} />
    </Box>
  );
};
