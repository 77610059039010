import { persistor, store } from './store';

import App from './App';
import { Loader } from '@components/Loader';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { Provider } from 'react-redux';
import { PublicClientApplication } from '@azure/msal-browser';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { msalConfig } from './config/authConfig';
import reportWebVitals from './reportWebVitals';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={<Loader show />} persistor={persistor}>
      <App msalInstance={msalInstance} />
    </PersistGate>
  </Provider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
