import { Mapper, createMap } from '@automapper/core';

import { IClient } from '@models/interfaces/entities/IClient';
import { IClientDto } from '@models/interfaces/dtos/IClientDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createClientMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IClientDto>('IClientDto', {
    id: String,
    name: String,
    accountNumber: String,
    externalId: String,
    addressState: String,
    institutionType: String,
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IClient>('IClient', {
    id: String,
    name: String,
    accountNumber: String,
    externalId: String,
    addressState: String,
    institutionType: String,
    links: 'ILinks',
  });

  createMap<IClientDto, IClient>(mapper, 'IClientDto', 'IClient');
};
