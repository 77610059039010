import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid-pro';
import {
  createFileCategoryField,
  deleteFileCategoryField,
  getFileCategoryFields,
  updateFileCategoryField,
} from '@services/api/fileCategoryFields';
import { useApi, useLoader } from '@hooks';
import { useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { Box } from '@mui/material';
import { CustomDialog } from '@components/CustomDialog';
import EditFilledIcon from '@assets/icons/item-view/edit.svg';
import { FieldsGridToolbar } from '../FieldsGridToolbar';
import { FileCategoryFieldType } from '@models/enums/FileCategoryFieldType';
import { IFileCategory } from '@models/interfaces/entities/IFileCategory';
import { IFileCategoryField } from '@models/interfaces/entities/IFileCategoryField';
import { ILink } from '@models/interfaces/entities/ILink';
import { Loader } from '@components/Loader';
import SaveFilledIcon from '@assets/icons/item-view/save-filled.svg';
import TrashFilledIcon from '@assets/icons/item-view/trash-filled.svg';
import XCircleFilledIcon from '@assets/icons/item-view/x-circle-filled.svg';
import { toast } from 'react-toastify';
import useStyles from './styles';

interface IProps {
  category: IFileCategory;
  open: boolean;
  onClose: () => void;
}

export const ManageFileCategoryFieldsDialog = ({ category, open, onClose }: IProps) => {
  const { classes } = useStyles();

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [fileCategoryFields, setFileCategoryFields] = useState<IFileCategoryField[]>([]);
  const [rows, setRows] = useState<IFileCategoryField[]>([]);

  const {
    request: getFileCategoryFieldsRequest,
    data: getFileCategoryFieldsData,
    loading: getFileCategoryFieldsLoading,
  } = useApi(getFileCategoryFields, null, { handleErrors: true });

  const {
    request: createFileCategoryFieldRequest,
    data: createFileCategoryFieldData,
    loading: createFileCategoryFieldLoading,
  } = useApi(createFileCategoryField, null, { handleErrors: true });

  const {
    request: updateFileCategoryFieldRequest,
    data: updateFileCategoryFieldData,
    loading: updateFileCategoryFieldLoading,
  } = useApi(updateFileCategoryField, null, { handleErrors: true });

  const { request: deleteFileCategoryFieldRequest, loading: deleteFileCategoryFieldLoading } =
    useApi(
      async (id: string, link: ILink) => {
        await deleteFileCategoryField(link.href);
        setFileCategoryFields((data) => data.filter((x) => x.id !== id));
        setRows((data) => data.filter((x) => x.id !== id));
      },
      null,
      { handleErrors: true },
    );

  const onCancel = () => {
    onClose();
  };

  useEffect(() => {
    if (open && category.links[Actions.getFields]) {
      getFileCategoryFieldsRequest(category.links[Actions.getFields].href);
    }
    if (!open) {
      setFileCategoryFields([]);
      setRows([]);
      setRowModesModel({});
    }
  }, [category, open]);

  useEffect(() => {
    if (getFileCategoryFieldsData) {
      setFileCategoryFields(getFileCategoryFieldsData.items);
      setRows(getFileCategoryFieldsData.items);
    }
  }, [getFileCategoryFieldsData]);

  useEffect(() => {
    if (createFileCategoryFieldData) {
      setFileCategoryFields((data) => [...data, createFileCategoryFieldData]);
      setRows((data) => [...data.slice(0, data.length - 1), createFileCategoryFieldData]);
    }
  }, [createFileCategoryFieldData]);

  useEffect(() => {
    if (updateFileCategoryFieldData) {
      setFileCategoryFields((data) =>
        data.map((d) =>
          d.id !== updateFileCategoryFieldData.id ? d : updateFileCategoryFieldData,
        ),
      );
      setRows((data) =>
        data.map((d) =>
          d.id !== updateFileCategoryFieldData.id ? d : updateFileCategoryFieldData,
        ),
      );
    }
  }, [updateFileCategoryFieldData]);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (item: IFileCategoryField) => () => {
    setRowModesModel((value) => ({ ...value, [item.id]: { mode: GridRowModes.Edit } }));
  };

  const handleSaveClick = (item: IFileCategoryField) => () => {
    setRowModesModel((value) => {
      return { ...value, [item.id]: { mode: GridRowModes.View } };
    });
  };

  const handleDeleteClick = (item: IFileCategoryField) => () => {
    if (item.links[Actions.delete]) {
      deleteFileCategoryFieldRequest(item.id, item.links[Actions.delete]);
    } else {
      setRows(rows.filter((row) => row.id !== item.id));
    }
  };

  const handleCancelClick = (item: IFileCategoryField) => () => {
    setRows(rows.filter((row) => row.id !== item.id));
  };

  const processRowUpdate = (updatedRow: IFileCategoryField) => {
    const field = fileCategoryFields.find((x) => x.id === updatedRow.id);
    const { name, displayName, type, required } = updatedRow;
    if (!name) {
      toast.error('Field name should be specified');
      setTimeout(() => {
        setRowModesModel((value) => {
          return { ...value, [updatedRow.id]: { mode: GridRowModes.Edit } };
        });
      }, 500);
      return updatedRow;
    }
    if (field) {
      if (field.links[Actions.update]) {
        updateFileCategoryFieldRequest(field.links[Actions.update].href, {
          name,
          displayName,
          type,
          required,
          position: 1,
        });
      }
    } else {
      if (getFileCategoryFieldsData?.links[Actions.createField]) {
        createFileCategoryFieldRequest(getFileCategoryFieldsData.links[Actions.createField].href, {
          name,
          displayName,
          type,
          required,
          position: 1,
        });
      }
    }
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        width: 200,
        editable: true,
      },
      {
        field: 'displayName',
        headerName: 'Display Name',
        width: 200,
        editable: true,
      },
      {
        field: 'type',
        headerName: 'Type',
        width: 200,
        editable: true,
        type: 'singleSelect',
        valueOptions: [
          FileCategoryFieldType.string,
          FileCategoryFieldType.integer,
          FileCategoryFieldType.decimal,
          FileCategoryFieldType.date,
        ],
      },
      {
        field: 'required',
        headerName: 'Is required',
        width: 100,
        editable: true,
        type: 'boolean',
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        cellClassName: 'actions',
        getActions: ({ row }: { row: IFileCategoryField }) => {
          const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                key={`${row.id}-save`}
                icon={<img src={SaveFilledIcon} alt='Save' />}
                label='Save'
                onClick={handleSaveClick(row)}
              />,
              <GridActionsCellItem
                key={`${row.id}-cancel`}
                icon={<img src={XCircleFilledIcon} alt='Cancel' />}
                label='Cancel'
                onClick={handleCancelClick(row)}
              />,
            ];
          }

          return [
            <GridActionsCellItem
              key={`${row.id}-edit`}
              icon={<img src={EditFilledIcon} alt='Edit' />}
              label='Edit'
              onClick={handleEditClick(row)}
            />,
            <GridActionsCellItem
              disabled={!row.links || !row.links[Actions.delete]}
              key={`${row.id}-delete`}
              icon={<img src={TrashFilledIcon} alt='Delete' />}
              label='Delete'
              onClick={handleDeleteClick(row)}
            />,
          ];
        },
      },
    ],
    [rowModesModel],
  );

  const showLoader = useLoader(
    getFileCategoryFieldsLoading,
    createFileCategoryFieldLoading,
    updateFileCategoryFieldLoading,
    deleteFileCategoryFieldLoading,
  );

  return (
    <CustomDialog
      title='Manage fields'
      onClose={onCancel}
      open={open}
      maxWidth='md'
      fullWidth
      actions={<></>}
    >
      <Box className={classes.container}>
        <DataGridPro
          rows={rows}
          columns={columns}
          editMode='row'
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: FieldsGridToolbar,
          }}
          slotProps={{
            toolbar: {
              setRows,
              setRowModesModel,
              disabled:
                !getFileCategoryFieldsData?.links[Actions.createField] ||
                rows.length !== fileCategoryFields.length,
            },
          }}
        />
      </Box>
      <Loader show={showLoader} fixed={false} />
    </CustomDialog>
  );
};
