import { Button, Typography } from '@mui/material';

import { Box } from '@mui/system';
import { Dropdown } from '@components/Dropdown';
import { Roles } from '@models/enums/Roles';
import { useMemo } from 'react';
import useStyles from './styles';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
  item: {
    groupName: string;
    role: Roles;
  };
  onRoleChanged: (role: Roles) => void;
  onRemove: () => void;
}

export const AccessItemCard = ({ item, onRoleChanged, onRemove }: IProps) => {
  const { classes } = useStyles();

  const roleOptions = useMemo(
    () => [
      {
        value: Roles.clientManager,
        label: 'Client Manager',
      },
      {
        value: Roles.clientUser,
        label: 'Client User',
      },
      {
        value: Roles.projectManager,
        label: 'Project Manager',
      },
    ],
    [],
  );

  return (
    <Box key={uuidv4()} className={classes.root}>
      <Typography variant='subtitle1'>{item.groupName}</Typography>
      <Box className={classes.controls}>
        <Dropdown
          value={item.role}
          onChanged={(value) => onRoleChanged(value as Roles)}
          options={roleOptions}
        />
        <Button
          className={classes.removeControl}
          variant='text'
          color='error'
          size='small'
          onClick={() => onRemove()}
        >
          Remove
        </Button>
      </Box>
    </Box>
  );
};
