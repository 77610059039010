import { Mapper, createMap } from '@automapper/core';

import { IAppInfo } from '@models/interfaces/entities/IAppInfo';
import { IAppInfoDto } from '@models/interfaces/dtos/IAppInfoDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createAppInfoMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IAppInfoDto>('IAppInfoDto', {
    id: String,
    name: String,
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IAppInfo>('IAppInfo', {
    id: String,
    name: String,
    links: 'ILinks',
  });

  createMap<IAppInfoDto, IAppInfo>(mapper, 'IAppInfoDto', 'IAppInfo');
};
