import BaseProvider from './BaseProvider';
import { IAddGroupMemberRequest } from '@models/interfaces/requests/IAddGroupMemberRequest';
import { IAddGroupMemberResponse } from '@models/interfaces/responses/IAddGroupMemberResponse';
import { ICreateUserRequest } from '@models/interfaces/requests/ICreateUserRequest';
import { ICreateUserResponse } from '@models/interfaces/responses/ICreateUserResponse';
import { IGetClientUsersResponse } from '@models/interfaces/responses/IGetClientUsersResponse';
import { IGetGroupMembersResponse } from '@models/interfaces/responses/IGetGroupMembersResponse';
import { IGetUserResponse } from '@models/interfaces/responses/IGetUserResponse';
import { IGetUsersResponse } from '@models/interfaces/responses/IGetUsersResponse';
import { IRemoveGroupMemberResponse } from '@models/interfaces/responses/IRemoveGroupMemberResponse';

export default class UsersProvider extends BaseProvider {
  public getUsers = (url: string) => {
    return this.get<IGetUsersResponse>(url);
  };

  public createUser = (url: string, data: ICreateUserRequest) => {
    return this.post<ICreateUserRequest, ICreateUserResponse>(url, data);
  };

  public getUser = (url: string) => {
    return this.get<IGetUserResponse>(url);
  };

  public deleteUser = (url: string) => {
    return this.delete<void>(url);
  };

  public getGroupMembers = (url: string) => {
    return this.get<IGetGroupMembersResponse>(url);
  };

  public addGroupMember = (url: string, data: IAddGroupMemberRequest) => {
    return this.post<IAddGroupMemberRequest, IAddGroupMemberResponse>(url, data);
  };

  public removeGroupMember = (url: string) => {
    return this.delete<IRemoveGroupMemberResponse>(url);
  };

  public getClientUsers = (url: string) => {
    return this.get<IGetClientUsersResponse>(url);
  };
}
