import { createAccountMapping } from './AccountMapping';
import { createActivityEventMapping } from './ActivityEventMapping';
import { createAppInfoMapping } from './AppInfoMapping';
import { createBalanceMapping } from './BalanceMapping';
import { createClientFeatureMapping } from './ClientFeatureMapping';
import { createClientMapping } from './ClientMapping';
import { createClientProductMapping } from './ClientProductMapping';
import { createFeatureMapping } from './FeatureMapping';
import { createFileCategoryFieldMapping } from './FileCategoryFieldMapping';
import { createFileCategoryMapping } from './FileCategoryMapping';
import { createFileContentMapping } from './FileContentMapping';
import { createFileMapping } from './FileMapping';
import { createFileSubCategoryMapping } from './FileSubCategoryMapping';
import { createFileSubCategoryMappingMapping } from './FileSubCategoryMappingMapping';
import { createFileSubCategoryTransformationScriptMapping } from './FileSubCategoryTransformationScriptMapping';
import { createGroupMapping } from './GroupMapping';
import { createImportAccountsMapping } from './ImportAccountsMapping';
import { createImportSubAccountsMapping } from './ImportSubAccountsMapping';
import { createLinksMapping } from './LinksMapping';
import { createMapper } from '@automapper/core';
import { createPiiIncidentCategoryMapping } from './PiiIncidentCategoryMapping';
import { createPiiIncidentDetailsPageMapping } from './PiiIncidentDetailsPageMapping';
import { createPiiIncidentMapping } from './PiiIncidentMapping';
import { createPiiScanResultMapping } from './PiiScanResultMapping';
import { createProductMapping } from './ProductMapping';
import { createProjectMapping } from './ProjectMapping';
import { createRequestMapping } from './RequestMapping';
import { createRoleAssignmentMapping } from './RoleAssignmentMapping';
import { createStandardPiiIncidentCategoryMapping } from './StandardPiiIncidentCategoryMapping';
import { createTypeCodeMapping } from './TypeCodeMapping';
import { createUserMapping } from './UserMapping';
import { createWhitelistRecordMapping } from './WhitelistRecordMapping';
import { pojos } from '@automapper/pojos';

const mapper = createMapper({ strategyInitializer: pojos() });

createLinksMapping(mapper);
createUserMapping(mapper);
createGroupMapping(mapper);
createProductMapping(mapper);
createClientMapping(mapper);
createFileMapping(mapper);
createAppInfoMapping(mapper);
createRequestMapping(mapper);
createPiiIncidentMapping(mapper);
createPiiScanResultMapping(mapper);
createFileContentMapping(mapper);
createClientProductMapping(mapper);
createRoleAssignmentMapping(mapper);
createProjectMapping(mapper);
createPiiIncidentCategoryMapping(mapper);
createPiiIncidentDetailsPageMapping(mapper);
createStandardPiiIncidentCategoryMapping(mapper);
createActivityEventMapping(mapper);
createFileCategoryMapping(mapper);
createFileSubCategoryMapping(mapper);
createFileCategoryFieldMapping(mapper);
createFileSubCategoryMappingMapping(mapper);
createFileSubCategoryTransformationScriptMapping(mapper);
createFeatureMapping(mapper);
createClientFeatureMapping(mapper);
createWhitelistRecordMapping(mapper);
createAccountMapping(mapper);
createImportAccountsMapping(mapper);
createImportSubAccountsMapping(mapper);
createBalanceMapping(mapper);
createTypeCodeMapping(mapper);

export default mapper;
