import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
    borderRadius: 10,
    backgroundColor: '#fff',
  },
  content: {
    gap: 8,
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
