import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

import { AlertContainer } from './AlertContainer';
import { AppNavBar } from './AppNavBar';
import { AuthenticatedContainer } from './AuthenticatedContainer';
import { Box } from '@mui/material';
import { GuideTourHelpButton } from './GuideTourHelpButton';
import { IssuesDrawer } from './IssuesDrawer';
import { Loader } from './Loader';
import { NewFilesDialog } from './NewFilesDialog';
import { Outlet } from 'react-router-dom';
import { UnauthenticatedContainer } from './UnauthenticatedContainer';

export const PageLayout = () => {
  return (
    <>
      <UnauthenticatedTemplate>
        <UnauthenticatedContainer>
          <AppNavBar />
          <Loader fixed show />
        </UnauthenticatedContainer>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <AuthenticatedContainer>
          <Box
            sx={{
              display: 'flex',
              height: '100vh',
              flexFlow: 'column',
              overflowX: 'hidden',
              overflowY: 'hidden',
            }}
          >
            <AppNavBar authenticated />
            <Box
              component='main'
              sx={{
                flexGrow: 1,
                overflow: 'hidden',
              }}
            >
              <Outlet />
            </Box>

            <IssuesDrawer />
            <NewFilesDialog />
            <AlertContainer />
            <GuideTourHelpButton />
          </Box>
        </AuthenticatedContainer>
      </AuthenticatedTemplate>
    </>
  );
};
