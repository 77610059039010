import { Mapper, createMap } from '@automapper/core';

import { IFeature } from '@models/interfaces/entities/IFeature';
import { IFeatureDto } from '@models/interfaces/dtos/IFeatureDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createFeatureMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IFeatureDto>('IFeatureDto', {
    id: String,
    name: String,
    description: String,
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IFeature>('IFeature', {
    id: String,
    name: String,
    description: String,
    links: 'ILinks',
  });

  createMap<IFeatureDto, IFeature>(mapper, 'IFeatureDto', 'IFeature');
};
