import 'react-toastify/dist/ReactToastify.css';

import { Box } from '@mui/material';
import { CloseButton } from './components/CloseButton';
import { ToastContainer } from 'react-toastify';
import useStyles from './styles';

interface IProps {
  autoClose: number;
}

export const AlertContainer = ({ autoClose }: IProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <ToastContainer
        position='top-right'
        autoClose={autoClose}
        hideProgressBar
        newestOnTop
        pauseOnHover
        closeOnClick
        closeButton={CloseButton}
      />
    </Box>
  );
};

AlertContainer.defaultProps = {
  autoClose: 30000,
};
