import { Accept, useDropzone } from 'react-dropzone';
import { Box, Button, Tooltip } from '@mui/material';
import {
  IJsonGLSubAccountsData,
  IJsonGLSubAccountsDataSchema,
} from '@models/interfaces/additional/IJsonGLSubAccountsData';
import {
  IUpdateSubAccountsData,
  IUpdateSubAccountsDataSubAccount,
  IUpdateSubAccountsDataSubAccountTierLimit,
} from '@models/interfaces/additional/IUpdateSubAccountsData';
import { useApi, useLoader } from '@hooks';

import { Actions } from '@models/enums/Actions';
import { IProject } from '@models/interfaces/entities/IProject';
import { Loader } from '@components/Loader';
import Papa from 'papaparse';
import { PathReporter } from 'io-ts/PathReporter';
import { SubAccountCategory } from '@models/enums/SubAccountCategory';
import UploadIcon from '@assets/icons/dashboard/upload.svg';
import mapper from '@models/mapper';
import { toast } from 'react-toastify';
import { updateSubAccounts } from '@services/api';
import { useCallback } from 'react';

interface IProps {
  id?: string;
  project: IProject;
  maxFileSize: number;
  accept: Accept | undefined;
}

export const SubAccountsImporter = ({ project, maxFileSize, accept }: IProps) => {
  const extractGLSubAccountsData = async (file: File) => {
    const fileContent = await file.text();

    try {
      const jsonData = JSON.parse(fileContent);
      const jsonDataRoot = jsonData.value0;

      const result = IJsonGLSubAccountsDataSchema.decode(jsonDataRoot);

      if (result._tag === 'Right') {
        const resultData: IJsonGLSubAccountsData = result.right;
        return resultData;
      } else {
        console.error('Validation errors:', PathReporter.report(result));
        throw new Error(
          'Failed to parse the General Ledger subaccounts file because of invalid structure.',
        );
      }
    } catch (error) {
      console.error('Error parsing JSON file:', error);
      throw new Error('Failed to parse the General Ledger subaccounts file.');
    }
  };

  const readCSVFile = useCallback(async (file: File) => {
    return new Promise<string[][]>((resolve, reject) => {
      Papa.parse<string[]>(file, {
        delimiter: ',',
        worker: true,
        error() {
          reject('Failed to parse the file');
        },
        complete({ data }) {
          resolve(data);
        },
      });
    });
  }, []);

  const extractCategoryFromFileName = useCallback((fileName: string) => {
    const match = fileName.match(/CMSubBud(\w+)\.dat$/);
    const category = match ? match[1] : '';
    switch (category) {
      case 'BR':
        return SubAccountCategory.borrowings;
      case 'CD':
        return SubAccountCategory.termDeposits;
      case 'IN':
        return SubAccountCategory.investments;
      case 'LN':
        return SubAccountCategory.loans;
      case 'SH':
        return SubAccountCategory.nonMaturityDeposits;
      case 'GL':
        return SubAccountCategory.generalLedger;
      default:
        return SubAccountCategory.unknown;
    }
  }, []);

  const extractOtherSubAccountsData = async (file: File, category: SubAccountCategory) => {
    try {
      const data = await readCSVFile(file);

      const subAccounts: IUpdateSubAccountsDataSubAccount[] = [];

      for (const row of data) {
        if (row.length < 10) {
          continue;
        }

        const token = row[0];
        const subAcctId = row[1];
        const accountType = Number(row[2]);

        let index = 3;
        const tierLimits: IUpdateSubAccountsDataSubAccountTierLimit[] = [];
        while (row[index] !== '' || row[index + 1] !== '0' || row[index + 2] !== '0') {
          if (!row[index] && !row[index + 1] && !row[index + 2]) {
            throw Error('Detected row with incorrect format');
          }

          tierLimits.push({
            name: row[index],
            lower: Number(row[index + 1]),
            upper: Number(row[index + 2]),
          });
          index += 3;
        }

        const subAcctDesc = row[index + 3];
        const downloadId = row[index + 4];
        const subAccountIdFormat = row[index + 5];
        const accountName = row[index + 6];

        const ignored = downloadId.toUpperCase() === 'IGNORE';

        subAccounts.push({
          token,
          subAcctId,
          accountType,
          tierLimits,
          subAcctDesc,
          downloadId: ignored ? '' : downloadId,
          subAccountIdFormat,
          accountName,
          category,
          ignored,
        });
      }
      return subAccounts;
    } catch (error) {
      console.error('Error parsing DAT file:', error);
      throw new Error(`Failed to parse the ${category} subaccounts file.`);
    }
  };

  const { request: updateSubAccountsRequest, loading: updateSubAccountsLoading } = useApi(
    updateSubAccounts,
    null,
    {
      handleErrors: true,
      onCallback: () => {
        toast.info('Successfully imported the sub accounts');
      },
    },
  );

  const onDropFiles = async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length || !project.links[Actions.importSubAccounts]?.href) return;

    try {
      let otherSubAccounts: IUpdateSubAccountsDataSubAccount[] = [];
      let glSubAccounts: IJsonGLSubAccountsData | undefined = undefined;

      for (const file of acceptedFiles) {
        const category = extractCategoryFromFileName(file.name);
        if (category === SubAccountCategory.unknown) continue;

        if (category === SubAccountCategory.generalLedger) {
          glSubAccounts = await extractGLSubAccountsData(file);
        } else {
          const categorySubAccounts = await extractOtherSubAccountsData(file, category);
          otherSubAccounts = [...otherSubAccounts, ...categorySubAccounts];
        }
      }

      if (!glSubAccounts) throw new Error('General Ledger subaccounts are missing');

      const updateSubAccountsData = mapper.map<IJsonGLSubAccountsData, IUpdateSubAccountsData>(
        glSubAccounts,
        'IJsonGLSubAccountsData',
        'IUpdateSubAccountsData',
      );
      updateSubAccountsData.SubAccounts = otherSubAccounts;

      updateSubAccountsRequest(
        project.links[Actions.importSubAccounts].href,
        updateSubAccountsData,
      );
    } catch (error) {
      toast.error(`Failed to extract subaccounts data! ${error}`);
    }
  };

  const dropzone = useDropzone({
    accept,
    multiple: true,
    noDragEventsBubbling: true,
    preventDropOnDocument: false,
    onDrop: onDropFiles,
    onDropRejected(fr) {
      toast.info(
        `File skipped due to ${
          fr[0]?.errors[0] ? `error: ${fr[0]?.errors[0].message}` : 'unknown error'
        }`,
      );
    },
    maxSize: maxFileSize * 1024 * 1024,
  });

  const onUploadButtonClicked = () => {
    dropzone.open();
  };

  const showLoader = useLoader(updateSubAccountsLoading);

  return (
    <>
      {project.links[Actions.importSubAccounts]?.href && (
        <>
          <Tooltip title='Import subaccounts'>
            <Button
              onClick={onUploadButtonClicked}
              variant='outlined'
              size='small'
              color='info'
              startIcon={<img alt='upload' src={UploadIcon} />}
            >
              Import subaccounts
            </Button>
          </Tooltip>
          <Box {...dropzone.getRootProps()}>
            <input data-testid='dropzone-input' {...dropzone.getInputProps()} />
          </Box>
        </>
      )}
      <Loader show={showLoader} />
    </>
  );
};

SubAccountsImporter.defaultProps = {
  maxFileSize: 10,
  accept: {
    'text/json': ['.dat'],
  },
};
