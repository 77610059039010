import 'react-multi-carousel/lib/styles.css';

import { Box, Container, Typography } from '@mui/material';
import { getClient, getClientProducts } from '@services/api';
import { useApi, useAppSelector, useLoader, useUpdateEffect } from '@hooks';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Actions } from '@models/enums/Actions';
import BeaconSample from '@assets/beacon-sample.png';
import Carousel from 'react-multi-carousel';
import { DashboardBreadcrumbs } from '@components/DashboardBreadcrumbs';
import { ExternalResources } from '@components/ExternalResources';
import { GuideTour } from '@components/GuideTour';
import { IProduct } from '@models/interfaces/entities/IProduct';
import { IssuesAlert } from '@components/IssuesAlert';
import { Loader } from '@components/Loader';
import { ProductCard } from './components/ProductCard';
import { Step } from 'react-joyride';
import clsx from 'clsx';
import { selectAuthProducts } from '@reducers/authSlice';
import useStyles from './styles';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 900 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 900, min: 600 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};

export const ProductsPage = () => {
  const { classes } = useStyles();
  const { clientId } = useParams();
  const navigate = useNavigate();
  const products = useAppSelector(selectAuthProducts);

  const [clientProducts, setClientProducts] = useState<(IProduct & { isEnabled: boolean })[]>([]);

  const {
    request: getClientRequest,
    data: getClientData,
    loading: getClientLoading,
  } = useApi(getClient, null, { handleErrors: true });

  const {
    request: getClientProductsRequest,
    data: getClientProductsData,
    loading: getClientProductsLoading,
  } = useApi(getClientProducts, null, { handleErrors: true });

  useEffect(() => {
    if (clientId) getClientRequest(`/clients/${clientId}`);
  }, [clientId]);

  useUpdateEffect(() => {
    if (getClientData?.links[Actions.getProducts]?.href) {
      getClientProductsRequest(getClientData?.links[Actions.getProducts]?.href);
    }
  }, [getClientData]);

  useUpdateEffect(() => {
    if (getClientProductsData) {
      setClientProducts(
        products.map((p) => ({
          ...p,
          isEnabled: getClientProductsData.findIndex((x) => p.id === x.productId) !== -1,
        })),
      );
    }
  }, [getClientProductsData]);

  const onClientProductSelected = (item: IProduct & { isEnabled: boolean }) => {
    if (!item.isEnabled) return;
    navigate(`/clients/${clientId}/products/${item.id}`);
  };

  const onCarouselProductSelected = (item: IProduct) => {
    if (!item.url) return;
    window.open(item.url, '_blank');
  };

  const activeItems = useMemo(() => {
    const activeClientProducts = clientProducts.filter((x) => x.isEnabled);
    return activeClientProducts;
  }, [clientProducts]);

  const inactiveItems = useMemo(() => {
    const activeClientProducts = clientProducts.filter((x) => !x.isEnabled);
    return activeClientProducts;
  }, [clientProducts]);

  const showLoader = useLoader(getClientLoading, getClientProductsLoading);

  const tourSteps = useMemo<Step[]>(
    () =>
      [
        {
          // eslint-disable-next-line quotes
          target: `[data-tour='client']`,
          content:
            'Welcome to the c. myers Client Portal. We have created several tutorials to guide you through the process of providing your data. Click “Next” to get started.',
          placement: 'center',
          isFixed: true,
        },
        {
          // eslint-disable-next-line quotes
          target: `[data-tour='client']`,
          content: (
            <Box>
              <Box>
                Whenever you see an indicator like this, it means that a tutorial is available.
                Click on the indicator to launch the tutorial.
              </Box>
              <img src={BeaconSample} className={classes.mt24} />
            </Box>
          ),
          placement: 'center',
          isFixed: true,
        },
        {
          target: '#guide-tour-help-button',
          content:
            'Once a tutorial is completed, by default, it will not be shown again. Click on this icon to enable all tutorials on the current page so you can view them again.',
          disableScrolling: true,
          placementBeacon: 'top-start',
        } as Step,
        activeItems.length
          ? {
              // eslint-disable-next-line quotes
              target: `[data-tour='active-products']`,
              content:
                'Your active services are shown here. Click on a service to upload files and view your results.',
            }
          : null,
        inactiveItems.length
          ? {
              // eslint-disable-next-line quotes
              target: `[data-tour='products-carousel']`,
              content: 'To discover and research other available services, click the images below.',
            }
          : null,
        {
          // eslint-disable-next-line quotes
          target: `[data-tour='resource-menus']`,
          content: 'Helpful resources can be found here',
        },
      ].filter((x) => x !== null) as Step[],
    [activeItems, inactiveItems],
  );

  return (
    <Box className={clsx([classes.root, getClientData && 'loaded'])}>
      {getClientData && (
        <>
          <GuideTour steps={tourSteps} name='product-selection' />

          <Box className={classes.container}>
            <Box className={classes.hiddenSection} />
            <Box className={classes.topSection}>
              <Container maxWidth='xl'>
                <Box className={classes.topSectionContainer}>
                  <DashboardBreadcrumbs clientId={clientId} />
                  <IssuesAlert clientId={clientId} />
                  <ExternalResources />
                </Box>
              </Container>
            </Box>
            <Box className={classes.mainSection}>
              <Box className={classes.title}>
                <Container maxWidth='xl'>
                  <Typography variant='h6' data-tour='client'>
                    {getClientData.name}
                  </Typography>
                </Container>
              </Box>
              <Container className={classes.overflowHidden} maxWidth='lg'>
                <Box className={classes.productsSection}>
                  <Box className={classes.mt24} data-tour='active-products'>
                    <Typography className={classes.subTitle} variant='h6'>
                      Your Active Products/Services
                    </Typography>
                    <Box className={classes.productsList}>
                      {activeItems.map((item) => (
                        <ProductCard
                          id={`product-${item.id}`}
                          key={item.id}
                          product={item}
                          onSelected={() => onClientProductSelected(item)}
                        />
                      ))}
                    </Box>
                  </Box>
                  {!!inactiveItems.length && (
                    <Box className={classes.mt40} data-tour='products-carousel'>
                      <Typography className={classes.subTitle} variant='h6'>
                        Other Ways We Can Help
                      </Typography>
                      <Carousel
                        responsive={responsive}
                        infinite
                        autoPlay
                        customTransition='all .5'
                        autoPlaySpeed={3000}
                        showDots
                        arrows={false}
                        containerClass={classes.carouselContainer}
                        itemClass={classes.carouselItem}
                        dotListClass={classes.dotsList}
                      >
                        {inactiveItems.map((item) => (
                          <ProductCard
                            id={`carousel-product-${item.id}`}
                            key={item.id}
                            product={item}
                            onSelected={() => onCarouselProductSelected(item)}
                          />
                        ))}
                      </Carousel>
                    </Box>
                  )}
                </Box>
              </Container>
            </Box>
          </Box>
        </>
      )}
      <Loader show={showLoader} />
    </Box>
  );
};
