import 'react-toastify/dist/ReactToastify.css';

import { CloseButtonProps } from 'react-toastify';
import { IconButton } from '@mui/material';
import XIcon from '@assets/icons/toast/x.svg';

export const CloseButton = ({ closeToast }: CloseButtonProps) => {
  return (
    <IconButton onClick={closeToast}>
      <img src={XIcon} alt='Remove' />
    </IconButton>
  );
};
