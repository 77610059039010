import FilesProvider from '@providers/FilesProvider';
import { IChangeFileCategoryData } from '@models/interfaces/additional/IChangeFileCategoryData';
import { IChangeFileCategoryRequest } from '@models/interfaces/requests/IChangeFileCategoryRequest';
import { IChangeFileSubCategoryMappingData } from '@models/interfaces/additional/IChangeFileSubCategoryMappingData';
import { IChangeFileSubCategoryMappingRequest } from '@models/interfaces/requests/IChangeFileSubCategoryMappingRequest';
import { IChangeFileSubCategoryTransformationScriptData } from '@models/interfaces/additional/IChangeFileSubCategoryTransformationScriptData';
import { IChangeFileSubCategoryTransformationScriptRequest } from '@models/interfaces/requests/IChangeFileSubCategoryTransformationScriptRequest';
import { IFile } from '@models/interfaces/entities/IFile';
import { IFileContent } from '@models/interfaces/entities/IFileContent';
import { IFileContentDto } from '@models/interfaces/dtos/IFileContentDto';
import { IFileDto } from '@models/interfaces/dtos/IFileDto';
import { IPiiIncidentDetailsPage } from '@models/interfaces/entities/IPiiIncidentDetailsPage';
import { IPiiIncidentDetailsPageDto } from '@models/interfaces/dtos/IPiiIncidentDetailsPageDto';
import { IPiiScanResult } from '@models/interfaces/entities/IPiiScanResult';
import { IPiiScanResultDto } from '@models/interfaces/dtos/IPiiScanResultDto';
import { IUpdateFileUserStatusData } from '@models/interfaces/additional/IUpdateFileUserStatusData';
import { IUpdateFileUserStatusRequest } from '@models/interfaces/requests/IUpdateFileUserStatusRequest';
import mapper from '@models/mapper';

const filesProvider = new FilesProvider();

export const getFile = async (url: string) => {
  const result = await filesProvider.getFile(url);
  return mapper.map<IFileDto, IFile>(result, 'IFileDto', 'IFile');
};

export const uploadProjectFile = async (url: string, file: File, replace = false) => {
  const result = await filesProvider.uploadFile(url, file, replace);
  return mapper.map<IFileDto, IFile>(result, 'IFileDto', 'IFile');
};

export const getProjectFiles = async (url: string) => {
  const result = await filesProvider.getProjectFiles(url);
  return mapper.mapArray<IFileDto, IFile>(result.items, 'IFileDto', 'IFile');
};

export const getProjectFilesNames = async (url: string, filesNames: string[]) => {
  const result = await filesProvider.getProjectFilesNames(url, filesNames);
  return result.items;
};

export const getProjectFilesDirectory = async (url: string) => {
  const result = await filesProvider.getProjectFilesDirectory(url);
  return result.uri;
};

export const getFiles = async (
  url: string,
  limit?: number,
  offset?: number,
  search?: string,
  clientId?: string,
  productId?: string,
  projectId?: string,
) => {
  const result = await filesProvider.getFiles(
    url,
    limit,
    offset,
    search,
    clientId,
    productId,
    projectId,
  );
  return mapper.mapArray<IFileDto, IFile>(result.items, 'IFileDto', 'IFile');
};

export const getFilesCount = async (url: string, search?: string) => {
  const result = await filesProvider.getFilesCount(url, search);
  return result.count;
};

export const getPiiScanResults = async (url: string) => {
  const result = await filesProvider.getPiiScanResults(url);
  return mapper.map<IPiiScanResultDto, IPiiScanResult>(
    result,
    'IPiiScanResultDto',
    'IPiiScanResult',
  );
};

export const getPiiIncidentDetails = async (url: string) => {
  const result = await filesProvider.getPiiIncidentDetails(url);
  return mapper.mapArray<IPiiIncidentDetailsPageDto, IPiiIncidentDetailsPage>(
    result.pages,
    'IPiiIncidentDetailsPageDto',
    'IPiiIncidentDetailsPage',
  );
};

export const deleteFile = async (url: string) => {
  await filesProvider.deleteFile(url);
  return true;
};

export const updateFileUserStatus = async (url: string, data: IUpdateFileUserStatusData) => {
  const requestData = mapper.map<IUpdateFileUserStatusData, IUpdateFileUserStatusRequest>(
    data,
    'IUpdateFileUserStatusData',
    'IUpdateFileUserStatusRequest',
  );
  const result = await filesProvider.updateFileUserStatus(url, requestData);
  return mapper.map<IFileDto, IFile>(result, 'IFileDto', 'IFile');
};

export const ignoreFile = async (url: string) => {
  const result = await filesProvider.ignoreFile(url);
  return mapper.map<IFileDto, IFile>(result, 'IFileDto', 'IFile');
};

export const unignoreFile = async (url: string) => {
  const result = await filesProvider.unignoreFile(url);
  return mapper.map<IFileDto, IFile>(result, 'IFileDto', 'IFile');
};

export const changeFileCategory = async (url: string, data: IChangeFileCategoryData) => {
  const requestData = mapper.map<IChangeFileCategoryData, IChangeFileCategoryRequest>(
    data,
    'IChangeFileCategoryData',
    'IChangeFileCategoryRequest',
  );
  const result = await filesProvider.changeFileCategory(url, requestData);
  return mapper.map<IFileDto, IFile>(result, 'IFileDto', 'IFile');
};

export const changeFileMapping = async (url: string, data: IChangeFileSubCategoryMappingData) => {
  const requestData = mapper.map<
    IChangeFileSubCategoryMappingData,
    IChangeFileSubCategoryMappingRequest
  >(data, 'IChangeFileSubCategoryMappingData', 'IChangeFileSubCategoryMappingRequest');
  const result = await filesProvider.changeFileMapping(url, requestData);
  return mapper.map<IFileDto, IFile>(result, 'IFileDto', 'IFile');
};

export const changeFileTransformationScript = async (
  url: string,
  data: IChangeFileSubCategoryTransformationScriptData,
) => {
  const requestData = mapper.map<
    IChangeFileSubCategoryTransformationScriptData,
    IChangeFileSubCategoryTransformationScriptRequest
  >(
    data,
    'IChangeFileSubCategoryTransformationScriptData',
    'IChangeFileSubCategoryTransformationScriptRequest',
  );
  const result = await filesProvider.changeFileTransformationScript(url, requestData);
  return mapper.map<IFileDto, IFile>(result, 'IFileDto', 'IFile');
};

export const getFileContent = async (url: string) => {
  const result = await filesProvider.getFileContent(url);
  return mapper.map<IFileContentDto, IFileContent>(result, 'IFileContentDto', 'IFileContent');
};
