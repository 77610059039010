import { Box, IconButton, Paper, Typography } from '@mui/material';
import {
  selectGeneralUploadingDialogOpened,
  setUploadingDialogOpened,
} from '@reducers/generalSlice';
import { useAppDispatch, useAppSelector } from '@hooks';

import CloseIcon from '@assets/icons/uploading-dialog/close.svg';
import useStyles from './styles';

export const NewFilesDialog = () => {
  const uploadingDialogOpened = useAppSelector(selectGeneralUploadingDialogOpened);
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(setUploadingDialogOpened(false));
  };

  const { classes } = useStyles();

  if (!uploadingDialogOpened) {
    return null;
  }

  return (
    <Paper data-testid='new-files-dialog' className={classes.paper} elevation={3}>
      <Box className={classes.header}>
        <Typography variant='h6' className={classes.headerText}>
          Uploading: 2 of 5
        </Typography>
        <IconButton onClick={onClose}>
          <img src={CloseIcon} alt='Close' />
        </IconButton>
      </Box>
      some content here
    </Paper>
  );
};
