import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    gap: 16,
    display: 'flex',
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 10,
    cursor: 'auto',
  },
  unprocessed: {
    border: `1px solid ${t.palette.divider}`,
  },
  processed: {
    backgroundColor: t.palette.custom.primary[5],
    border: `1px solid ${t.palette.custom.primary[5]}`,
  },
  withError: {
    backgroundColor: t.palette.custom.error[10],
    border: `1px solid ${t.palette.custom.error[100]}`,
  },
  accepted: {
    backgroundColor: t.palette.custom.success[10],
    border: `1px solid ${t.palette.custom.success[100]}`,
  },
  rejected: {
    backgroundColor: t.palette.custom.error[10],
    border: `1px solid ${t.palette.custom.error[100]}`,
  },
  statusIcon: {
    height: 30,
    cursor: 'pointer',

    '&.animated': {
      animation: `${keyframes`
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(90deg);
      }
      `} 2s infinite ease-in-out`,
    },
  },
  w100: { width: '100%' },
  gap4: {
    gap: 4,
  },
  jcCenter: {
    justifyContent: 'center',
  },
  jcSpaceBetween: {
    justifyContent: 'space-between',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  flexDirectionColumn: {
    flexDirection: 'column',
  },
  textBold: {
    fontWeight: 600,
  },
  textGrey: {
    color: t.palette.custom.dark[60],
  },
  textSuccess: {
    color: t.palette.success.main,
  },
  textError: {
    color: t.palette.error.main,
  },
  actionButton: {
    borderWidth: 2,
    '&:hover': {
      borderWidth: 2,
    },
  },
  draggable: {
    cursor: 'grab',
  },
  title: {
    wordBreak: 'break-word',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    visibility: 'visible',
    overflow: 'hidden',
  },
}));

export default useStyles;
