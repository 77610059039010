import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  paper: {
    position: 'fixed',
    bottom: 40,
    right: 40,
    width: 384,
    borderRadius: 10,
  },
  header: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    height: 64,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: t.palette.primary.main,
  },
  headerText: {
    fontWeight: 600,
    color: '#fff',
  },
}));

export default useStyles;
