import { Badge, Box, Button, IconButton, Typography } from '@mui/material';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  createWhitelistRecord,
  getStandardPiiIncidentCategory,
  getWhitelistRecords,
  updateStandardPiiIncidentCategory,
} from '@services/api';
import { useApi, useLoader, useUpdateEffect } from '@hooks';

import { Actions } from '@models/enums/Actions';
import { CreateWhitelistRecordDialog } from '../CreateWhitelistRecordDialog';
import { EditCategoryDialog } from './components/EditCategoryDialog';
import EditFilledIcon from '@assets/icons/item-view/edit-3-filled.svg';
import { ICreateWhitelistRecordData } from '@models/interfaces/additional/ICreateWhitelistRecordData';
import { IStandardPiiIncidentCategory } from '@models/interfaces/entities/IStandardPiiIncidentCategory';
import { IUpdateStandardPiiIncidentCategoryData } from '@models/interfaces/additional/IUpdateStandardPiiIncidentCategoryData';
import { IWhitelistRecord } from '@models/interfaces/entities/IWhitelistRecord';
import { Loader } from '@components/Loader';
import PlusIcon from '@assets/icons/item-view/plus-filled.svg';
import { WhitelistRecordCard } from '../WhitelistRecordCard';
import { toast } from 'react-toastify';
import useStyles from './styles';

interface IProps {
  categoryId: string;
  onCategoryChanged: () => void;
}

export const StandardCategoryView = ({ onCategoryChanged, categoryId }: IProps) => {
  const { classes } = useStyles();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openCreateWhitelistRecordDialog, setOpenCreateWhitelistRecordDialog] = useState(false);
  const [category, setCategory] = useState<IStandardPiiIncidentCategory | null>(null);
  const [whitelistRecords, setWhitelistRecords] = useState<IWhitelistRecord[]>([]);

  const {
    request: getCategoryRequest,
    loading: getCategoryLoading,
    data: getCategoryData,
  } = useApi(getStandardPiiIncidentCategory, null, { handleErrors: true });

  const { request: updateCategoryRequest, loading: updateCategoryLoading } = useApi(
    updateStandardPiiIncidentCategory,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        setCategory(data);
        setOpenEditDialog(false);
        toast.info('Successfully updated the category');
        onCategoryChanged();
      },
    },
  );

  const {
    request: getWhitelistRecordsRequest,
    data: getWhitelistRecordsData,
    loading: getWhitelistRecordsLoading,
  } = useApi(getWhitelistRecords, null, { handleErrors: true });

  const {
    request: createWhitelistRecordRequest,
    loading: createWhitelistRecordLoading,
    data: createWhitelistRecordData,
  } = useApi(createWhitelistRecord, null, { handleErrors: true });

  const reloadWhitelistRecords = () => {
    if (category?.links[Actions.getWhitelistRecords]?.href) {
      getWhitelistRecordsRequest(category.links[Actions.getWhitelistRecords].href);
    }
  };

  const onEdit = () => {
    setOpenEditDialog(true);
  };

  const onCreateWhitelistRecord = () => {
    setOpenCreateWhitelistRecordDialog(true);
  };

  const onCloseCreateWhitelistRecordDialog = async (data?: ICreateWhitelistRecordData) => {
    if (!data) {
      setOpenCreateWhitelistRecordDialog(false);
    } else {
      const url = getWhitelistRecordsData?.links[Actions.createWhitelistRecord]?.href;
      if (url) {
        createWhitelistRecordRequest(url, data);
      }
    }
  };

  const onCloseEditDialog = async (item?: IUpdateStandardPiiIncidentCategoryData) => {
    if (!item) {
      setOpenEditDialog(false);
    } else {
      const url = getCategoryData?.links[Actions.update]?.href;
      if (url) {
        updateCategoryRequest(url, item);
      }
    }
  };

  const onRecordChanged = async () => {
    reloadWhitelistRecords();
  };

  useUpdateEffect(() => {
    if (getCategoryData) {
      setCategory(getCategoryData);
    }
  }, [getCategoryData]);

  useUpdateEffect(() => {
    if (createWhitelistRecordData) {
      setOpenCreateWhitelistRecordDialog(false);
      toast.info('Successfully created the whitelist record');
      reloadWhitelistRecords();
    }
  }, [createWhitelistRecordData]);

  useEffect(() => {
    if (getWhitelistRecordsData) {
      setWhitelistRecords(getWhitelistRecordsData.items);
    }
  }, [getWhitelistRecordsData]);

  useEffect(() => {
    if (categoryId) {
      getCategoryRequest(`/standard-pii-categories/${categoryId}`);
    }
  }, [categoryId]);

  useEffect(() => {
    reloadWhitelistRecords();
  }, [category]);

  const infoItems = useMemo<{ value: string; label: string; action?: ReactNode }[]>(
    () => [
      {
        label: 'Scanning',
        value: category?.enabled ? 'enabled' : 'disabled',
      },
      {
        label: 'Min confidence',
        value: category?.confidence.toString() || '-',
      },
    ],
    [category],
  );

  const showLoader = useLoader(
    getCategoryLoading,
    updateCategoryLoading,
    getWhitelistRecordsLoading,
    createWhitelistRecordLoading,
  );

  return (
    <Box className={classes.container}>
      {category && (
        <>
          <Box className={classes.infoSection}>
            <Box className={classes.title}>
              <Typography variant='h6'>{category.name}</Typography>
              {!!category?.links[Actions.update]?.href && (
                <IconButton onClick={onEdit}>
                  <img src={EditFilledIcon} alt='Edit' />
                </IconButton>
              )}
            </Box>
            <Box className={classes.infoContent}>
              {infoItems.map((item) => (
                <Box key={item.label} className={classes.infoItem}>
                  <Typography className='label' variant='caption'>
                    {item.label}
                  </Typography>
                  <Box className={classes.flexRow}>
                    <Typography variant='subtitle1'>{item.value}</Typography>
                    {item.action}
                  </Box>
                </Box>
              ))}
            </Box>
            <Box className={classes.whitelistRecords}>
              <Box className={classes.whitelistRecordsCountContainer}>
                <Typography variant='subtitle1'>Whitelist:</Typography>
                <Badge
                  className={classes.whitelistRecordsCount}
                  badgeContent={whitelistRecords.length}
                  color='secondary'
                />
              </Box>
              {!!getWhitelistRecordsData?.links[Actions.createWhitelistRecord]?.href && (
                <Button
                  className={classes.addButton}
                  variant='text'
                  color='info'
                  startIcon={<img src={PlusIcon} alt='Add' />}
                  onClick={onCreateWhitelistRecord}
                >
                  Add whitelist record
                </Button>
              )}
            </Box>
          </Box>

          <Box className={classes.whitelistRecordsList}>
            {whitelistRecords.map((item) => (
              <WhitelistRecordCard key={item.id} record={item} onRecordChanged={onRecordChanged} />
            ))}
          </Box>

          <EditCategoryDialog
            category={category}
            open={openEditDialog}
            onClose={onCloseEditDialog}
          />

          {!!getWhitelistRecordsData?.links[Actions.createWhitelistRecord] && (
            <CreateWhitelistRecordDialog
              open={openCreateWhitelistRecordDialog}
              onClose={onCloseCreateWhitelistRecordDialog}
            />
          )}
        </>
      )}
      <Loader show={showLoader} />
    </Box>
  );
};
