import ActivityEventsProvider from '@providers/ActivityEventsProvider';
import { IActivityEvent } from '@models/interfaces/entities/IActivityEvent';
import { IActivityEventDto } from '@models/interfaces/dtos/IActivityEventDto';
import mapper from '@models/mapper';

const activityEventsProvider = new ActivityEventsProvider();

export const getActivityEvents = async (
  url: string,
  limit?: number,
  offset?: number,
  search?: string,
  startDate?: Date,
  endDate?: Date,
  clientId?: string,
) => {
  const result = await activityEventsProvider.getActivityEvents(
    url,
    limit,
    offset,
    search,
    startDate ? startDate.getTime() : undefined,
    endDate ? endDate.getTime() : undefined,
    clientId,
  );
  return mapper.mapArray<IActivityEventDto, IActivityEvent>(
    result.items,
    'IActivityEventDto',
    'IActivityEvent',
  );
};
