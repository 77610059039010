import { Mapper, createMap, forMember, mapFrom } from '@automapper/core';

import { IFileContent } from '@models/interfaces/entities/IFileContent';
import { IFileContentDto } from '@models/interfaces/dtos/IFileContentDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createFileContentMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IFileContentDto>('IFileContentDto', {
    content: ArrayBuffer,
    fileName: String,
    contentType: String,
  });

  PojosMetadataMap.create<IFileContent>('IFileContent', {
    content: File,
  });

  createMap<IFileContentDto, IFileContent>(
    mapper,
    'IFileContentDto',
    'IFileContent',
    forMember(
      (destination) => destination.content,
      mapFrom(
        (source) =>
          new File([source.content], source.fileName, {
            type: source.contentType,
          }),
      ),
    ),
  );
};
