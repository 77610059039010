import { Context, Hub } from './types';
import { removeDuplicates, sendWithHermes } from '../utils';

import { HubConnectionState } from '@microsoft/signalr';
import { createUseSignalREffect } from './hooks';
import hermes from 'hermes-channel';
import { providerFactory } from './provider';
import { providerNativeFactory } from './provider/providerNativeFactory';
import { v4 as uuid } from 'uuid';

const SIGNAL_R_INVOKE = 'SIGNAL_R_INVOKE';
function createSignalRContext<T extends Hub>(options?: { shareConnectionBetweenTab?: boolean }) {
  const events: T['callbacksName'][] = [];
  const context: Context<T> & { reOn: () => void } = {
    connection: null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useSignalREffect: null as any, // Assigned after context
    shareConnectionBetweenTab: options?.shareConnectionBetweenTab || false,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    invoke: (methodName: string, ...args: any[]) => {
      const SIGNAL_R_RESPONSE = uuid();
      sendWithHermes(
        SIGNAL_R_INVOKE,
        { methodName, args, callbackResponse: SIGNAL_R_RESPONSE },
        context.shareConnectionBetweenTab,
      );
      return new Promise((resolve) => {
        hermes.on(SIGNAL_R_RESPONSE, (data) => {
          resolve(data);
        });
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Provider: null as any, // just for ts ignore
    on: (event: string) => {
      if (!events.includes(event)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        context.connection?.on(event, (...message: any) => {
          sendWithHermes(event, message, context.shareConnectionBetweenTab);
        });
      }
      events.push(event);
    },
    off: (event: string) => {
      if (events.includes(event)) {
        events.splice(events.indexOf(event), 1);
      }

      if (!events.includes(event)) {
        context.connection?.off(event);
      }
    },

    reOn: () => {
      const uniqueEvents = removeDuplicates(events);

      uniqueEvents.forEach((event) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        context.connection?.on(event, (...message: any) => {
          sendWithHermes(event, message, context.shareConnectionBetweenTab);
        });
      });
    },
  };

  context.Provider = context.shareConnectionBetweenTab
    ? providerFactory(context)
    : providerNativeFactory(context);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function invoke(data: { methodName: string; args: any[]; callbackResponse: string }) {
    const response = await context.connection?.invoke(data.methodName, ...data.args);
    sendWithHermes(data.callbackResponse, response, context.shareConnectionBetweenTab);
  }

  context.useSignalREffect = createUseSignalREffect(context);

  hermes.on(SIGNAL_R_INVOKE, (data) => {
    if (context.connection?.state === HubConnectionState.Connected) {
      invoke(data);
    }
  });

  return context;
}

export { createSignalRContext };
