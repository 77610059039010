import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';

import AlertCircleIcon from '@assets/icons/dialog/alert-circle.svg';
import { Box } from '@mui/system';
import CloseIcon from '@assets/icons/dialog/close.svg';
import { ReactNode } from 'react';
import useStyles from './styles';

export interface IProps {
  title: string;
  subtitle?: string;
  steps?: number;
  activeStep?: number;
  actions: ReactNode;
  footerAdditional: ReactNode | null;
  showCloseIcon: boolean;
  centered: boolean;
  onAlertClicked?: () => void;
}

export const CustomDialog = ({
  onClose,
  children,
  actions,
  footerAdditional,
  steps,
  activeStep,
  title,
  subtitle,
  centered,
  showCloseIcon,
  onAlertClicked,
  ...restProps
}: DialogProps & IProps) => {
  const { classes } = useStyles({ centered });

  return (
    <Dialog onClose={onClose} {...restProps}>
      <Box className={classes.title}>
        <Box className={classes.infoContainer}>
          <Box>
            <DialogTitle>{title}</DialogTitle>
            {subtitle && <Typography>{subtitle}</Typography>}
          </Box>
          {onAlertClicked && (
            <IconButton onClick={() => onAlertClicked()}>
              <img src={AlertCircleIcon} alt='Alert' />
            </IconButton>
          )}
        </Box>
        {steps && (
          <Box className={classes.stepsContainer}>
            <Typography variant='body2'>Step:</Typography>
            <Typography className={classes.activeStep} component='div' variant='subtitle2'>
              {activeStep}
            </Typography>
            <Typography variant='body2'>of</Typography>
            <Typography className={classes.steps} component='div' variant='subtitle2'>
              {steps}
            </Typography>
          </Box>
        )}
        {showCloseIcon && (
          <IconButton onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
            <img src={CloseIcon} alt='Close' />
          </IconButton>
        )}
      </Box>
      <Divider variant='dialog' />
      <DialogContent className={classes.contentContainer}>{children}</DialogContent>
      <DialogActions className={classes.actionsContainer}>{actions}</DialogActions>
      {footerAdditional && <Box className={classes.additionalContainer}>{footerAdditional}</Box>}
    </Dialog>
  );
};

CustomDialog.defaultProps = {
  showCloseIcon: true,
  centered: false,
  footerAdditional: null,
};
