import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  dateRangePickerPaper: {
    '& .MuiDayPicker-weekContainer': { gap: 2 },
    '& .MuiDateRangePickerDay-rangeIntervalDayHighlight': {
      borderRadius: 10,
      backgroundColor: 'transparent',

      '& button:not(.Mui-selected)': {
        backgroundColor: t.palette.custom.primary[10],
        color: t.palette.custom.dark[87],
      },
    },

    '& .MuiPickersDay-root': {
      borderRadius: 10,
      fontWeight: t.typography.body2.fontWeight,
      fontSize: t.typography.body2.fontSize,
      lineHeight: t.typography.body2.lineHeight,
      letterSpacing: t.typography.body2.letterSpacing,

      '&.MuiPickersDay-today': {
        '&:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          width: 12,
          height: 4,
          borderRadius: '2px 2px 0px 0px',
          backgroundColor: t.palette.custom.primary[100],
          bottom: 0,
          left: '50%',
          marginLeft: -6,
        },
      },
    },
  },
}));

export default useStyles;
