import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
  },
}));

export default useStyles;
