import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  info: {
    marginBottom: 16,
    textAlign: 'center',
  },
  description: {
    textAlign: 'center',
    color: t.palette.custom.dark[60],
  },
  detailsContainer: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'column',
  },
  textSuccess: {
    color: t.palette.success.main,
  },
  textError: {
    color: t.palette.error.main,
  },
  switchWrapper: {
    marginTop: 16,
  },
}));

export default useStyles;
