import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  container: {
    height: 'calc(100vh - 221px)',
    maxHeight: 500,
  },
  table: {
    '& .MuiDataGrid-virtualScroller': {
      minHeight: 52,
    },
  },
}));

export default useStyles;
