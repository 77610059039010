import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: 10,
    backgroundColor: t.palette.custom.primary[5],
    border: `1px solid ${t.palette.custom.primary[5]}`,
  },
  content: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: 8,
  },
  controls: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  removeBtn: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    textTransform: 'none',
    fontWeight: 600,
    fontSize: t.typography.caption.fontSize,
    lineHeight: t.typography.caption.lineHeight,
    letterSpacing: t.typography.caption.letterSpacing,
  },
}));

export default useStyles;
