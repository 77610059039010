import { useApi, useAppSelector } from '@hooks';
import { useCallback, useEffect, useMemo } from 'react';

import { Actions } from '@models/enums/Actions';
import { Box } from '@mui/material';
import { Loader } from '@components/Loader';
import { UserView } from './components/UserView';
import { UsersMenu } from './components/UsersMenu';
import { getUsers } from '@services/api';
import { selectAuthGetUsersActionLink } from '@reducers/authSlice';
import { useParams } from 'react-router-dom';
import useStyles from './styles';

export const UsersPage = () => {
  const { classes } = useStyles();
  const getUsersActionLink = useAppSelector(selectAuthGetUsersActionLink);
  const { userId } = useParams();

  const {
    request: getUsersRequest,
    data: getUsersData,
    loading: getUsersLoading,
  } = useApi(getUsers, { items: [], links: {} }, { handleErrors: true });

  useEffect(() => {
    if (getUsersActionLink) getUsersRequest(getUsersActionLink.href);
  }, [getUsersActionLink]);

  const showLoader = useMemo(() => getUsersLoading, [getUsersLoading]);

  const onChanged = useCallback(() => {
    if (getUsersActionLink) getUsersRequest(getUsersActionLink.href);
  }, [getUsersActionLink]);

  return (
    <Box className={classes.container}>
      <UsersMenu
        onUserAdded={onChanged}
        users={getUsersData.items}
        createUserActionLink={getUsersData.links[Actions.createUser]}
        activeUserId={userId}
      />
      {userId && <UserView onUserChanged={onChanged} userId={userId} />}
      <Loader show={showLoader} />
    </Box>
  );
};
