import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  container: {
    paddingTop: 40,
    borderRight: `1px solid ${t.palette.divider}`,
    width: 260,
    minWidth: 260,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 57px)',
    overflow: 'hidden',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 16,
    marginRight: 16,
    alignItems: 'center',
    minHeight: 36,
  },
  createButton: {
    borderRadius: 10,
    minWidth: 'auto',
    padding: 8,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: t.palette.custom.dark[100],
  },
  searchContainer: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 29,
    paddingBottom: 16,
    borderBottom: `1px solid ${t.palette.divider}`,
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    padding: 16,
    overflowY: 'auto',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: t.palette.custom.dark[67],
      borderRadius: 4,
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    width: '100%',
    cursor: 'pointer',
    '& .title': {
      color: t.palette.custom.dark[60],
    },
    '& .subtitle': {
      color: t.palette.custom.dark[38],
    },
    '& .info': {
      flexGrow: 1,
    },
    '& .additional': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },

  activeItem: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: '#fff',
    borderRadius: 10,
    cursor: 'pointer',
    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.1)',
    '& .title': {
      color: t.palette.custom.dark[100],
    },
    '& .subtitle': {
      color: t.palette.custom.dark[60],
    },
    '& .info': {
      flexGrow: 1,
    },
    '& .additional': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  avatar: {
    marginRight: 8,
  },
}));

export default useStyles;
