import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  container: {
    '& .Toastify__toast': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .Toastify__toast--success': {
      backgroundColor: t.palette.success.main,
      color: t.palette.success.contrastText,
      '& .Toastify__toast-icon>svg': {
        fill: t.palette.success.contrastText,
      },
    },
    '& .Toastify__toast--error': {
      backgroundColor: t.palette.error.main,
      color: t.palette.error.contrastText,
      '& .Toastify__toast-icon>svg': {
        fill: t.palette.error.contrastText,
      },
    },
    '& .Toastify__toast--info': {
      backgroundColor: t.palette.info.main,
      color: t.palette.info.contrastText,
      '& .Toastify__toast-icon>svg': {
        fill: t.palette.info.contrastText,
      },
    },
    '& .Toastify__toast--warning': {
      backgroundColor: t.palette.warning.main,
      color: t.palette.warning.contrastText,
      '& .Toastify__toast-icon>svg': {
        fill: t.palette.warning.contrastText,
      },
    },
    '& .Toastify__toast--default': {
      backgroundColor: t.palette.primary.main,
      color: t.palette.primary.contrastText,
      '& .Toastify__toast-icon>svg': {
        fill: t.palette.primary.contrastText,
      },
    },
    '& .Toastify__toast--dark': {
      backgroundColor: t.palette.primary.dark,
      color: t.palette.primary.contrastText,
      '& .Toastify__toast-icon>svg': {
        fill: t.palette.primary.contrastText,
      },
    },
  },
}));

export default useStyles;
