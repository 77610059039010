import BaseProvider from './BaseProvider';
import { ICreateFileSubCategoryTransformationScriptRequest } from '@models/interfaces/requests/ICreateFileSubCategoryTransformationScriptRequest';
import { ICreateFileSubCategoryTransformationScriptResponse } from '@models/interfaces/responses/ICreateFileSubCategoryTransformationScriptResponse';
import { IGetFileSubCategoryTransformationScriptResponse } from '@models/interfaces/responses/IGetFileSubCategoryTransformationScriptResponse';

export default class FileSubCategoryTransformationScriptsProvider extends BaseProvider {
  public getFileSubCategoryTransformationScript = (url: string) => {
    return this.get<IGetFileSubCategoryTransformationScriptResponse>(url);
  };

  public createFileSubCategoryTransformationScript = (
    url: string,
    data: ICreateFileSubCategoryTransformationScriptRequest,
  ) => {
    return this.post<
      ICreateFileSubCategoryTransformationScriptRequest,
      ICreateFileSubCategoryTransformationScriptResponse
    >(url, data);
  };
}
