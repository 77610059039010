import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    display: 'grid',
    gap: 8,
    gridTemplateColumns: '2fr 32px 3fr',
  },
  infoSection: {
    display: 'flex',
    alignItems: 'center',

    '& img': {
      borderRadius: '50%',
      backgroundColor: t.palette.custom.white[100],
    },
  },
}));

export default useStyles;
