import { getClient, getClientProduct, getProject } from '@services/api';
import { useApi, useAppSelector, useLoader } from '@hooks';
import { useEffect, useMemo } from 'react';

import { Breadcrumbs } from '@components/Breadcrumbs';
import { Loader } from '@components/Loader';
import UsersFilledIcon from '@assets/icons/dashboard/users-filled.svg';
import { selectAuthProducts } from '@reducers/authSlice';
import { selectClientClientsCount } from '@reducers/clientSlice';

export interface IProps {
  clientId?: string;
  productId?: string;
  projectId?: string;
}

export const DashboardBreadcrumbs = ({ clientId, productId, projectId }: IProps) => {
  const products = useAppSelector(selectAuthProducts);
  const clientsCount = useAppSelector(selectClientClientsCount);

  const {
    request: getClientRequest,
    data: getClientData,
    loading: getClientLoading,
  } = useApi(getClient, null, { handleErrors: true });

  const {
    request: getClientProductRequest,
    data: getClientProductData,
    loading: getClientProductLoading,
  } = useApi(getClientProduct, null, { handleErrors: true });

  const {
    request: getProjectRequest,
    data: getProjectData,
    loading: getProjectLoading,
  } = useApi(getProject, null, { handleErrors: true });

  useEffect(() => {
    if (clientId) getClientRequest(`/clients/${clientId}`);
  }, [clientId]);

  useEffect(() => {
    if (clientId && productId)
      getClientProductRequest(`/clients/${clientId}/products/${productId}`);
  }, [clientId, productId]);

  useEffect(() => {
    if (projectId) getProjectRequest(`/projects/${projectId}`);
  }, [projectId]);

  const showLoader = useLoader(getClientLoading, getClientProductLoading, getProjectLoading);

  const product = useMemo(
    () =>
      getClientProductData ? products.find((x) => x.id === getClientProductData.productId) : null,
    [products, getClientProductData],
  );

  const breadcrumbs = useMemo(() => {
    const links: {
      title: string;
      href: string;
      icon?: string;
    }[] = [];

    if (clientsCount !== 1 || (!clientId && !productId && !projectId)) {
      links.push({ title: 'All Clients', href: '/' });
    }

    if (getClientData) {
      links.push({
        title: getClientData.name,
        href: `/clients/${getClientData.id}`,
        icon: UsersFilledIcon,
      });
    }

    if (getClientData && product) {
      links.push({
        title: product.name,
        href: `/clients/${getClientData.id}/products/${product.id}`,
      });
    }

    if (getClientData && product && getProjectData) {
      links.push({
        title: getProjectData.name,
        href: `/clients/${getClientData.id}/products/${product.id}/projects/${getProjectData.id}`,
      });
    }

    return links;
  }, [getClientData, product, getProjectData, clientsCount]);

  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      <Loader show={showLoader} />
    </>
  );
};
