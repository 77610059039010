import { Mapper, createMap } from '@automapper/core';

import { IFileSubCategory } from '@models/interfaces/entities/IFileSubCategory';
import { IFileSubCategoryDto } from '@models/interfaces/dtos/IFileSubCategoryDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createFileSubCategoryMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IFileSubCategoryDto>('IFileSubCategoryDto', {
    id: String,
    disabled: Boolean,
    notes: String,
    statusNotes: String,
    fileCategoryId: String,
    clientId: String,
    productId: String,
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IFileSubCategory>('IFileSubCategory', {
    id: String,
    disabled: Boolean,
    notes: String,
    statusNotes: String,
    fileCategoryId: String,
    clientId: String,
    productId: String,
    links: 'ILinks',
  });

  createMap<IFileSubCategoryDto, IFileSubCategory>(
    mapper,
    'IFileSubCategoryDto',
    'IFileSubCategory',
  );
};
