import FileSubCategoryTransformationScriptsProvider from '@providers/FileSubCategoryTransformationScriptsProvider';
import { ICreateFileSubCategoryTransformationScriptData } from '@models/interfaces/additional/ICreateFileSubCategoryTransformationScriptData';
import { ICreateFileSubCategoryTransformationScriptRequest } from '@models/interfaces/requests/ICreateFileSubCategoryTransformationScriptRequest';
import { IFileSubCategoryTransformationScript } from '@models/interfaces/entities/IFileSubCategoryTransformationScript';
import { IFileSubCategoryTransformationScriptDto } from '@models/interfaces/dtos/IFileSubCategoryTransformationScriptDto';
import mapper from '@models/mapper';

const fileSubCategoryTransformationScriptsProvider =
  new FileSubCategoryTransformationScriptsProvider();

export const createFileSubCategoryTransformationScript = async (
  url: string,
  data: ICreateFileSubCategoryTransformationScriptData,
) => {
  const requestData = mapper.map<
    ICreateFileSubCategoryTransformationScriptData,
    ICreateFileSubCategoryTransformationScriptRequest
  >(
    data,
    'ICreateFileSubCategoryTransformationScriptData',
    'ICreateFileSubCategoryTransformationScriptRequest',
  );
  const result =
    await fileSubCategoryTransformationScriptsProvider.createFileSubCategoryTransformationScript(
      url,
      requestData,
    );
  return mapper.map<IFileSubCategoryTransformationScriptDto, IFileSubCategoryTransformationScript>(
    result,
    'IFileSubCategoryTransformationScriptDto',
    'IFileSubCategoryTransformationScript',
  );
};

export const getFileSubCategoryTransformationScript = async (url: string) => {
  const result =
    await fileSubCategoryTransformationScriptsProvider.getFileSubCategoryTransformationScript(url);
  return mapper.map<IFileSubCategoryTransformationScriptDto, IFileSubCategoryTransformationScript>(
    result,
    'IFileSubCategoryTransformationScriptDto',
    'IFileSubCategoryTransformationScript',
  );
};
