import { Mapper, createMap } from '@automapper/core';

import { IFileSubCategoryTransformationScript } from '@models/interfaces/entities/IFileSubCategoryTransformationScript';
import { IFileSubCategoryTransformationScriptDto } from '@models/interfaces/dtos/IFileSubCategoryTransformationScriptDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createFileSubCategoryTransformationScriptMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IFileSubCategoryTransformationScriptDto>(
    'IFileSubCategoryTransformationScriptDto',
    {
      id: String,
      script: String,
      fileSubCategoryId: String,
      links: 'ILinksDto',
    },
  );

  PojosMetadataMap.create<IFileSubCategoryTransformationScript>(
    'IFileSubCategoryTransformationScript',
    {
      id: String,
      script: String,
      fileSubCategoryId: String,
      links: 'ILinks',
    },
  );

  createMap<IFileSubCategoryTransformationScriptDto, IFileSubCategoryTransformationScript>(
    mapper,
    'IFileSubCategoryTransformationScriptDto',
    'IFileSubCategoryTransformationScript',
  );
};
