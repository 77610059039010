import { Box, Typography } from '@mui/material';
import { getClients, getFilesCount } from '@services/api';
import {
  selectAcceptableFilesCount,
  setAcceptableFilesCount,
  setIssuesDrawerData,
} from '@reducers/generalSlice';
import {
  selectAuthGetClientsActionLink,
  selectAuthGetFilesCountActionLink,
} from '@reducers/authSlice';
import { useApi, useAppDispatch, useAppSelector, useDebounce, useUpdateEffect } from '@hooks';
import { useContext, useEffect, useState } from 'react';

import AlertTriangleIcon from '@assets/icons/issues-alert/alert-triangle.svg';
import { FilesHubCallbacksNames } from '@hubs/filesHub';
import FilesHubContext from '@contexts/FilesHubContext';
import useStyles from './styles';

interface IProps {
  clientId?: string;
  productId?: string;
  projectId?: string;
  delay: number;
}

export const IssuesAlert = ({ delay, clientId, productId, projectId }: IProps) => {
  const { classes } = useStyles();
  const [updateDetected, setUpdateDetected] = useState<Date | null>(null);
  const debouncedUpdateDetected = useDebounce<Date | null>(updateDetected, delay);
  const acceptableFilesCount = useAppSelector(selectAcceptableFilesCount);
  const getFilesCountActionLink = useAppSelector(selectAuthGetFilesCountActionLink);
  const getClientsActionLink = useAppSelector(selectAuthGetClientsActionLink);
  const dispatch = useAppDispatch();

  const [clientIds, setClientIds] = useState<string[]>([]);
  const filesHub = useContext(FilesHubContext);

  const { request: getFilesCountRequest, data: getFilesCountData } = useApi(getFilesCount, null, {
    handleErrors: true,
  });

  const { request: getClientsRequest, data: getClientsData } = useApi(getClients, null, {
    handleErrors: true,
  });

  useEffect(() => {
    if (getFilesCountActionLink) getFilesCountRequest(getFilesCountActionLink.href);
  }, [getFilesCountActionLink]);

  useUpdateEffect(() => {
    dispatch(setAcceptableFilesCount(getFilesCountData || 0));
  }, [getFilesCountData]);

  useEffect(() => {
    if (getClientsActionLink) getClientsRequest(getClientsActionLink.href);
  }, [getClientsActionLink]);

  useUpdateEffect(() => {
    if (getClientsData) {
      setClientIds(getClientsData.map((x) => x.id));
    }
  }, [getClientsData]);

  const onAlertClicked = () => {
    dispatch(setIssuesDrawerData({ open: true, clientId, productId, projectId }));
  };

  filesHub.useSignalREffect(
    FilesHubCallbacksNames.whitelistRecordCreated,
    ({ clientId }) => {
      if (clientIds.includes(clientId) && getFilesCountActionLink) {
        setUpdateDetected(new Date());
      }
    },
    [clientIds, getFilesCountActionLink],
  );

  filesHub.useSignalREffect(
    FilesHubCallbacksNames.fileUpdated,
    ({ clientId }) => {
      if (clientIds.includes(clientId) && getFilesCountActionLink) {
        setUpdateDetected(new Date());
      }
    },
    [clientIds, getFilesCountActionLink],
  );

  filesHub.useSignalREffect(
    FilesHubCallbacksNames.fileStatusUpdated,
    ({ clientId }) => {
      if (clientIds.includes(clientId) && getFilesCountActionLink) {
        setUpdateDetected(new Date());
      }
    },
    [clientIds, getFilesCountActionLink],
  );

  filesHub.useSignalREffect(
    FilesHubCallbacksNames.fileDeleted,
    () => {
      if (getFilesCountActionLink) {
        setUpdateDetected(new Date());
      }
    },
    [getFilesCountActionLink],
  );

  useUpdateEffect(() => {
    if (debouncedUpdateDetected && getFilesCountActionLink)
      getFilesCountRequest(getFilesCountActionLink.href);
  }, [debouncedUpdateDetected, getFilesCountActionLink]);

  return acceptableFilesCount !== 0 ? (
    <Box className={classes.container} onClick={onAlertClicked}>
      <img src={AlertTriangleIcon} alt='Alert' />
      <Typography variant='subtitle2'> Some files require your attention.</Typography>
    </Box>
  ) : null;
};

IssuesAlert.defaultProps = {
  delay: 1000,
};
