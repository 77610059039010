import { Mapper, createMap } from '@automapper/core';

import { IPiiIncidentCategory } from '@models/interfaces/entities/IPiiIncidentCategory';
import { IPiiIncidentCategoryDto } from '@models/interfaces/dtos/IPiiIncidentCategoryDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createPiiIncidentCategoryMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IPiiIncidentCategoryDto>('IPiiIncidentCategoryDto', {
    id: String,
    name: String,
    template: String,
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IPiiIncidentCategory>('IPiiIncidentCategory', {
    id: String,
    name: String,
    template: String,
    links: 'ILinks',
  });

  createMap<IPiiIncidentCategoryDto, IPiiIncidentCategory>(
    mapper,
    'IPiiIncidentCategoryDto',
    'IPiiIncidentCategory',
  );
};
