import { Box, Drawer, IconButton, Typography } from '@mui/material';
import { CustomAutocomplete, IAutocompleteOption } from '@components/CustomAutocomplete';
import { SearchInput, SearchInputColors } from '@components/SearchInput';
import {
  deleteFile,
  getClientProducts,
  getClients,
  getFile,
  getFileContent,
  getFiles,
  getProjects,
  updateFileUserStatus,
} from '@services/api';
import {
  selectAcceptableFilesCount,
  selectGeneralAutoCloseScanResultsDialog,
  selectGeneralIssuesDrawerOpened,
  selectGeneralIssuesDrawerSelectedFileId,
  selectGeneralIssuesDrawerSelectedFilters,
  setIssuesDrawerData,
} from '@reducers/generalSlice';
import {
  selectAuthGetClientsActionLink,
  selectAuthGetFilesActionLink,
  selectAuthProducts,
} from '@reducers/authSlice';
import {
  useApi,
  useAppDispatch,
  useAppSelector,
  useConfirm,
  useDebounce,
  useInput,
  useLoader,
  useUpdateEffect,
} from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import CloseIcon from '@assets/icons/issues-drawer/close.svg';
import { FileCard } from './components/FileCard';
import { FileUserStatuses } from '@models/enums/FileUserStatuses';
import { IClient } from '@models/interfaces/entities/IClient';
import { IClientProduct } from '@models/interfaces/entities/IClientProduct';
import { IFile } from '@models/interfaces/entities/IFile';
import { IProject } from '@models/interfaces/entities/IProject';
import InfiniteScroll from 'react-infinite-scroll-component';
import InfoCircleIcon from '@assets/icons/issues-drawer/info-circle.svg';
import { Loader } from '@components/Loader';
import { PiiScanResultDetailsDialog } from '@components/PiiScanResultDetailsDialog';
import XCircleIcon from '@assets/icons/date-picker/x-circle.svg';
import clsx from 'clsx';
import saveAs from 'file-saver';
import useStyles from './styles';

const pageSize = 7;

interface IProps {
  delay: number;
}

export const IssuesDrawer = ({ delay }: IProps) => {
  const { classes } = useStyles();
  const confirm = useConfirm();
  const products = useAppSelector(selectAuthProducts);

  const issuesDrawerOpened = useAppSelector(selectGeneralIssuesDrawerOpened);
  const issuesDrawerSelectedFileId = useAppSelector(selectGeneralIssuesDrawerSelectedFileId);
  const issuesDrawerSelectedFilters = useAppSelector(selectGeneralIssuesDrawerSelectedFilters);
  const getFilesActionLink = useAppSelector(selectAuthGetFilesActionLink);
  const getClientsActionLink = useAppSelector(selectAuthGetClientsActionLink);
  const acceptableFilesCount = useAppSelector(selectAcceptableFilesCount);
  const autoCloseScanResultsDialog = useAppSelector(selectGeneralAutoCloseScanResultsDialog);
  const dispatch = useAppDispatch();

  const searchInput = useInput<string>('');
  const clientInput = useInput<IAutocompleteOption | null>(null);
  const productInput = useInput<IAutocompleteOption | null>(null);
  const projectInput = useInput<IAutocompleteOption | null>(null);
  const [topFile, setTopFile] = useState<IFile | null>(null);
  const [files, setFiles] = useState<IFile[]>([]);
  const [hasMore, setHasMore] = useState(true);

  const [selectedFile, setSelectedFile] = useState<IFile | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [clients, setClients] = useState<IClient[]>([]);
  const [clientProducts, setClientProducts] = useState<IClientProduct[]>([]);
  const [projects, setProjects] = useState<IProject[]>([]);

  const [preselectedClientId, setPreselectedClientId] = useState<string | null>(null);
  const [preselectedProductId, setPreselectedProductId] = useState<string | null>(null);
  const [preselectedProjectId, setPreselectedProjectId] = useState<string | null>(null);

  const preSelectionApplied = useMemo(
    () => !preselectedClientId && !preselectedProductId && !preselectedProjectId,
    [preselectedClientId, preselectedProductId, preselectedProjectId],
  );
  const debouncedPreSelectionApplied = useDebounce(preSelectionApplied, delay);

  const filters = useMemo(
    () => ({
      search: searchInput.value,
      clientId: clientInput.value?.value,
      productId: productInput.value?.value,
      projectId: projectInput.value?.value,
    }),
    [searchInput.value, clientInput.value, productInput.value, projectInput.value],
  );
  const debouncedFilters = useDebounce(filters, delay);

  const {
    request: getClientsRequest,
    data: getClientsData,
    loading: getClientsLoading,
  } = useApi(getClients, null, { handleErrors: true });

  const {
    request: getClientProductsRequest,
    data: getClientProductsData,
    loading: getClientProductsLoading,
  } = useApi(getClientProducts, null, { handleErrors: true });

  const {
    request: getProjectsRequest,
    data: getProjectsData,
    loading: getProjectsLoading,
  } = useApi(getProjects, null, { handleErrors: true });

  const {
    request: getFileRequest,
    data: getFileData,
    loading: getFileLoading,
  } = useApi(getFile, null, { handleErrors: true });

  const {
    request: deleteFileRequest,
    data: deleteFileData,
    loading: deleteFileLoading,
  } = useApi(
    async (link: string, id: string) => {
      await deleteFile(link);
      return id;
    },
    null,
    {
      handleErrors: true,
    },
  );

  const {
    request: getFilesRequest,
    data: getFilesData,
    loading: getFilesLoading,
  } = useApi(getFiles, null, { handleErrors: true });

  const {
    request: getMoreFilesRequest,
    data: getMoreFilesData,
    loading: getMoreFilesLoading,
  } = useApi(getFiles, null, { handleErrors: true });

  const { request: getFileContentRequest, loading: getFileContentLoading } = useApi(
    getFileContent,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) saveAs(data.content);
      },
    },
  );

  const {
    request: updateFileUserStatusRequest,
    data: updateFileUserStatusData,
    loading: updateFileUserStatusLoading,
  } = useApi(updateFileUserStatus, null, {
    handleErrors: true,
  });

  const getUniqueFiles = useCallback(
    (arr1: IFile[], arr2: IFile[]) =>
      arr1
        .concat(arr2)
        .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id)),
    [],
  );

  const onRefreshData = () => {
    if (getFilesActionLink && issuesDrawerOpened) {
      getFilesRequest(
        getFilesActionLink.href,
        Math.min(Math.max(files.length, pageSize), 100),
        0,
        debouncedFilters.search,
        debouncedFilters.clientId,
        debouncedFilters.productId,
        debouncedFilters.projectId,
      );
    }
  };

  const onReloadTopFile = () => {
    if (issuesDrawerOpened && issuesDrawerSelectedFileId) {
      getFileRequest(`/files/${issuesDrawerSelectedFileId}`);
    }
  };

  const excludeItemAndRefresh = (id: string) => {
    setFiles((files) => {
      return files.filter((x) => x.id !== id);
    });
    setTopFile((file) => (file?.id === id ? null : file));
    onRefreshData();
    if (autoCloseScanResultsDialog) {
      setOpenDialog(false);
    }
  };

  const clearTopFile = () => {
    setTopFile(null);
  };

  useUpdateEffect(() => {
    if (clientInput.value?.value) {
      const selectedClient = clients.find((x) => x.id == clientInput.value?.value);
      if (selectedClient?.links[Actions.getProducts]) {
        getClientProductsRequest(selectedClient.links[Actions.getProducts].href);
      }
    } else {
      setClientProducts([]);
    }
  }, [clients, clientInput.value?.value]);

  useUpdateEffect(() => {
    productInput.set(null);
  }, [clientInput.value?.value]);

  useUpdateEffect(() => {
    if (productInput.value?.value) {
      const selectedClientProduct = clientProducts.find(
        (x) => x.productId == productInput.value?.value,
      );
      if (selectedClientProduct?.links[Actions.getProjects]) {
        getProjectsRequest(selectedClientProduct.links[Actions.getProjects].href);
      }
    } else {
      setProjects([]);
    }
  }, [clientProducts, productInput.value?.value]);

  useUpdateEffect(() => {
    projectInput.set(null);
  }, [productInput.value?.value]);

  useEffect(() => {
    if (issuesDrawerOpened && issuesDrawerSelectedFileId) {
      getFileRequest(`/files/${issuesDrawerSelectedFileId}`);
    }
  }, [issuesDrawerOpened, issuesDrawerSelectedFileId]);

  useEffect(() => {
    if (!debouncedPreSelectionApplied) return;
    if (issuesDrawerOpened && getFilesActionLink) {
      getFilesRequest(
        getFilesActionLink.href,
        pageSize,
        0,
        debouncedFilters.search,
        debouncedFilters.clientId,
        debouncedFilters.productId,
        debouncedFilters.projectId,
      );
    }
  }, [issuesDrawerOpened, getFilesActionLink, debouncedFilters, debouncedPreSelectionApplied]);

  useEffect(() => {
    if (issuesDrawerOpened && getClientsActionLink) {
      getClientsRequest(getClientsActionLink.href);
    }
  }, [issuesDrawerOpened, getClientsActionLink]);

  useEffect(() => {
    if (!searchInput.usedSetter) clearTopFile();
  }, [searchInput.value, searchInput.usedSetter]);

  useEffect(() => {
    if (!clientInput.usedSetter) clearTopFile();
  }, [clientInput.value, clientInput.usedSetter]);

  useEffect(() => {
    if (!productInput.usedSetter) clearTopFile();
  }, [productInput.value, productInput.usedSetter]);

  useEffect(() => {
    if (!projectInput.usedSetter) clearTopFile();
  }, [projectInput.value, projectInput.usedSetter]);

  useEffect(() => {
    if (!issuesDrawerOpened) {
      setTopFile(null);
      setFiles([]);
      setClients([]);
      setClientProducts([]);
      setProjects([]);
      searchInput.set('');
      clientInput.set(null);
      productInput.set(null);
      projectInput.set(null);
    }
  }, [issuesDrawerOpened]);

  useUpdateEffect(() => {
    if (getFileData) {
      if (getFileData.links[Actions.updateUserStatus]) {
        setTopFile(getFileData);
      } else {
        setTopFile(null);
      }
    }
  }, [getFileData]);

  useUpdateEffect(() => {
    if (getFilesData) {
      setFiles(getFilesData);
      setHasMore(true);
    }
  }, [getFilesData]);

  useUpdateEffect(() => {
    if (getClientsData) {
      setClients(getClientsData);
    }
  }, [getClientsData]);

  useUpdateEffect(() => {
    if (getClientProductsData) {
      setClientProducts(getClientProductsData);
    }
  }, [getClientProductsData]);

  useUpdateEffect(() => {
    if (getProjectsData) {
      setProjects(getProjectsData.items);
    }
  }, [getProjectsData]);

  useUpdateEffect(() => {
    if (getMoreFilesData) {
      setFiles((files) => getUniqueFiles(files, getMoreFilesData));
      setHasMore(getMoreFilesData.length === pageSize);
    }
  }, [getMoreFilesData]);

  useUpdateEffect(() => {
    if (updateFileUserStatusData) {
      excludeItemAndRefresh(updateFileUserStatusData.id);
    }
  }, [updateFileUserStatusData]);

  useUpdateEffect(() => {
    if (deleteFileData) {
      excludeItemAndRefresh(deleteFileData);
    }
  }, [deleteFileData]);

  useUpdateEffect(() => {
    if (acceptableFilesCount) {
      onRefreshData();
      onReloadTopFile();
    }
  }, [acceptableFilesCount]);

  useUpdateEffect(() => {
    setPreselectedClientId(issuesDrawerSelectedFilters.clientId);
    setPreselectedProductId(issuesDrawerSelectedFilters.productId);
    setPreselectedProjectId(issuesDrawerSelectedFilters.projectId);
  }, [
    issuesDrawerSelectedFilters.clientId,
    issuesDrawerSelectedFilters.productId,
    issuesDrawerSelectedFilters.projectId,
  ]);

  const onFetchMoreFiles = () => {
    if (getFilesActionLink)
      getMoreFilesRequest(
        getFilesActionLink.href,
        pageSize,
        files.length,
        debouncedFilters.search,
        debouncedFilters.clientId,
        debouncedFilters.productId,
        debouncedFilters.projectId,
      );
  };

  const onClose = () => {
    dispatch(setIssuesDrawerData({ open: false }));
  };

  const onDownloadFile = (file: IFile) => {
    if (file.links[Actions.getContent]?.href) {
      getFileContentRequest(file.links[Actions.getContent].href);
    }
  };

  const onDeleteFile = async (file: IFile) => {
    if (file?.links[Actions.delete].href) {
      await confirm({
        title: 'Delete file',
        description: 'This will permanently delete this file.',
        confirmationText: 'Confirm',
      });
      deleteFileRequest(file.links[Actions.delete].href, file.id);
    }
  };

  const onViewPiiScanResultDetails = (file: IFile) => {
    setSelectedFile(file);
    setOpenDialog(true);
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
    setSelectedFile(null);
  };

  const onUpdateStatus = (file: IFile, status: FileUserStatuses) => {
    if (file?.links[Actions.updateUserStatus]?.href) {
      updateFileUserStatusRequest(file.links[Actions.updateUserStatus].href, {
        status,
      });
    }
  };

  const combinedFiles = useMemo<IFile[]>(() => {
    if (topFile) return [topFile, ...files.filter((x) => x.id !== topFile.id)];
    return files;
  }, [topFile, files]);

  const clientOptions = useMemo<IAutocompleteOption[]>(() => {
    return clients.map((x) => ({
      value: x.id,
      title: x.name,
      subtitle: [x.addressState, x.externalId].filter((x) => !!x).join(' - '),
    }));
  }, [clients]);

  useUpdateEffect(() => {
    if (preselectedClientId && clientOptions.length) {
      const selectedClientOption = clientOptions.find((x) => x.value === preselectedClientId);
      clientInput.set(selectedClientOption || null);
      setPreselectedClientId(null);
    } else if (clientOptions.length == 1) {
      const [firstClientOption] = clientOptions;
      clientInput.set(firstClientOption);
    }
  }, [clientOptions, preselectedClientId]);

  const productOptions = useMemo<IAutocompleteOption[]>(() => {
    return products
      .filter((x) => clientProducts.some((cp) => cp.productId == x.id))
      .map((x) => ({
        value: x.id,
        title: x.name,
      }));
  }, [products, clientProducts]);

  useUpdateEffect(() => {
    if (preselectedProductId && productOptions.length) {
      const selectedProductOption = productOptions.find((x) => x.value === preselectedProductId);
      productInput.set(selectedProductOption || null);
      setPreselectedProductId(null);
    }
  }, [productOptions, preselectedProductId]);

  const projectOptions = useMemo<IAutocompleteOption[]>(() => {
    return projects.map((x) => ({
      value: x.id,
      title: x.name,
    }));
  }, [projects]);

  useUpdateEffect(() => {
    if (preselectedProjectId && projectOptions.length) {
      const selectedProjectOption = projectOptions.find((x) => x.value === preselectedProjectId);
      projectInput.set(selectedProjectOption || null);
      setPreselectedProjectId(null);
    }
  }, [projectOptions, preselectedProjectId]);

  const showLoader = useLoader(
    getFileLoading,
    deleteFileLoading,
    getFilesLoading,
    getClientsLoading,
    getClientProductsLoading,
    getProjectsLoading,
    getMoreFilesLoading,
    getFileContentLoading,
    updateFileUserStatusLoading,
  );

  return (
    <>
      <Drawer
        anchor='right'
        variant='temporary'
        open={issuesDrawerOpened}
        onClose={onClose}
        data-testid='issues-drawer'
      >
        <Box className={classes.container}>
          <Box className={clsx([classes.flex, classes.spaceBetween])}>
            <Typography variant='h5' fontWeight={800}>
              Issues
            </Typography>
            <IconButton onClick={onClose}>
              <img src={CloseIcon} alt='Close' />
            </IconButton>
          </Box>
          <Box className={classes.infoSection}>
            <img src={InfoCircleIcon} alt='Info' />
            <Typography variant='subtitle2'>The following files require your attention.</Typography>
          </Box>
          <Box className={classes.controlsSection}>
            {clientOptions.length > 1 && (
              <CustomAutocomplete
                value={clientInput.value}
                label='Client'
                placeholder='Select Client'
                onChange={(value) => clientInput.onChange({ target: { value } })}
                options={clientOptions}
                clearIcon={<img src={XCircleIcon} alt='Clear' />}
              />
            )}
            <CustomAutocomplete
              value={productInput.value}
              label='Product'
              placeholder='Select Product'
              onChange={(value) => productInput.onChange({ target: { value } })}
              options={productOptions}
              clearIcon={<img src={XCircleIcon} alt='Clear' />}
            />
            <CustomAutocomplete
              value={projectInput.value}
              label='Project'
              placeholder='Select Project'
              onChange={(value) => projectInput.onChange({ target: { value } })}
              options={projectOptions}
              clearIcon={<img src={XCircleIcon} alt='Clear' />}
            />
            <SearchInput
              onChange={(value) => searchInput.onChange({ target: { value } })}
              color={SearchInputColors.grey}
              maxWidth={180}
              placeholder='Search by name'
            />
          </Box>
          <Box className={classes.itemsContainer} id='scrollable-container'>
            <InfiniteScroll
              dataLength={combinedFiles.length}
              next={onFetchMoreFiles}
              hasMore={hasMore}
              loader={null}
              scrollableTarget='scrollable-container'
              className={classes.scrollContainer}
            >
              {combinedFiles.map((item) => (
                <FileCard
                  key={item.id}
                  item={item}
                  onDetails={() => onViewPiiScanResultDetails(item)}
                  onDownload={() => onDownloadFile(item)}
                  onDelete={() => onDeleteFile(item)}
                />
              ))}
            </InfiniteScroll>
          </Box>
          <Loader show={showLoader} fixed={false} />
        </Box>
      </Drawer>

      {selectedFile && (
        <PiiScanResultDetailsDialog
          file={selectedFile}
          open={openDialog}
          onClose={onCloseDialog}
          onUpdate={onUpdateStatus}
        />
      )}
    </>
  );
};

IssuesDrawer.defaultProps = {
  delay: 500,
};
