import { Box, Typography } from '@mui/material';

import { IClient } from '@models/interfaces/entities/IClient';
import UsersFilledIcon from '@assets/icons/dashboard/users-filled.svg';
import useStyles from './styles';

export interface IProps {
  client: IClient;
  onSelected: () => void;
}

export const ClientCard = ({ client, onSelected }: IProps) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.root} onClick={() => onSelected()}>
      <Box className={classes.icon}>
        <img src={UsersFilledIcon} alt='users' />
      </Box>
      <Typography variant='subtitle1' className={classes.title}>
        {client.name}
      </Typography>
      {client.addressState && (
        <Box className={classes.additional}>
          <Typography variant='overline'>{client.addressState}</Typography>
        </Box>
      )}
    </Box>
  );
};
