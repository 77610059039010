import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    backgroundColor: t.palette.custom.dark[5],
  },
  card: {
    padding: 8,
  },
  title: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  balanceLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '&>span': {
      fontWeight: 600,
    },
  },
  table: {
    maxHeight: 300,

    '& .MuiDataGrid-virtualScroller': {
      minHeight: 52,
    },
  },
  flexCell: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    gap: 8,
  },
  accordionSummaryContent: {
    margin: 0,
    justifyContent: 'space-between',
    paddingTop: 2,
    paddingBottom: 2,

    '&.Mui-expanded': {
      marginTop: 8,
      marginBottom: 8,
    },
  },
  helperText: {
    position: 'absolute',
    top: 9,
    zIndex: -1,
  },
}));

export default useStyles;
