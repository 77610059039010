import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    minHeight: 50,
    gap: 16,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 10,
    border: `1px dashed ${t.palette.custom.dark[20]}`,
    backgroundColor: t.palette.custom.dark[5],
    justifyContent: 'space-between',
  },
}));

export default useStyles;
