import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    minHeight: 50,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 10,
    border: `1px solid ${t.palette.custom.dark[20]}`,
    justifyContent: 'space-between',

    '&.inactive': {
      backgroundColor: t.palette.custom.dark[10],
    },

    '&.active:after': {
      width: 60,
      height: 2,
      position: 'absolute',
      right: 0,
      top: '50%',
      marginTop: -1,
      marginRight: -55,
      content: '""',
      backgroundColor: t.palette.custom.dark[40],
    },
  },
  title: {
    wordBreak: 'break-word',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    visibility: 'visible',
    overflow: 'hidden',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
}));

export default useStyles;
