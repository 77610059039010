import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  container: { width: 438, padding: 24, height: '100vh', display: 'flex', flexDirection: 'column' },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  gap16: {
    gap: 16,
  },
  mt24: {
    marginTop: 24,
  },
  infoSection: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    marginTop: 20,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 4,
    backgroundColor: '#4DB4EE',
    color: '#fff',
    boxShadow: '0px 10px 20px rgba(81, 105, 117, 0.2)',
  },
  controlsSection: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 16,
    paddingBottom: 16,
    borderBottom: `1px solid ${t.palette.divider}`,
    gap: 16,
  },
  itemsContainer: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingRight: 8,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: t.palette.custom.dark[67],
      borderRadius: 4,
    },
  },
  scrollContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
}));

export default useStyles;
