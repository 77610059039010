import { Alert, Box, Button, IconButton, Typography } from '@mui/material';
import { useConfirm, useUpdateEffect } from '@hooks';
import { useContext, useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { CustomDialog } from '@components/CustomDialog';
import { FileTypes } from '@models/enums/FileTypes';
import { IProject } from '@models/interfaces/entities/IProject';
import { ProjectFilesCacheActions } from '@reducers/projectFilesCacheReducer';
import ProjectFilesCacheContext from '@contexts/ProjectFilesCacheContext';
import { ProjectStatuses } from '@models/enums/ProjectStatuses';
import UploadIcon from '@assets/icons/dialog/upload.svg';
import XIcon from '@assets/icons/dialog/x.svg';
import useStyles from './styles';
import { v4 as uuidv4 } from 'uuid';

export interface IProps {
  project: IProject;
  fileType: FileTypes;
  open: boolean;
  onClose: (data?: boolean) => void;
}

export interface IFormData {
  files: {
    content: File;
  }[];
}

export const UploadingDialog = ({ project, fileType, open, onClose }: IProps) => {
  const { classes } = useStyles();
  const confirm = useConfirm();

  const { cache, dispatch: cacheDispatch } = useContext(ProjectFilesCacheContext);

  const files = useMemo(
    () => cache.find((x) => x.projectId === project.id)?.files || [],
    [cache, project],
  );

  const formDefaultData = useMemo<IFormData>(() => {
    return {
      files,
    };
  }, [open, files]);

  const form = useForm<IFormData>({
    defaultValues: formDefaultData,
  });

  const watchFiles = form.watch('files');

  const { fields: fileItems, remove: removeFileItem } = useFieldArray({
    name: 'files',
    control: form.control,
  });

  const onRemoveFileItem = (index: number) => {
    removeFileItem(index);
  };

  const onCancel = () => {
    onClose();
  };

  const onFormSubmit = async () => {
    if (
      (project.status !== ProjectStatuses.open && fileType === FileTypes.dataFile) ||
      (project.status !== ProjectStatuses.inProgress && fileType === FileTypes.resultFile)
    ) {
      await confirm({
        title: 'Upload Files',
        description:
          project.status === ProjectStatuses.inProgress
            ? 'We have started processing the projects files. Are you sure you still intend to upload new files to this project?'
            : 'The project is currently closed. Are you sure you still intend to upload new files to this project?',
        confirmationText: 'Confirm',
      });
    }

    onClose(true);
  };

  useEffect(() => {
    form.reset(formDefaultData);
  }, [open]);

  useUpdateEffect(() => {
    if (open) {
      cacheDispatch({
        type: ProjectFilesCacheActions.setFiles,
        payload: {
          projectId: project.id,
          files: watchFiles,
        },
      });
    }
  }, [open, watchFiles, project]);

  return (
    <>
      <CustomDialog
        title='Upload'
        onClose={onCancel}
        open={open}
        maxWidth='sm'
        fullWidth
        actions={
          <>
            <Typography variant='body2'>Total: {watchFiles.length}</Typography>
            <Button
              type='submit'
              form='form'
              variant='contained'
              size='large'
              color='secondary'
              disabled={!watchFiles.length}
              startIcon={<img alt='upload' src={UploadIcon} />}
            >
              Upload
            </Button>
          </>
        }
      >
        <Alert color='warning' icon={false} className={classes.mb24}>
          Notice: we only scan random portions of the file for PII and thus could still potentially
          miss PII that is present. Please verify that no PII is present in the file.
        </Alert>

        <form
          id='form'
          onSubmit={form.handleSubmit(() => {
            onFormSubmit();
          })}
          noValidate
        >
          {fileItems.map((item, index) => (
            <Box key={uuidv4()} className={classes.fileItemContainer} data-testid='file-record'>
              <Typography variant='subtitle2'>{item.content.name}</Typography>
              <Box className={classes.fileItemControls}>
                <IconButton onClick={() => onRemoveFileItem(index)}>
                  <img src={XIcon} alt='Remove' />
                </IconButton>
              </Box>
            </Box>
          ))}
        </form>
      </CustomDialog>
    </>
  );
};
