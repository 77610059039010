import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  container: {
    gap: 8,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 8,
    borderRadius: 10,
    border: `1px solid ${t.palette.divider}`,
  },
  withPii: {
    backgroundColor: t.palette.custom.error[10],
    border: `1px solid ${t.palette.custom.error[100]}`,
  },
  unscanned: {
    border: `1px solid ${t.palette.custom.primary[100]}`,
  },
  gap4: {
    gap: 4,
  },
  justifyContentSpaceBetween: {
    justifyContent: 'space-between',
  },
  justifyContentEnd: {
    justifyContent: 'end',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  textBold: {
    fontWeight: 600,
  },
  textGrey: {
    color: t.palette.custom.dark[60],
  },
  textInfo: {
    color: t.palette.custom.info[100],
  },
  actionButton: {
    backgroundColor: t.palette.custom.white[100],
    borderWidth: 2,
    '&:hover': {
      borderWidth: 2,
      backgroundColor: t.palette.custom.error[5],
    },
  },
}));

export default useStyles;
