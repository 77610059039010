import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  optionContainer: { display: 'flex', alignItems: 'center' },
  optionAvatarContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 16,
  },
  optionContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  optionTitle: {
    color: t.palette.custom.dark[60],
  },
  optionSubtitle: {
    color: t.palette.custom.dark[60],
  },
}));

export default useStyles;
