import { Theme } from '@mui/material/styles';
import { Variants } from '.';
import { makeStyles } from 'tss-react/mui';

const getColor = (t: Theme, variant: Variants, color: string) => {
  switch (variant) {
    case Variants.dark:
      return { background: t.palette.custom.dark[100] };
    case Variants.grey:
      return { background: t.palette.custom.dark[60] };
    default:
      return { background: color };
  }
};

const useStyles = makeStyles<{ color: string; variant: Variants; size: number }>()(
  (t, { color, variant, size }) => {
    const selectedColor = getColor(t, variant, color);
    return {
      avatar: {
        backgroundColor: selectedColor.background,
        width: size,
        height: size,
      },
    };
  },
);

export default useStyles;
