import { Box, Button, Typography } from '@mui/material';

import { IGroup } from '@models/interfaces/entities/IGroup';
import { IUser } from '@models/interfaces/entities/IUser';
import { selectAuthAccount } from '@reducers/authSlice';
import { useAppSelector } from '@hooks';
import useStyles from './styles';

interface IProps {
  user: IUser;
  membership: IGroup;
  onRemove: () => void;
}

export const MembershipCard = ({ user, membership, onRemove }: IProps) => {
  const account = useAppSelector(selectAuthAccount);
  const { classes } = useStyles();

  return (
    <Box className={classes.root} key={membership.id}>
      <Box className={classes.content}>
        <Typography variant='subtitle1'>{membership.name}</Typography>
      </Box>
      <Box className={classes.controls}>
        <Button
          className={classes.removeBtn}
          variant='text'
          color='error'
          onClick={() => onRemove()}
        >
          {user?.id === account?.localAccountId ? 'Leave' : 'Remove'}
        </Button>
      </Box>
    </Box>
  );
};
