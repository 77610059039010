import { Mapper, createMap, forMember, mapFrom } from '@automapper/core';

import { IProject } from '@models/interfaces/entities/IProject';
import { IProjectDto } from '@models/interfaces/dtos/IProjectDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createProjectMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IProjectDto>('IProjectDto', {
    id: String,
    name: String,
    startDate: Number,
    endDate: Number,
    status: Number,
    statusMessage: String,
    confirmed: Boolean,
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IProject>('IProject', {
    id: String,
    name: String,
    startDate: Date,
    endDate: Date,
    status: String,
    confirmed: Boolean,
    links: 'ILinks',
  });

  createMap<IProjectDto, IProject>(
    mapper,
    'IProjectDto',
    'IProject',
    forMember(
      (destination) => destination.startDate,
      mapFrom((source) => new Date(source.startDate)),
    ),
    forMember(
      (destination) => destination.endDate,
      mapFrom((source) => (source.endDate ? new Date(source.endDate) : null)),
    ),
    forMember(
      (destination) => destination.status,
      mapFrom((source) => source.statusMessage),
    ),
  );
};
