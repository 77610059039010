import { Mapper, createMap } from '@automapper/core';

import { IFileCategory } from '@models/interfaces/entities/IFileCategory';
import { IFileCategoryDto } from '@models/interfaces/dtos/IFileCategoryDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createFileCategoryMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IFileCategoryDto>('IFileCategoryDto', {
    id: String,
    name: String,
    description: String,
    subCategoriesDisabled: Boolean,
    mappingsDisabled: Boolean,
    subAccountMatchingDisabled: Boolean,
    position: Number,
    productId: String,
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IFileCategory>('IFileCategory', {
    id: String,
    name: String,
    description: String,
    subCategoriesDisabled: Boolean,
    mappingsDisabled: Boolean,
    subAccountMatchingDisabled: Boolean,
    position: Number,
    productId: String,
    links: 'ILinks',
  });

  createMap<IFileCategoryDto, IFileCategory>(mapper, 'IFileCategoryDto', 'IFileCategory');
};
