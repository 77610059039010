import { Autocomplete, Box, Button, IconButton, TextField, Tooltip } from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';

import { CustomDialog } from '@components/CustomDialog';
import { IFileCategoryField } from '@models/interfaces/entities/IFileCategoryField';
import TrashIcon from '@assets/icons/dashboard/trash.svg';
import useStyles from './styles';
import { useUpdateEffect } from '@hooks';
import { v4 as uuidv4 } from 'uuid';

export interface SubAccountFormatData {
  format: string[];
  delinquentLoanThreshold: number;
}

export interface IProps {
  format: string[];
  delinquentLoanThreshold: number;
  fields: IFileCategoryField[];
  open: boolean;
  onClose: (parts?: SubAccountFormatData) => void;
}

export interface IFormData {
  parts: {
    id: string;
    value: string;
  }[];
  delinquentLoanThreshold: number;
}

export const ConfigureSubAccountFormatDialog = ({
  fields,
  format,
  delinquentLoanThreshold,
  open,
  onClose,
}: IProps) => {
  const { classes } = useStyles();

  const formDefaultData = useMemo<IFormData>(() => {
    return {
      parts: format.map((x) => ({ id: uuidv4(), value: x })),
      delinquentLoanThreshold,
    };
  }, [open]);

  const form = useForm<IFormData>({
    defaultValues: formDefaultData,
  });

  const {
    fields: parts,
    append: addPart,
    remove: removePart,
    replace: replaceParts,
  } = useFieldArray({
    name: 'parts',
    control: form.control,
  });

  const watchParts = form.watch('parts');

  const onAddPart = () => {
    addPart({ id: uuidv4(), value: '' });
  };

  const onRemovePart = (index: number) => {
    removePart(index);
  };

  const onCancel = () => {
    onClose();
  };

  const onReset = () => {
    form.reset(formDefaultData);
    replaceParts(formDefaultData.parts);
  };

  useEffect(() => {
    if (open) {
      onReset();
    }
  }, [open]);

  const onFormSubmit = async () => {
    const data = form.getValues();
    onClose({
      format: data.parts.map((x) => x.value),
      delinquentLoanThreshold: +data.delinquentLoanThreshold,
    });
  };

  const fieldOptions = useMemo(() => fields.map((x) => x.displayName || x.name), [fields]);

  const showDelinquentLoanThreshold = useMemo(
    () => watchParts.map((x) => x.value).join('') === 'Type-Delinquent Loans (days)',
    [watchParts.map((x) => x.value).join('')],
  );

  useUpdateEffect(() => {
    if (!showDelinquentLoanThreshold) {
      form.setValue('delinquentLoanThreshold', 0);
    }
  }, [showDelinquentLoanThreshold]);

  return (
    <CustomDialog
      title='SubAccount ID Format'
      onClose={onCancel}
      open={open}
      maxWidth='sm'
      fullWidth
      actions={
        <>
          <Button variant='outlined' color='secondary' className='narrow' onClick={onAddPart}>
            Add
          </Button>
          <Button type='submit' form='said-form' variant='contained' size='large' color='secondary'>
            Save
          </Button>
        </>
      }
    >
      <Box className={classes.root}>
        <form
          id='said-form'
          onSubmit={form.handleSubmit(() => {
            onFormSubmit();
          })}
          noValidate
          className={classes.scrollableContainer}
        >
          <Box className={classes.controlsList}>
            {parts?.map((item, index) => (
              <Box key={item.id} className={classes.controlRow}>
                <Controller
                  name={`parts.${index}.value`}
                  control={form.control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      className={classes.autocomplete}
                      freeSolo
                      options={fieldOptions}
                      value={value}
                      onInputChange={(e, v) => {
                        onChange({ target: { value: v } });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant='outlined' fullWidth />
                      )}
                    />
                  )}
                />
                <Box className={classes.controls}>
                  <Tooltip title='Delete file'>
                    <IconButton onClick={() => onRemovePart(index)} data-testid='delete-button'>
                      <img src={TrashIcon} alt='trash' />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            ))}

            {showDelinquentLoanThreshold && (
              <Controller
                name={'delinquentLoanThreshold'}
                control={form.control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    type='number'
                    fullWidth
                    value={value}
                    variant='outlined'
                    label='Delinquent loan threshold'
                    onChange={onChange}
                    autoComplete='off'
                    className={classes.mt40}
                    inputProps={{
                      'data-testid': 'delinquent-loan-threshold',
                    }}
                  />
                )}
              />
            )}
          </Box>
        </form>
      </Box>
    </CustomDialog>
  );
};
