import ClientsProvider from '@providers/ClientsProvider';
import { IClient } from '@models/interfaces/entities/IClient';
import { IClientDto } from '@models/interfaces/dtos/IClientDto';
import { ICreateClientData } from '@models/interfaces/additional/ICreateClientData';
import { ICreateClientRequest } from '@models/interfaces/requests/ICreateClientRequest';
import { IUpdateClientData } from '@models/interfaces/additional/IUpdateClientData';
import { IUpdateClientRequest } from '@models/interfaces/requests/IUpdateClientRequest';
import mapper from '@models/mapper';

const clientsProvider = new ClientsProvider();

export const getClients = async (url: string) => {
  const result = await clientsProvider.getClients(url);
  return mapper.mapArray<IClientDto, IClient>(result.items, 'IClientDto', 'IClient');
};

export const getClient = async (url: string) => {
  const result = await clientsProvider.getClient(url);
  return mapper.map<IClientDto, IClient>(result, 'IClientDto', 'IClient');
};

export const createClient = async (url: string, data: ICreateClientData) => {
  const requestData = mapper.map<ICreateClientData, ICreateClientRequest>(
    data,
    'ICreateClientData',
    'ICreateClientRequest',
  );
  const result = await clientsProvider.createClient(url, requestData);
  return mapper.map<IClientDto, IClient>(result, 'IClientDto', 'IClient');
};

export const updateClient = async (url: string, data: IUpdateClientData) => {
  const requestData = mapper.map<IUpdateClientData, IUpdateClientRequest>(
    data,
    'IUpdateClientData',
    'IUpdateClientRequest',
  );
  const result = await clientsProvider.updateClient(url, requestData);
  return mapper.map<IClientDto, IClient>(result, 'IClientDto', 'IClient');
};

export const deleteClient = async (url: string) => {
  await clientsProvider.deleteClient(url);
  return true;
};
