import {
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
  useGridApiContext,
} from '@mui/x-data-grid-pro';

import { Button } from '@mui/material';
import { FileCategoryFieldType } from '@models/enums/FileCategoryFieldType';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
  disabled: boolean;
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

export const FieldsGridToolbar = (props: IProps) => {
  const { setRows, setRowModesModel, disabled } = props;
  const apiRef = useGridApiContext();

  const handleClick = () => {
    const id = uuidv4();
    setRows((oldRows) => [
      ...oldRows,
      { id, name: '', displayName: '', type: FileCategoryFieldType.string, isRequired: false },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));

    setTimeout(() => {
      apiRef.current.scroll({ left: 0, top: 9999 });
    }, 100);
  };

  return (
    <GridToolbarContainer>
      <Button
        color='primary'
        variant='outlined'
        size='small'
        onClick={handleClick}
        disabled={disabled}
      >
        Add Field
      </Button>
    </GridToolbarContainer>
  );
};
