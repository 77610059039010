type EnvType = {
  REACT_APP_API_URL: string;
  REACT_APP_UI_URL: string;
  REACT_APP_ENVIRONMENT: string;
  REACT_APP_B2C_CLIENT_ID: string;
  REACT_APP_B2C_TENANT_ID: string;
  REACT_APP_B2C_API: string;
  REACT_APP_B2C_SCOPES: string;
  REACT_APP_ADMIN_GROUP: string;
  REACT_APP_MUI_LICENSE_KEY: string;
  REACT_APP_B2C_DOMAIN: string;
};

declare global {
  interface Window {
    __RUNTIME_CONFIG__: EnvType;
  }
}

export const env: EnvType = { ...process.env, ...window.__RUNTIME_CONFIG__ };
