import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  productsList: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: 16,
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 16,
  },
}));

export default useStyles;
