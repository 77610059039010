import BaseProvider from './BaseProvider';
import { IGetFeatureResponse } from '@models/interfaces/responses/IGetFeatureResponse';
import { IGetFeaturesResponse } from '@models/interfaces/responses/IGetFeaturesResponse';
import { IUpdateFeatureRequest } from '@models/interfaces/requests/IUpdateFeatureRequest';
import { IUpdateFeatureResponse } from '@models/interfaces/responses/IUpdateFeatureResponse';

export default class FeaturesProvider extends BaseProvider {
  public getFeatures = (url: string) => {
    return this.get<IGetFeaturesResponse>(url);
  };

  public getFeature = (url: string) => {
    return this.get<IGetFeatureResponse>(url);
  };

  public updateFeature = (url: string, data: IUpdateFeatureRequest) => {
    return this.patch<IUpdateFeatureRequest, IUpdateFeatureResponse>(url, data);
  };
}
