import { Box, Button, Typography } from '@mui/material';
import { StringAvatar, Variants } from '@components/StringAvatar';

import PlusIcon from '@assets/icons/plus-filled.svg';
import { SearchInput } from '@components/SearchInput';
import { useInput } from '@hooks';
import { useMemo } from 'react';
import useStyles from './styles';

export interface ISectionMenuItem {
  id: string;
  title: string;
  subtitle?: string;
  additional?: string;
}

interface IProps {
  title: string;
  items: ISectionMenuItem[];
  showAddButton?: boolean;
  showAvatar?: boolean;
  activeItemId?: string;
  onAddItem?: () => void;
  onItemSelected: (id: string) => void;
}

export const SectionMenu = ({
  title,
  items,
  showAddButton,
  showAvatar,
  activeItemId,
  onAddItem,
  onItemSelected,
}: IProps) => {
  const { classes } = useStyles();

  const searchInput = useInput<string>('');

  const filteredItems = useMemo(() => {
    const regexp = new RegExp(searchInput.value, 'i');
    return regexp ? items.filter((x) => regexp.test(x.title)) : items;
  }, [items, searchInput.value]);

  return (
    <Box className={classes.container}>
      <Box className={classes.title}>
        <Typography variant='h6'>{title}</Typography>
        {showAddButton && (
          <Button className={classes.createButton} onClick={onAddItem}>
            <img src={PlusIcon} alt='Add' />
          </Button>
        )}
      </Box>
      <Box className={classes.searchContainer}>
        <SearchInput onChange={(value) => searchInput.onChange({ target: { value } })} />
      </Box>
      <Box className={classes.itemsContainer}>
        {filteredItems.map((item) => (
          <Box
            key={item.id}
            className={activeItemId === item.id ? classes.activeItem : classes.item}
            onClick={() => onItemSelected(item.id)}
          >
            {showAvatar && (
              <Box className={classes.avatar}>
                <StringAvatar
                  variant={activeItemId === item.id ? Variants.dark : Variants.grey}
                  value={item.title}
                  size='sm'
                />
              </Box>
            )}
            <Box className='info'>
              <Typography className='title' variant='subtitle2'>
                {item.title}
              </Typography>
              <Box className='additional'>
                {!!item.subtitle && (
                  <Typography className='subtitle' variant='overline'>
                    {item.subtitle}
                  </Typography>
                )}
                {!!item.additional && (
                  <Typography className='subtitle' variant='overline'>
                    {item.additional}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
