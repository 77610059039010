import BaseProvider from './BaseProvider';
import { IGetActivityEventsResponse } from '@models/interfaces/responses/IGetActivityEventsResponse';

export default class ActivityEventsProvider extends BaseProvider {
  public getActivityEvents = async (
    url: string,
    limit?: number,
    offset?: number,
    search?: string,
    startDate?: number,
    endDate?: number,
    clientId?: string,
  ) => {
    return this.get<IGetActivityEventsResponse>(url, {
      params: {
        limit,
        offset,
        search,
        startDate,
        endDate,
        clientId,
      },
    });
  };
}
