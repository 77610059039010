import FileSubCategoryMappingsProvider from '@providers/FileSubCategoryMappingsProvider';
import { ICreateFileSubCategoryMappingData } from '@models/interfaces/additional/ICreateFileSubCategoryMappingData';
import { ICreateFileSubCategoryMappingRequest } from '@models/interfaces/requests/ICreateFileSubCategoryMappingRequest';
import { IFileSubCategoryMapping } from '@models/interfaces/entities/IFileSubCategoryMapping';
import { IFileSubCategoryMappingDto } from '@models/interfaces/dtos/IFileSubCategoryMappingDto';
import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import { IUpdateFileSubCategoryMappingData } from '@models/interfaces/additional/IUpdateFileSubCategoryMappingData';
import { IUpdateFileSubCategoryMappingRequest } from '@models/interfaces/requests/IUpdateFileSubCategoryMappingRequest';
import mapper from '@models/mapper';

const fileSubCategoryMappingsProvider = new FileSubCategoryMappingsProvider();

export const getFileSubCategoryMappings = async (url: string) => {
  const result = await fileSubCategoryMappingsProvider.getFileSubCategoryMappings(url);
  return {
    items: mapper.mapArray<IFileSubCategoryMappingDto, IFileSubCategoryMapping>(
      result.items,
      'IFileSubCategoryMappingDto',
      'IFileSubCategoryMapping',
    ),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const createFileSubCategoryMapping = async (
  url: string,
  data: ICreateFileSubCategoryMappingData,
) => {
  const requestData = mapper.map<
    ICreateFileSubCategoryMappingData,
    ICreateFileSubCategoryMappingRequest
  >(data, 'ICreateFileSubCategoryMappingData', 'ICreateFileSubCategoryMappingRequest');
  const result = await fileSubCategoryMappingsProvider.createFileSubCategoryMapping(
    url,
    requestData,
  );
  return mapper.map<IFileSubCategoryMappingDto, IFileSubCategoryMapping>(
    result,
    'IFileSubCategoryMappingDto',
    'IFileSubCategoryMapping',
  );
};

export const getFileSubCategoryMapping = async (url: string) => {
  const result = await fileSubCategoryMappingsProvider.getFileSubCategoryMapping(url);
  return mapper.map<IFileSubCategoryMappingDto, IFileSubCategoryMapping>(
    result,
    'IFileSubCategoryMappingDto',
    'IFileSubCategoryMapping',
  );
};

export const updateFileSubCategoryMapping = async (
  url: string,
  data: IUpdateFileSubCategoryMappingData,
) => {
  const requestData = mapper.map<
    IUpdateFileSubCategoryMappingData,
    IUpdateFileSubCategoryMappingRequest
  >(data, 'IUpdateFileSubCategoryMappingData', 'IUpdateFileSubCategoryMappingRequest');
  const result = await fileSubCategoryMappingsProvider.updateFileSubCategoryMapping(
    url,
    requestData,
  );
  return mapper.map<IFileSubCategoryMappingDto, IFileSubCategoryMapping>(
    result,
    'IFileSubCategoryMappingDto',
    'IFileSubCategoryMapping',
  );
};

export const cloneFileSubCategoryMapping = async (url: string) => {
  const result = await fileSubCategoryMappingsProvider.cloneFileSubCategoryMapping(url);
  return mapper.map<IFileSubCategoryMappingDto, IFileSubCategoryMapping>(
    result,
    'IFileSubCategoryMappingDto',
    'IFileSubCategoryMapping',
  );
};
