import { selectAuthGetProductsActionLink, setProducts } from '@reducers/authSlice';
import { useApi, useAppDispatch, useAppSelector, useLoader, useUpdateEffect } from '@hooks';
import { useCallback, useEffect } from 'react';

import { Actions } from '@models/enums/Actions';
import { Box } from '@mui/material';
import { Loader } from '@components/Loader';
import { ProductView } from './components/ProductView';
import { ProductsMenu } from './components/ProductsMenu';
import { getProducts } from '@services/api';
import { useParams } from 'react-router-dom';
import useStyles from './styles';

export const ProductsPage = () => {
  const { classes } = useStyles();
  const getProductsActionLink = useAppSelector(selectAuthGetProductsActionLink);
  const { productId } = useParams();
  const dispatch = useAppDispatch();

  const {
    request: getProductsRequest,
    data: getProductsData,
    loading: getProductsLoading,
  } = useApi(getProducts, null, { handleErrors: true });

  useEffect(() => {
    if (getProductsActionLink) getProductsRequest(getProductsActionLink.href);
  }, [getProductsActionLink]);

  const onChanged = useCallback(() => {
    if (getProductsActionLink) getProductsRequest(getProductsActionLink.href);
  }, [getProductsActionLink]);

  useUpdateEffect(() => {
    if (getProductsData) dispatch(setProducts(getProductsData.items));
  }, [getProductsData]);

  const showLoader = useLoader(getProductsLoading);

  return (
    <Box className={classes.container}>
      <ProductsMenu
        onProductAdded={onChanged}
        products={getProductsData?.items || []}
        createProductActionLink={getProductsData?.links[Actions.createProduct]}
        activeProductId={productId}
      />
      {productId && <ProductView onProductChanged={onChanged} productId={productId} />}
      <Loader show={showLoader} />
    </Box>
  );
};
