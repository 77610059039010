import { Mapper, createMap } from '@automapper/core';

import { IPiiScanResult } from '@models/interfaces/entities/IPiiScanResult';
import { IPiiScanResultDto } from '@models/interfaces/dtos/IPiiScanResultDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createPiiScanResultMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IPiiScanResultDto>('IPiiScanResultDto', {
    fileId: String,
    status: Number,
    statusMessage: String,
    message: String,
    clientId: String,
    piiTypes: [String],
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IPiiScanResult>('IPiiScanResult', {
    fileId: String,
    status: Number,
    statusMessage: String,
    message: String,
    clientId: String,
    piiTypes: [String],
    links: 'ILinks',
  });

  createMap<IPiiScanResultDto, IPiiScanResult>(mapper, 'IPiiScanResultDto', 'IPiiScanResult');
};
