import { Box } from '@mui/material';
import { FeatureCard } from './components/FeatureCard';
import { IClientFeature } from '@models/interfaces/entities/IClientFeature';
import { IFeature } from '@models/interfaces/entities/IFeature';
import { selectAuthFeatures } from '@reducers/authSlice';
import { useAppSelector } from '@hooks';
import { useMemo } from 'react';
import useStyles from './styles';

const columnsMappingFeatureName = 'Columns mapping';
const subAccountMatchingFeatureName = 'Subaccount matching';

interface IProps {
  clientFeatures: IClientFeature[];
  isActivationEnabled: boolean;
  onActivateFeature: (featureId: string) => void;
  onDeactivateFeature: (
    feature: IFeature & {
      clientFeature?: IClientFeature | undefined;
    },
  ) => void;
}

export const FeaturesTabView = ({
  clientFeatures,
  isActivationEnabled,
  onActivateFeature,
  onDeactivateFeature,
}: IProps) => {
  const { classes } = useStyles();
  const features = useAppSelector(selectAuthFeatures);

  const clientFeatureItems = useMemo<(IFeature & { clientFeature?: IClientFeature })[]>(() => {
    if (!features.length) return [];
    const items = features.map((p) => ({
      ...p,
      clientFeature: clientFeatures.find((x) => p.id === x.featureId),
    }));
    return items;
  }, [features, clientFeatures]);

  const columnsMappingFeature = useMemo(
    () => clientFeatureItems.find((x) => x.name === columnsMappingFeatureName),
    [clientFeatureItems],
  );

  const subAccountMatchingFeature = useMemo(
    () => clientFeatureItems.find((x) => x.name === subAccountMatchingFeatureName),
    [clientFeatureItems],
  );

  return (
    <Box className={classes.list}>
      {!!clientFeatureItems.length &&
        clientFeatureItems.map((item) => (
          <FeatureCard
            key={item.id}
            feature={item}
            onActivate={() => onActivateFeature(item.id)}
            onDeactivate={() => onDeactivateFeature(item)}
            isActivationEnabled={
              isActivationEnabled &&
              (item.name === subAccountMatchingFeatureName
                ? !!columnsMappingFeature?.clientFeature
                : true)
            }
            isDeactivationEnabled={
              item.name === columnsMappingFeatureName
                ? !subAccountMatchingFeature?.clientFeature
                : true
            }
            info={
              item.name === subAccountMatchingFeatureName && !columnsMappingFeature?.clientFeature
                ? 'The column mapping feature must be enabled first in order to enable this feature.'
                : item.name === columnsMappingFeatureName &&
                  !!subAccountMatchingFeature?.clientFeature
                ? 'The subaccount matching feature must be disabled first in order to disable this feature.'
                : undefined
            }
          />
        ))}
    </Box>
  );
};
