import { ISectionMenuItem, SectionMenu } from '@components/SectionMenu';
import { useApi, useAppSelector } from '@hooks';
import { useEffect, useMemo, useState } from 'react';

import { CreateUserDialog } from './components/CreateUserDialog';
import { ICreateUserData } from '@models/interfaces/additional/ICreateUserData';
import { ILink } from '@models/interfaces/entities/ILink';
import { IUser } from '@models/interfaces/entities/IUser';
import { Loader } from '@components/Loader';
import { createUser } from '@services/api';
import { selectAuthAccount } from '@reducers/authSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface IProps {
  users: IUser[];
  activeUserId?: string;
  onUserAdded: () => void;
  createUserActionLink?: ILink;
}

export const UsersMenu = ({ users, activeUserId, onUserAdded, createUserActionLink }: IProps) => {
  const account = useAppSelector(selectAuthAccount);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const {
    request: createUserRequest,
    loading: createUserLoading,
    data: createUserData,
  } = useApi(createUser, null, { handleErrors: true });

  const onAddNewUser = () => {
    setOpenDialog(true);
  };

  const onCloseCreateDialog = async (item?: ICreateUserData) => {
    if (!item) {
      setOpenDialog(false);
    } else {
      const url = createUserActionLink?.href;
      if (url) {
        createUserRequest(url, item);
      }
    }
  };

  const onUserSelected = (id: string) => {
    navigate(`/admin/users/${id}`);
  };

  useEffect(() => {
    if (createUserData) {
      setOpenDialog(false);
      toast.info('User successfully created');
      onUserAdded();
    }
  }, [createUserData]);

  const showLoader = useMemo(() => createUserLoading, [createUserLoading]);

  const menuItems = useMemo<ISectionMenuItem[]>(() => {
    return users.map((x) => ({
      id: x.id,
      title: `${x.fullName} ${account?.localAccountId === x.id ? '(you)' : ''}`.trim(),
      subtitle: x.email || undefined,
    }));
  }, [users]);

  return (
    <>
      <SectionMenu
        title='Users'
        activeItemId={activeUserId}
        items={menuItems}
        showAddButton={!!createUserActionLink}
        showAvatar
        onAddItem={onAddNewUser}
        onItemSelected={onUserSelected}
      />
      <CreateUserDialog open={openDialog} onClose={onCloseCreateDialog} />
      <Loader show={showLoader} />
    </>
  );
};
