import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import { IStandardPiiIncidentCategory } from '@models/interfaces/entities/IStandardPiiIncidentCategory';
import { IStandardPiiIncidentCategoryDto } from '@models/interfaces/dtos/IStandardPiiIncidentCategoryDto';
import { IUpdateStandardPiiIncidentCategoryData } from '@models/interfaces/additional/IUpdateStandardPiiIncidentCategoryData';
import { IUpdateStandardPiiIncidentCategoryRequest } from '@models/interfaces/requests/IUpdateStandardPiiIncidentCategoryRequest';
import StandardPiiIncidentCategoriesProvider from '@providers/StandardPiiIncidentCategoriesProvider';
import mapper from '@models/mapper';

const standardPiiIncidentCategoriesProvider = new StandardPiiIncidentCategoriesProvider();

export const getStandardPiiIncidentCategories = async (url: string) => {
  const result = await standardPiiIncidentCategoriesProvider.getStandardPiiIncidentCategories(url);
  return {
    items: mapper.mapArray<IStandardPiiIncidentCategoryDto, IStandardPiiIncidentCategory>(
      result.items,
      'IStandardPiiIncidentCategoryDto',
      'IStandardPiiIncidentCategory',
    ),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const updateStandardPiiIncidentCategory = async (
  url: string,
  data: IUpdateStandardPiiIncidentCategoryData,
) => {
  const requestData = mapper.map<
    IUpdateStandardPiiIncidentCategoryData,
    IUpdateStandardPiiIncidentCategoryRequest
  >(data, 'IUpdateStandardPiiIncidentCategoryData', 'IUpdateStandardPiiIncidentCategoryRequest');
  const result = await standardPiiIncidentCategoriesProvider.updateStandardPiiIncidentCategory(
    url,
    requestData,
  );
  return mapper.map<IStandardPiiIncidentCategoryDto, IStandardPiiIncidentCategory>(
    result,
    'IStandardPiiIncidentCategoryDto',
    'IStandardPiiIncidentCategory',
  );
};

export const getStandardPiiIncidentCategory = async (url: string) => {
  const result = await standardPiiIncidentCategoriesProvider.getStandardPiiIncidentCategory(url);
  return mapper.map<IStandardPiiIncidentCategoryDto, IStandardPiiIncidentCategory>(
    result,
    'IStandardPiiIncidentCategoryDto',
    'IStandardPiiIncidentCategory',
  );
};
