import { Accept, useDropzone } from 'react-dropzone';
import { Box, Typography } from '@mui/material';

import UploadCloudIcon from '@assets/icons/uploader/upload-cloud.svg';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useCallback } from 'react';
import useStyles from './styles';

export interface IProps {
  accept: Accept | undefined;
  onScriptUploaded: (file: File) => void;
  maxFileSize: number;
}

export const ScriptUploader = ({ onScriptUploaded, maxFileSize, accept }: IProps) => {
  const { classes } = useStyles();

  const onDropFiles = useCallback(async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) return;
    const [file] = acceptedFiles;
    onScriptUploaded(file);
  }, []);

  const dropzone = useDropzone({
    accept,
    multiple: false,
    noDragEventsBubbling: true,
    preventDropOnDocument: false,
    onDrop: onDropFiles,
    onDropRejected(fr) {
      toast.info(
        `File skipped due to ${
          fr[0]?.errors[0] ? `error: ${fr[0]?.errors[0].message}` : 'unknown error'
        }`,
      );
    },
    maxSize: maxFileSize * 1024 * 1024,
  });

  return (
    <>
      <Box className={classes.dropzoneContainer}>
        <Box
          {...dropzone.getRootProps()}
          className={clsx([classes.dropzone, dropzone.isDragActive && 'active'])}
          data-testid='dropzone'
        >
          <input data-testid='dropzone-input' {...dropzone.getInputProps()} />
          <img className={classes.dropzoneControlsIcon} alt='upload' src={UploadCloudIcon} />
          <Box className={classes.dropzoneControlsTextContent}>
            <Typography className={classes.textWhite} variant='subtitle2'>
              Click to upload
            </Typography>
            <Typography className={classes.textWhite80} variant='body2'>
              or drag & drop
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.textWhite60} variant='overline'>
              py. (Max. file size {maxFileSize} MB.)
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

ScriptUploader.defaultProps = {
  maxFileSize: 1,
  accept: {
    '': ['.py'],
    'text/x-python': ['.py'],
  },
};
