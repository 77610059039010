import { Mapper, createMap, forMember, mapFrom } from '@automapper/core';

import { IUser } from '@models/interfaces/entities/IUser';
import { IUserDto } from '@models/interfaces/dtos/IUserDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createUserMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IUserDto>('IUserDto', {
    id: String,
    firstName: String,
    lastName: String,
    phone: String,
    email: String,
    companyName: String,
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IUser>('IUser', {
    id: String,
    firstName: String,
    lastName: String,
    fullName: String,
    phone: String,
    email: String,
    companyName: String,
    links: 'ILinks',
  });

  createMap<IUserDto, IUser>(
    mapper,
    'IUserDto',
    'IUser',
    forMember(
      (destination) => destination.fullName,
      mapFrom((source) => `${source.firstName} ${source.lastName}`.trim()),
    ),
  );
};
