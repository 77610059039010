import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import ChevronDownIcon from '@assets/icons/accordion/chevron-down.svg';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { ICreateWhitelistRecordData } from '@models/interfaces/additional/ICreateWhitelistRecordData';
import { IPiiIncidentDetailsPage } from '@models/interfaces/entities/IPiiIncidentDetailsPage';
import PlusFilledIcon from '@assets/icons/item-view/plus-filled.svg';
import useStyles from './styles';
import { v4 as uuidv4 } from 'uuid';

interface IPiiRecord {
  id: string;
  type: string;
  content: string;
  confidence: number;
}

interface IProps {
  id?: string;
  page: IPiiIncidentDetailsPage;
  open?: boolean;
  onCreateWhitelistRecord?: (data: ICreateWhitelistRecordData) => void;
}

export const DetailsPage = ({ id, page, open, onCreateWhitelistRecord }: IProps) => {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState(open || false);

  const onAddClick = useCallback(
    (record: IPiiRecord) => {
      if (onCreateWhitelistRecord) {
        onCreateWhitelistRecord({
          name: record.id,
          template: record.content,
        });
      }
    },
    [onCreateWhitelistRecord],
  );

  const columns = useMemo(
    () => [
      {
        field: 'type',
        headerName: 'Type',
        type: 'string',
        width: 150,
      },
      {
        field: 'confidence',
        headerName: 'Confidence',
        type: 'number',
        width: 120,
      },
      {
        field: 'content',
        headerName: 'Content',
        type: 'string',
        width: 410,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Whitelist',
        renderCell: ({ row }: { row: IPiiRecord }) => {
          return (
            <Tooltip title='Add to the whitelist'>
              <IconButton
                data-tour='add-to-whitelist'
                onClick={() => onAddClick(row)}
                disabled={!onCreateWhitelistRecord}
              >
                <img src={PlusFilledIcon} alt='Add' />
              </IconButton>
            </Tooltip>
          );
        },
      },
    ],
    [onCreateWhitelistRecord],
  );

  const rows = useMemo<IPiiRecord[]>(() => page.pii.map((p) => ({ ...p, id: uuidv4() })), [page]);

  return (
    <Accordion
      id={id}
      key={page.pageTitle}
      expanded={expanded}
      onChange={(e, isExpanded) => setExpanded(isExpanded)}
    >
      <AccordionSummary
        expandIcon={<img alt='arrow down' data-tour='expand-toggle' src={ChevronDownIcon} />}
      >
        <Typography variant='subtitle1' data-tour='title'>
          {page.pageTitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <DataGridPro rows={rows} columns={columns} density='standard' />
      </AccordionDetails>
    </Accordion>
  );
};
