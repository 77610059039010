import { Box, Button, FormControlLabel, Switch, Typography } from '@mui/material';
import {
  createWhitelistRecord,
  deleteWhitelistRecord,
  getClient,
  getFile,
  getPiiIncidentDetails,
  getPiiScanResults,
  getWhitelistRecords,
} from '@services/api';
import {
  selectGeneralAutoCloseScanResultsDialog,
  setAutoCloseScanResultsDialog,
} from '@reducers/generalSlice';
import {
  useApi,
  useAppDispatch,
  useAppSelector,
  useConfirm,
  useLoader,
  useUpdateEffect,
} from '@hooks';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { CustomDialog } from '@components/CustomDialog';
import { DetailsPage } from './components/DetailsPage';
import { FileUserStatuses } from '@models/enums/FileUserStatuses';
import { FilesHubCallbacksNames } from '@hubs/filesHub';
import FilesHubContext from '@contexts/FilesHubContext';
import { GuideTour } from '@components/GuideTour';
import { ICreateWhitelistRecordData } from '@models/interfaces/additional/ICreateWhitelistRecordData';
import { IFile } from '@models/interfaces/entities/IFile';
import { IPiiIncidentDetailsPage } from '@models/interfaces/entities/IPiiIncidentDetailsPage';
import { IWhitelistRecord } from '@models/interfaces/entities/IWhitelistRecord';
import { Loader } from '@components/Loader';
import { PIIScanResultStatuses } from '@models/enums/PIIScanResultStatuses';
import { Step } from 'react-joyride';
import { WhitelistRecordsView } from './components/WhitelistRecordsView';
import { toast } from 'react-toastify';
import useStyles from './styles';

interface IProps {
  file: IFile;
  open: boolean;
  onClose: () => void;
  onUpdate: (file: IFile, status: FileUserStatuses) => void;
}

export const PiiScanResultDetailsDialog = ({
  file: initialFile,
  open,
  onClose,
  onUpdate,
}: IProps) => {
  const { classes } = useStyles();
  const [pages, setPages] = useState<IPiiIncidentDetailsPage[]>([]);
  const [file, setFile] = useState<IFile | null>(initialFile);
  const [whitelistRecords, setWhitelistRecords] = useState<IWhitelistRecord[]>([]);
  const filesHub = useContext(FilesHubContext);
  const confirm = useConfirm();
  const autoCloseScanResultsDialog = useAppSelector(selectGeneralAutoCloseScanResultsDialog);
  const dispatch = useAppDispatch();

  const onCancel = () => {
    onClose();
  };

  const onAccept = () => {
    if (file) onUpdate(file, FileUserStatuses.accepted);
  };

  const onReject = () => {
    if (file) onUpdate(file, FileUserStatuses.rejected);
  };

  const hasPages = useMemo(() => !!pages.length, [pages]);
  const fileSelfLinkHref = useMemo(() => file?.links[Actions.self].href, [file]);

  const {
    request: getWhitelistRecordsRequest,
    loading: getWhitelistRecordsLoading,
    data: getWhitelistRecordsData,
  } = useApi(getWhitelistRecords, null, { handleErrors: true });

  const {
    request: createWhitelistRecordRequest,
    loading: createWhitelistRecordLoading,
    data: createWhitelistRecordData,
  } = useApi(createWhitelistRecord, null, { handleErrors: true });

  const {
    request: getClientRequest,
    loading: getClientLoading,
    data: getClientData,
  } = useApi(getClient, null, { handleErrors: true });

  const {
    request: getFileRequest,
    loading: getFileLoading,
    data: getFileData,
  } = useApi(getFile, null, { handleErrors: true });

  const {
    request: getPiiScanResultsRequest,
    loading: getPiiScanResultsLoading,
    data: getPiiScanResultsData,
  } = useApi(getPiiScanResults, null, { handleErrors: true });

  const {
    request: getPiiIncidentDetailsRequest,
    loading: getPiiIncidentDetailsLoading,
    data: getPiiIncidentDetailsData,
  } = useApi(getPiiIncidentDetails, null, { handleErrors: true });

  const {
    request: deleteWhitelistRecordRequest,
    loading: deleteWhitelistRecordLoading,
    data: deleteWhitelistRecordData,
  } = useApi(
    async (url: string, id: string) => {
      await deleteWhitelistRecord(url);
      return id;
    },
    null,
    {
      handleErrors: true,
    },
  );

  const reloadWhitelistRecords = useCallback(() => {
    if (getClientData?.links[Actions.getWhitelistRecords]?.href) {
      getWhitelistRecordsRequest(getClientData?.links[Actions.getWhitelistRecords].href);
    }
  }, [getClientData]);

  const reloadPiiIncidents = useCallback(() => {
    if (file?.links[Actions.getPiiIncidents]?.href) {
      getPiiScanResultsRequest(file?.links[Actions.getPiiIncidents].href);
    }
  }, [file]);

  const updateFile = (file: IFile) => {
    if (open) {
      setFile(file);
    }
  };

  useEffect(() => {
    if (initialFile && open) {
      setFile(initialFile);
    }
  }, [initialFile, open]);

  useEffect(() => {
    if (!open) {
      setPages([]);
      setFile(null);
    }
  }, [open]);

  useEffect(() => {
    if (file?.links[Actions.getPiiIncidents]?.href) {
      getPiiScanResultsRequest(file.links[Actions.getPiiIncidents].href);
    }
    if (file?.links[Actions.getClient]?.href) {
      getClientRequest(file.links[Actions.getClient].href);
    }
  }, [file]);

  useUpdateEffect(() => {
    if (getFileData) {
      updateFile(getFileData);
    }
  }, [getFileData]);

  useUpdateEffect(() => {
    if (open && !hasPages && fileSelfLinkHref) {
      getFileRequest(fileSelfLinkHref);
    }
  }, [open && hasPages, fileSelfLinkHref]);

  useUpdateEffect(() => {
    if (getPiiIncidentDetailsData) {
      setPages(getPiiIncidentDetailsData);
    }
  }, [getPiiIncidentDetailsData]);

  useUpdateEffect(() => {
    if (getWhitelistRecordsData) {
      setWhitelistRecords(getWhitelistRecordsData.items);
    }
  }, [getWhitelistRecordsData]);

  useUpdateEffect(() => {
    if (getPiiScanResultsData?.links[Actions.getDetails]?.href) {
      if (!getPiiScanResultsData.piiTypes.length) {
        setPages([]);
      } else if (getPiiScanResultsData.piiTypes.length) {
        getPiiIncidentDetailsRequest(getPiiScanResultsData?.links[Actions.getDetails].href);
      }
    }
  }, [getPiiScanResultsData]);

  useUpdateEffect(() => {
    if (getClientData?.links[Actions.getWhitelistRecords]?.href) {
      getWhitelistRecordsRequest(getClientData?.links[Actions.getWhitelistRecords].href);
    }
  }, [getClientData]);

  useUpdateEffect(() => {
    if (createWhitelistRecordData) {
      setWhitelistRecords((prev) => [...prev, createWhitelistRecordData]);
    }
  }, [createWhitelistRecordData]);

  useUpdateEffect(() => {
    if (deleteWhitelistRecordData) {
      toast.info('Successfully deleted the whitelist record');
      reloadWhitelistRecords();
      reloadPiiIncidents();
    }
  }, [deleteWhitelistRecordData, reloadWhitelistRecords, reloadPiiIncidents]);

  const onChangeAutoClose = (value: boolean) => {
    dispatch(setAutoCloseScanResultsDialog(value));
  };

  const onCreateWhitelistRecord = useMemo(() => {
    if (!getWhitelistRecordsData?.links[Actions.createWhitelistRecord]) return undefined;
    return async (data: ICreateWhitelistRecordData) => {
      await confirm({
        title: 'Create Whitelist Record',
        description: 'Are you sure that you want to create the new whitelist record?',
        confirmationText: 'Create',
      });

      createWhitelistRecordRequest(
        getWhitelistRecordsData.links[Actions.createWhitelistRecord].href,
        data,
      );
    };
  }, [getWhitelistRecordsData]);

  const onDeleteWhitelistRecord = useCallback(
    async (record: IWhitelistRecord) => {
      if (record?.links[Actions.delete]) {
        await confirm({
          title: 'Delete Whitelist Record',
          description: 'Are you sure that you want to delete this whitelist record?',
          confirmationText: 'Delete',
        });

        deleteWhitelistRecordRequest(record.links[Actions.delete].href, record.id);
      }
    },
    [getWhitelistRecordsData],
  );

  filesHub.useSignalREffect(
    FilesHubCallbacksNames.whitelistRecordCreated,
    ({ clientId }) => {
      if (file?.clientId === clientId) {
        reloadPiiIncidents();
      }
    },
    [file, reloadPiiIncidents],
  );

  filesHub.useSignalREffect(
    FilesHubCallbacksNames.fileStatusUpdated,
    ({ fileId }) => {
      if (fileId === file?.id) {
        getFileRequest(file?.links[Actions.self].href);
      }
    },
    [file],
  );

  filesHub.useSignalREffect(
    FilesHubCallbacksNames.fileUpdated,
    ({ fileId }) => {
      if (fileId === file?.id) {
        getFileRequest(file?.links[Actions.self].href);
      }
    },
    [file],
  );

  const showLoader = useLoader(
    getFileLoading,
    getPiiScanResultsLoading,
    getPiiIncidentDetailsLoading,
    getClientLoading,
    getWhitelistRecordsLoading,
    createWhitelistRecordLoading,
    deleteWhitelistRecordLoading,
  );

  const tourSteps = useMemo<Step[]>(() => {
    if (!open || !pages.length || !file?.links[Actions.updateUserStatus]) return [];
    const index = 0;
    return [
      {
        // eslint-disable-next-line quotes
        target: `[data-tour='pii-types']`,
        content:
          'An overview of the types of personally identifiable information (PII) that may have been discovered in the listed file(s) is shown here.',
      },
      {
        target: `#page-${index} [data-tour='title']`,
        content: `The filename containing detected PII is displayed here. In cases where there are multiple files 
          (e.g., Excel files with multiple worksheets), each worksheet is shown separately.`,
      },
      {
        target: `#page-${index} [data-tour='expand-toggle']`,
        content:
          'For each file, you can expand or collapse the PII summaries by clicking on this icon.',
      },
      {
        target: `#page-${index} [data-rowindex='0']`,
        content: `Here, you'll find details about potential PII instances. This includes the type of PII,
          the confidence level in its classification as PII, and the actual flagged value.`,
        disableScrolling: true,
      },
      {
        target: `#page-${index} [data-rowindex='0'] [data-tour='add-to-whitelist']`,
        content: `If a detected value is expected to recur in your files and is determined not to be PII,
          you can add it to a whitelist by clicking this icon.
          Whitelisted values will be ignored when assessing PII detection across all files.`,
        disableScrolling: true,
      },
      {
        // eslint-disable-next-line quotes
        target: `[data-tour='whitelist-toggle']`,
        content:
          'Access all whitelisted values by clicking this icon. You can also remove previously whitelisted values from the expanded whitelist view.',
      },
      {
        // eslint-disable-next-line quotes
        target: `[data-tour='reject-button']`,
        content:
          'If PII is indeed detected in a file, reject the file, remove the PII from the original file, and upload it again once the PII has been removed.',
      },
      {
        // eslint-disable-next-line quotes
        target: `[data-tour='accept-button']`,
        content: `If all potential PII is determined not to be actual PII, you can accept the file by clicking the “Accept” button.
          Acceptance signifies that you’ve verified no PII is present in the file.`,
      },
    ].filter((x) => x !== null) as Step[];
  }, [pages, file, open]);

  return (
    <>
      <CustomDialog
        title='Details'
        onClose={onCancel}
        open={open}
        maxWidth='md'
        fullWidth
        actions={
          file?.links[Actions.updateUserStatus]?.href ? (
            <>
              <Button
                size='medium'
                variant='contained'
                color='success'
                onClick={onAccept}
                data-tour='accept-button'
              >
                Accept
              </Button>
              <Button
                size='medium'
                variant='contained'
                color='error'
                onClick={onReject}
                data-tour='reject-button'
              >
                Reject
              </Button>
            </>
          ) : (
            <>
              {file?.userStatus ? (
                <Box>
                  <Typography variant='subtitle1'>
                    This file has been{' '}
                    {file.statusApprover
                      ? `${file.userStatus.toLowerCase()} by ${file.statusApprover}`
                      : `automatically ${file.userStatus.toLowerCase()}`}
                    .
                  </Typography>
                </Box>
              ) : (
                <div />
              )}
              <Button size='medium' variant='contained' color='primary' onClick={onCancel}>
                Ok
              </Button>
            </>
          )
        }
        footerAdditional={
          <FormControlLabel
            className={classes.switchWrapper}
            control={
              <Switch
                inputProps={{ role: 'switch' }}
                checked={autoCloseScanResultsDialog}
                onChange={(evt, checked) => onChangeAutoClose(checked)}
              />
            }
            label='Close the dialog immediately on accepting or rejecting'
          />
        }
      >
        {getPiiScanResultsData && (
          <>
            <GuideTour steps={tourSteps} name='accept-reject-dialog' zIndex={2000} />

            <Typography variant='h6' className={classes.info}>
              {getPiiScanResultsData.statusMessage !== PIIScanResultStatuses.failure &&
                getPiiScanResultsData.statusMessage !== PIIScanResultStatuses.unscanned &&
                (getPiiScanResultsData.piiTypes?.length
                  ? 'Detected the following types of PII'
                  : file?.isSource
                  ? 'All of the sheets have been processed.'
                  : 'No PII has been detected.')}
              {getPiiScanResultsData.statusMessage === PIIScanResultStatuses.failure &&
                `A problem was encountered, and we were unable to scan this document for personally identifiable information (PII). 
                Please review the contents of the document and confirm that the file does not contain PII. Finally, accept or reject the file as appropriate.`}
              {getPiiScanResultsData.statusMessage === PIIScanResultStatuses.unscanned &&
                'Files of this type are not automatically scanned for PII. Please confirm whether this file is free of PII.'}
            </Typography>

            <Box>
              {getPiiScanResultsData.statusMessage === PIIScanResultStatuses.completedWithPii &&
                !!getPiiScanResultsData.piiTypes?.length && (
                  <Typography
                    variant='subtitle2'
                    className={classes.description}
                    data-tour='pii-types'
                  >
                    {getPiiScanResultsData.piiTypes.join(', ')}
                  </Typography>
                )}
            </Box>

            {getPiiScanResultsData.statusMessage !== PIIScanResultStatuses.failure && (
              <WhitelistRecordsView
                records={whitelistRecords}
                onDeleteWhitelistRecord={onDeleteWhitelistRecord}
              />
            )}
          </>
        )}

        {!!pages.length && (
          <Box className={classes.detailsContainer}>
            {pages.map((page, i) => (
              <DetailsPage
                id={`page-${i}`}
                key={page.pageTitle}
                page={page}
                open={i === 0}
                onCreateWhitelistRecord={onCreateWhitelistRecord}
              />
            ))}
          </Box>
        )}
        <Loader show={showLoader} fixed={false} />
      </CustomDialog>
    </>
  );
};
