import { Mapper, createMap } from '@automapper/core';

import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAccountDto } from '@models/interfaces/dtos/IAccountDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createAccountMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IAccountDto>('IAccountDto', {
    id: String,
    fileId: String,
    accountName: String,
    accountCategory: String,
    tierGroup: String,
    isNew: Boolean,
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IAccount>('IAccount', {
    id: String,
    fileId: String,
    accountName: String,
    accountCategory: String,
    tierGroup: String,
    isNew: Boolean,
    links: 'ILinks',
  });

  createMap<IAccountDto, IAccount>(mapper, 'IAccountDto', 'IAccount');
};
