import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  helpButtonContainer: {
    position: 'fixed',
    bottom: 10,
    right: 10,
    zIndex: 2001,
  },
  helpButtonIcon: {
    height: 40,
    width: 40,
    zIndex: 2001,
    position: 'relative',
  },
}));

export default useStyles;
