import ClientProductsProvider from '@providers/ClientProductsProvider';
import { IClientProduct } from '@models/interfaces/entities/IClientProduct';
import { IClientProductDto } from '@models/interfaces/dtos/IClientProductDto';
import { ICreateClientProductData } from '@models/interfaces/additional/ICreateClientProductData';
import { ICreateClientProductRequest } from '@models/interfaces/requests/ICreateClientProductRequest';
import mapper from '@models/mapper';

const clientProductsProvider = new ClientProductsProvider();

export const getClientProduct = async (url: string) => {
  const result = await clientProductsProvider.getClientProduct(url);
  return mapper.map<IClientProductDto, IClientProduct>(
    result,
    'IClientProductDto',
    'IClientProduct',
  );
};

export const getClientProducts = async (url: string) => {
  const result = await clientProductsProvider.getClientProducts(url);
  return mapper.mapArray<IClientProductDto, IClientProduct>(
    result.items,
    'IClientProductDto',
    'IClientProduct',
  );
};

export const createClientProduct = async (url: string, data: ICreateClientProductData) => {
  const requestData = mapper.map<ICreateClientProductData, ICreateClientProductRequest>(
    data,
    'ICreateClientProductData',
    'ICreateClientProductRequest',
  );
  const result = await clientProductsProvider.createClientProduct(url, requestData);
  return mapper.mapArray<IClientProductDto, IClientProduct>(
    result.items,
    'IClientProductDto',
    'IClientProduct',
  );
};
