import ConfirmContext, { IConfirmOptions } from '../../contexts/ConfirmContext';
import React, { Fragment, useCallback, useState } from 'react';

import ConfirmationDialog from '../ConfirmationDialog';

interface IProps {
  children: React.ReactNode;
  defaultOptions?: IConfirmOptions;
}

const DEFAULT_OPTIONS = {
  title: 'Are you sure?',
  description: '',
  confirmationText: 'Ok',
  cancellationText: 'Cancel',
  showNotesInput: false,
  notesInputLabel: 'Notes',
  notesInputRequired: false,
};

const ConfirmProvider = ({ children, defaultOptions = {} }: IProps) => {
  const [options, setOptions] = useState({});
  const [resolveReject, setResolveReject] = useState<((data?: { notes: string }) => void)[]>([]);
  const [resolve, reject] = resolveReject;

  const confirm = useCallback((options: IConfirmOptions = {}) => {
    return new Promise<{ notes: string } | undefined>((resolve, reject) => {
      setOptions(options);
      setResolveReject([resolve, reject]);
    });
  }, []);

  const handleClose = useCallback(() => {
    setResolveReject([]);
  }, []);

  const handleCancel = useCallback(() => {
    if (reject) {
      reject();
      handleClose();
    }
  }, [reject, handleClose]);

  const handleConfirm = useCallback(
    (data: { notes: string }) => {
      if (resolve) {
        resolve(data);
        handleClose();
      }
    },
    [resolve, handleClose],
  );

  return (
    <Fragment>
      <ConfirmContext.Provider value={confirm}>{children}</ConfirmContext.Provider>
      <ConfirmationDialog
        open={resolveReject.length === 2}
        options={{ ...DEFAULT_OPTIONS, ...defaultOptions, ...options }}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </Fragment>
  );
};

export default ConfirmProvider;
