import { IStep1FormData, Step1 } from './components/Step1';
import { IStep2FormData, Step2 } from './components/Step2';
import { useEffect, useMemo, useState } from 'react';

import ArrowLeftIcon from '@assets/icons/dialog/arrow-left.svg';
import ArrowRightIcon from '@assets/icons/dialog/arrow-right.svg';
import { Button } from '@mui/material';
import { CustomDialog } from '@components/CustomDialog';
import { ICreateClientData } from '@models/interfaces/additional/ICreateClientData';
import UserFilledIcon from '@assets/icons/dialog/user-filled.svg';
import { useForm } from 'react-hook-form';

interface IProps {
  open: boolean;
  onClose: (data?: ICreateClientData) => void;
}

export const CreateClientDialog = ({ open, onClose }: IProps) => {
  const step1FormId = 'step1Form';
  const step2FormId = 'step2Form';

  const [step, setStep] = useState(1);

  const step1FormDefaultData = useMemo(
    () => ({
      name: '',
      accountNumber: '',
      externalId: '',
      addressState: '',
      institutionType: '',
    }),
    [],
  );

  const step2FormDefaultData = useMemo(
    () => ({
      access: [],
    }),
    [],
  );

  const step1Form = useForm<IStep1FormData>({
    defaultValues: step1FormDefaultData,
  });

  const step2Form = useForm<IStep2FormData>({
    defaultValues: step2FormDefaultData,
  });

  const watchAccess = step2Form.watch('access');

  const onCancel = () => {
    onClose();
  };

  const onStepBack = () => {
    setStep((step) => step - 1);
  };

  const onStep1FormSubmit = () => {
    setStep(2);
  };

  const onStep2FormSubmit = () => {
    const step1FormData = step1Form.getValues();
    const step2FormData = step2Form.getValues();

    onClose({ ...step1FormData, ...step2FormData });
  };

  useEffect(() => {
    setStep(1);
    step1Form.reset(step1FormDefaultData);
    step2Form.reset(step2FormDefaultData);
  }, [open]);

  return (
    <CustomDialog
      title='Create New Client'
      onClose={onCancel}
      open={open}
      maxWidth='md'
      fullWidth
      steps={2}
      activeStep={step}
      actions={
        <>
          {step === 1 && (
            <>
              <div />
              <Button
                type='submit'
                form={step1FormId}
                variant='contained'
                color='secondary'
                className='narrow'
                startIcon={<img alt='next' src={ArrowRightIcon} />}
              >
                Next
              </Button>
              <div />
            </>
          )}
          {step === 2 && (
            <>
              <Button
                variant='outlined'
                color='secondary'
                className='narrow'
                onClick={onStepBack}
                startIcon={<img alt='next' src={ArrowLeftIcon} />}
              >
                Back
              </Button>
              <Button
                type='submit'
                form={step2FormId}
                variant='contained'
                size='large'
                color='secondary'
                disabled={!watchAccess.length}
                startIcon={<img alt='create client' src={UserFilledIcon} />}
              >
                Create client
              </Button>
            </>
          )}
        </>
      }
    >
      {step === 1 && (
        <Step1 form={step1Form} formId={step1FormId} onFormSubmit={onStep1FormSubmit} />
      )}
      {step === 2 && (
        <Step2 form={step2Form} formId={step2FormId} onFormSubmit={onStep2FormSubmit} />
      )}
    </CustomDialog>
  );
};
