import { Avatar, Typography } from '@mui/material';

import { useMemo } from 'react';
import useStyles from './styles';

export enum Variants {
  dark = 'dark',
  grey = 'grey',
  colored = 'colored',
}

interface IProps {
  value: string;
  variant: Variants;
  size?: 'sm' | 'md';
}

export const StringAvatar = ({ value, variant, size }: IProps) => {
  const color = useMemo(() => {
    if (variant !== Variants.colored) return '';
    let hash = 0;
    let i;

    for (i = 0; i < value.length; i += 1) {
      hash = value.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }, [value, variant]);

  const content = useMemo(
    () =>
      value
        .split(' ')
        .map((x) => {
          return x[0];
        })
        .join('')
        .substring(0, 2),
    [value],
  );

  const sizePx = useMemo(() => {
    switch (size) {
      case 'sm':
        return 24;
      default:
        return 40;
    }
  }, [size]);

  const typographyVariant = useMemo(() => {
    switch (size) {
      case 'sm':
        return 'caption';
      default:
        return 'body1';
    }
  }, [size]);

  const { classes } = useStyles({ color, variant, size: sizePx });

  return (
    <Avatar className={classes.avatar}>
      <Typography variant={typographyVariant}>{content}</Typography>
    </Avatar>
  );
};

StringAvatar.defaultProps = {
  size: 'md',
  variant: Variants.dark,
};
