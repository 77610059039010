import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  container: {
    gap: 8,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 10,
    backgroundColor: t.palette.divider,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  textBold: {
    fontWeight: 600,
  },
  textGrey: {
    color: t.palette.custom.dark[60],
  },
}));

export default useStyles;
