import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { GridColumnMenuContainer, GridColumnMenuProps } from '@mui/x-data-grid-pro';
import { useEffect, useMemo, useState } from 'react';

import { AdjustmentActions } from '@models/enums/AdjustmentActions';
import { Dropdown } from '@components/Dropdown';
import SaveFilledIcon from '@assets/icons/dashboard/save-filled.svg';
import useStyles from './styles';

interface IProps extends GridColumnMenuProps {
  records: {
    columnIndex: number;
    fields: {
      id: string;
      action?: AdjustmentActions;
      coefficient?: number;
    }[];
  }[];
  onAdjustmentUpdated: (
    fieldId: string,
    columnIndex: number,
    action: AdjustmentActions,
    coefficient?: number,
  ) => void;
}

export const ColumnMenu = ({ hideMenu, colDef, open, records, onAdjustmentUpdated }: IProps) => {
  const [action, setAction] = useState(AdjustmentActions.none);
  const [coefficient, setCoefficient] = useState<number | undefined>(undefined);

  const { classes } = useStyles();

  const columnData = useMemo(() => {
    const regex = /^c(\d+)_(.*)$/;
    const match = colDef.field.match(regex);

    if (match) {
      const columnIndex = parseInt(match[1], 10);
      const fieldId = match[2];
      return { columnIndex, fieldId };
    }
  }, [colDef.field]);

  useEffect(() => {
    if (columnData) {
      const record = records.find((x) => x.columnIndex === columnData.columnIndex);
      if (record) {
        const field = record.fields.find((x) => x.id === columnData.fieldId);
        if (field) {
          setCoefficient(field.coefficient);
          setAction(field.action || AdjustmentActions.none);
        }
      }
    }
  }, [columnData, records]);

  const onUpdateAdjustment: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (columnData) {
      onAdjustmentUpdated(
        columnData.fieldId,
        columnData.columnIndex,
        action,
        coefficient || undefined,
      );
      hideMenu(e);
    }
  };

  const onActionChanged = (value: AdjustmentActions) => {
    setAction(value);
    if (value === AdjustmentActions.none) {
      setCoefficient(undefined);
    } else if (coefficient === undefined) {
      setCoefficient(1);
    }
  };

  const onCoefficientChanged = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCoefficient(e.target.value !== '' ? Number(e.target.value) : undefined);
  };

  const invalid =
    (action !== AdjustmentActions.none && coefficient === null) ||
    (action === AdjustmentActions.divide && coefficient === 0);

  const actionOptions = useMemo(
    () => [
      {
        value: AdjustmentActions.none,
        label: 'None',
      },
      {
        value: AdjustmentActions.multiply,
        label: 'Multiply',
      },
      {
        value: AdjustmentActions.divide,
        label: 'Divide',
      },
    ],
    [],
  );

  return (
    <GridColumnMenuContainer hideMenu={hideMenu} colDef={colDef} open={open}>
      <Box className={classes.root}>
        <TextField
          type={'number'}
          value={coefficient !== undefined ? coefficient : ''}
          onChange={onCoefficientChanged}
          size='small'
          className={classes.input}
          label='Adjustment'
          variant='outlined'
          InputProps={{
            error: invalid,
            disabled: action === AdjustmentActions.none,
            startAdornment: (
              <InputAdornment position='start'>
                <Dropdown
                  value={action}
                  onChanged={(value) => onActionChanged(value as AdjustmentActions)}
                  options={actionOptions}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={onUpdateAdjustment}
                  disabled={invalid}
                  className={invalid ? classes.disabledIconButton : undefined}
                >
                  <img src={SaveFilledIcon} alt='Save' />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </GridColumnMenuContainer>
  );
};
