import { Box, Button, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';

import { CustomDialog } from '@components/CustomDialog';
import { ICreateUserData } from '@models/interfaces/additional/ICreateUserData';
import UserFilledIcon from '@assets/icons/dialog/user-filled.svg';
import useStyles from './styles';

interface IProps {
  open: boolean;
  onClose: (data?: ICreateUserData) => void;
}

type IFormData = ICreateUserData;

export const CreateUserDialog = ({ open, onClose }: IProps) => {
  const { classes } = useStyles();

  const formDefaultData = useMemo(
    () => ({
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      phone: '',
    }),
    [],
  );

  const form = useForm<IFormData>({
    defaultValues: formDefaultData,
  });

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const { phone, ...rest } = form.getValues();

    onClose({ ...rest, phone: phone || undefined });
  };

  useEffect(() => {
    form.reset(formDefaultData);
  }, [open]);

  return (
    <CustomDialog
      title='Create New User'
      onClose={onCancel}
      open={open}
      maxWidth='md'
      fullWidth
      actions={
        <>
          <div />
          <Button
            type='submit'
            form='form'
            variant='contained'
            color='secondary'
            size='large'
            startIcon={<img alt='create user' src={UserFilledIcon} />}
          >
            Create
          </Button>
          <div />
        </>
      }
    >
      <form
        id='form'
        onSubmit={form.handleSubmit(() => {
          onSave();
        })}
        noValidate
      >
        <Box className={classes.firstFormGrid}>
          <Box>
            <Controller
              name={'firstName'}
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: 'Please enter the first name',
                },
              }}
              render={({ field: { onChange, value }, formState }) => (
                <TextField
                  fullWidth
                  label='First Name'
                  variant='standard'
                  error={!!formState.errors.firstName}
                  helperText={formState.errors.firstName?.message}
                  onChange={onChange}
                  value={value}
                  autoComplete='off'
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name={'lastName'}
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: 'Please enter the last name',
                },
              }}
              render={({ field: { onChange, value }, formState }) => (
                <TextField
                  fullWidth
                  label='Last Name'
                  variant='standard'
                  error={!!formState.errors.lastName}
                  helperText={formState.errors.lastName?.message}
                  onChange={onChange}
                  value={value}
                  autoComplete='off'
                />
              )}
            />
          </Box>
        </Box>
        <Box className={classes.secondFormGrid}>
          <Box>
            <Controller
              name={'phone'}
              control={form.control}
              rules={{
                pattern: {
                  value: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
                  message: 'Please enter the valid phone number',
                },
              }}
              render={({ field: { onChange, value }, formState }) => (
                <TextField
                  fullWidth
                  label='Phone Number'
                  variant='standard'
                  error={!!formState.errors.phone}
                  helperText={formState.errors.phone?.message}
                  onChange={onChange}
                  value={value}
                  autoComplete='off'
                />
              )}
            />
          </Box>

          <Box>
            <Controller
              name={'email'}
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: 'Please enter the email',
                },
                pattern: {
                  value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: 'Please enter the valid email',
                },
              }}
              render={({ field: { onChange, value }, formState }) => (
                <TextField
                  fullWidth
                  label='Email'
                  variant='standard'
                  error={!!formState.errors.email}
                  helperText={formState.errors.email?.message}
                  onChange={onChange}
                  value={value}
                  autoComplete='off'
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name={'companyName'}
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: 'Please enter the company name',
                },
              }}
              render={({ field: { onChange, value }, formState }) => (
                <TextField
                  fullWidth
                  label='Company Name'
                  variant='standard'
                  error={!!formState.errors.companyName}
                  helperText={formState.errors.companyName?.message}
                  onChange={onChange}
                  value={value}
                  autoComplete='off'
                />
              )}
            />
          </Box>
        </Box>
      </form>
    </CustomDialog>
  );
};
