import { Box, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';

import Button from '@mui/material/Button';
import { CustomDialog } from '@components/CustomDialog';
import useStyles from './styles';

interface IFormData {
  notes: string;
}

interface IProps {
  open: boolean;
  options: {
    title: string;
    description: string;
    confirmationText: string;
    cancellationText: string;
    showNotesInput: boolean;
    notesInputLabel?: string;
    notesInputRequired?: boolean;
  };
  onCancel: () => void;
  onConfirm: (data: { notes: string }) => void;
}

const ConfirmationDialog = ({ open, options, onCancel, onConfirm }: IProps) => {
  const {
    title,
    description,
    confirmationText,
    cancellationText,
    showNotesInput,
    notesInputLabel,
    notesInputRequired,
  } = options;

  const { classes } = useStyles();

  const formDefaultData = useMemo(
    () => ({
      notes: '',
    }),
    [],
  );

  const form = useForm<IFormData>({
    defaultValues: formDefaultData,
  });

  const onSave = () => {
    const { notes } = form.getValues();

    onConfirm({ notes });
  };

  useEffect(() => {
    form.reset(formDefaultData);
    if (open) {
      setTimeout(() => {
        form.setFocus('notes');
      }, 0);
    }
  }, [open]);

  return (
    <CustomDialog
      title={title}
      centered
      fullWidth
      maxWidth='xs'
      showCloseIcon={false}
      open={open}
      actions={
        <>
          <Button color='secondary' variant='outlined' className='narrow' onClick={onCancel}>
            {cancellationText}
          </Button>
          <Button
            color='secondary'
            variant='contained'
            className='wide'
            type='submit'
            form='confirmationDialogForm'
          >
            {confirmationText}
          </Button>
        </>
      }
    >
      <form
        id='confirmationDialogForm'
        onSubmit={form.handleSubmit(() => {
          onSave();
        })}
        noValidate
      >
        <Typography variant='subtitle1'>{description}</Typography>

        {showNotesInput && (
          <Box className={classes.descriptionContainer}>
            <Controller
              name={'notes'}
              control={form.control}
              rules={{
                required: {
                  value: notesInputRequired ?? false,
                  message: 'Please enter the notes',
                },
              }}
              render={({ field: { onChange, value, ref }, formState }) => (
                <TextField
                  inputRef={ref}
                  multiline
                  rows={4}
                  fullWidth
                  label={notesInputLabel}
                  variant='standard'
                  error={!!formState.errors.notes}
                  helperText={formState.errors.notes?.message}
                  onChange={onChange}
                  value={value}
                  autoComplete='off'
                />
              )}
            />
          </Box>
        )}
      </form>
    </CustomDialog>
  );
};

export default ConfirmationDialog;
