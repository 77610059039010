import { Mapper, createMap } from '@automapper/core';

import { IProduct } from '@models/interfaces/entities/IProduct';
import { IProductDto } from '@models/interfaces/dtos/IProductDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createProductMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IProductDto>('IProductDto', {
    id: String,
    name: String,
    url: String,
    image: String,
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IProduct>('IProduct', {
    id: String,
    name: String,
    url: String,
    image: String,
    links: 'ILinks',
  });

  createMap<IProductDto, IProduct>(mapper, 'IProductDto', 'IProduct');
};
