import {
  selectAuthCreateClientActionLink,
  selectAuthGetClientsActionLink,
} from '@reducers/authSlice';
import { useApi, useAppSelector, useLoader } from '@hooks';
import { useCallback, useEffect } from 'react';

import { Box } from '@mui/material';
import { ClientView } from './components/ClientView';
import { ClientsMenu } from './components/ClientsMenu';
import { Loader } from '@components/Loader';
import { getClients } from '@services/api';
import { useParams } from 'react-router-dom';
import useStyles from './styles';

export const ClientsPage = () => {
  const { classes } = useStyles();
  const getClientsActionLink = useAppSelector(selectAuthGetClientsActionLink);
  const createClientActionLink = useAppSelector(selectAuthCreateClientActionLink);
  const { clientId } = useParams();

  const {
    request: getClientsRequest,
    data: getClientsData,
    loading: getClientsLoading,
  } = useApi(getClients, [], { handleErrors: true });

  useEffect(() => {
    if (getClientsActionLink) getClientsRequest(getClientsActionLink.href);
  }, [getClientsActionLink]);

  const showLoader = useLoader(getClientsLoading);

  const onChanged = useCallback(() => {
    if (getClientsActionLink) getClientsRequest(getClientsActionLink.href);
  }, [getClientsActionLink]);

  return (
    <Box className={classes.container}>
      <ClientsMenu
        onClientAdded={onChanged}
        clients={getClientsData}
        createClientActionLink={createClientActionLink}
        activeClientId={clientId}
      />
      {clientId && <ClientView onClientChanged={onChanged} clientId={clientId} />}
      <Loader show={showLoader} />
    </Box>
  );
};
