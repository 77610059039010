import { Controller, UseFormReturn } from 'react-hook-form';
import { TextField, Typography } from '@mui/material';

import { Box } from '@mui/system';
import useStyles from './styles';

export interface IStep1FormData {
  name: string;
  externalId: string;
  accountNumber: string;
  addressState: string;
  institutionType: string;
}

interface IProps {
  form: UseFormReturn<IStep1FormData>;
  formId: string;
  onFormSubmit: () => void;
}

export const Step1 = ({ form, formId, onFormSubmit }: IProps) => {
  const { classes } = useStyles();

  return (
    <form
      id={formId}
      onSubmit={form.handleSubmit(() => {
        onFormSubmit();
      })}
      noValidate
    >
      <Typography variant='h6' className={classes.info}>
        Enter client information
      </Typography>
      <Box className={classes.formGrid}>
        <Box>
          <Controller
            name={'name'}
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Please enter the name',
              },
            }}
            render={({ field: { onChange, value }, formState }) => (
              <TextField
                fullWidth
                label='Name'
                variant='standard'
                error={!!formState.errors.name}
                helperText={formState.errors.name?.message}
                onChange={onChange}
                value={value}
                autoComplete='off'
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            name={'accountNumber'}
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Please enter the account number',
              },
            }}
            render={({ field: { onChange, value }, formState }) => (
              <TextField
                fullWidth
                label='Account Number'
                variant='standard'
                error={!!formState.errors.accountNumber}
                helperText={formState.errors.accountNumber?.message}
                onChange={onChange}
                value={value}
                autoComplete='off'
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            name={'externalId'}
            control={form.control}
            render={({ field: { onChange, value }, formState }) => (
              <TextField
                fullWidth
                label='NetSuite Id'
                variant='standard'
                error={!!formState.errors.externalId}
                helperText={formState.errors.externalId?.message}
                onChange={onChange}
                value={value}
                autoComplete='off'
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            name={'addressState'}
            control={form.control}
            render={({ field: { onChange, value }, formState }) => (
              <TextField
                fullWidth
                label='State'
                variant='standard'
                error={!!formState.errors.addressState}
                helperText={formState.errors.addressState?.message}
                onChange={onChange}
                value={value}
                autoComplete='off'
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            name={'institutionType'}
            control={form.control}
            render={({ field: { onChange, value }, formState }) => (
              <TextField
                fullWidth
                label='Institution Type'
                variant='standard'
                error={!!formState.errors.institutionType}
                helperText={formState.errors.institutionType?.message}
                onChange={onChange}
                value={value}
                autoComplete='off'
              />
            )}
          />
        </Box>
      </Box>
    </form>
  );
};
