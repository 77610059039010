import { Box, IconButton, Typography } from '@mui/material';

import { IUser } from '@models/interfaces/entities/IUser';
import { StringAvatar } from '@components/StringAvatar';
import XCircleIcon from '@assets/icons/item-view/x-circle-filled.svg';
import useStyles from './styles';

interface IProps {
  member: IUser;
  onRemove: () => void;
}

export const MemberCard = ({ member, onRemove }: IProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root} key={member.id}>
      <Box className={classes.content}>
        <StringAvatar value={member.fullName} size='sm' />
        <Typography variant='subtitle2'>{member.fullName}</Typography>
      </Box>
      <IconButton onClick={() => onRemove()}>
        <img src={XCircleIcon} alt='Remove' />
      </IconButton>
    </Box>
  );
};
