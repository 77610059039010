import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  w100: {
    width: '100%',
  },
  textGray: {
    color: t.palette.custom.dark[60],
  },
  fw600: {
    fontWeight: 600,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
  justifyContentSpaceBetween: {
    justifyContent: 'space-between',
  },
  gap4: {
    gap: 4,
  },
  gap0: {
    gap: 0,
  },
  root: {
    cursor: 'pointer',
    gap: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    padding: 8,
    borderRadius: 10,
    backgroundColor: t.palette.custom.primary[5],

    '&.inactive': {
      cursor: 'not-allowed',
    },
  },
  inactiveAlert: {
    backgroundColor: '#959B9D',
  },
  openAlert: {
    backgroundColor: '#002060',
  },
  inProgressAlert: {
    backgroundColor: '#71ADB7',
  },
  completeAlert: {
    backgroundColor: '#000000',
  },
}));

export default useStyles;
