import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AccountInfo } from '@azure/msal-browser';
import { Actions } from '@models/enums/Actions';
import { IAppInfo } from '@models/interfaces/entities/IAppInfo';
import { IFeature } from '@models/interfaces/entities/IFeature';
import { IProduct } from '@models/interfaces/entities/IProduct';
import { RootState } from '../../store';
import { env } from '@config/env';

interface IAuthToken {
  accessToken: string;
  expiresOn: number | null;
}

export interface AuthState {
  account: AccountInfo | null;
  token: IAuthToken | null;
  appInfo: IAppInfo | null;
  products: IProduct[];
  features: IFeature[];
}

const initialState: AuthState = {
  account: null,
  token: null,
  appInfo: null,
  products: [],
  features: [],
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAccount: (state) => {
      state.account = null;
    },
    setAccount: (state, action: PayloadAction<AccountInfo>) => {
      state.account = action.payload;
    },
    clearToken: (state) => {
      state.token = null;
    },
    setToken: (state, action: PayloadAction<IAuthToken>) => {
      state.token = action.payload;
    },
    clearAppInfo: (state) => {
      state.appInfo = null;
    },
    setAppInfo: (state, action: PayloadAction<IAppInfo>) => {
      state.appInfo = action.payload;
    },
    clearProducts: (state) => {
      state.products = [];
    },
    setProducts: (state, action: PayloadAction<IProduct[]>) => {
      state.products = action.payload;
    },
    clearFeatures: (state) => {
      state.features = [];
    },
    setFeatures: (state, action: PayloadAction<IFeature[]>) => {
      state.features = action.payload;
    },
    logout: (state) => {
      state.token = null;
      state.account = null;
      state.appInfo = null;
      state.products = [];
      state.features = [];
    },
  },
});

export const {
  logout,
  setToken,
  clearToken,
  setAccount,
  clearAccount,
  setAppInfo,
  clearAppInfo,
  setProducts,
  clearProducts,
  setFeatures,
  clearFeatures,
} = authSlice.actions;

export const selectAuthAccount = (state: RootState) => state.auth.account;
export const selectAuthToken = (state: RootState) => state.auth.token;
export const selectAuthAppInfo = (state: RootState) => state.auth.appInfo;
export const selectAuthProducts = (state: RootState) => state.auth.products;
export const selectAuthFeatures = (state: RootState) => state.auth.features;
export const selectAuthIsAuthenticated = (state: RootState) =>
  !!state.auth.token &&
  !!state.auth.token.expiresOn &&
  new Date(state.auth.token.expiresOn) > new Date();
export const selectAuthIsAdmin = (state: RootState) => {
  const groups =
    state.auth.account && state.auth.account.idTokenClaims
      ? (state.auth.account.idTokenClaims['groups'] as string[])
      : [];

  return groups.findIndex((x) => x === env.REACT_APP_ADMIN_GROUP) !== -1;
};
export const selectAuthIsNonAdmin = (state: RootState) => {
  const groups =
    state.auth.account && state.auth.account.idTokenClaims
      ? (state.auth.account.idTokenClaims['groups'] as string[])
      : [];

  return groups.findIndex((x) => x !== env.REACT_APP_ADMIN_GROUP) !== -1;
};

// action links
export const selectAuthGetClientsActionLink = (state: RootState) =>
  state.auth.appInfo ? state.auth.appInfo.links[Actions.getClients] : undefined;

export const selectAuthCreateClientActionLink = (state: RootState) =>
  state.auth.appInfo ? state.auth.appInfo.links[Actions.createClient] : undefined;

export const selectAuthGetFilesActionLink = (state: RootState) =>
  state.auth.appInfo ? state.auth.appInfo.links[Actions.getFiles] : undefined;

export const selectAuthGetFilesCountActionLink = (state: RootState) =>
  state.auth.appInfo ? state.auth.appInfo.links[Actions.getFilesCount] : undefined;

export const selectAuthGetGroupsActionLink = (state: RootState) =>
  state.auth.appInfo ? state.auth.appInfo.links[Actions.getGroups] : undefined;

export const selectAuthGetUsersActionLink = (state: RootState) =>
  state.auth.appInfo ? state.auth.appInfo.links[Actions.getUsers] : undefined;

export const selectAuthGetProductsActionLink = (state: RootState) =>
  state.auth.appInfo ? state.auth.appInfo.links[Actions.getProducts] : undefined;

export const selectAuthGetFeaturesActionLink = (state: RootState) =>
  state.auth.appInfo ? state.auth.appInfo.links[Actions.getFeatures] : undefined;

export const selectAuthGetPiiIncidentCategoriesActionLink = (state: RootState) =>
  state.auth.appInfo ? state.auth.appInfo.links[Actions.getPiiCategories] : undefined;

export const selectAuthGetStandardPiiIncidentCategoriesActionLink = (state: RootState) =>
  state.auth.appInfo ? state.auth.appInfo.links[Actions.getStandardPiiCategories] : undefined;

export const selectAuthGetActivityEventsActionLink = (state: RootState) =>
  state.auth.appInfo ? state.auth.appInfo.links[Actions.getActivities] : undefined;

export const selectAuthGetFileCategoriesActionLink = (state: RootState) =>
  state.auth.appInfo ? state.auth.appInfo.links[Actions.getFileCategories] : undefined;

export const selectAuthGetAlertActionLink = (state: RootState) =>
  state.auth.appInfo ? state.auth.appInfo.links[Actions.getAlert] : undefined;

export default authSlice.reducer;
