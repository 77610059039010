import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    gap: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    padding: 6,
    borderRadius: 10,
    backgroundColor: t.palette.custom.primary[5],
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: t.palette.custom.dark[10],
  },
  active: {
    backgroundColor: t.palette.custom.white[100],
  },
  inactive: {
    cursor: 'not-allowed',
  },
  switch: {
    marginLeft: -12,
  },
}));

export default useStyles;
