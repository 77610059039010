import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',

    '&.loaded::after': {
      display: 'none',
    },

    '&::after': {
      backgroundColor: t.palette.custom.white[100],
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      position: 'absolute',
      top: 40,
      left: 0,
      right: 0,
      bottom: 0,
      content: '""',
    },
  },
  hiddenSection: {
    marginTop: 107,
  },
  topSection: {
    minHeight: 40,
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: 4,
    paddingTop: 4,
    width: '100%',
    position: 'relative',
  },
  mainSection: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: t.palette.custom.white[100],
    borderBottom: `1px solid ${t.palette.divider}`,
  },
  topSectionContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    gap: 16,

    '&>nav': {
      maxWidth: '40%',
    },
  },
  title: {
    paddingTop: 10,
    paddingBottom: 10,
    height: 65,
    borderBottom: `1px solid ${t.palette.divider}`,
  },
  tabsContainer: {
    marginBottom: -7,
    marginTop: 7,
  },
  hidden: {
    maxHeight: 0,
  },
  grid: {
    display: 'grid',
    columnGap: 16,
    transition: '300ms',
  },
  oneColumnGrid: {
    gridTemplateColumns: '1fr',
  },
  uploadingGrid: {
    gridTemplateColumns: '4fr 1px 6fr',
  },
  verticalDivider: {
    backgroundColor: t.palette.custom.dark[10],
    cursor: 'pointer',
    position: 'relative',

    '&:hover': {
      backgroundColor: t.palette.custom.dark[20],
    },
  },
  dividerIcon: {
    top: 'calc(50% - 5px)',
    left: 'calc(50% - 3px)',
    position: 'relative',
  },
  filesSection: {
    gap: 8,
    paddingTop: 24,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  filesList: {
    transition: 'max-height 0.3s',
    paddingBottom: 8,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    overflowY: 'auto',
    height: '100%',
    boxShadow: '0 0 0 10px rgba(255, 121, 63, 0)',
    borderRadius: 10,
    position: 'relative',

    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: t.palette.custom.dark[67],
      borderRadius: 4,
    },

    '&.invalid': {
      animation: `${keyframes`
      0% {
        box-shadow: inset 0 0 0 0 ${t.palette.custom.error[80]};
      }
      70% {
        box-shadow: inset 0 0 10px 5px ${t.palette.custom.error[60]};
      }
      100% {
        box-shadow: inset 0 0 0 0 ${t.palette.custom.error[60]};
      }
      `} 2s infinite ease-in-out`,
    },
  },
  fileCategoriesList: {
    paddingTop: 24,
    paddingBottom: 8,
    display: 'flex',
    gap: 16,
    flexDirection: 'column',
    overflowY: 'auto',
    height: '100%',
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: t.palette.custom.dark[67],
      borderRadius: 4,
    },
  },
  filesCount: {
    width: 60,
  },
  gap4: {
    gap: 4,
  },
  gap16: {
    gap: 16,
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  center: {
    justifyContent: 'center',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexGrow1: {
    flexGrow: 1,
  },
  h100: {
    height: '100%',
  },
  overflowHidden: {
    overflow: 'hidden',
  },
}));

export default useStyles;
