import { Box, Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { CustomAutocomplete, IAutocompleteOption } from '@components/CustomAutocomplete';
import { useApi, useAppSelector, useLoader } from '@hooks';
import { useEffect, useMemo, useState } from 'react';

import { CustomDialog } from '@components/CustomDialog';
import { IAddGroupMemberData } from '@models/interfaces/additional/IAddGroupMemberData';
import { IUser } from '@models/interfaces/entities/IUser';
import { Loader } from '@components/Loader';
import PlusFilledIcon from '@assets/icons/dialog/plus-filled.svg';
import { getUsers } from '@services/api';
import { selectAuthGetUsersActionLink } from '@reducers/authSlice';

interface IProps {
  members: IUser[];
  open: boolean;
  onClose: (data?: IAddGroupMemberData) => void;
}

type IFormData = {
  user: IAutocompleteOption | null;
};

export const AddGroupMemberDialog = ({ members, open, onClose }: IProps) => {
  const formDefaultData = useMemo(
    () => ({
      user: null,
    }),
    [],
  );

  const form = useForm<IFormData>({
    defaultValues: formDefaultData,
  });

  const [users, setUsers] = useState<IUser[]>([]);
  const getUsersActionLink = useAppSelector(selectAuthGetUsersActionLink);
  const {
    request: getUsersRequest,
    loading: getUsersLoading,
    data: getUsersData,
  } = useApi(getUsers, null, { handleErrors: true });

  useEffect(() => {
    if (open && getUsersActionLink) {
      getUsersRequest(getUsersActionLink.href);
    }
  }, [open, getUsersActionLink]);

  useEffect(() => {
    if (getUsersData) {
      setUsers(getUsersData.items);
    }
  }, [getUsersData]);

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const data = form.getValues();

    onClose(data.user?.value ? { userId: data.user.value } : undefined);
  };

  useEffect(() => {
    form.reset(formDefaultData);
  }, [open]);

  const userOptions = useMemo<IAutocompleteOption[]>(() => {
    return users
      .filter((u) => !members.find((m) => m.id === u.id))
      .map((x) => ({
        value: x.id,
        title: x.fullName,
        showAvatar: true,
      }));
  }, [users, members]);

  const showLoader = useLoader(getUsersLoading);

  return (
    <>
      <CustomDialog
        title='Add Member'
        onClose={onCancel}
        open={open}
        maxWidth='xs'
        fullWidth
        actions={
          <>
            <div />
            <Button
              type='submit'
              form='form'
              variant='contained'
              color='secondary'
              size='large'
              startIcon={<img alt='add' src={PlusFilledIcon} />}
            >
              Add Member
            </Button>
            <div />
          </>
        }
      >
        <form
          id='form'
          onSubmit={form.handleSubmit(() => {
            onSave();
          })}
          noValidate
        >
          <Box>
            <Controller
              name={'user'}
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: 'Please select the user',
                },
              }}
              render={({ field: { onChange, value }, formState }) => (
                <CustomAutocomplete
                  value={value}
                  label='User'
                  placeholder='Select User'
                  onChange={onChange}
                  error={!!formState.errors.user}
                  helperText={formState.errors.user?.message}
                  options={userOptions}
                />
              )}
            />
          </Box>
        </form>
      </CustomDialog>
      <Loader show={showLoader} />
    </>
  );
};
