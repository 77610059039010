import { Badge, Box, Button, IconButton, Typography } from '@mui/material';
import {
  addUserMembership,
  deleteUser,
  getUser,
  getUserMemberships,
  removeGroupMember,
} from '@services/api';
import { useApi, useAppSelector, useConfirm, useLoader, useUpdateEffect } from '@hooks';
import { useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { AddUserMembershipDialog } from './components/AddUserMembershipDialog';
import EditFilledIcon from '@assets/icons/item-view/edit-3-filled.svg';
import { IAddUserMembershipData } from '@models/interfaces/additional/IAddUserMembershipData';
import { IGroup } from '@models/interfaces/entities/IGroup';
import { IUser } from '@models/interfaces/entities/IUser';
import { Loader } from '@components/Loader';
import { MembershipCard } from './components/MembershipCard';
import PlusIcon from '@assets/icons/item-view/plus-filled.svg';
import { StringAvatar } from '@components/StringAvatar';
import TrashFilledIcon from '@assets/icons/item-view/trash-filled.svg';
import { selectAuthAccount } from '@reducers/authSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';

interface IProps {
  userId: string;
  onUserChanged: () => void;
}

export const UserView = ({ onUserChanged, userId }: IProps) => {
  const account = useAppSelector(selectAuthAccount);
  const { classes } = useStyles();
  const confirm = useConfirm();
  const [openDialog, setOpenDialog] = useState(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [memberships, setMemberships] = useState<IGroup[]>([]);
  const navigate = useNavigate();

  const {
    request: getUserRequest,
    loading: getUserLoading,
    data: getUserData,
  } = useApi(getUser, null, { handleErrors: true });

  const { request: deleteUserRequest, loading: deleteUserLoading } = useApi(deleteUser, null, {
    handleErrors: true,
    onCallback: () => {
      toast.info('Successfully deleted the user');
      onUserChanged();
      navigate('/admin/users');
    },
  });

  const {
    request: getUserMembershipsRequest,
    data: getUserMembershipsData,
    loading: getUserMembershipsLoading,
  } = useApi(getUserMemberships, null, { handleErrors: true });

  const {
    request: removeGroupMemberRequest,
    loading: removeGroupMemberLoading,
    data: removeGroupMemberData,
  } = useApi(removeGroupMember, null, { handleErrors: true });

  const {
    request: addUserMembershipRequest,
    loading: addUserMembershipLoading,
    data: addUserMembershipData,
  } = useApi(addUserMembership, null, { handleErrors: true });

  const onEdit = () => {
    toast.info('Not implemented yet');
  };

  const onDelete = async () => {
    if (user?.links[Actions.delete]?.href) {
      await confirm({
        title: 'Delete User',
        description: 'Are you sure that you want to delete this user?',
        confirmationText: 'Delete',
      });
      deleteUserRequest(user.links[Actions.delete].href);
    }
  };

  const onAddToGroup = () => {
    setOpenDialog(true);
  };

  const onRemoveMembership = async (item: IGroup) => {
    if (item.links[Actions.removeMember]?.href) {
      await confirm({
        title: user?.id === account?.localAccountId ? 'Leave Group' : 'Remove From Group',
        description: `Are you sure that you want to ${
          user?.id === account?.localAccountId ? 'leave' : `remove ${user?.fullName} from`
        } ${item.name}?`,
        confirmationText: user?.id === account?.localAccountId ? 'Leave' : 'Remove',
      });
      removeGroupMemberRequest(item.links[Actions.removeMember].href);
    }
  };

  const onCloseAddMembershipDialog = async (data?: IAddUserMembershipData) => {
    if (!data) {
      setOpenDialog(false);
    } else {
      const url = user?.links[Actions.addMembership]?.href;
      if (url) {
        addUserMembershipRequest(url, data);
      }
    }
  };

  useUpdateEffect(() => {
    if (getUserData) {
      setUser(getUserData);
    }
  }, [getUserData]);

  useEffect(() => {
    if (getUserMembershipsData) {
      setMemberships(getUserMembershipsData);
    }
  }, [getUserMembershipsData]);

  useUpdateEffect(() => {
    if (addUserMembershipData) {
      setOpenDialog(false);
      toast.info('Successfully added to the group');
      setMemberships(addUserMembershipData);
    }
  }, [addUserMembershipData]);

  useUpdateEffect(() => {
    if (removeGroupMemberData) {
      toast.info('Successfully removed from the group');
      if (user?.links[Actions.getMemberships]?.href) {
        getUserMembershipsRequest(user.links[Actions.getMemberships].href);
      }
    }
  }, [removeGroupMemberData, user]);

  useEffect(() => {
    if (userId) {
      getUserRequest(`/users/${userId}`);
    }
  }, [userId]);

  useEffect(() => {
    if (user?.links[Actions.getMemberships]?.href) {
      getUserMembershipsRequest(user.links[Actions.getMemberships].href);
    }
  }, [user]);

  const infoItems = useMemo<{ value: string; label: string }[]>(
    () => [
      {
        label: 'Phone Number',
        value: user?.phone || '-',
      },
      {
        label: 'Email',
        value: user?.email || '-',
      },
      {
        label: 'Company',
        value: user?.companyName || '-',
      },
    ],
    [user],
  );

  const showLoader = useLoader(
    getUserLoading,
    getUserMembershipsLoading,
    removeGroupMemberLoading,
    addUserMembershipLoading,
    deleteUserLoading,
  );

  return (
    <Box className={classes.container}>
      {user && (
        <>
          <Box className={classes.infoSection}>
            <Box className={classes.title}>
              <StringAvatar value={user.fullName} size='sm' />
              <Typography variant='h6'>{user.fullName}</Typography>
              <IconButton onClick={onEdit}>
                <img src={EditFilledIcon} alt='Edit' />
              </IconButton>
              {!!user?.links[Actions.delete]?.href && (
                <IconButton onClick={onDelete}>
                  <img src={TrashFilledIcon} alt='Delete' />
                </IconButton>
              )}
            </Box>
            <Box className={classes.infoContent}>
              {infoItems.map((item) => (
                <Box key={item.label} className={classes.infoItem}>
                  <Typography className='label' variant='caption'>
                    {item.label}
                  </Typography>
                  <Typography variant='subtitle1'>{item.value}</Typography>
                </Box>
              ))}
            </Box>
            <Box className={classes.memberships}>
              <Box className={classes.membershipsCountContainer}>
                <Typography variant='subtitle1'>In Groups:</Typography>
                <Badge
                  className={classes.membershipsCount}
                  badgeContent={memberships.length}
                  color='secondary'
                />
              </Box>
              {!!user?.links[Actions.addMembership]?.href && (
                <Button
                  className={classes.addButton}
                  variant='text'
                  color='info'
                  startIcon={<img src={PlusIcon} alt='Add' />}
                  onClick={onAddToGroup}
                >
                  Add To Group
                </Button>
              )}
            </Box>
          </Box>
          <Box className={classes.membershipsList}>
            {memberships.map((item) => (
              <MembershipCard
                key={item.id}
                user={user}
                membership={item}
                onRemove={() => onRemoveMembership(item)}
              />
            ))}
          </Box>
        </>
      )}
      <AddUserMembershipDialog
        open={openDialog}
        onClose={onCloseAddMembershipDialog}
        memberships={memberships}
      />
      <Loader show={showLoader} />
    </Box>
  );
};
