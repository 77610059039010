import { createContext } from 'react';

export interface IConfirmOptions {
  title?: string;
  description?: string;
  confirmationText?: string;
  cancellationText?: string;
  showNotesInput?: boolean;
  notesInputLabel?: string;
  notesInputRequired?: boolean;
}

export default createContext<(options: IConfirmOptions) => Promise<{ notes: string } | undefined>>(
  () => Promise.resolve({ notes: '' }),
);
