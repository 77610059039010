import { Context } from '@modules/react-signalr/signalr/types';
import { FilesHub } from '@hubs/filesHub';
import { createContext } from 'react';
import { createSignalRContext } from '@modules/react-signalr';

export default createContext<Context<FilesHub>>(
  createSignalRContext<FilesHub>({
    shareConnectionBetweenTab: true,
  }),
);
