export enum ActivityEventTypes {
  clientCreated = 'Client created',
  clientUpdated = 'Client updated',
  clientDeleted = 'Client deleted',
  clientRolesUpdated = 'Client roles updated',
  featureAddedToClient = 'Feature added to client',
  fileCreated = 'File created',
  fileDeleted = 'File deleted',
  fileContentDeleted = 'File content deleted',
  fileUserStatusUpdated = 'File user status updated',
  fileIgnoreStatusUpdated = 'File ignore status updated',
  fileCategoryChanged = 'File category changed',
  fileCategoryCreated = 'File category created',
  fileCategoryUpdated = 'File category updated',
  fileCategoryDeleted = 'File category deleted',
  fileCategoryFieldCreated = 'File category field created',
  fileCategoryFieldUpdated = 'File category field updated',
  fileCategoryFieldDeleted = 'File category field deleted',
  fileMappingChanged = 'File mapping changed',
  fileTransformationScriptChanged = 'File transformation script changed',
  fileSubCategoryCreated = 'File sub category created',
  fileSubCategoryMappingCreated = 'File sub category mapping created',
  fileSubCategoryMappingUpdated = 'File sub category mapping updated',
  fileSubCategoryTransformationScriptCreated = 'File sub category transformation script created',
  fileSubCategoryTransformationScriptUpdated = 'File sub category transformation script updated',
  fileSubCategoryUpdated = 'File sub category updated',
  groupCreated = 'Group created',
  groupDeleted = 'Group deleted',
  piiIncidentCategoryCreated = 'Pii category created',
  piiIncidentCategoryUpdated = 'Pii category updated',
  piiIncidentCategoryDeleted = 'Pii category deleted',
  whitelistRecordCreated = 'Whitelist record created',
  whitelistRecordUpdated = 'Whitelist record updated',
  whitelistRecordDeleted = 'Whitelist record deleted',
  productCreated = 'Product created',
  productUpdated = 'Product updated',
  productDeleted = 'Product deleted',
  productAddedToClient = 'Product added to client',
  projectCreated = 'Project created',
  projectUpdated = 'Project updated',
  projectDeleted = 'Project deleted',
  projectAccountsUpdated = 'Project accounts updated',
  standardPiiIncidentCategoryUpdated = 'Standard Pii category updated',
  userCreated = 'User created',
  userDeleted = 'User deleted',
  userAddedToGroup = 'User added to group',
  userRemovedFromGroup = 'User removed from group',

  unknownEvent = 'Unknown event',
}
