import BaseProvider from './BaseProvider';
import { ICreatePiiIncidentCategoryRequest } from '@models/interfaces/requests/ICreatePiiIncidentCategoryRequest';
import { ICreatePiiIncidentCategoryResponse } from '@models/interfaces/responses/ICreatePiiIncidentCategoryResponse';
import { IGetPiiIncidentCategoriesResponse } from '@models/interfaces/responses/IGetPiiIncidentCategoriesResponse';
import { IGetPiiIncidentCategoryResponse } from '@models/interfaces/responses/IGetPiiIncidentCategoryResponse';
import { IUpdatePiiIncidentCategoryRequest } from '@models/interfaces/requests/IUpdatePiiIncidentCategoryRequest';
import { IUpdatePiiIncidentCategoryResponse } from '@models/interfaces/responses/IUpdatePiiIncidentCategoryResponse';

export default class PiiIncidentCategoriesProvider extends BaseProvider {
  public getPiiIncidentCategories = (url: string) => {
    return this.get<IGetPiiIncidentCategoriesResponse>(url);
  };

  public getPiiIncidentCategory = (url: string) => {
    return this.get<IGetPiiIncidentCategoryResponse>(url);
  };

  public createPiiIncidentCategory = (url: string, data: ICreatePiiIncidentCategoryRequest) => {
    return this.post<ICreatePiiIncidentCategoryRequest, ICreatePiiIncidentCategoryResponse>(
      url,
      data,
    );
  };

  public deletePiiIncidentCategory = (url: string) => {
    return this.delete<void>(url);
  };

  public updatePiiIncidentCategory = (url: string, data: IUpdatePiiIncidentCategoryRequest) => {
    return this.patch<IUpdatePiiIncidentCategoryRequest, IUpdatePiiIncidentCategoryResponse>(
      url,
      data,
    );
  };
}
