import { Mapper, createMap } from '@automapper/core';

import { IGroup } from '@models/interfaces/entities/IGroup';
import { IGroupDto } from '@models/interfaces/dtos/IGroupDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createGroupMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IGroupDto>('IGroupDto', {
    id: String,
    name: String,
    description: String,
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IGroup>('IGroup', {
    id: String,
    name: String,
    description: String,
    links: 'ILinks',
  });

  createMap<IGroupDto, IGroup>(mapper, 'IGroupDto', 'IGroup');
};
