import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  overflowHidden: {
    overflow: 'hidden',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  container: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  hiddenSection: {
    marginTop: 107,
  },
  topSection: {
    minHeight: 40,
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: 4,
    paddingTop: 4,
    width: '100%',
    position: 'relative',
  },
  mainSection: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: t.palette.custom.white[100],
  },
  topSectionContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    gap: 16,

    '&>nav': {
      maxWidth: '40%',
    },
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
  flexGap60: {
    gap: 60,
  },
  title: {
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: `1px solid ${t.palette.divider}`,
  },
  clientsSection: {
    overflowY: 'auto',
    height: '100%',
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: t.palette.custom.dark[67],
      borderRadius: 4,
    },
  },
  clientsList: {
    paddingTop: 24,
    paddingBottom: 24,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: 16,
  },
}));

export default useStyles;
