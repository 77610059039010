import BaseProvider from './BaseProvider';
import { IGetAppInfoResponse } from '@models/interfaces/responses/IGetAppInfoResponse';

export default class AppInfoProvider extends BaseProvider {
  public getAppInfo = () => {
    return this.get<IGetAppInfoResponse>('/index');
  };
  public getAppAlert = (url: string) => {
    return this.get<string>(url);
  };
}
