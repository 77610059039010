import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  overflowHidden: {
    overflow: 'hidden',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',

    '&.loaded::after': {
      display: 'none',
    },

    '&::after': {
      backgroundColor: t.palette.custom.white[100],
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      position: 'absolute',
      top: 40,
      left: 0,
      right: 0,
      bottom: 0,
      content: '""',
    },
  },
  container: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  hiddenSection: {
    marginTop: 107,
  },
  topSection: {
    minHeight: 40,
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: 4,
    paddingTop: 4,
    width: '100%',
    position: 'relative',
  },
  mainSection: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: t.palette.custom.white[100],
  },
  topSectionContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    gap: 16,

    '&>nav': {
      maxWidth: '40%',
    },
  },
  title: {
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: `1px solid ${t.palette.divider}`,
  },
  productsSection: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
    paddingBottom: 20,
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: t.palette.custom.dark[67],
      borderRadius: 4,
    },
  },
  productsList: {
    paddingTop: 24,
    paddingBottom: 24,
    display: 'grid',
    gap: 16,
    [t.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [t.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
  mt40: {
    marginTop: 40,
  },
  mt24: {
    marginTop: 24,
  },
  subTitle: {
    fontWeight: 'bold',
  },
  carouselContainer: {
    paddingTop: 24,
    paddingBottom: 40,
    marginLeft: -8,
    marginRight: -8,
  },
  carouselItem: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  dotsList: {
    '& .react-multi-carousel-dot': {
      '& button': {
        transition: 'all .3',
        borderColor: 'transparent',
        backgroundColor: t.palette.custom.primary[40],
      },
      '&.react-multi-carousel-dot--active': {
        '& button': {
          borderColor: t.palette.custom.error[40],
          backgroundColor: t.palette.custom.error[100],
        },
      },
    },
  },
}));

export default useStyles;
