import * as t from 'io-ts';

import {
  IJsonDMAccountAdjustmentData,
  IJsonDMAccountAdjustmentDataSchema,
} from './IJsonDMAccountAdjustmentData';

export interface IJsonDMAccountData {
  sFileID: string;
  nVersion: number;
  sAcctName: string;
  dInitBalance: number;
  dManualAdj: number;
  dAdjBal: number;
  dFees: number;
  dTotal: number;
  dGLBal: number;
  dYld: number;
  nType: number;
  dPushBal: number;
  dRatio: number;
  m_Adjustments: IJsonDMAccountAdjustmentData[];
}

export const IJsonDMAccountDataSchema = t.type({
  sFileID: t.string,
  nVersion: t.number,
  sAcctName: t.string,
  dInitBalance: t.number,
  dManualAdj: t.number,
  dAdjBal: t.number,
  dFees: t.number,
  dTotal: t.number,
  dGLBal: t.number,
  dYld: t.number,
  nType: t.number,
  dPushBal: t.number,
  dRatio: t.number,
  // eslint-disable-next-line camelcase
  m_Adjustments: t.array(IJsonDMAccountAdjustmentDataSchema),
});
