import { Mapper, createMap } from '@automapper/core';

import { IBalance } from '@models/interfaces/entities/IBalance';
import { IBalanceDto } from '@models/interfaces/dtos/IBalanceDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createBalanceMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IBalanceDto>('IBalanceDto', {
    projectId: String,
    categoryId: String,
    totalBalance: Number,
    usedBalance: Number,
    ignoredBalance: Number,
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IBalance>('IBalance', {
    projectId: String,
    categoryId: String,
    totalBalance: Number,
    usedBalance: Number,
    ignoredBalance: Number,
    links: 'ILinks',
  });

  createMap<IBalanceDto, IBalance>(mapper, 'IBalanceDto', 'IBalance');
};
