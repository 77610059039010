import { useState } from 'react';

export const useInput = <T>(initialValue: T) => {
  const [value, setValue] = useState(initialValue);
  const [usedSetter, setUsedSetter] = useState(false);

  const handleChange = (event: {
    target: {
      value: T;
    };
  }) => {
    setValue(event.target.value);
    setUsedSetter(false);
  };

  const handleSet = (value: T) => {
    setValue(value);
    setUsedSetter(true);
  };

  return {
    value,
    usedSetter,
    onChange: handleChange,
    set: handleSet,
  };
};
