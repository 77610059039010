import { Box, Container } from '@mui/material';

import { Outlet } from 'react-router-dom';
import { SideMenu } from '@components/SideMenu';
import useStyles from './styles';

export const AdminLayout = () => {
  const { classes } = useStyles();
  return (
    <Container maxWidth='xl'>
      <Box data-testid='container' className={classes.container}>
        <Box data-testid='side-section' className={classes.sideSection}>
          <SideMenu />
        </Box>
        <Box data-testid='main-section' className={classes.mainSection}>
          <Outlet />
        </Box>
      </Box>
    </Container>
  );
};
