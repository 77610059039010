import BaseProvider from './BaseProvider';
import { IGetExistingTypeCodesResponse } from '@models/interfaces/responses/IGetExistingTypeCodesResponse';
import { IGetNewTypeCodesResponse } from '@models/interfaces/responses/IGetNewTypeCodesResponse';
import { IUpdateNewTypeCodeRequest } from '@models/interfaces/requests/IUpdateNewTypeCodeRequest';

export default class TypeCodesProvider extends BaseProvider {
  public getExistingTypeCodes = async (url: string, categoryId: string, ignored: boolean) => {
    return this.get<IGetExistingTypeCodesResponse>(url, {
      params: {
        categoryId,
        ignored,
      },
    });
  };

  public getNewTypeCodes = async (url: string, categoryId: string) => {
    return this.get<IGetNewTypeCodesResponse>(url, {
      params: {
        categoryId,
      },
    });
  };

  public updateNewTypeCode = (url: string, data: IUpdateNewTypeCodeRequest) => {
    return this.put<IUpdateNewTypeCodeRequest, void>(url, data);
  };
}
