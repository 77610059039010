import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    cursor: 'pointer',
    gap: 4,
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    borderRadius: 10,
    backgroundColor: t.palette.custom.primary[5],
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    minWidth: 24,
    borderRadius: '50%',
    backgroundColor: t.palette.custom.white[100],
  },
  title: {
    flexGrow: 1,
  },
  additional: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    height: '100%',
  },
}));

export default useStyles;
