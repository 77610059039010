import { Mapper, createMap } from '@automapper/core';

import { IStandardPiiIncidentCategory } from '@models/interfaces/entities/IStandardPiiIncidentCategory';
import { IStandardPiiIncidentCategoryDto } from '@models/interfaces/dtos/IStandardPiiIncidentCategoryDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createStandardPiiIncidentCategoryMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IStandardPiiIncidentCategoryDto>('IStandardPiiIncidentCategoryDto', {
    id: String,
    name: String,
    enabled: Boolean,
    confidence: Number,
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IStandardPiiIncidentCategory>('IStandardPiiIncidentCategory', {
    id: String,
    name: String,
    enabled: Boolean,
    confidence: Number,
    links: 'ILinks',
  });

  createMap<IStandardPiiIncidentCategoryDto, IStandardPiiIncidentCategory>(
    mapper,
    'IStandardPiiIncidentCategoryDto',
    'IStandardPiiIncidentCategory',
  );
};
