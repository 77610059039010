import { Box } from '@mui/material';
import { IClientProduct } from '@models/interfaces/entities/IClientProduct';
import { IProduct } from '@models/interfaces/entities/IProduct';
import { ProductCard } from './components/ProductCard';
import { selectAuthProducts } from '@reducers/authSlice';
import { useAppSelector } from '@hooks';
import { useMemo } from 'react';
import useStyles from './styles';

interface IProps {
  clientProducts: IClientProduct[];
  isActivationEnabled: boolean;
  onActivateProduct: (productId: string) => void;
}

export const ProductsTabView = ({
  clientProducts,
  isActivationEnabled,
  onActivateProduct,
}: IProps) => {
  const { classes } = useStyles();
  const products = useAppSelector(selectAuthProducts);

  const clientProductItems = useMemo<(IProduct & { isEnabled: boolean })[]>(() => {
    if (!products.length) return [];
    const items = products.map((p) => ({
      ...p,
      isEnabled: clientProducts.findIndex((x) => p.id === x.productId) !== -1,
    }));
    return items;
  }, [products, clientProducts]);

  return (
    <Box className={classes.productsList}>
      {!!clientProductItems.length &&
        clientProductItems.map((item) => (
          <ProductCard
            key={item.id}
            product={item}
            onActivate={() => onActivateProduct(item.id)}
            isActivationEnabled={isActivationEnabled}
          />
        ))}
    </Box>
  );
};
