import { Box, Button, IconButton, Link, Tooltip, Typography } from '@mui/material';

import { Actions } from '@models/enums/Actions';
import Divider from '@mui/material/Divider';
import DownloadIcon from '@assets/icons/issues-drawer/download.svg';
import { IFile } from '@models/interfaces/entities/IFile';
import { NavLink } from 'react-router-dom';
import { PIIScanResultStatuses } from '@models/enums/PIIScanResultStatuses';
import TrashIcon from '@assets/icons/dashboard/trash.svg';
import { clsx } from 'clsx';
import moment from 'moment';
import { selectAuthProducts } from '@reducers/authSlice';
import { useAppSelector } from '@hooks';
import { useMemo } from 'react';
import useStyles from './styles';

interface IProps {
  item: IFile;
  onDownload: () => void;
  onDetails: () => void;
  onDelete: () => void;
}

export const FileCard = ({ item, onDetails, onDownload, onDelete }: IProps) => {
  const { classes } = useStyles();
  const products = useAppSelector(selectAuthProducts);

  const productLink = useMemo(() => `/clients/${item.clientId}/products/${item.productId}`, [item]);

  const projectLink = useMemo(
    () => `/clients/${item.clientId}/products/${item.productId}/projects/${item.projectId}`,
    [item],
  );

  const productLabel = useMemo(() => {
    const product = products.find((x) => item.productId === x.id);
    return product?.name || 'Unknown';
  }, [item, products]);

  return (
    <Box
      key={item.id}
      className={clsx([
        classes.container,
        item.piiScanResultStatus === PIIScanResultStatuses.completedWithPii && classes.withPii,
        item.piiScanResultStatus === PIIScanResultStatuses.unscanned && classes.unscanned,
      ])}
    >
      <Box className={clsx([classes.flex, classes.justifyContentSpaceBetween])}>
        <Typography variant='subtitle2'>
          {item.sourceFileName ? `${item.sourceFileName} > ${item.name}` : item.name}
        </Typography>
        <Box className={clsx([classes.flex, classes.gap4])}>
          {!!item.links[Actions.getContent]?.href && (
            <Tooltip title='Download file'>
              <IconButton onClick={() => onDownload()} data-testid='download-button'>
                <img src={DownloadIcon} alt='download' />
              </IconButton>
            </Tooltip>
          )}
          {!!item.links[Actions.delete]?.href && (
            <Tooltip title='Delete file'>
              <IconButton onClick={() => onDelete()} data-testid='delete-button'>
                <img src={TrashIcon} alt='trash' />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Divider />
      <Box data-testid='upload-info'>
        <Typography className={classes.textGrey} variant='caption'>
          uploaded by
        </Typography>{' '}
        <Typography className={classes.textBold} variant='caption'>
          {item.uploadedBy}
        </Typography>{' '}
        <Typography className={classes.textGrey} variant='caption'>
          at
        </Typography>{' '}
        <Typography className={classes.textBold} variant='caption'>
          {moment(item.uploaded).format('hh:mma, MM.DD.YYYY')}
        </Typography>
      </Box>
      <Box className={clsx([classes.flex, classes.gap4])}>
        <Typography className={classes.textGrey} variant='caption'>
          Product:
        </Typography>
        <Link
          to={productLink}
          variant='caption'
          className={clsx([classes.textBold, classes.textInfo])}
          underline='none'
          component={NavLink}
        >
          {productLabel}
        </Link>
      </Box>
      <Box className={clsx([classes.flex, classes.gap4])}>
        <Typography className={classes.textGrey} variant='caption'>
          Project:
        </Typography>
        <Link
          to={projectLink}
          variant='caption'
          className={clsx([classes.textBold, classes.textInfo])}
          underline='none'
          component={NavLink}
        >
          {item.projectName}
        </Link>
      </Box>
      <Divider />
      <Box className={clsx([classes.flex, classes.justifyContentEnd])}>
        {item.piiScanResultStatus === PIIScanResultStatuses.completedWithPii && (
          <Button
            className={classes.actionButton}
            variant='outlined'
            color='error'
            size='small'
            onClick={() => onDetails()}
          >
            View PII
          </Button>
        )}

        {item.piiScanResultStatus === PIIScanResultStatuses.unscanned && (
          <Button
            className={classes.actionButton}
            variant='outlined'
            color='secondary'
            size='small'
            onClick={() => onDetails()}
          >
            Unscanned
          </Button>
        )}
      </Box>
    </Box>
  );
};
