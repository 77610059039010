import { logout, selectAuthToken } from '@reducers/authSlice';
import { useAppDispatch, useAppSelector } from '@hooks';

import { useEffect } from 'react';

export const UnauthenticatedContainer = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const token = useAppSelector(selectAuthToken);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (token?.accessToken && (!token.expiresOn || new Date(token.expiresOn) < new Date())) {
      dispatch(logout());
    }
  }, []);

  return <>{children}</>;
};
