import { Box, IconButton, Tooltip, Typography } from '@mui/material';

import HelpCircleIcon from '@assets/icons/dashboard/help-circle.svg';
import useStyles from './styles';

interface IProps {
  onDrop: () => void;
}

export const NewFileSubCategoryCard = ({ onDrop }: IProps) => {
  const { classes } = useStyles();

  return (
    <Box
      className={classes.root}
      onDrop={onDrop}
      data-testid='new-file-sub-category-card'
      data-tour='new-file-sub-category'
    >
      <Typography variant='caption'>Create a new file pairing</Typography>
      <Tooltip
        title={`You can provide new files for processing that we have not historically processed in the past. 
        Drag an accepted uploaded file here to indicate that you wish for us to process this file.`}
      >
        <IconButton data-testid='help-button'>
          <img src={HelpCircleIcon} alt='help' />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
