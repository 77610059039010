import BaseProvider from './BaseProvider';
import { ICreateWhitelistRecordRequest } from '@models/interfaces/requests/ICreateWhitelistRecordRequest';
import { ICreateWhitelistRecordResponse } from '@models/interfaces/responses/ICreateWhitelistRecordResponse';
import { IGetWhitelistRecordResponse } from '@models/interfaces/responses/IGetWhitelistRecordResponse';
import { IGetWhitelistRecordsResponse } from '@models/interfaces/responses/IGetWhitelistRecordsResponse';
import { IUpdateWhitelistRecordRequest } from '@models/interfaces/requests/IUpdateWhitelistRecordRequest';
import { IUpdateWhitelistRecordResponse } from '@models/interfaces/responses/IUpdateWhitelistRecordResponse';

export default class WhitelistRecordsProvider extends BaseProvider {
  public getWhitelistRecords = (url: string) => {
    return this.get<IGetWhitelistRecordsResponse>(url, { cache: { ttl: 1000 } });
  };

  public getWhitelistRecord = (url: string) => {
    return this.get<IGetWhitelistRecordResponse>(url);
  };

  public createWhitelistRecord = (url: string, data: ICreateWhitelistRecordRequest) => {
    return this.post<ICreateWhitelistRecordRequest, ICreateWhitelistRecordResponse>(url, data);
  };

  public deleteWhitelistRecord = (url: string) => {
    return this.delete<void>(url);
  };

  public updateWhitelistRecord = (url: string, data: IUpdateWhitelistRecordRequest) => {
    return this.patch<IUpdateWhitelistRecordRequest, IUpdateWhitelistRecordResponse>(url, data);
  };
}
