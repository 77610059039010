import {
  IUpdateClientRolesData,
  IUpdateClientRolesDataRole,
} from '@models/interfaces/additional/IUpdateClientRolesData';

import ClientRolesProvider from '@providers/ClientRolesProvider';
import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import { IRoleAssignment } from '@models/interfaces/entities/IRoleAssignment';
import { IRoleAssignmentDto } from '@models/interfaces/dtos/IRoleAssignmentDto';
import { IUpdateClientRolesRequestRole } from '@models/interfaces/requests/IUpdateClientRolesRequest';
import mapper from '@models/mapper';

const clientRolesProvider = new ClientRolesProvider();

export const getClientRoles = async (url: string) => {
  const result = await clientRolesProvider.getClientRoles(url);
  return {
    items: mapper.mapArray<IRoleAssignmentDto, IRoleAssignment>(
      result.items,
      'IRoleAssignmentDto',
      'IRoleAssignment',
    ),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const updateClientRoles = async (url: string, data: IUpdateClientRolesData) => {
  const requestData = mapper.mapArray<IUpdateClientRolesDataRole, IUpdateClientRolesRequestRole>(
    data,
    'IUpdateClientRolesDataRole',
    'IUpdateClientRolesRequestRole',
  );
  const result = await clientRolesProvider.updateClientRoles(url, requestData);
  return {
    items: mapper.mapArray<IRoleAssignmentDto, IRoleAssignment>(
      result.items,
      'IRoleAssignmentDto',
      'IRoleAssignment',
    ),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};
