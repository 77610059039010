import { Box, FormControlLabel, Switch } from '@mui/material';

import useStyles from './styles';

interface IProps {
  unusedColumnsVisibility: boolean;
  onChangeUnusedColumnsVisibility: (value: boolean) => void;
}

export const PreviewTableFooter = ({
  unusedColumnsVisibility,
  onChangeUnusedColumnsVisibility,
}: IProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <Box>
        <FormControlLabel
          control={
            <Switch
              inputProps={{ role: 'switch' }}
              checked={unusedColumnsVisibility}
              onChange={(_, val) => onChangeUnusedColumnsVisibility(val)}
            />
          }
          label='Show unmapped columns'
        />
      </Box>
    </Box>
  );
};
