import BaseProvider from './BaseProvider';
import { IChangeFileCategoryRequest } from '@models/interfaces/requests/IChangeFileCategoryRequest';
import { IChangeFileCategoryResponse } from '@models/interfaces/responses/IChangeFileCategoryResponse';
import { IChangeFileSubCategoryMappingRequest } from '@models/interfaces/requests/IChangeFileSubCategoryMappingRequest';
import { IChangeFileSubCategoryMappingResponse } from '@models/interfaces/responses/IChangeFileSubCategoryMappingResponse';
import { IChangeFileSubCategoryTransformationScriptRequest } from '@models/interfaces/requests/IChangeFileSubCategoryTransformationScriptRequest';
import { IChangeFileSubCategoryTransformationScriptResponse } from '@models/interfaces/responses/IChangeFileSubCategoryTransformationScriptResponse';
import { IFileContentDto } from '@models/interfaces/dtos/IFileContentDto';
import { IGetCountResponse } from '@models/interfaces/responses/IGetCountResponse';
import { IGetFileResponse } from '@models/interfaces/responses/IGetFileResponse';
import { IGetFilesResponse } from '@models/interfaces/responses/IGetFilesResponse';
import { IGetPiiIncidentDetailsResponse } from '@models/interfaces/responses/IGetPiiIncidentDetailsResponse';
import { IGetPiiScanResultsResponse } from '@models/interfaces/responses/IGetPiiScanResultsResponse';
import { IGetProjectFilesDirectoryResponse } from '@models/interfaces/responses/IGetProjectFilesDirectoryResponse';
import { IGetProjectFilesNamesResponse } from '@models/interfaces/responses/IGetProjectFilesNamesResponse';
import { IGetProjectFilesResponse } from '@models/interfaces/responses/IGetProjectFilesResponse';
import { IUpdateFileUserStatusRequest } from '@models/interfaces/requests/IUpdateFileUserStatusRequest';
import { IUpdateFileUserStatusResponse } from '@models/interfaces/responses/IUpdateFileUserStatusResponse';
import { IUploadFileResponse } from '@models/interfaces/responses/IUploadFileResponse';

export default class FilesProvider extends BaseProvider {
  public getFile = (url: string) => {
    return this.get<IGetFileResponse>(url);
  };

  public uploadFile = async (url: string, file: File, replace = true) => {
    const formData = new FormData();
    formData.set('file', file);
    formData.set('replace', replace.toString());
    return this.post<FormData, IUploadFileResponse>(url, formData);
  };

  public getProjectFiles = async (url: string) => {
    return this.get<IGetProjectFilesResponse>(url);
  };

  public getProjectFilesNames = async (url: string, filesNames: string[]) => {
    return this.get<IGetProjectFilesNamesResponse>(url, {
      params: {
        names: filesNames,
      },
    });
  };

  public getFiles = async (
    url: string,
    limit?: number,
    offset?: number,
    search?: string,
    clientId?: string,
    productId?: string,
    projectId?: string,
  ) => {
    return this.get<IGetFilesResponse>(url, {
      params: {
        limit,
        offset,
        search,
        clientId,
        productId,
        projectId,
      },
      cache: false,
    });
  };

  public getFilesCount = async (url: string, search?: string) => {
    return this.get<IGetCountResponse>(url, {
      params: {
        search,
      },
      cache: { ttl: 1000 },
    });
  };

  public getProjectFilesDirectory = async (url: string) => {
    return this.get<IGetProjectFilesDirectoryResponse>(url);
  };

  public getPiiScanResults = (url: string) => {
    return this.get<IGetPiiScanResultsResponse>(url);
  };

  public getPiiIncidentDetails = (url: string) => {
    return this.get<IGetPiiIncidentDetailsResponse>(url);
  };

  public deleteFile = (url: string) => {
    return this.delete<void>(url);
  };

  public updateFileUserStatus = (url: string, data: IUpdateFileUserStatusRequest) => {
    return this.post<IUpdateFileUserStatusRequest, IUpdateFileUserStatusResponse>(url, data);
  };

  public ignoreFile = (url: string) => {
    return this.put<null, IUpdateFileUserStatusResponse>(url, null);
  };

  public unignoreFile = (url: string) => {
    return this.put<null, IUpdateFileUserStatusResponse>(url, null);
  };

  public changeFileCategory = (url: string, data: IChangeFileCategoryRequest) => {
    return this.post<IChangeFileCategoryRequest, IChangeFileCategoryResponse>(url, data);
  };

  public changeFileMapping = (url: string, data: IChangeFileSubCategoryMappingRequest) => {
    return this.post<IChangeFileSubCategoryMappingRequest, IChangeFileSubCategoryMappingResponse>(
      url,
      data,
    );
  };
  public changeFileTransformationScript = (
    url: string,
    data: IChangeFileSubCategoryTransformationScriptRequest,
  ) => {
    return this.post<
      IChangeFileSubCategoryTransformationScriptRequest,
      IChangeFileSubCategoryTransformationScriptResponse
    >(url, data);
  };

  public getFileContent = async (url: string): Promise<IFileContentDto> => {
    const response = await this.getWithHeaders<ArrayBuffer>(url, {
      responseType: 'arraybuffer',
    });

    const contentDisposition = response.headers['content-disposition'];
    const contentType = response.headers['content-type'];
    const match = contentDisposition.match(/filename=(.*);/);
    const fileName = match && match[1] ? match[1].replace(/"/g, '') : 'Unknown';

    return {
      content: response.data,
      fileName,
      contentType,
    };
  };
}
