import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  trigger: {
    display: 'flex',
    alignItems: 'center',
    color: t.palette.custom.white[100],
    cursor: 'pointer',
    gap: 4,
  },
  menuPaper: {
    borderRadius: '0 0 10px 10px',
    boxShadow: '5px 5px 10px #0000001A',
    marginTop: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
  menuItem: {
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 220,
  },
  menuItemText: {
    marginLeft: 8,
  },
}));

export default useStyles;
