import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  appBar: {
    borderBottom: `1px solid ${t.palette.divider}`,
    boxShadow: 'none',
    position: 'absolute',
    zIndex: 2,
  },
  toolBar: {
    justifyContent: 'space-between',
    [t.breakpoints.up('sm')]: { minHeight: 56 },
    [t.breakpoints.up('xs')]: { minHeight: 56 },
  },
  logo: {
    height: 32,
    alignSelf: 'end',
    marginBottom: 2,
  },
  betaMarker: {
    alignSelf: 'center',
    marginTop: 2,
    color: t.palette.custom.error[100],
  },
  logoSection: {
    display: 'flex',
    height: 56,
    gap: 16,
  },
  infoSection: {
    display: 'flex',
    height: 56,
    gap: 16,
  },
  envLabel: {
    alignSelf: 'center',
    marginTop: 2,
    color: t.palette.custom.error[100],
  },
  alertLabel: {
    alignSelf: 'center',
    marginTop: 2,
    maxWidth: 900,
    paddingLeft: 24,
    paddingRight: 24,
    textAlign: 'center',
    color: t.palette.custom.info[100],
  },
  moreButton: {
    marginRight: -16,
    color: `1px solid ${t.palette.custom.dark[38]}`,
  },
  menuPaper: {
    borderRadius: '0 0 10px 10px',
    boxShadow: '5px 5px 10px #0000001A',
    marginTop: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
  menuItem: {
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 220,
  },
  menuItemText: {
    marginLeft: 8,
  },
  divider: {
    marginTop: 8,
    marginBottom: 8,
  },
}));

export default useStyles;
