import BaseProvider from './BaseProvider';
import { ICreateClientRequest } from '@models/interfaces/requests/ICreateClientRequest';
import { ICreateClientResponse } from '@models/interfaces/responses/ICreateClientResponse';
import { IGetClientResponse } from '@models/interfaces/responses/IGetClientResponse';
import { IGetClientsResponse } from '@models/interfaces/responses/IGetClientsResponse';
import { IUpdateClientRequest } from '@models/interfaces/requests/IUpdateClientRequest';
import { IUpdateClientResponse } from '@models/interfaces/responses/IUpdateClientResponse';

export default class ClientsProvider extends BaseProvider {
  public getClients = (url: string) => {
    return this.get<IGetClientsResponse>(url);
  };
  public getClient = (url: string) => {
    return this.get<IGetClientResponse>(url);
  };
  public createClient = (url: string, data: ICreateClientRequest) => {
    return this.post<ICreateClientRequest, ICreateClientResponse>(url, data);
  };
  public updateClient = (url: string, data: IUpdateClientRequest) => {
    return this.patch<IUpdateClientRequest, IUpdateClientResponse>(url, data);
  };
  public deleteClient = (url: string) => {
    return this.delete<void>(url);
  };
}
