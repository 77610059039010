import { Mapper, createMap } from '@automapper/core';

import { IPiiIncident } from '@models/interfaces/entities/IPiiIncident';
import { IPiiIncidentDto } from '@models/interfaces/dtos/IPiiIncidentDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createPiiIncidentMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IPiiIncidentDto>('IPiiIncidentDto', {
    category: Number,
    confidence: Number,
    offset: Number,
  });

  PojosMetadataMap.create<IPiiIncident>('IPiiIncident', {
    category: Number,
    confidence: Number,
    offset: Number,
  });

  createMap<IPiiIncidentDto, IPiiIncident>(mapper, 'IPiiIncidentDto', 'IPiiIncident');
};
