import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    gap: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 8,
    borderRadius: 10,
    backgroundColor: t.palette.custom.primary[10],
    transition: 'background-color 0.3s ease',
    color: t.palette.custom.error[100],
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: t.palette.custom.primary[20],
    },
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: '47.407%', // 256/540
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

export default useStyles;
