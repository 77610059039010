import BaseProvider from './BaseProvider';
import { ICreateProjectResultsUploadedNotificationRequest } from '@models/interfaces/requests/ICreateProjectResultsUploadedNotificationRequest';

export default class EmailNotificationsProvider extends BaseProvider {
  public createProjectResultsUploadedNotification = (
    url: string,
    data: ICreateProjectResultsUploadedNotificationRequest,
  ) => {
    return this.post<ICreateProjectResultsUploadedNotificationRequest, void>(url, data);
  };
}
