import { Box, Card, Collapse, Divider, IconButton, Tooltip, Typography } from '@mui/material';

import { Actions } from '@models/enums/Actions';
import AlertCircleFilledIcon from '@assets/icons/dashboard/alert-circle-filled.svg';
import AlertTriangleFilledIcon from '@assets/icons/dashboard/alert-triangle-filled.svg';
import ChevronDownIcon from '@assets/icons/dashboard/chevron-down-gray.svg';
import ChevronUpIcon from '@assets/icons/dashboard/chevron-up-gray.svg';
import DownloadIcon from '@assets/icons/dashboard/download.svg';
import EyeOffIcon from '@assets/icons/dashboard/eye-off.svg';
import { FileCard } from '../FileCard';
import { IFile } from '@models/interfaces/entities/IFile';
import PairingAllowedIcon from '@assets/icons/dashboard/pairing-allowed.svg';
import PairingDisallowedIcon from '@assets/icons/dashboard/pairing-disallowed.svg';
import SettingsFilledIcon from '@assets/icons/dashboard/settings-filled.svg';
import { TransformationResultStatuses } from '@models/enums/TransformationResultStatuses';
import TrashIcon from '@assets/icons/dashboard/trash.svg';
import { clsx } from 'clsx';
import moment from 'moment';
import { useMemo } from 'react';
import useStyles from './styles';

export interface IProps {
  id?: string;
  item: IFile;
  draggable: boolean;
  collapsed: boolean;
  onFileDragStart: (file: IFile) => void;
  onFileDragEnd: (file: IFile) => void;
  onViewPiiScanResultDetails: (file: IFile) => void;
  onDeleteFile: (file: IFile) => void;
  onDownloadFile: (file: IFile) => void;
  onIgnoreFile: (file: IFile) => void;
  onUnignoreFile: (file: IFile) => void;
  onChangeCollapsed: (value: boolean) => void;
}

export const SourceFileCard = ({
  id,
  item,
  draggable,
  collapsed,
  onFileDragStart,
  onFileDragEnd,
  onViewPiiScanResultDetails,
  onDeleteFile,
  onDownloadFile,
  onIgnoreFile,
  onUnignoreFile,
  onChangeCollapsed,
}: IProps) => {
  const { classes } = useStyles();

  const onToggleCollapsed = () => {
    onChangeCollapsed(!collapsed);
  };

  const itemClass = useMemo(() => {
    switch (item.transformationResultStatus) {
      case TransformationResultStatuses.inProgress:
      case TransformationResultStatuses.queued: {
        return classes.unprocessed;
      }
      case TransformationResultStatuses.skipped:
      case TransformationResultStatuses.complete: {
        return classes.processed;
      }
      case TransformationResultStatuses.failure: {
        return classes.withError;
      }
    }
  }, [classes, item]);

  const transformationStatusLabel = useMemo(() => {
    switch (item.transformationResultStatus) {
      case TransformationResultStatuses.inProgress:
      case TransformationResultStatuses.queued: {
        return 'in progress';
      }
      case TransformationResultStatuses.skipped: {
        return 'skipped';
      }
      case TransformationResultStatuses.complete: {
        return 'completed';
      }
      case TransformationResultStatuses.failure: {
        return 'failed';
      }
    }
  }, [item]);

  const fileSizeLabel = useMemo(() => {
    if (item.size === undefined) return;
    let bytes = item.size;
    const thresh = 1024;
    const dp = 2;

    if (bytes < thresh) {
      return bytes + ' B';
    }

    const units = ['kB', 'MB', 'GB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
  }, [item]);

  const transformationInProgress = useMemo(
    () =>
      item.transformationResultStatus === TransformationResultStatuses.queued ||
      item.transformationResultStatus === TransformationResultStatuses.inProgress,
    [item.transformationResultStatus],
  );

  return (
    <Box
      className={clsx([classes.root, draggable && classes.draggable, itemClass])}
      id={id || item.id}
      key={item.id}
      draggable={draggable}
      onDragStart={() => onFileDragStart(item)}
      onDragEnd={() => onFileDragEnd(item)}
    >
      <Box
        className={clsx([classes.flex, classes.flexDirectionColumn, classes.gap4, classes.w100])}
      >
        <Box className={clsx([classes.flex, classes.gap4, classes.spaceBetween, classes.w100])}>
          <Box className={clsx([classes.flex, classes.gap16])}>
            {!transformationInProgress &&
              item.transformationResultStatus !== TransformationResultStatuses.failure && (
                <>
                  {draggable ? (
                    <Tooltip title='This multi-sheet file can be paired as a whole'>
                      <Box className={clsx([classes.flex])} data-tour='pairing-status'>
                        <img
                          className={classes.statusIcon}
                          src={PairingAllowedIcon}
                          alt='pairing allowed'
                        />
                      </Box>
                    </Tooltip>
                  ) : (
                    <Tooltip title='A multi-sheet file cannot be paired if any sub-sheets have not been accepted or have already been individually paired'>
                      <Box className={clsx([classes.flex])} data-tour='pairing-status'>
                        <img
                          className={classes.statusIcon}
                          src={PairingDisallowedIcon}
                          alt='pairing disallowed'
                        />
                      </Box>
                    </Tooltip>
                  )}
                </>
              )}
            {item.links[Actions.updateUserStatus] && (
              <Tooltip title='Action required'>
                <Box className={clsx([classes.flex])}>
                  <img
                    src={AlertTriangleFilledIcon}
                    onClick={() => onViewPiiScanResultDetails(item)}
                    data-testid='warning-button'
                    alt='warning'
                    className={classes.statusIcon}
                  />
                </Box>
              </Tooltip>
            )}

            {transformationInProgress && (
              <Box className={clsx([classes.flex])}>
                <Tooltip title='File processing is currently underway'>
                  <img
                    src={SettingsFilledIcon}
                    alt='pairing disallowed'
                    className={clsx([classes.statusIcon, 'animated'])}
                  />
                </Tooltip>
              </Box>
            )}

            {item.transformationResultStatus === TransformationResultStatuses.failure && (
              <Tooltip
                title={
                  item.transformationResultMessage ===
                  // eslint-disable-next-line quotes
                  "The file was created using an unsupported version of Excel. Please update the file to at least Excel '97/2000/XP/2003' and try again."
                    ? item.transformationResultMessage
                    : 'Extraction failed'
                }
              >
                <Box className={clsx([classes.flex])}>
                  <img
                    src={AlertCircleFilledIcon}
                    alt='failure'
                    data-testid='failure-button'
                    className={classes.statusIcon}
                  />
                </Box>
              </Tooltip>
            )}
            <Tooltip
              title={
                <>
                  <Box>
                    <Typography variant='caption'>{item.name}</Typography>
                  </Box>
                  <Box>
                    <Typography variant='caption'>Uploaded by</Typography>{' '}
                    <Typography className={classes.textBold} variant='caption'>
                      {item.uploadedBy}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant='caption'>at</Typography>{' '}
                    <Typography className={classes.textBold} variant='caption'>
                      {moment(item.uploaded).format('hh:mma, MM.DD.YYYY')}
                    </Typography>
                  </Box>
                  {item.size !== undefined && (
                    <Box>
                      <Typography variant='caption'>size</Typography>
                      {' - '}
                      <Typography className={classes.textBold} variant='caption'>
                        {fileSizeLabel}
                      </Typography>
                    </Box>
                  )}
                  <Box>
                    <Divider />
                  </Box>
                  <Box>
                    <Typography variant='caption'>Processing</Typography>{' '}
                    <Typography className={classes.textBold} variant='caption'>
                      {transformationStatusLabel}
                    </Typography>
                  </Box>
                </>
              }
            >
              <Typography
                variant='subtitle2'
                className={classes.title}
                data-testid='name-label'
                data-tour='name'
              >
                {item.name}
              </Typography>
            </Tooltip>
          </Box>
          <Box className={clsx([classes.flex, classes.gap4, classes.spaceBetween])}>
            {item.links[Actions.ignore]?.href && (
              <Tooltip title='Ignore all Accepted sub-sheets'>
                <IconButton
                  onClick={() => onIgnoreFile(item)}
                  data-testid='ignore-button'
                  data-tour='ignore-button'
                >
                  <img src={EyeOffIcon} alt='ignore' />
                </IconButton>
              </Tooltip>
            )}
            {item.links[Actions.getContent]?.href ? (
              <Tooltip title='Download file'>
                <IconButton
                  onClick={() => onDownloadFile(item)}
                  data-testid='download-button'
                  data-tour='download'
                >
                  <img src={DownloadIcon} alt='download' />
                </IconButton>
              </Tooltip>
            ) : (
              (item.hasDeletedParts || item.hasRejectedParts) && (
                <Tooltip title='Source file is no longer available due to one or more child files having been deleted'>
                  <IconButton className={classes.disabledIconButton}>
                    <img src={DownloadIcon} alt='download' />
                  </IconButton>
                </Tooltip>
              )
            )}
            {item.links[Actions.delete]?.href ? (
              <Tooltip title='Delete file'>
                <IconButton
                  onClick={() => onDeleteFile(item)}
                  data-testid='delete-button'
                  data-tour='delete'
                >
                  <img src={TrashIcon} alt='trash' />
                </IconButton>
              </Tooltip>
            ) : item.hasDeletedParts || item.hasRejectedParts ? (
              <Tooltip title='Source file is no longer available due to one or more child files having been deleted'>
                <IconButton className={classes.disabledIconButton}>
                  <img src={TrashIcon} alt='trash' />
                </IconButton>
              </Tooltip>
            ) : (
              item.hasCategorizedParts && (
                <Tooltip title='Source file can not be deleted due to pair associations existing with one or more child files'>
                  <IconButton className={classes.disabledIconButton}>
                    <img src={TrashIcon} alt='trash' />
                  </IconButton>
                </Tooltip>
              )
            )}
            {item.parts?.length && (
              <IconButton
                data-testid='collapse-button'
                data-tour='collapse-button'
                onClick={onToggleCollapsed}
              >
                {collapsed ? (
                  <img src={ChevronDownIcon} alt='arrow down' />
                ) : (
                  <img src={ChevronUpIcon} alt='arrow up' />
                )}
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
      <Collapse in={!collapsed} timeout='auto' unmountOnExit>
        {item.parts?.length && (
          <Card className={classes.filesList} data-testid='file-parts-card'>
            {item.parts.map((part) => (
              <FileCard
                id={`file-${part.id}`}
                key={part.id}
                item={part}
                draggable={!!part.links[Actions.changeCategory]?.href}
                onDetails={() => onViewPiiScanResultDetails(part)}
                onDelete={() => onDeleteFile(part)}
                onDownload={() => onDownloadFile(part)}
                onDragStart={() => onFileDragStart(part)}
                onDragEnd={() => onFileDragEnd(part)}
                onIgnore={() => onIgnoreFile(part)}
                onUnignore={() => onUnignoreFile(part)}
              />
            ))}
          </Card>
        )}
      </Collapse>
    </Box>
  );
};
