import 'react-multi-carousel/lib/styles.css';

import { Box } from '@mui/material';
import { ResourcesMenu } from '@components/ResourcesMenu';
import useStyles from './styles';

export const ExternalResources = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root} data-tour='resource-menus'>
      <ResourcesMenu
        id='simulation-resources'
        title='Simulation Resources'
        links={[
          {
            href: 'https://www.cmyers.com/deliverables-guide/',
            text: 'ALM Deliverables Guide',
          },
          {
            href: 'https://www.cmyers.com/education-tools-events/alm-conversations/',
            text: 'Schedule Results Presentation',
          },
        ]}
      />
      <ResourcesMenu
        id='other-resources'
        title='Other Resources'
        links={[
          {
            href: 'https://www.cmyers.com/thought-leadership-2/',
            text: 'Thought Leadership',
          },
          {
            href: 'https://www.cmyers.com/education-tools-events/interactive-tools/',
            text: 'Interactive Tools',
          },
          {
            href: 'https://www.cmyers.com/due-diligence/',
            text: 'Vendor Due Diligence',
          },
          {
            href: 'https://www.cmyers.com/education-tools-events/portal-feedback/',
            text: 'Feedback Form',
          },
        ]}
      />
    </Box>
  );
};
