import BaseProvider from './BaseProvider';
import { IGetAccountsResponse } from '@models/interfaces/responses/IGetAccountsResponse';
import { IUpdateAccountsRequest } from '@models/interfaces/requests/IUpdateAccountsRequest';
import { IUpdateAccountsResponse } from '@models/interfaces/responses/IUpdateAccountsResponse';
import { IUpdateSubAccountsRequest } from '@models/interfaces/requests/IUpdateSubAccountsRequest';

export default class AccountsProvider extends BaseProvider {
  public getAccounts = (url: string) => {
    return this.get<IGetAccountsResponse>(url);
  };

  public updateAccounts = (url: string, data: IUpdateAccountsRequest) => {
    return this.put<IUpdateAccountsRequest, IUpdateAccountsResponse>(url, data);
  };

  public updateSubAccounts = (url: string, data: IUpdateSubAccountsRequest) => {
    return this.put<IUpdateSubAccountsRequest, void>(url, data);
  };
}
