import { Box, Link as MuiLink } from '@mui/material';

import ChevronRightIcon from '@assets/icons/breadcrumbs/chevron-right.svg';
import { Link } from 'react-router-dom';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import useStyles from './styles';

export interface IProps {
  links: {
    href: string;
    title: string;
    icon?: string;
  }[];
}

export const Breadcrumbs = ({ links }: IProps) => {
  const { classes } = useStyles();
  return (
    <MuiBreadcrumbs separator={<img src={ChevronRightIcon} alt='>' />}>
      {links.map((item, index) => (
        <MuiLink
          className={classes.breadcrumbsItem}
          underline='hover'
          key={index}
          component={Link}
          to={item.href}
        >
          {item.icon && (
            <Box className={classes.icon}>
              <img src={item.icon} alt='icon' />
            </Box>
          )}
          {item.title}
        </MuiLink>
      ))}
    </MuiBreadcrumbs>
  );
};
