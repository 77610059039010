import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  w100: {
    width: '100%',
  },
  textGray: {
    color: t.palette.custom.dark[60],
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
  justifyContentSpaceBetween: {
    justifyContent: 'space-between',
  },
  gap0: {
    gap: 0,
  },
  root: {
    cursor: 'pointer',
    gap: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    padding: 8,
    borderRadius: 10,
    backgroundColor: t.palette.custom.primary[5],
  },
}));

export default useStyles;
