import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../store';

export interface ClientState {
  clientsCount?: number;
}

const initialState: ClientState = {
  clientsCount: undefined,
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClientsCount: (state, action: PayloadAction<number>) => {
      state.clientsCount = action.payload;
    },
  },
});

export const { setClientsCount } = clientSlice.actions;

export const selectClientClientsCount = (state: RootState) => state.client.clientsCount;

export default clientSlice.reducer;
