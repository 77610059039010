import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  container: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: 16,
    paddingRight: 16,
    color: t.palette.custom.white[100],
    backgroundColor: t.palette.custom.error[100],
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    position: 'absolute',
    bottom: -24,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
}));

export default useStyles;
