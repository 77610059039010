import {
  AppBar,
  Box,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import { loginRequest, msalConfig } from '@config/authConfig';
import { logout, selectAuthGetAlertActionLink, selectAuthIsAdmin } from '@reducers/authSlice';
import { useApi, useAppDispatch, useAppSelector } from '@hooks';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import GridIcon from '@assets/icons/grid.svg';
import HomeIcon from '@assets/icons/home.svg';
import LogOutIcon from '@assets/icons/log-out.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { UserProfile } from '../UserProfile';
import { env } from '@config/env';
import { getAppAlert } from '@services/api';
import logo from '@assets/logo.svg';
import { useMsal } from '@azure/msal-react';
import useStyles from './styles';

interface IProps {
  authenticated: boolean;
}

export const AppNavBar = ({ authenticated }: IProps) => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const hasAdminGroup = useAppSelector(selectAuthIsAdmin);
  const getAlertActionLink = useAppSelector(selectAuthGetAlertActionLink);

  const { classes } = useStyles();

  const { request: getAppAlertRequest, data: appAlert } = useApi(getAppAlert, null);

  useEffect(() => {
    if (getAlertActionLink) {
      getAppAlertRequest(getAlertActionLink.href);
    }
  }, [getAlertActionLink]);

  const handleLogout = () => {
    dispatch(logout());
    instance.logoutRedirect({
      postLogoutRedirectUri: msalConfig.auth.redirectUri,
    });
  };

  const handleOpenAdminDashboard = () => {
    handleClose();
    navigate('/admin/clients');
  };

  const handleOpenHome = () => {
    handleClose();
    navigate('/');
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!authenticated) {
      instance.loginRedirect(loginRequest);
    }
  }, [authenticated, instance, loginRequest]);

  if (!authenticated) return null;

  return (
    <AppBar position='static' color='inherit' className={classes.appBar}>
      <Container maxWidth='xl'>
        <Toolbar className={classes.toolBar}>
          <Box className={classes.logoSection}>
            <img className={classes.logo} src={logo} alt='logo' />
            <Typography className={classes.betaMarker} variant='h6'>
              BETA
            </Typography>
          </Box>
          {(env.REACT_APP_ENVIRONMENT || appAlert) && (
            <Box className={classes.infoSection}>
              {appAlert ? (
                <Typography className={classes.alertLabel} variant='subtitle1'>
                  {appAlert}
                </Typography>
              ) : (
                <Typography className={classes.envLabel} variant='h6'>
                  {env.REACT_APP_ENVIRONMENT}
                </Typography>
              )}
            </Box>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <UserProfile />
            <IconButton
              data-testid='more-button'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleMenu}
              className={classes.moreButton}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                className: classes.menuPaper,
              }}
            >
              {hasAdminGroup && (
                <>
                  {pathname.startsWith('/admin') ? (
                    <MenuItem onClick={handleOpenHome} className={classes.menuItem}>
                      <img src={HomeIcon} alt='home' />
                      <Typography variant='subtitle2' className={classes.menuItemText}>
                        Home
                      </Typography>
                    </MenuItem>
                  ) : (
                    <MenuItem onClick={handleOpenAdminDashboard} className={classes.menuItem}>
                      <img src={GridIcon} alt='Admin dashboard' />
                      <Typography variant='subtitle2' className={classes.menuItemText}>
                        Admin
                      </Typography>
                    </MenuItem>
                  )}
                </>
              )}
              <Divider className={classes.divider} />

              <MenuItem onClick={handleLogout} className={classes.menuItem}>
                <img src={LogOutIcon} alt='logout' />
                <Typography color='error' variant='subtitle2' className={classes.menuItemText}>
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

AppNavBar.defaultProps = {
  authenticated: false,
};
