import { Box, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { NavLink, NavLinkProps } from 'react-router-dom';

import React from 'react';
import useStyles from './styles';

export interface IProps {
  icon: {
    default: string;
    active: string;
  };
  collapsed: boolean;
  label: string;
  href: string;
  end?: boolean;
}

export default function SideMenuItem(props: IProps) {
  type CustomNavLinkProps = Omit<NavLinkProps, 'to'>;
  const CustomNavLink = React.useMemo(() => {
    const component = React.forwardRef<HTMLAnchorElement, CustomNavLinkProps>(
      (navLinkProps, ref) => {
        const { className: previousClasses, ...rest } = navLinkProps;
        const elementClasses = previousClasses?.toString() ?? '';
        return (
          <NavLink
            {...rest}
            ref={ref}
            to={props.href}
            end={props.end}
            className={({ isActive }) =>
              isActive ? elementClasses + ' Mui-selected' : elementClasses
            }
          />
        );
      },
    );
    component.displayName = 'CustomNavLink';
    return component;
  }, [props.href]);

  const { classes } = useStyles({ collapsed: props.collapsed });

  return (
    <ListItemButton component={CustomNavLink} className={classes.button}>
      <Box className={classes.marker} />
      <ListItemIcon className={classes.defaultIcon}>
        <img className='default-icon' src={props.icon.default} alt={props.label} />
      </ListItemIcon>
      <ListItemIcon className={classes.activeIcon}>
        <img className='active-icon' src={props.icon.active} alt={props.label} />
      </ListItemIcon>
      <ListItemText className={classes.textContainer}>
        <Typography variant='subtitle1' className={classes.text}>
          {props.label}
        </Typography>
      </ListItemText>
    </ListItemButton>
  );
}
