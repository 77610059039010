import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  textWhite60: {
    color: t.palette.custom.white[60],
  },
  textWhite80: {
    color: t.palette.custom.white[80],
  },
  textWhite: {
    color: t.palette.custom.white[100],
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 10,
    padding: 16,
    backgroundColor: t.palette.primary.main,
  },
  dropzone: {
    height: 101,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    '&.active': {
      animation: `${keyframes`
      0% {
        background-color: transparent;
        box-shadow: inset 0 0 0 0 ${t.palette.custom.white[60]};
      }
      70% {
        background-color: ${t.palette.custom.white[15]};
        box-shadow: inset 0 0 5px 2px ${t.palette.custom.white[40]};
      }
      100% {
        background-color: transparent;
        box-shadow: inset 0 0 0 0 ${t.palette.custom.white[40]};
      }
      `} 2s infinite ease-in-out`,
    },
  },
  dropzoneControlsIcon: {
    marginTop: 10,
  },
  dropzoneControlsTextContent: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
    gap: 4,
  },
  dropzoneControlsDivider: {
    width: '100%',
    marginTop: 8,
    borderColor: t.palette.custom.white[10],
  },
  dropzoneControlsButton: {
    textTransform: 'none',
    fontWeight: t.typography.subtitle2.fontWeight,
    fontSize: t.typography.subtitle2.fontSize,
    lineHeight: t.typography.subtitle2.lineHeight,
    letterSpacing: t.typography.subtitle2.letterSpacing,
    color: t.palette.custom.white[100],
    padding: '4px 12px',
  },
}));

export default useStyles;
