import { Mapper, constructUsing, createMap } from '@automapper/core';

import { ILink } from '@models/interfaces/entities/ILink';
import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createLinksMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<ILinksDto>('ILinksDto');

  PojosMetadataMap.create<ILinks>('ILinks');

  createMap<ILinksDto, ILinks>(
    mapper,
    'ILinksDto',
    'ILinks',
    constructUsing((sourceObject) => {
      const destinationObject = Object.fromEntries(
        Object.entries(sourceObject).map(([key, value]) => [key, value as ILink]),
      );
      return destinationObject;
    }),
  );
};
