import BaseProvider from './BaseProvider';
import { ICreateClientFeatureRequest } from '@models/interfaces/requests/ICreateClientFeatureRequest';
import { ICreateClientFeatureResponse } from '@models/interfaces/responses/ICreateClientFeatureResponse';
import { IGetClientFeatureResponse } from '@models/interfaces/responses/IGetClientFeatureResponse';
import { IGetClientFeaturesResponse } from '@models/interfaces/responses/IGetClientFeaturesResponse';

export default class ClientFeaturesProvider extends BaseProvider {
  public getClientFeature = (url: string) => {
    return this.get<IGetClientFeatureResponse>(url);
  };

  public getClientFeatures = (url: string) => {
    return this.get<IGetClientFeaturesResponse>(url);
  };

  public createClientFeature = (url: string, data: ICreateClientFeatureRequest) => {
    return this.post<ICreateClientFeatureRequest, ICreateClientFeatureResponse>(url, data);
  };

  public deleteClientFeature = (url: string) => {
    return this.delete<void>(url);
  };
}
