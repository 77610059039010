import { Box, Button, FormControlLabel, Switch, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';

import { CustomDialog } from '@components/CustomDialog';
import { ICreateFileCategoryData } from '@models/interfaces/additional/ICreateFileCategoryData';
import PlusFilledIcon from '@assets/icons/dialog/plus-filled.svg';
import useStyles from './styles';
import { useUpdateEffect } from '@hooks';

interface IProps {
  position: number;
  open: boolean;
  onClose: (data?: ICreateFileCategoryData) => void;
}

interface IFormData {
  name: string;
  description: string;
  filePairing: boolean;
  columnsMapping: boolean;
  subAccountMatching: boolean;
}

export const CreateFileCategoryDialog = ({ position, open, onClose }: IProps) => {
  const { classes } = useStyles();

  const formDefaultData = useMemo(
    () => ({
      name: '',
      description: '',
      filePairing: false,
      columnsMapping: false,
      subAccountMatching: false,
    }),
    [],
  );

  const form = useForm<IFormData>({
    defaultValues: formDefaultData,
  });

  const watchFilePairing = form.watch('filePairing');
  const watchColumnsMapping = form.watch('columnsMapping');

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const { name, description, filePairing, columnsMapping, subAccountMatching } = form.getValues();

    onClose({
      name,
      description,
      subCategoriesDisabled: !filePairing,
      mappingsDisabled: !columnsMapping,
      subAccountMatchingDisabled: !subAccountMatching,
      position,
    });
  };

  useEffect(() => {
    form.reset(formDefaultData);
  }, [open]);

  useUpdateEffect(() => {
    if (!watchFilePairing) {
      form.setValue('columnsMapping', false);
      form.setValue('subAccountMatching', false);
    }
  }, [watchFilePairing, form]);

  useUpdateEffect(() => {
    if (!watchColumnsMapping) {
      form.setValue('subAccountMatching', false);
    }
  }, [watchColumnsMapping, form]);

  return (
    <CustomDialog
      title='Add New Category'
      onClose={onCancel}
      open={open}
      maxWidth='xs'
      fullWidth
      actions={
        <>
          <div />
          <Button
            type='submit'
            form='form'
            variant='contained'
            color='secondary'
            size='large'
            startIcon={<img alt='add' src={PlusFilledIcon} />}
          >
            Add Category
          </Button>
          <div />
        </>
      }
    >
      <form
        id='form'
        onSubmit={form.handleSubmit(() => {
          onSave();
        })}
        noValidate
      >
        <Box>
          <Controller
            name={'name'}
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Please enter the name',
              },
            }}
            render={({ field: { onChange, value }, formState }) => (
              <TextField
                fullWidth
                label='Name'
                variant='standard'
                error={!!formState.errors.name}
                helperText={formState.errors.name?.message}
                onChange={onChange}
                value={value}
                autoComplete='off'
              />
            )}
          />
        </Box>
        <Box className={classes.marginTop24}>
          <Controller
            name={'description'}
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Please enter the description',
              },
            }}
            render={({ field: { onChange, value }, formState }) => (
              <TextField
                multiline
                rows={4}
                fullWidth
                label='Description'
                variant='standard'
                error={!!formState.errors.description}
                helperText={formState.errors.description?.message}
                onChange={onChange}
                value={value}
                autoComplete='off'
              />
            )}
          />
        </Box>
        <Box className={classes.marginTop24}>
          <Controller
            name={'filePairing'}
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={
                  <Switch
                    inputProps={{ role: 'switch' }}
                    checked={value}
                    onChange={onChange}
                    className={classes.marginLeft16}
                  />
                }
                label='File pairing'
              />
            )}
          />
        </Box>
        {watchFilePairing && (
          <Box className={classes.marginTop24}>
            <Controller
              name={'columnsMapping'}
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      inputProps={{ role: 'switch' }}
                      checked={value}
                      onChange={onChange}
                      className={classes.marginLeft16}
                    />
                  }
                  label='Columns mapping'
                />
              )}
            />
          </Box>
        )}
        {watchColumnsMapping && (
          <Box className={classes.marginTop24}>
            <Controller
              name={'subAccountMatching'}
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      inputProps={{ role: 'switch' }}
                      checked={value}
                      onChange={onChange}
                      className={classes.marginLeft16}
                    />
                  }
                  label='Subaccount matching'
                />
              )}
            />
          </Box>
        )}
      </form>
    </CustomDialog>
  );
};
