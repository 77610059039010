import { ICreateProductData } from '@models/interfaces/additional/ICreateProductData';
import { ICreateProductRequest } from '@models/interfaces/requests/ICreateProductRequest';
import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import { IProduct } from '@models/interfaces/entities/IProduct';
import { IProductDto } from '@models/interfaces/dtos/IProductDto';
import { IUpdateProductData } from '@models/interfaces/additional/IUpdateProductData';
import { IUpdateProductRequest } from '@models/interfaces/requests/IUpdateProductRequest';
import ProductsProvider from '@providers/ProductsProvider';
import mapper from '@models/mapper';

const productsProvider = new ProductsProvider();

export const getProducts = async (url: string) => {
  const result = await productsProvider.getProducts(url);
  return {
    items: mapper.mapArray<IProductDto, IProduct>(result.items, 'IProductDto', 'IProduct'),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const createProduct = async (url: string, data: ICreateProductData, file: File) => {
  const requestData = mapper.map<ICreateProductData, ICreateProductRequest>(
    data,
    'ICreateProductData',
    'ICreateProductRequest',
  );
  const result = await productsProvider.createProduct(url, requestData, file);
  return mapper.map<IProductDto, IProduct>(result, 'IProductDto', 'IProduct');
};

export const updateProduct = async (url: string, data: IUpdateProductData, file?: File) => {
  const requestData = mapper.map<IUpdateProductData, IUpdateProductRequest>(
    data,
    'IUpdateProductData',
    'IUpdateProductRequest',
  );
  const result = await productsProvider.updateProduct(url, requestData, file);
  return mapper.map<IProductDto, IProduct>(result, 'IProductDto', 'IProduct');
};

export const getProduct = async (url: string) => {
  const result = await productsProvider.getProduct(url);
  return mapper.map<IProductDto, IProduct>(result, 'IProductDto', 'IProduct');
};

export const deleteProduct = async (url: string) => {
  await productsProvider.deleteProduct(url);
  return true;
};
