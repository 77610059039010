import { Alert, Box, Button, Stack, Switch, TextField, Typography } from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { createProjectResultsUploadedNotification, getClient, getClientUsers } from '@services/api';
import { useApi, useLoader, useUpdateEffect } from '@hooks';
import { useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { CustomDialog } from '@components/CustomDialog';
import { IProject } from '@models/interfaces/entities/IProject';
import { IUser } from '@models/interfaces/entities/IUser';
import { Loader } from '@components/Loader';
import { toast } from 'react-toastify';
import useStyles from './styles';
import { v4 as uuidv4 } from 'uuid';

export interface IProps {
  project: IProject;
  open: boolean;
  onClose: () => void;
}

export interface IFormData {
  message: string;
  users: {
    user: IUser;
    selected: boolean;
  }[];
}

export const SendNotificationDialog = ({ project, open, onClose }: IProps) => {
  const { classes } = useStyles();
  const [clientUsers, setClientUsers] = useState<IUser[] | null>(null);

  const formDefaultData = useMemo<IFormData>(
    () => ({
      message: `Dear user! The result files were uploaded for the ${project.name} project.`,
      users: (clientUsers || []).map((x) => ({ user: x, selected: true })),
    }),
    [open, clientUsers],
  );

  const form = useForm<IFormData>({
    defaultValues: formDefaultData,
  });

  const watchUsers = form.watch('users');

  const { fields: users } = useFieldArray({
    name: 'users',
    control: form.control,
  });

  const {
    request: getClientRequest,
    data: getClientData,
    loading: getClientLoading,
  } = useApi(getClient, null, { handleErrors: true });

  const {
    request: getClientUsersRequest,
    data: getClientUsersData,
    loading: getClientUsersLoading,
  } = useApi(getClientUsers, null, { handleErrors: true });

  const {
    request: createProjectResultsUploadedNotificationRequest,
    loading: createProjectResultsUploadedNotificationLoading,
  } = useApi(createProjectResultsUploadedNotification, null, {
    handleErrors: true,
    onCallback: () => {
      toast.success('Notifications were successfully sent to the selected users');
      onClose();
    },
  });

  useEffect(() => {
    if (project.links[Actions.getClient]) {
      getClientRequest(project.links[Actions.getClient].href);
    }
  }, [project.id]);

  useUpdateEffect(() => {
    if (getClientData?.links[Actions.getUsers]) {
      getClientUsersRequest(getClientData?.links[Actions.getUsers].href);
    }
  }, [getClientData]);

  useUpdateEffect(() => {
    if (getClientUsersData) {
      setClientUsers(getClientUsersData);
    }
  }, [getClientUsersData]);

  useUpdateEffect(() => {
    if (open && project.links[Actions.getClient]) {
      getClientRequest(project.links[Actions.getClient].href);
    }
  }, [open, project]);

  useUpdateEffect(() => {
    if (open) {
      form.reset(formDefaultData);
    }
  }, [open, formDefaultData]);

  const onFormSubmit = async () => {
    if (project.links[Actions.createResultsUploadedNotification]) {
      const { message, users } = form.getValues();
      const userIds = users.filter((x) => x.selected).map((x) => x.user.id);
      createProjectResultsUploadedNotificationRequest(
        project.links[Actions.createResultsUploadedNotification].href,
        {
          message,
          userIds,
        },
      );
    }
  };

  const isSendEnabled = watchUsers.some((x) => x.selected);

  const showLoader = useLoader(
    getClientLoading,
    getClientUsersLoading,
    createProjectResultsUploadedNotificationLoading,
  );

  return (
    <>
      <CustomDialog
        title='Send notification'
        onClose={onClose}
        open={open}
        maxWidth='sm'
        fullWidth
        actions={
          <>
            <div />
            <Button
              type='submit'
              form='notification-sending-form'
              variant='contained'
              size='large'
              color='secondary'
              disabled={!isSendEnabled}
            >
              Send
            </Button>
          </>
        }
      >
        <form
          id='notification-sending-form'
          onSubmit={form.handleSubmit(() => {
            onFormSubmit();
          })}
          noValidate
        >
          {clientUsers && !clientUsers.length && (
            <Alert color='error' icon={false} className={classes.mb24}>
              Unable to find any recipient for this client.
            </Alert>
          )}

          <Controller
            name={'message'}
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Please enter the message',
              },
            }}
            render={({ field: { onChange, value }, formState }) => (
              <TextField
                multiline
                rows={3}
                fullWidth
                label='Message'
                variant='outlined'
                error={!!formState.errors.message}
                helperText={formState.errors.message?.message}
                onChange={onChange}
                value={value}
                autoComplete='off'
              />
            )}
          />

          {clientUsers && (
            <Box className={classes.usersList}>
              {users.map((item, index) => (
                <Box key={uuidv4()} className={classes.userItemContainer} data-testid='client-user'>
                  <Typography variant='subtitle2'>{item.user.fullName}</Typography>
                  <Box className={classes.userItemControls}>
                    <Controller
                      name={`users.${index}.selected`}
                      control={form.control}
                      render={({ field: { onChange, value } }) => (
                        <Stack direction='row' spacing={1} alignItems='center'>
                          <Typography variant='caption'>Skip</Typography>
                          <Switch
                            inputProps={{ role: 'switch' }}
                            checked={value}
                            onChange={onChange}
                          />
                          <Typography variant='caption'>Send</Typography>
                        </Stack>
                      )}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </form>
        <Loader show={showLoader} fixed={false} />
      </CustomDialog>
    </>
  );
};
