import { SearchInputColors } from '.';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const getColor = (t: Theme, color: SearchInputColors) => {
  switch (color) {
    case SearchInputColors.white:
      return { text: t.palette.custom.white[80] };
    default:
      return { text: t.palette.custom.dark[60] };
  }
};

const useStyles = makeStyles<{ color: SearchInputColors; maxWidth: number }>()(
  (t, { color, maxWidth }) => {
    const selectedColor = getColor(t, color);
    return {
      searchInput: {
        '& > .MuiInputBase-root': {
          width: '100%',
          maxWidth,
          color: selectedColor.text,
          fontWeight: 600,
          '& .MuiInput-input': {
            padding: 0,
            height: 'auto',
            '&::placeholder': {
              opacity: 1,
            },
          },
          '&:not(.Mui-disabled):hover': {
            '&:before': {
              borderBottom: 'none',
              transition: 'none',
            },
            '&:after': {
              borderBottom: 'none',
              transition: 'none',
            },
          },
          '&:not(.Mui-disabled).Mui-focused': {
            '&:before': {
              borderBottom: 'none',
              transition: 'none',
            },
            '&:after': {
              borderBottom: 'none',
              transition: 'none',
            },
          },
          '&:before': {
            borderBottom: 'none',
            transition: 'none',
          },
          '&:after': {
            borderBottom: 'none',
            transition: 'none',
          },
        },
      },
    };
  },
);

export default useStyles;
