import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { deleteFileCategory, updateFileCategory } from '@services/api';
import { useApi, useConfirm, useLoader } from '@hooks';

import { Actions } from '@models/enums/Actions';
import { EditFileCategoryDialog } from '../EditFileCategoryDialog';
import EditIcon from '@assets/icons/item-view/edit.svg';
import { IFileCategory } from '@models/interfaces/entities/IFileCategory';
import { IUpdateFileCategoryData } from '@models/interfaces/additional/IUpdateFileCategoryData';
import ListIcon from '@assets/icons/item-view/list.svg';
import { Loader } from '@components/Loader';
import { ManageFileCategoryFieldsDialog } from '../ManageFileCategoryFieldsDialog';
import TrashFilledIcon from '@assets/icons/item-view/trash-filled.svg';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useState } from 'react';
import useStyles from './styles';

export interface IProps {
  category: IFileCategory;
  onCategoryChanged: () => void;
}

export const FileCategoryCard = ({ category, onCategoryChanged }: IProps) => {
  const { classes } = useStyles();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openManageFieldsDialog, setOpenManageFieldsDialog] = useState(false);
  const confirm = useConfirm();

  const { request: deleteFileCategoryRequest, loading: deleteFileCategoryLoading } = useApi(
    deleteFileCategory,
    null,
    {
      handleErrors: true,
      onCallback: () => {
        toast.info('Successfully deleted the file category');
        onCategoryChanged();
      },
    },
  );

  const { request: updateFileCategoryRequest, loading: updateFileCategoryLoading } = useApi(
    updateFileCategory,
    null,
    {
      handleErrors: true,
      onCallback: () => {
        setOpenEditDialog(false);
        toast.info('Successfully updated the file category');
        onCategoryChanged();
      },
    },
  );

  const onDelete = async () => {
    if (category.links[Actions.delete]?.href) {
      await confirm({
        title: 'Delete Category',
        description: 'Are you sure that you want to delete this file category?',
        confirmationText: 'Delete',
      });
      deleteFileCategoryRequest(category.links[Actions.delete].href);
    }
  };

  const onEdit = () => {
    setOpenEditDialog(true);
  };

  const onManageFields = () => {
    setOpenManageFieldsDialog(true);
  };

  const onCloseEditDialog = (data?: IUpdateFileCategoryData) => {
    if (!data) {
      setOpenEditDialog(false);
    } else {
      const url = category.links[Actions.update]?.href;
      if (url) {
        updateFileCategoryRequest(url, data);
      }
    }
  };

  const onCloseManageFieldsDialog = () => {
    setOpenManageFieldsDialog(false);
  };

  const showLoader = useLoader(deleteFileCategoryLoading, updateFileCategoryLoading);

  return (
    <>
      <Box className={classes.root}>
        <Box
          className={clsx([
            classes.flexRow,
            classes.justifyContentSpaceBetween,
            classes.alignItemsStart,
            classes.w100,
          ])}
        >
          <Typography variant='subtitle1'>{category.name}</Typography>
          <Box className={clsx([classes.flexRow, classes.gap0])}>
            {category.links[Actions.getFields]?.href && (
              <Tooltip title='Manage fields'>
                <IconButton data-testid='manage-fields-button' onClick={onManageFields}>
                  <img src={ListIcon} alt='List' />
                </IconButton>
              </Tooltip>
            )}
            {category.links[Actions.update]?.href && (
              <Tooltip title='Edit Category'>
                <IconButton data-testid='edit-button' onClick={onEdit}>
                  <img src={EditIcon} alt='Edit' />
                </IconButton>
              </Tooltip>
            )}
            {category.links[Actions.delete]?.href && (
              <Tooltip title='Delete Category'>
                <IconButton data-testid='delete-button' onClick={onDelete}>
                  <img src={TrashFilledIcon} alt='Trash' />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>

        <Box className={clsx([classes.flexColumn, classes.w100])}>
          <Divider className={classes.w100} />
          <Box
            className={clsx([classes.flexRow, classes.justifyContentSpaceBetween, classes.w100])}
          >
            <Typography variant='caption' className={classes.textGray}>
              Files pairing:
            </Typography>
            <Typography variant='caption' className={classes.fw600}>
              {category.subCategoriesDisabled ? 'disabled' : 'enabled'}
            </Typography>
          </Box>
        </Box>
      </Box>
      {!!category?.links[Actions.update] && (
        <EditFileCategoryDialog
          category={category}
          open={openEditDialog}
          onClose={onCloseEditDialog}
        />
      )}
      {!!category?.links[Actions.getFields] && (
        <ManageFileCategoryFieldsDialog
          category={category}
          open={openManageFieldsDialog}
          onClose={onCloseManageFieldsDialog}
        />
      )}
      <Loader show={showLoader} />
    </>
  );
};
