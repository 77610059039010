import { ICreateWhitelistRecordData } from '@models/interfaces/additional/ICreateWhitelistRecordData';
import { ICreateWhitelistRecordRequest } from '@models/interfaces/requests/ICreateWhitelistRecordRequest';
import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import { IUpdateWhitelistRecordData } from '@models/interfaces/additional/IUpdateWhitelistRecordData';
import { IUpdateWhitelistRecordRequest } from '@models/interfaces/requests/IUpdateWhitelistRecordRequest';
import { IWhitelistRecord } from '@models/interfaces/entities/IWhitelistRecord';
import { IWhitelistRecordDto } from '@models/interfaces/dtos/IWhitelistRecordDto';
import WhitelistRecordsProvider from '@providers/WhitelistRecordsProvider';
import mapper from '@models/mapper';

const whitelistRecordsProvider = new WhitelistRecordsProvider();

export const getWhitelistRecords = async (url: string) => {
  const result = await whitelistRecordsProvider.getWhitelistRecords(url);
  return {
    items: mapper.mapArray<IWhitelistRecordDto, IWhitelistRecord>(
      result.items,
      'IWhitelistRecordDto',
      'IWhitelistRecord',
    ),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const createWhitelistRecord = async (url: string, data: ICreateWhitelistRecordData) => {
  const requestData = mapper.map<ICreateWhitelistRecordData, ICreateWhitelistRecordRequest>(
    data,
    'ICreateWhitelistRecordData',
    'ICreateWhitelistRecordRequest',
  );
  const result = await whitelistRecordsProvider.createWhitelistRecord(url, requestData);
  return mapper.map<IWhitelistRecordDto, IWhitelistRecord>(
    result,
    'IWhitelistRecordDto',
    'IWhitelistRecord',
  );
};

export const updateWhitelistRecord = async (url: string, data: IUpdateWhitelistRecordData) => {
  const requestData = mapper.map<IUpdateWhitelistRecordData, IUpdateWhitelistRecordRequest>(
    data,
    'IUpdateWhitelistRecordData',
    'IUpdateWhitelistRecordRequest',
  );
  const result = await whitelistRecordsProvider.updateWhitelistRecord(url, requestData);
  return mapper.map<IWhitelistRecordDto, IWhitelistRecord>(
    result,
    'IWhitelistRecordDto',
    'IWhitelistRecord',
  );
};

export const getWhitelistRecord = async (url: string) => {
  const result = await whitelistRecordsProvider.getWhitelistRecord(url);
  return mapper.map<IWhitelistRecordDto, IWhitelistRecord>(
    result,
    'IWhitelistRecordDto',
    'IWhitelistRecord',
  );
};

export const deleteWhitelistRecord = async (url: string) => {
  await whitelistRecordsProvider.deleteWhitelistRecord(url);
  return true;
};
