import { Box, IconButton, Typography } from '@mui/material';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  createClientFeature,
  deleteClientFeature,
  getClientFeatures,
  getClients,
  getFeature,
  updateFeature,
} from '@services/api';
import { useApi, useAppSelector, useConfirm, useLoader, useUpdateEffect } from '@hooks';

import { Actions } from '@models/enums/Actions';
import { ClientCard } from './components/ClientCard';
import { EditFeatureDialog } from './components/EditFeatureDialog';
import EditFilledIcon from '@assets/icons/item-view/edit-3-filled.svg';
import { IClient } from '@models/interfaces/entities/IClient';
import { IClientFeature } from '@models/interfaces/entities/IClientFeature';
import { IFeature } from '@models/interfaces/entities/IFeature';
import { IUpdateFeatureData } from '@models/interfaces/additional/IUpdateFeatureData';
import { Loader } from '@components/Loader';
import { selectAuthGetClientsActionLink } from '@reducers/authSlice';
import { toast } from 'react-toastify';
import useStyles from './styles';

interface IProps {
  featureId: string;
  onFeatureChanged: () => void;
}

export const FeatureView = ({ onFeatureChanged, featureId }: IProps) => {
  const { classes } = useStyles();
  const confirm = useConfirm();
  const getClientsActionLink = useAppSelector(selectAuthGetClientsActionLink);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [feature, setFeature] = useState<IFeature | null>(null);
  const [clients, setClients] = useState<IClient[] | null>(null);
  const [clientFeatures, setClientFeatures] = useState<IClientFeature[] | null>(null);

  const {
    request: getFeatureRequest,
    loading: getFeatureLoading,
    data: getFeatureData,
  } = useApi(getFeature, null, { handleErrors: true });

  const { request: updateFeatureRequest, loading: updateFeatureLoading } = useApi(
    updateFeature,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        setFeature(data);
        setOpenEditDialog(false);
        toast.info('Successfully updated the feature');
        onFeatureChanged();
      },
    },
  );

  const {
    request: getClientFeaturesRequest,
    data: getClientFeaturesData,
    loading: getClientFeaturesLoading,
  } = useApi(getClientFeatures, null, { handleErrors: true });

  const {
    request: getClientsRequest,
    data: getClientsData,
    loading: getClientsLoading,
  } = useApi(getClients, null, { handleErrors: true });

  const {
    request: createClientFeatureRequest,
    data: createClientFeatureData,
    loading: createClientFeatureLoading,
  } = useApi(createClientFeature, null, { handleErrors: true });

  const { request: deleteClientFeatureRequest, loading: deleteClientFeatureLoading } = useApi(
    deleteClientFeature,
    null,
    {
      handleErrors: true,
      onCallback: () => {
        reloadClientFeatures();
      },
    },
  );

  const onEdit = () => {
    setOpenEditDialog(true);
  };

  const onCloseEditDialog = async (item?: IUpdateFeatureData) => {
    if (!item) {
      setOpenEditDialog(false);
    } else {
      const url = getFeatureData?.links[Actions.update]?.href;
      if (url) {
        updateFeatureRequest(url, item);
      }
    }
  };

  useEffect(() => {
    if (getClientsActionLink) getClientsRequest(getClientsActionLink.href);
  }, [getClientsActionLink]);

  useUpdateEffect(() => {
    if (getClientsData) {
      setClients(getClientsData);
    }
  }, [getClientsData]);

  useUpdateEffect(() => {
    if (getClientFeaturesData) {
      setClientFeatures(getClientFeaturesData);
    }
  }, [getClientFeaturesData]);

  useUpdateEffect(() => {
    if (getFeatureData) {
      setFeature(getFeatureData);
    }
  }, [getFeatureData]);

  useUpdateEffect(() => {
    if (createClientFeatureData) {
      setClientFeatures(createClientFeatureData);
    }
  }, [createClientFeatureData]);

  useEffect(() => {
    if (featureId) {
      getFeatureRequest(`/features/${featureId}`);
    }
  }, [featureId]);

  useUpdateEffect(() => {
    reloadClientFeatures();
  }, [feature]);

  const reloadClientFeatures = () => {
    if (feature?.links[Actions.getClients]?.href) {
      getClientFeaturesRequest(feature.links[Actions.getClients].href);
    }
  };

  const onEnableClient = async (clientId: string) => {
    if (feature?.links[Actions.addClient]?.href) {
      await confirm({
        title: 'Enable Feature',
        description: 'Are you sure that you want to enable the feature for this client?',
        confirmationText: 'Enable',
      });
      createClientFeatureRequest(feature.links[Actions.addClient].href, {
        featureId: feature.id,
        clientId,
      });
    }
  };

  const onDisableClient = async (client: IClient & { clientFeature?: IClientFeature }) => {
    if (client.clientFeature?.links[Actions.delete]?.href) {
      await confirm({
        title: 'Disable Feature',
        description: 'Are you sure that you want to disable the feature for this client?',
        confirmationText: 'Disable',
      });
      deleteClientFeatureRequest(client.clientFeature.links[Actions.delete].href);
    }
  };

  const infoItems = useMemo<{ value: string; label: string; action?: ReactNode }[]>(
    () => [
      {
        label: 'Description',
        value: feature?.description || '-',
      },
    ],
    [feature],
  );

  const clientItems = useMemo<(IClient & { clientFeature?: IClientFeature })[]>(() => {
    if (!clients?.length) return [];
    const items = clients.map((c) => ({
      ...c,
      clientFeature: clientFeatures?.find((x) => c.id === x.clientId),
    }));
    return items;
  }, [clients, clientFeatures]);

  const showLoader = useLoader(
    getFeatureLoading,
    updateFeatureLoading,
    getClientsLoading,
    getClientFeaturesLoading,
    createClientFeatureLoading,
    deleteClientFeatureLoading,
  );

  return (
    <Box className={classes.container}>
      {feature && (
        <>
          <Box className={classes.infoSection}>
            <Box className={classes.title}>
              <Typography variant='h6'>{feature.name}</Typography>
              {!!feature?.links[Actions.update]?.href && (
                <IconButton onClick={onEdit}>
                  <img src={EditFilledIcon} alt='Edit' />
                </IconButton>
              )}
            </Box>
            <Box className={classes.infoContent}>
              {infoItems.map((item) => (
                <Box key={item.label} className={classes.infoItem}>
                  <Typography className='label' variant='caption'>
                    {item.label}
                  </Typography>
                  <Box className={classes.flexRow}>
                    <Typography variant='subtitle1'>{item.value}</Typography>
                    {item.action}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>

          <Box className={classes.list}>
            {!!clientItems.length &&
              clientItems.map((item) => (
                <ClientCard
                  key={item.id}
                  client={item}
                  onActivate={() => onEnableClient(item.id)}
                  onDeactivate={() => onDisableClient(item)}
                  isActivationEnabled={!!feature?.links[Actions.addClient]?.href}
                />
              ))}
          </Box>

          <EditFeatureDialog feature={feature} open={openEditDialog} onClose={onCloseEditDialog} />
        </>
      )}
      <Loader show={showLoader} />
    </Box>
  );
};
