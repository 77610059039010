import { Box, Button } from '@mui/material';
import { b2cPolicies, msalConfig, tokenRequest } from '@config/authConfig';
import {
  clearAccount,
  logout,
  selectAuthAccount,
  selectAuthIsAuthenticated,
  selectAuthToken,
  setAccount,
  setAppInfo,
  setFeatures,
  setProducts,
  setToken,
} from '@reducers/authSlice';
import { getFeatures, getProducts } from '@services/api';
import { useApi, useAppDispatch, useAppSelector, useDebounce, useUpdateEffect } from '@hooks';
import { useEffect, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { CustomDialog } from './CustomDialog';
import { FilesHub } from '@hubs/filesHub';
import FilesHubContext from '@contexts/FilesHubContext';
import { Loader } from './Loader';
import { createSignalRContext } from '@modules/react-signalr';
import { env } from '@config/env';
import { getAppInfo } from '@services/api/appInfo';
import { toast } from 'react-toastify';
import { useIdleTimer } from 'react-idle-timer';
import { useMsal } from '@azure/msal-react';

const SignalRContext = createSignalRContext<FilesHub>({
  shareConnectionBetweenTab: true,
});

enum SignalRConnectionStatus {
  notConnected = 1,
  connected = 2,
  failed = 3,
  closed = 4,
}

const timeout = 30 * 60 * 1000;
const promptBeforeIdle = 30 * 1000;

export const AuthenticatedContainer = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const isAuthenticated = useAppSelector(selectAuthIsAuthenticated);
  const token = useAppSelector(selectAuthToken);
  const dispatch = useAppDispatch();
  const { instance: msal, accounts } = useMsal();
  const { request: getAppInfoRequest, data: appInfo } = useApi(getAppInfo, null);
  const { request: getProductsRequest, data: products } = useApi(getProducts, null);
  const { request: getFeaturesRequest, data: features } = useApi(getFeatures, null);
  const account = useAppSelector(selectAuthAccount);

  const [signalrConnectionStatus, setSignalrConnectionStatus] = useState<SignalRConnectionStatus>(
    SignalRConnectionStatus.notConnected,
  );
  const debouncedSignalrConnectionStatus = useDebounce(signalrConnectionStatus, 20000);

  const [timerRemaining, setTimerRemaining] = useState<number>(timeout);
  const [openTimerPromptDialog, setOpenTimerPromptDialog] = useState(false);

  const forceLogout = () => {
    dispatch(logout());
    msal.logoutRedirect({
      postLogoutRedirectUri: msalConfig.auth.redirectUri,
    });
  };

  useUpdateEffect(() => {
    if (
      debouncedSignalrConnectionStatus === SignalRConnectionStatus.closed ||
      debouncedSignalrConnectionStatus === SignalRConnectionStatus.failed
    ) {
      toast.info(
        'Live updates are disabled because of connection issues. Please refresh the page to enable it again.',
        {
          autoClose: false,
        },
      );
    }
  }, [debouncedSignalrConnectionStatus]);

  useEffect(() => {
    if (token?.accessToken && (!token.expiresOn || new Date(token.expiresOn) < new Date())) {
      forceLogout();
    }
  }, [token, msal]);

  useUpdateEffect(() => {
    if (!token?.accessToken) {
      forceLogout();
    }
  }, [token, msal]);

  useEffect(() => {
    if (account) {
      const updateToken = () => {
        msal.acquireTokenSilent({ ...tokenRequest, account }).then((response) => {
          // In case the response from B2C server has an empty accessToken field
          // throw an error to initiate token acquisition
          if (!response.accessToken || response.accessToken === '') {
            throw new Error('Failed to retrieve access token to API');
          }
          dispatch(
            setToken({
              accessToken: response.accessToken,
              expiresOn: response.expiresOn ? response.expiresOn.getTime() : null,
            }),
          );
        });
      };

      setTimeout(updateToken, 1000);

      const interval = setInterval(updateToken, 900000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [account, msal]);

  useEffect(() => {
    if (isAuthenticated) {
      getAppInfoRequest();
    }
  }, [isAuthenticated, account]);

  useEffect(() => {
    if (isAuthenticated && appInfo?.links[Actions.getProducts]?.href) {
      getProductsRequest(appInfo.links[Actions.getProducts].href);
    }
    if (isAuthenticated && appInfo?.links[Actions.getFeatures]?.href) {
      getFeaturesRequest(appInfo.links[Actions.getFeatures].href);
    }
  }, [isAuthenticated, appInfo]);

  useEffect(() => {
    if (appInfo) dispatch(setAppInfo(appInfo));
  }, [appInfo]);

  useEffect(() => {
    if (products) dispatch(setProducts(products.items));
  }, [products]);

  useEffect(() => {
    if (features) dispatch(setFeatures(features.items));
  }, [features]);

  useEffect(() => {
    /**
     * See here for more information on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    if (accounts.length < 1) {
      dispatch(clearAccount());
    } else if (accounts.length > 1) {
      /**
       * Due to the way MSAL caches account objects, the auth response from initiating a user-flow
       * is cached as a new account, which results in more than one account in the cache. Here we make
       * sure we are selecting the account with homeAccountId that contains the sign-up/sign-in user-flow,
       * as this is the default flow the user initially signed-in with.
       */
      const filtered = accounts.filter(
        (account) =>
          account.homeAccountId
            .toUpperCase()
            .includes(b2cPolicies.names.signUpSignIn.toUpperCase()) &&
          (account.idTokenClaims?.iss || '')
            .toUpperCase()
            .includes(b2cPolicies.authorityDomain.toUpperCase()) &&
          account.idTokenClaims?.aud === msalConfig.auth.clientId,
      );

      if (filtered.length > 1) {
        // localAccountId identifies the entity for which the token asserts information.
        if (filtered.every((account) => account.localAccountId === filtered[0].localAccountId)) {
          // All accounts belong to the same user
          dispatch(setAccount(filtered[0]));
        } else {
          // Multiple users detected. Logout all to be safe.
          forceLogout();
        }
      } else if (filtered.length === 1) {
        dispatch(setAccount(filtered[0]));
      }
    } else if (accounts.length === 1) {
      dispatch(setAccount(accounts[0]));
    }
  }, [accounts, msal]);

  const onTimerIdle = () => {
    setOpenTimerPromptDialog(false);
    if (isAuthenticated) {
      forceLogout();
    }
  };

  const onTimerActive = () => {
    setOpenTimerPromptDialog(false);
  };

  const onTimerPrompt = () => {
    if (isAuthenticated) {
      setOpenTimerPromptDialog(true);
    }
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle: onTimerIdle,
    onActive: onTimerActive,
    onPrompt: onTimerPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  const onTimerPromptStillHere = () => {
    activate();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const remainingTime = getRemainingTime();

      // update the timerRemaining only if remaining time is close to the prompt displaying time
      if (remainingTime < promptBeforeIdle + 1000) {
        setTimerRemaining(Math.ceil(remainingTime / 1000));
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <>
      {isAuthenticated ? (
        <FilesHubContext.Provider value={SignalRContext}>
          <SignalRContext.Provider
            connectEnabled={isAuthenticated}
            accessTokenFactory={() => token?.accessToken || ''}
            dependencies={[token, isAuthenticated]}
            onReconnect={() => {
              setSignalrConnectionStatus(SignalRConnectionStatus.connected);
            }}
            onClosed={() => {
              setSignalrConnectionStatus(SignalRConnectionStatus.closed);
            }}
            onOpen={() => {
              setSignalrConnectionStatus(SignalRConnectionStatus.connected);
            }}
            onConnectionFailed={() => {
              setSignalrConnectionStatus(SignalRConnectionStatus.failed);
            }}
            url={`${env.REACT_APP_API_URL}/hubs/files`}
            retriesDelay={10000}
            retriesCount={6}
          >
            <>
              {children}
              <CustomDialog
                title={'Are you still here?'}
                open={openTimerPromptDialog}
                showCloseIcon={false}
                maxWidth='xs'
                fullWidth
                actions={
                  <>
                    <div />
                    <Button
                      variant='contained'
                      color='secondary'
                      className='narrow'
                      onClick={onTimerPromptStillHere}
                    >
                      I&apos;m still here
                    </Button>
                    <div />
                  </>
                }
              >
                <Box>Logging out in {timerRemaining} seconds</Box>
              </CustomDialog>
            </>
          </SignalRContext.Provider>
        </FilesHubContext.Provider>
      ) : (
        <Loader show />
      )}
    </>
  );
};
