import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ collapsed: boolean }>()((t, { collapsed }) => ({
  container: {
    paddingTop: 24,
    paddingBottom: 24,
    width: collapsed ? 60 : 200,
    transition: t.transitions.create(['width'], {
      easing: t.transitions.easing.sharp,
      duration: t.transitions.duration.enteringScreen,
    }),
  },
  controls: { marginTop: 80, marginRight: 20, display: 'flex', justifyContent: 'end' },
}));

export default useStyles;
