import BaseProvider from './BaseProvider';
import { ICreateFileCategoryFieldRequest } from '@models/interfaces/requests/ICreateFileCategoryFieldRequest';
import { ICreateFileCategoryFieldResponse } from '@models/interfaces/responses/ICreateFileCategoryFieldResponse';
import { IGetFileCategoryFieldResponse } from '@models/interfaces/responses/IGetFileCategoryFieldResponse';
import { IGetFileCategoryFieldsResponse } from '@models/interfaces/responses/IGetFileCategoryFieldsResponse';
import { IUpdateFileCategoryFieldRequest } from '@models/interfaces/requests/IUpdateFileCategoryFieldRequest';
import { IUpdateFileCategoryFieldResponse } from '@models/interfaces/responses/IUpdateFileCategoryFieldResponse';

export default class FileCategoryFieldsProvider extends BaseProvider {
  public getFileCategoryFields = (url: string) => {
    return this.get<IGetFileCategoryFieldsResponse>(url);
  };

  public getFileCategoryField = (url: string) => {
    return this.get<IGetFileCategoryFieldResponse>(url);
  };

  public createFileCategoryField = (url: string, data: ICreateFileCategoryFieldRequest) => {
    return this.post<ICreateFileCategoryFieldRequest, ICreateFileCategoryFieldResponse>(url, data);
  };

  public deleteFileCategoryField = (url: string) => {
    return this.delete<void>(url);
  };

  public updateFileCategoryField = (url: string, data: IUpdateFileCategoryFieldRequest) => {
    return this.patch<IUpdateFileCategoryFieldRequest, IUpdateFileCategoryFieldResponse>(url, data);
  };
}
