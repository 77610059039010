import { ICreatePiiIncidentCategoryData } from '@models/interfaces/additional/ICreatePiiIncidentCategoryData';
import { ICreatePiiIncidentCategoryRequest } from '@models/interfaces/requests/ICreatePiiIncidentCategoryRequest';
import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import { IPiiIncidentCategory } from '@models/interfaces/entities/IPiiIncidentCategory';
import { IPiiIncidentCategoryDto } from '@models/interfaces/dtos/IPiiIncidentCategoryDto';
import { IUpdatePiiIncidentCategoryData } from '@models/interfaces/additional/IUpdatePiiIncidentCategoryData';
import { IUpdatePiiIncidentCategoryRequest } from '@models/interfaces/requests/IUpdatePiiIncidentCategoryRequest';
import PiiIncidentCategoriesProvider from '@providers/PiiIncidentCategoriesProvider';
import mapper from '@models/mapper';

const piiIncidentCategoriesProvider = new PiiIncidentCategoriesProvider();

export const getPiiIncidentCategories = async (url: string) => {
  const result = await piiIncidentCategoriesProvider.getPiiIncidentCategories(url);
  return {
    items: mapper.mapArray<IPiiIncidentCategoryDto, IPiiIncidentCategory>(
      result.items,
      'IPiiIncidentCategoryDto',
      'IPiiIncidentCategory',
    ),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const createPiiIncidentCategory = async (
  url: string,
  data: ICreatePiiIncidentCategoryData,
) => {
  const requestData = mapper.map<ICreatePiiIncidentCategoryData, ICreatePiiIncidentCategoryRequest>(
    data,
    'ICreatePiiIncidentCategoryData',
    'ICreatePiiIncidentCategoryRequest',
  );
  const result = await piiIncidentCategoriesProvider.createPiiIncidentCategory(url, requestData);
  return mapper.map<IPiiIncidentCategoryDto, IPiiIncidentCategory>(
    result,
    'IPiiIncidentCategoryDto',
    'IPiiIncidentCategory',
  );
};

export const updatePiiIncidentCategory = async (
  url: string,
  data: IUpdatePiiIncidentCategoryData,
) => {
  const requestData = mapper.map<IUpdatePiiIncidentCategoryData, IUpdatePiiIncidentCategoryRequest>(
    data,
    'IUpdatePiiIncidentCategoryData',
    'IUpdatePiiIncidentCategoryRequest',
  );
  const result = await piiIncidentCategoriesProvider.updatePiiIncidentCategory(url, requestData);
  return mapper.map<IPiiIncidentCategoryDto, IPiiIncidentCategory>(
    result,
    'IPiiIncidentCategoryDto',
    'IPiiIncidentCategory',
  );
};

export const getPiiIncidentCategory = async (url: string) => {
  const result = await piiIncidentCategoriesProvider.getPiiIncidentCategory(url);
  return mapper.map<IPiiIncidentCategoryDto, IPiiIncidentCategory>(
    result,
    'IPiiIncidentCategoryDto',
    'IPiiIncidentCategory',
  );
};

export const deletePiiIncidentCategory = async (url: string) => {
  await piiIncidentCategoriesProvider.deletePiiIncidentCategory(url);
  return true;
};
