import * as t from 'io-ts';

export interface IJsonGLAccountData {
  sSubAcctID: string;
  sSubAcctDesc: string;
  sSubAcctIDFormat: string;
  sDownloadID: string;
  sAccountName: string;
  nAccountType: number;
  bDownloaded: boolean;
  sFilename: string;
  dBalance: number;
  dAvgBalance: number;
  dIncome: number;
  bCalcYield: boolean;
  nOperation: number;
  dBalAdjust: number;
  dRatio: number;
  sAcctsCompBalance: string[];
  sAcctsPushBalancing: string[];
  sAcctsPushDeferred: string[];
  sAcctsPushYield: string[];
}

export const IJsonGLAccountDataSchema = t.type({
  sSubAcctID: t.string,
  sSubAcctDesc: t.string,
  sSubAcctIDFormat: t.string,
  sDownloadID: t.string,
  sAccountName: t.string,
  nAccountType: t.number,
  bDownloaded: t.boolean,
  sFilename: t.string,
  dBalance: t.number,
  dAvgBalance: t.number,
  dIncome: t.number,
  bCalcYield: t.boolean,
  nOperation: t.number,
  dBalAdjust: t.number,
  dRatio: t.number,
  sAcctsCompBalance: t.array(t.string),
  sAcctsPushBalancing: t.array(t.string),
  sAcctsPushDeferred: t.array(t.string),
  sAcctsPushYield: t.array(t.string),
});
