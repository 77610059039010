import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ fixed: boolean }>()((t, { fixed }) => ({
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    position: fixed ? 'fixed' : 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
  },
  label: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: t.palette.secondary.main,
    backgroundColor: '#e5e5e5',
    width: 84,
    height: 84,
    display: 'flex',
    borderRadius: 42,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
