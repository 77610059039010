import { Box, IconButton, TextField, Typography } from '@mui/material';
import { CustomAutocomplete, IAutocompleteOption } from '@components/CustomAutocomplete';
import { SearchInput, SearchInputColors } from '@components/SearchInput';
import { getActivityEvents, getClients } from '@services/api';
import {
  selectAuthGetActivityEventsActionLink,
  selectAuthGetClientsActionLink,
} from '@reducers/authSlice';
import { useApi, useAppSelector, useInput, useLoader, useUpdateEffect } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ActivityEventTypes } from '@models/enums/ActivityEventTypes';
import { ActivityEventsBlock } from './components/ActivityEventsBlock';
import CalendarIcon from '@assets/icons/date-picker/calendar.svg';
import { CustomDateRangePicker } from '@components/CustomDateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro';
import { IActivityEvent } from '@models/interfaces/entities/IActivityEvent';
import { IClient } from '@models/interfaces/entities/IClient';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '@components/Loader';
import XCircleIcon from '@assets/icons/date-picker/x-circle.svg';
import moment from 'moment';
import useStyles from './styles';

const pageSize = 10;

export const ActivityEventsPage = () => {
  const { classes } = useStyles();
  const getClientsActionLink = useAppSelector(selectAuthGetClientsActionLink);
  const getActivityEventsActionLink = useAppSelector(selectAuthGetActivityEventsActionLink);

  const searchInput = useInput<string>('');
  const clientInput = useInput<IAutocompleteOption | null>(null);
  const [clients, setClients] = useState<IClient[]>([]);
  const [activityEvents, setActivityEvents] = useState<IActivityEvent[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [dateRange, setDateRange] = useState<DateRange<Date | null>>([null, null]);

  const {
    request: getClientsRequest,
    loading: getClientsLoading,
    data: getClientsData,
  } = useApi(getClients, null, { handleErrors: true });

  const {
    request: getActivityEventsRequest,
    data: getActivityEventsData,
    loading: getActivityEventsLoading,
  } = useApi(getActivityEvents, null, { handleErrors: true });

  const {
    request: getMoreActivityEventsRequest,
    data: getMoreActivityEventsData,
    loading: getMoreActivityEventsLoading,
  } = useApi(getActivityEvents, null, { handleErrors: true });

  const onRefreshData = () => {
    if (getActivityEventsActionLink) {
      const [startDate, endDate] = dateRange;
      getActivityEventsRequest(
        getActivityEventsActionLink.href,
        pageSize,
        0,
        searchInput.value,
        startDate || undefined,
        endDate || undefined,
        clientInput.value?.value || undefined,
      );
    }
  };

  const getUniqueItems = useCallback(
    (arr1: IActivityEvent[], arr2: IActivityEvent[]) =>
      arr1
        .concat(arr2)
        .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id)),
    [],
  );

  useEffect(() => {
    if (getClientsActionLink) {
      getClientsRequest(getClientsActionLink.href);
    }
  }, [getClientsActionLink]);

  useEffect(() => {
    if (getClientsData) {
      setClients(getClientsData);
    }
  }, [getClientsData]);

  useUpdateEffect(() => {
    if (getActivityEventsData) {
      setActivityEvents(getActivityEventsData);
      setHasMore(true);
    }
  }, [getActivityEventsData]);

  useUpdateEffect(() => {
    if (getMoreActivityEventsData) {
      setActivityEvents((activityEvents) =>
        getUniqueItems(activityEvents, getMoreActivityEventsData),
      );
      setHasMore(getMoreActivityEventsData.length === pageSize);
    }
  }, [getMoreActivityEventsData]);

  useEffect(() => {
    const [startDate, endDate] = dateRange;
    if ((startDate && !endDate) || (!startDate && endDate)) return;
    onRefreshData();
  }, [searchInput.value, dateRange, clientInput.value]);

  const onFetchMoreActivityEvents = () => {
    if (getActivityEventsActionLink) {
      const [startDate, endDate] = dateRange;
      getMoreActivityEventsRequest(
        getActivityEventsActionLink.href,
        pageSize,
        activityEvents.length,
        searchInput.value,
        startDate || undefined,
        endDate || undefined,
        clientInput.value?.value || undefined,
      );
    }
  };

  const filteredActivityEvents = useMemo(
    () => activityEvents.filter((x) => x.name !== ActivityEventTypes.unknownEvent),
    [activityEvents],
  );

  const activityEventsBlocks = useMemo(() => {
    const map = new Map<string, IActivityEvent[]>();
    filteredActivityEvents.forEach((event) => {
      const date = moment(event.timestamp).startOf('day').format('YYYY-MM-DD');
      if (map.has(date)) {
        const events = map.get(date) || [];
        map.set(date, [...events, event]);
      } else {
        map.set(date, [event]);
      }
    });
    return Array.from(map);
  }, [filteredActivityEvents]);

  const clientOptions = useMemo<IAutocompleteOption[]>(() => {
    return clients.map((x) => ({
      value: x.id,
      title: x.name,
    }));
  }, [clients]);

  const showLoader = useLoader(
    getActivityEventsLoading,
    getClientsLoading,
    getMoreActivityEventsLoading,
  );

  return (
    <Box className={classes.container}>
      <Typography variant='h6' className={classes.mb24}>
        Activity
      </Typography>
      <Box className={classes.controlsSection}>
        <SearchInput
          onChange={(value) => searchInput.onChange({ target: { value } })}
          color={SearchInputColors.grey}
          maxWidth={350}
          placeholder='Search activity by user'
        />
        <Box className={classes.clientAutocomplete}>
          <CustomAutocomplete
            value={clientInput.value}
            placeholder='Filter by client'
            onChange={(value) => clientInput.onChange({ target: { value: value || null } })}
            options={clientOptions}
            clearIcon={<img src={XCircleIcon} alt='Clear' />}
          />
        </Box>
        <CustomDateRangePicker
          value={dateRange}
          onChange={(newValue) =>
            setDateRange([
              newValue[0] ? new Date(moment(newValue[0]).startOf('day').toString()) : null,
              newValue[1] ? new Date(moment(newValue[1]).endOf('day').toString()) : null,
            ])
          }
          renderInput={(startProps) => (
            <TextField
              {...startProps}
              className={classes.dateRangePickerInput}
              label={null}
              value={
                dateRange[0] || dateRange[1]
                  ? `${(dateRange[0] && moment(dateRange[0]).format('MM.DD.YYYY')) || 'none'} - ${
                      (dateRange[1] && moment(dateRange[1]).format('MM.DD.YYYY')) || 'none'
                    }`
                  : ''
              }
              variant='standard'
              autoComplete='off'
              inputProps={{
                placeholder: 'Pick date range',
              }}
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <img src={CalendarIcon} alt='Calendar' />
                  </IconButton>
                ),
                endAdornment: (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setDateRange([null, null]);
                    }}
                  >
                    <img src={XCircleIcon} alt='Clear' />
                  </IconButton>
                ),
              }}
            />
          )}
        />
      </Box>
      <Box id='scrollable-container' className={classes.scrollContainer}>
        <InfiniteScroll
          dataLength={activityEvents.length}
          next={onFetchMoreActivityEvents}
          hasMore={hasMore}
          loader={null}
          scrollableTarget='scrollable-container'
        >
          {activityEventsBlocks.map(([date, events]) => (
            <ActivityEventsBlock key={date.toString()} date={date} events={events} />
          ))}
        </InfiniteScroll>
      </Box>
      <Loader show={showLoader} />
    </Box>
  );
};
