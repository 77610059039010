import Joyride, { CallBackProps, Step } from 'react-joyride';
import {
  addCompletedTour,
  addCurrentTour,
  removeCurrentTour,
  selectGeneralCompletedTours,
} from '@reducers/generalSlice';
import { useAppDispatch, useAppSelector } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import useStyles from './styles';

interface IProps {
  steps: Step[];
  name: string;
  zIndex: number;
  previous?: string;
  onStepComplete?: (step: number) => void;
}

export const GuideTour = ({ steps, name, previous, onStepComplete, zIndex }: IProps) => {
  const { theme } = useStyles();

  const [visible, setVisible] = useState(false);

  const completedTours = useAppSelector(selectGeneralCompletedTours);
  const dispatch = useAppDispatch();

  const completed = completedTours.includes(name);
  const previousCompleted = previous ? completedTours.includes(previous) : false;

  useEffect(() => {
    if (steps.length) {
      dispatch(addCurrentTour(name));

      return () => {
        dispatch(removeCurrentTour(name));
      };
    }
  }, [steps.length]);

  const onCompleted = () => {
    dispatch(addCompletedTour(name));
  };

  const onCallback = useCallback(
    (evt: CallBackProps) => {
      const { action, lifecycle, status, type, index } = evt;

      // tour skipped or finished
      if (
        lifecycle === 'init' &&
        (status === 'finished' || status === 'skipped') &&
        type === 'tour:end'
      ) {
        onCompleted();
        setVisible(false);
      }

      // step complete
      if (
        status === 'running' &&
        lifecycle === 'complete' &&
        action === 'next' &&
        onStepComplete !== undefined
      ) {
        onStepComplete(index);
      }

      // tooltip shown
      if (lifecycle === 'tooltip' && status === 'running') {
        setVisible(true);
      }

      // beacon shown
      if (lifecycle === 'beacon' && status === 'running') {
        setVisible(false);
      }
    },
    [name, onStepComplete, onCompleted, setVisible],
  );

  const z = useMemo(() => zIndex + (visible ? 200 : 0), [zIndex, visible]);

  return steps.length !== 0 && !completed && (!previous || (previous && previousCompleted)) ? (
    <Joyride
      steps={steps}
      disableOverlayClose={true}
      styles={{
        options: {
          zIndex: z,
        },
        beaconInner: {
          backgroundColor: theme.palette.custom.info[100],
        },
        beaconOuter: {
          borderColor: theme.palette.custom.info[100],
          backgroundColor: theme.palette.custom.info[20],
        },
        buttonBack: {
          color: theme.palette.custom.primary[100],
        },
        buttonNext: {
          backgroundColor: theme.palette.custom.info[100],
        },
      }}
      showSkipButton
      continuous={steps.length > 1}
      callback={onCallback}
      showProgress={steps.length > 1}
      scrollDuration={50}
      disableScrollParentFix
    />
  ) : null;
};

GuideTour.defaultProps = {
  zIndex: 300,
};
