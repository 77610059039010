import { Box, IconButton, Switch, Tooltip, Typography } from '@mui/material';

import { Actions } from '@models/enums/Actions';
import DownloadIcon from '@assets/icons/dashboard/download.svg';
import { IFile } from '@models/interfaces/entities/IFile';
import clsx from 'clsx';
import useStyles from './styles';

export interface IProps {
  id?: string;
  item: IFile;
  isActive: boolean;
  onDownload: () => void;
  changeDisabled: boolean;
  onChangeActive?: (value: boolean) => void;
}

export const OldFileCard = ({
  id,
  item,
  onDownload,
  onChangeActive,
  isActive,
  changeDisabled,
}: IProps) => {
  const { classes } = useStyles();

  return (
    <Box id={id} className={clsx([classes.root, isActive ? 'active' : 'inactive'])}>
      <Tooltip title={item.sourceFileName ? `${item.sourceFileName} > ${item.name}` : item.name}>
        <Typography variant='caption' className={classes.title}>
          {item.sourceFileName ? `${item.sourceFileName} > ${item.name}` : item.name}
        </Typography>
      </Tooltip>
      <Box className={classes.controls}>
        {!!item.links[Actions.getContent]?.href && (
          <Tooltip title='Download file'>
            <IconButton onClick={onDownload} data-testid='download-button' data-tour='download'>
              <img src={DownloadIcon} alt='download' />
            </IconButton>
          </Tooltip>
        )}
        {onChangeActive && (
          <Switch
            data-tour='switch'
            size='small'
            disabled={changeDisabled}
            inputProps={{ role: 'switch' }}
            checked={isActive}
            onChange={(e) => onChangeActive(e.target.checked)}
          />
        )}
      </Box>
    </Box>
  );
};
