import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  mb24: {
    marginBottom: 24,
  },
  usersList: {
    maxHeight: 200,
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: t.palette.custom.dark[67],
      borderRadius: 4,
    },
  },
  userItemContainer: {
    borderBottom: `1px solid ${t.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 4,
  },
  userItemControls: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
}));

export default useStyles;
