import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  controls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 16,
    marginTop: 16,
  },
}));

export default useStyles;
