import { IAddGroupMemberData } from '@models/interfaces/additional/IAddGroupMemberData';
import { IAddGroupMemberRequest } from '@models/interfaces/requests/IAddGroupMemberRequest';
import { ICreateUserData } from '@models/interfaces/additional/ICreateUserData';
import { ICreateUserRequest } from '@models/interfaces/requests/ICreateUserRequest';
import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import { IUser } from '@models/interfaces/entities/IUser';
import { IUserDto } from '@models/interfaces/dtos/IUserDto';
import UsersProvider from '@providers/UsersProvider';
import mapper from '@models/mapper';

const usersProvider = new UsersProvider();

export const getUsers = async (url: string) => {
  const result = await usersProvider.getUsers(url);
  return {
    items: mapper.mapArray<IUserDto, IUser>(result.items, 'IUserDto', 'IUser'),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const createUser = async (url: string, data: ICreateUserData) => {
  const requestData = mapper.map<ICreateUserData, ICreateUserRequest>(
    data,
    'ICreateUserData',
    'ICreateUserRequest',
  );
  const result = await usersProvider.createUser(url, requestData);
  return mapper.map<IUserDto, IUser>(result, 'IUserDto', 'IUser');
};

export const getUser = async (url: string) => {
  const result = await usersProvider.getUser(url);
  return mapper.map<IUserDto, IUser>(result, 'IUserDto', 'IUser');
};

export const getGroupMembers = async (url: string) => {
  const result = await usersProvider.getGroupMembers(url);
  return mapper.mapArray<IUserDto, IUser>(result.items, 'IUserDto', 'IUser');
};

export const addGroupMember = async (url: string, data: IAddGroupMemberData) => {
  const requestData = mapper.map<IAddGroupMemberData, IAddGroupMemberRequest>(
    data,
    'IAddGroupMemberData',
    'IAddGroupMemberRequest',
  );
  const result = await usersProvider.addGroupMember(url, requestData);
  return mapper.mapArray<IUserDto, IUser>(result.items, 'IUserDto', 'IUser');
};

export const removeGroupMember = async (url: string) => {
  const result = await usersProvider.removeGroupMember(url);
  return mapper.mapArray<IUserDto, IUser>(result.items, 'IUserDto', 'IUser');
};

export const getClientUsers = async (url: string) => {
  const result = await usersProvider.getClientUsers(url);
  return mapper.mapArray<IUserDto, IUser>(result.items, 'IUserDto', 'IUser');
};

export const deleteUser = async (url: string) => {
  await usersProvider.deleteUser(url);
  return true;
};
