import { Alert, Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';

import { Actions } from '@models/enums/Actions';
import ArrowLeftCircleIcon from '@assets/icons/dashboard/arrow-left-circle.svg';
import EditIcon from '@assets/icons/dashboard/edit.svg';
import { IProject } from '@models/interfaces/entities/IProject';
import PlayCircleIcon from '@assets/icons/dashboard/play-circle.svg';
import { ProjectStatuses } from '@models/enums/ProjectStatuses';
import RefreshIcon from '@assets/icons/dashboard/refresh.svg';
import TrashIcon from '@assets/icons/dashboard/trash.svg';
import XCircleIcon from '@assets/icons/dashboard/x-circle.svg';
import { clsx } from 'clsx';
import moment from 'moment';
import useStyles from './styles';

export interface IProps {
  id: string;
  project: IProject;
  onSelected: () => void;
  onStart: () => void;
  onClose: () => void;
  onRestart: () => void;
  onReopen: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

export const ProjectCard = ({
  id,
  project,
  onSelected,
  onStart,
  onClose,
  onRestart,
  onReopen,
  onEdit,
  onDelete,
}: IProps) => {
  const { classes } = useStyles();

  const onProjectReopen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onReopen();
  };

  const onProjectStart = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onStart();
  };

  const onProjectClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onClose();
  };

  const onProjectRestart = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onRestart();
  };

  const onProjectEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onEdit();
  };

  const onProjectDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onDelete();
  };

  return (
    <Box
      id={id}
      className={clsx([classes.root, project.status === ProjectStatuses.inactive && 'inactive'])}
      onClick={() => {
        if (project.status !== ProjectStatuses.inactive) {
          onSelected();
        }
      }}
    >
      <Box className={clsx([classes.flexRow, classes.justifyContentSpaceBetween, classes.w100])}>
        {project.status === ProjectStatuses.inactive && (
          <Alert icon={false} variant='filled' className={classes.inactiveAlert}>
            Inactive
          </Alert>
        )}
        {project.status === ProjectStatuses.open && (
          <Alert icon={false} variant='filled' className={classes.openAlert}>
            Open
          </Alert>
        )}
        {project.status === ProjectStatuses.inProgress && (
          <Alert icon={false} variant='filled' className={classes.inProgressAlert}>
            In Progress
          </Alert>
        )}
        {project.status === ProjectStatuses.closed && (
          <Alert icon={false} variant='filled' className={classes.completeAlert}>
            Complete
          </Alert>
        )}
        <Box className={clsx([classes.flexRow, classes.gap0])}>
          {project.links[Actions.reopen]?.href && (
            <Tooltip title='Reopen project'>
              <IconButton data-testid='reopen-button' onClick={onProjectReopen}>
                <img src={ArrowLeftCircleIcon} alt='Arrow Left' />
              </IconButton>
            </Tooltip>
          )}
          {project.links[Actions.restart]?.href && (
            <Tooltip title='Restart project'>
              <IconButton data-testid='restart-button' onClick={onProjectRestart}>
                <img src={RefreshIcon} alt='Refresh' />
              </IconButton>
            </Tooltip>
          )}
          {project.links[Actions.start]?.href && (
            <Tooltip title='Start project'>
              <IconButton data-testid='start-button' onClick={onProjectStart}>
                <img src={PlayCircleIcon} alt='Play' />
              </IconButton>
            </Tooltip>
          )}
          {project.links[Actions.close]?.href && (
            <Tooltip title='Close project'>
              <IconButton data-testid='close-button' onClick={onProjectClose}>
                <img src={XCircleIcon} alt='X' />
              </IconButton>
            </Tooltip>
          )}
          {project.links[Actions.update]?.href && (
            <Tooltip title='Edit project'>
              <IconButton data-testid='edit-button' onClick={onProjectEdit}>
                <img src={EditIcon} alt='Edit' />
              </IconButton>
            </Tooltip>
          )}
          {project.links[Actions.delete]?.href && (
            <Tooltip title='Delete project'>
              <IconButton data-testid='delete-button' onClick={onProjectDelete}>
                <img src={TrashIcon} alt='Delete' />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Typography variant='subtitle1'>{project.name}</Typography>
      <Divider className={classes.w100} />
      <Box className={clsx([classes.flexRow, classes.justifyContentSpaceBetween, classes.w100])}>
        <Typography variant='caption' className={classes.textGray}>
          {project.status !== ProjectStatuses.inactive ? 'Started:' : 'Starts:'}
        </Typography>
        <Typography variant='caption' className={classes.fw600}>
          {moment(project.startDate).format('MM.DD.YYYY')}
        </Typography>
      </Box>
      {project.endDate && (
        <Box className={clsx([classes.flexRow, classes.justifyContentSpaceBetween, classes.w100])}>
          <Typography variant='caption' className={classes.textGray}>
            Closed:
          </Typography>
          <Typography variant='caption' className={classes.fw600}>
            {moment(project.endDate).format('MM.DD.YYYY')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
