import { Mapper, createMap, forMember, mapFrom } from '@automapper/core';

import { INewTypeCode } from '@models/interfaces/entities/INewTypeCode';
import { INewTypeCodeDto } from '@models/interfaces/dtos/INewTypeCodeDto';
import { ITypeCode } from '@models/interfaces/entities/ITypeCode';
import { ITypeCodeDto } from '@models/interfaces/dtos/ITypeCodeDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createTypeCodeMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<ITypeCodeDto>('ITypeCodeDto', {
    code: String,
    description: String,
    accountName: String,
  });

  PojosMetadataMap.create<ITypeCode>('ITypeCode', {
    code: String,
    description: String,
    accountName: String,
  });

  PojosMetadataMap.create<INewTypeCodeDto>('INewTypeCodeDto', {
    code: String,
    balance: Number,
    description: String,
    additional: String,
    accountId: String,
    status: String,
  });

  PojosMetadataMap.create<INewTypeCode>('INewTypeCode', {
    code: String,
    balance: Number,
    description: String,
    additional: String,
    accountId: String,
    status: String,
  });

  createMap<ITypeCodeDto, ITypeCode>(mapper, 'ITypeCodeDto', 'ITypeCode');
  createMap<INewTypeCodeDto, INewTypeCode>(
    mapper,
    'INewTypeCodeDto',
    'INewTypeCode',
    forMember(
      (destination) => destination.id,
      mapFrom((source) => source.code),
    ),
  );
};
