export const FileContentTypes = {
  excel: [
    'application/xls',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],
  binary: [
    'application/pdf',
    'application/msword',
    'application/x-zip-compressed',
    'image/png',
    'image/bmp',
    'image/jpeg',
    'text/html',
    'video/mp4',
  ],
};
