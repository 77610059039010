import { Box, Button, FormControlLabel, Switch, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';

import { CustomDialog } from '@components/CustomDialog';
import { IStandardPiiIncidentCategory } from '@models/interfaces/entities/IStandardPiiIncidentCategory';
import { IUpdateStandardPiiIncidentCategoryData } from '@models/interfaces/additional/IUpdateStandardPiiIncidentCategoryData';
import SaveFilledIcon from '@assets/icons/dialog/save-filled.svg';
import useStyles from './styles';

interface IProps {
  category: IStandardPiiIncidentCategory;
  open: boolean;
  onClose: (data?: IUpdateStandardPiiIncidentCategoryData) => void;
}

type IFormData = IUpdateStandardPiiIncidentCategoryData;

export const EditCategoryDialog = ({ category, open, onClose }: IProps) => {
  const { classes } = useStyles();

  const formDefaultData = useMemo(
    () => ({
      enabled: category.enabled,
      confidence: category.confidence,
    }),
    [category],
  );

  const form = useForm<IFormData>({
    defaultValues: formDefaultData,
  });

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const data = form.getValues();

    onClose(data);
  };

  useEffect(() => {
    form.reset(formDefaultData);
  }, [category, open]);

  return (
    <CustomDialog
      title='Edit Category'
      onClose={onCancel}
      open={open}
      maxWidth='xs'
      fullWidth
      actions={
        <>
          <div />
          <Button
            type='submit'
            form='form'
            variant='contained'
            color='secondary'
            size='large'
            startIcon={<img alt='add' src={SaveFilledIcon} />}
          >
            Save
          </Button>
          <div />
        </>
      }
    >
      <form
        id='form'
        onSubmit={form.handleSubmit(() => {
          onSave();
        })}
        noValidate
      >
        <Box>
          <Controller
            name={'confidence'}
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Please enter the min confidence',
              },
              min: {
                value: 0,
                message: 'Please enter the value in range 0-100',
              },
              max: {
                value: 100,
                message: 'Please enter the value in range 0-100',
              },
            }}
            render={({ field: { onChange, value }, formState }) => (
              <TextField
                fullWidth
                label='Min confidence'
                variant='standard'
                error={!!formState.errors.confidence}
                helperText={formState.errors.confidence?.message}
                onChange={(e) =>
                  onChange({
                    target: {
                      value: !e.target.value ? e.target.value : +e.target.value,
                    },
                  })
                }
                value={value}
                autoComplete='off'
                type='number'
                inputProps={{ min: 0, max: 100 }}
              />
            )}
          />
        </Box>
        <Box className={classes.marginTop24}>
          <Controller
            name={'enabled'}
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={
                  <Switch
                    inputProps={{ role: 'switch' }}
                    checked={value}
                    onChange={onChange}
                    className={classes.marginLeft16}
                  />
                }
                label='Scanning'
              />
            )}
          />
        </Box>
      </form>
    </CustomDialog>
  );
};
