import { useCallback, useMemo } from 'react';

import { Box } from '@mui/material';
import { GroupCard } from './components/GroupCard';
import { IGroup } from '@models/interfaces/entities/IGroup';
import { IRoleAssignment } from '@models/interfaces/entities/IRoleAssignment';
import { IUpdateClientRolesData } from '@models/interfaces/additional/IUpdateClientRolesData';
import { Roles } from '@models/enums/Roles';
import { env } from '@config/env';
import { useConfirm } from '@hooks';
import useStyles from './styles';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
  groups: IGroup[];
  roleAssignments: IRoleAssignment[];
  onChanged: (data: IUpdateClientRolesData) => void;
}

export const GroupsTabView = ({ roleAssignments, groups, onChanged }: IProps) => {
  const { classes } = useStyles();
  const confirm = useConfirm();

  const getGroup = useCallback((groupId: string) => groups.find((x) => x.id === groupId), [groups]);

  const roleAssignmentItems = useMemo<(IRoleAssignment & { name: string })[]>(() => {
    if (!groups.length || !roleAssignments.length) return [];
    const items = roleAssignments.map((x) => ({
      ...x,
      name: getGroup(x.groupId)?.name || 'Unknown',
    }));
    items.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    return items;
  }, [groups, roleAssignments]);

  const onRemoveRoleAssignment = async (index: number) => {
    await confirm({
      title: 'Delete Group',
      description: 'Are you sure that you want to delete this group?',
      confirmationText: 'Delete',
    });
    const updatedRoleAssignments = roleAssignmentItems.filter((x, i) => i !== index);
    onChanged(
      updatedRoleAssignments.map((x) => ({
        role: x.role,
        adGroup: x.groupId,
      })),
    );
  };

  const onRoleAssignmentRoleChanged = async (index: number, role: Roles) => {
    await confirm({
      title: 'Update Group',
      description: 'Are you sure that you want to update this group?',
      confirmationText: 'Update',
    });
    const updatedRoleAssignments = roleAssignmentItems.map((x, i) =>
      i !== index ? x : { ...x, role },
    );
    onChanged(
      updatedRoleAssignments.map((x) => ({
        role: x.role,
        adGroup: x.groupId,
      })),
    );
  };

  return (
    <Box className={classes.groupsList}>
      {!!roleAssignmentItems.length &&
        roleAssignmentItems.map((item, index) => (
          <GroupCard
            key={uuidv4()}
            item={{
              groupName: item.name,
              role: item.role,
            }}
            onRemove={() => onRemoveRoleAssignment(index)}
            onRoleChanged={(role) => onRoleAssignmentRoleChanged(index, role)}
          />
        ))}
    </Box>
  );
};
