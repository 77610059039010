import { Box, Typography } from '@mui/material';

import { StringAvatar } from './StringAvatar';
import { selectAuthAccount } from '@reducers/authSlice';
import { useAppSelector } from '@hooks/index';

export const UserProfile = () => {
  const account = useAppSelector(selectAuthAccount);

  const name = account?.name || 'User';

  return account ? (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <StringAvatar value={name} size='sm' />
      <Typography variant='subtitle2' sx={{ ml: 1 }}>
        {name}
      </Typography>
    </Box>
  ) : null;
};
