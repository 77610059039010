import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  info: {
    marginBottom: 32,
    textAlign: 'center',
  },
  formGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 16,
  },
}));

export default useStyles;
