import { ISectionMenuItem, SectionMenu } from '@components/SectionMenu';
import { useApi, useLoader } from '@hooks';
import { useEffect, useMemo, useState } from 'react';

import { CreateGroupDialog } from './components/CreateGroupDialog';
import { ICreateGroupData } from '@models/interfaces/additional/ICreateGroupData';
import { IGroup } from '@models/interfaces/entities/IGroup';
import { ILink } from '@models/interfaces/entities/ILink';
import { Loader } from '@components/Loader';
import { createGroup } from '@services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface IProps {
  groups: IGroup[];
  activeGroupId?: string;
  onGroupAdded: () => void;
  createGroupActionLink?: ILink;
}

export const GroupsMenu = ({
  groups,
  activeGroupId,
  onGroupAdded,
  createGroupActionLink,
}: IProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const {
    request: createGroupRequest,
    loading: createGroupLoading,
    data: createGroupData,
  } = useApi(createGroup, null, { handleErrors: true });

  const onAddNewGroup = () => {
    setOpenDialog(true);
  };

  const onCloseCreateDialog = async (item?: ICreateGroupData) => {
    if (!item) {
      setOpenDialog(false);
    } else {
      const url = createGroupActionLink?.href;
      if (url) {
        createGroupRequest(url, item);
      }
    }
  };

  const onGroupSelected = (id: string) => {
    navigate(`/admin/groups/${id}`);
  };

  useEffect(() => {
    if (createGroupData) {
      setOpenDialog(false);
      toast.info('Group successfully created');
      onGroupAdded();
    }
  }, [createGroupData]);

  const menuItems = useMemo<ISectionMenuItem[]>(() => {
    return groups.map((x) => ({
      id: x.id,
      title: x.name,
    }));
  }, [groups]);

  const showLoader = useLoader(createGroupLoading);

  return (
    <>
      <SectionMenu
        title='Groups'
        activeItemId={activeGroupId}
        items={menuItems}
        showAddButton={!!createGroupActionLink}
        onAddItem={onAddNewGroup}
        onItemSelected={onGroupSelected}
      />
      <CreateGroupDialog open={openDialog} onClose={onCloseCreateDialog} />
      <Loader show={showLoader} />
    </>
  );
};
