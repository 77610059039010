import { Box, Button, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';

import { CustomDialog } from '@components/CustomDialog';
import { IUpdateWhitelistRecordData } from '@models/interfaces/additional/IUpdateWhitelistRecordData';
import { IWhitelistRecord } from '@models/interfaces/entities/IWhitelistRecord';
import SaveFilledIcon from '@assets/icons/dialog/save-filled.svg';
import useStyles from './styles';

interface IProps {
  record: IWhitelistRecord;
  open: boolean;
  onClose: (data?: IUpdateWhitelistRecordData) => void;
}

type IFormData = IUpdateWhitelistRecordData;

export const EditWhitelistRecordDialog = ({ record, open, onClose }: IProps) => {
  const { classes } = useStyles();

  const formDefaultData = useMemo(
    () => ({
      name: record.name,
      template: record.template,
    }),
    [record],
  );

  const form = useForm<IFormData>({
    defaultValues: formDefaultData,
  });

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const data = form.getValues();

    onClose(data);
  };

  useEffect(() => {
    form.reset(formDefaultData);
  }, [record, open]);

  return (
    <CustomDialog
      title='Edit Whitelist Record'
      onClose={onCancel}
      open={open}
      maxWidth='xs'
      fullWidth
      actions={
        <>
          <div />
          <Button
            type='submit'
            form='form'
            variant='contained'
            color='secondary'
            size='large'
            startIcon={<img alt='add' src={SaveFilledIcon} />}
          >
            Save
          </Button>
          <div />
        </>
      }
    >
      <form
        id='form'
        onSubmit={form.handleSubmit(() => {
          onSave();
        })}
        noValidate
      >
        <Box>
          <Controller
            name={'name'}
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Please enter the name',
              },
            }}
            render={({ field: { onChange, value }, formState }) => (
              <TextField
                fullWidth
                label='Name'
                variant='standard'
                error={!!formState.errors.name}
                helperText={formState.errors.name?.message}
                onChange={onChange}
                value={value}
                autoComplete='off'
              />
            )}
          />
        </Box>
        <Box className={classes.marginTop24}>
          <Controller
            name={'template'}
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Please enter the template',
              },
            }}
            render={({ field: { onChange, value }, formState }) => (
              <TextField
                fullWidth
                label='Template'
                variant='standard'
                error={!!formState.errors.template}
                helperText={formState.errors.template?.message}
                onChange={onChange}
                value={value}
                autoComplete='off'
              />
            )}
          />
        </Box>
      </form>
    </CustomDialog>
  );
};
