import { Box, Switch, Typography } from '@mui/material';

import { IClient } from '@models/interfaces/entities/IClient';
import { IClientFeature } from '@models/interfaces/entities/IClientFeature';
import clsx from 'clsx';
import { useMemo } from 'react';
import useStyles from './styles';

export interface IProps {
  client: IClient & {
    clientFeature?: IClientFeature;
  };
  onActivate: () => void;
  onDeactivate: () => void;
  isActivationEnabled: boolean;
}

export const ClientCard = ({ client, onActivate, onDeactivate }: IProps) => {
  const { classes } = useStyles();

  const switchChecked = useMemo(() => !!client.clientFeature, [client.clientFeature]);
  // const switchEnabled = useMemo(
  //   () =>
  //     (!!client.clientFeature && client.clientFeature.links[Actions.delete]) ||
  //     (!client.clientFeature && isActivationEnabled),
  //   [client.clientFeature, isActivationEnabled],
  // );

  return (
    <Box className={clsx([classes.root, client.clientFeature ? classes.active : classes.inactive])}>
      <Switch
        className={classes.switch}
        // disabled={!switchEnabled}
        disabled
        checked={switchChecked}
        inputProps={{ role: 'switch' }}
        onChange={(_, value) => {
          if (value) {
            onActivate();
          } else {
            onDeactivate();
          }
        }}
      />
      <Box className={classes.infoSection}>
        <Typography variant='subtitle1'>{client.name}</Typography>
        <Typography className={classes.subtitle} variant='overline'>
          {client.externalId}
        </Typography>
      </Box>
    </Box>
  );
};
