import { Box, Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { CustomAutocomplete, IAutocompleteOption } from '@components/CustomAutocomplete';
import { useApi, useAppSelector, useLoader } from '@hooks';
import { useEffect, useMemo, useState } from 'react';

import { CustomDialog } from '@components/CustomDialog';
import { IAddUserMembershipData } from '@models/interfaces/additional/IAddUserMembershipData';
import { IGroup } from '@models/interfaces/entities/IGroup';
import { Loader } from '@components/Loader';
import PlusFilledIcon from '@assets/icons/dialog/plus-filled.svg';
import { getGroups } from '@services/api';
import { selectAuthGetGroupsActionLink } from '@reducers/authSlice';

interface IProps {
  memberships: IGroup[];
  open: boolean;
  onClose: (data?: IAddUserMembershipData) => void;
}

type IFormData = {
  group: IAutocompleteOption | null;
};

export const AddUserMembershipDialog = ({ memberships, open, onClose }: IProps) => {
  const formDefaultData = useMemo(
    () => ({
      group: null,
    }),
    [],
  );

  const form = useForm<IFormData>({
    defaultValues: formDefaultData,
  });

  const [groups, setGroups] = useState<IGroup[]>([]);
  const getGroupsActionLink = useAppSelector(selectAuthGetGroupsActionLink);
  const {
    request: getGroupsRequest,
    loading: getGroupsLoading,
    data: getGroupsData,
  } = useApi(getGroups, null, { handleErrors: true });

  useEffect(() => {
    if (open && getGroupsActionLink) {
      getGroupsRequest(getGroupsActionLink.href);
    }
  }, [open, getGroupsActionLink]);

  useEffect(() => {
    if (getGroupsData) {
      setGroups(getGroupsData.items);
    }
  }, [getGroupsData]);

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const data = form.getValues();

    onClose(data.group?.value ? { groupId: data.group.value } : undefined);
  };

  useEffect(() => {
    form.reset(formDefaultData);
  }, [open]);

  const groupOptions = useMemo<IAutocompleteOption[]>(() => {
    return groups
      .filter((u) => !memberships.find((m) => m.id === u.id))
      .map((x) => ({
        value: x.id,
        title: x.name,
      }));
  }, [groups, memberships]);

  const showLoader = useLoader(getGroupsLoading);

  return (
    <>
      <CustomDialog
        title='Add To Group'
        onClose={onCancel}
        open={open}
        maxWidth='xs'
        fullWidth
        actions={
          <>
            <div />
            <Button
              type='submit'
              form='form'
              variant='contained'
              color='secondary'
              size='large'
              startIcon={<img alt='add' src={PlusFilledIcon} />}
            >
              Add To Group
            </Button>
            <div />
          </>
        }
      >
        <form
          id='form'
          onSubmit={form.handleSubmit(() => {
            onSave();
          })}
          noValidate
        >
          <Box>
            <Controller
              name={'group'}
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: 'Please select the group',
                },
              }}
              render={({ field: { onChange, value }, formState }) => (
                <CustomAutocomplete
                  value={value}
                  label='Group'
                  placeholder='Select Group'
                  onChange={onChange}
                  error={!!formState.errors.group}
                  helperText={formState.errors.group?.message}
                  options={groupOptions}
                />
              )}
            />
          </Box>
        </form>
      </CustomDialog>
      <Loader show={showLoader} />
    </>
  );
};
