import { ISectionMenuItem, SectionMenu } from '@components/SectionMenu';
import { useApi, useLoader } from '@hooks';
import { useEffect, useMemo, useState } from 'react';

import { CreateCategoryDialog } from './components/CreateCategoryDialog';
import { ICreatePiiIncidentCategoryData } from '@models/interfaces/additional/ICreatePiiIncidentCategoryData';
import { ILink } from '@models/interfaces/entities/ILink';
import { IPiiIncidentCategory } from '@models/interfaces/entities/IPiiIncidentCategory';
import { IStandardPiiIncidentCategory } from '@models/interfaces/entities/IStandardPiiIncidentCategory';
import { Loader } from '@components/Loader';
import { createPiiIncidentCategory } from '@services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export interface IProps {
  customCategories: IPiiIncidentCategory[];
  standardCategories: IStandardPiiIncidentCategory[];
  activeCategoryId?: string;
  onCustomCategoryAdded: () => void;
  createCustomCategoryActionLink?: ILink;
}

export const CategoriesMenu = ({
  customCategories,
  standardCategories,
  activeCategoryId,
  onCustomCategoryAdded,
  createCustomCategoryActionLink,
}: IProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const {
    request: createCustomCategoryRequest,
    loading: createCustomCategoryLoading,
    data: createCustomCategoryData,
  } = useApi(createPiiIncidentCategory, null, { handleErrors: true });

  const onAddNewCategory = () => {
    setOpenDialog(true);
  };

  const onCloseCreateDialog = async (item?: ICreatePiiIncidentCategoryData) => {
    if (!item) {
      setOpenDialog(false);
    } else {
      const url = createCustomCategoryActionLink?.href;
      if (url) {
        createCustomCategoryRequest(url, item);
      }
    }
  };

  const onCategorySelected = (id: string) => {
    navigate(`/admin/pii-categories/${id}`);
  };

  useEffect(() => {
    if (createCustomCategoryData) {
      setOpenDialog(false);
      toast.info('Category successfully created');
      onCustomCategoryAdded();
    }
  }, [createCustomCategoryData]);

  const showLoader = useLoader(createCustomCategoryLoading);

  const menuItems = useMemo<ISectionMenuItem[]>(() => {
    const items = [
      ...standardCategories.map((x) => ({
        id: x.id,
        title: `${x.name} (basic)`,
      })),
      ...customCategories.map((x) => ({
        id: x.id,
        title: `${x.name} (custom)`,
      })),
    ];
    return items;
  }, [customCategories, standardCategories]);

  return (
    <>
      <SectionMenu
        title='Pii Categories'
        activeItemId={activeCategoryId}
        items={menuItems}
        showAddButton={!!createCustomCategoryActionLink}
        onAddItem={onAddNewCategory}
        onItemSelected={onCategorySelected}
      />
      <CreateCategoryDialog open={openDialog} onClose={onCloseCreateDialog} />
      <Loader show={showLoader} />
    </>
  );
};
