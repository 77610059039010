import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  container: {
    flexGrow: 1,
    paddingTop: 40,
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100vh - 57px)',
    marginRight: -8,
    paddingRight: 8,

    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: t.palette.custom.dark[67],
      borderRadius: 4,
    },
  },
  infoSection: {
    paddingLeft: 16,
    paddingBottom: 16,
    borderBottom: `1px solid ${t.palette.divider}`,
  },
  title: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  infoContent: {
    marginTop: 16,
    display: 'flex',
    gap: 64,
    alignItems: 'center',
  },
  infoItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    '& .label': {
      color: t.palette.custom.dark[60],
    },
  },
  memberships: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 24,
  },
  membershipsCountContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  membershipsCount: {
    marginLeft: 20,
  },
  addButton: {
    padding: 0,
    textTransform: 'none',
    fontWeight: t.typography.subtitle2.fontWeight,
    fontSize: t.typography.subtitle2.fontSize,
    lineHeight: t.typography.subtitle2.lineHeight,
    letterSpacing: t.typography.subtitle2.letterSpacing,
    marginRight: -8,
    paddingLeft: 8,
    paddingRight: 8,
  },
  membershipsList: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: 16,
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 16,
  },
}));

export default useStyles;
