import { Alert, Box, Button, Stack, Switch, Typography } from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';

import { CustomDialog } from '@components/CustomDialog';
import useStyles from './styles';

export interface IProps {
  names: string[];
  open: boolean;
  onClose: (data?: string[]) => void;
}

export interface IFormData {
  files: {
    name: string;
    replace: boolean;
  }[];
}

export const NamesConflictsResolvingDialog = ({ names, open, onClose }: IProps) => {
  const { classes } = useStyles();

  const formId = 'namesConflictsResolvingDialogForm';

  const formDefaultData = useMemo<IFormData>(() => {
    return {
      files: names.map((x) => ({
        name: x,
        replace: false,
      })),
    };
  }, [open, names]);

  const form = useForm<IFormData>({
    defaultValues: formDefaultData,
  });

  const { fields } = useFieldArray({ name: 'files', control: form.control });

  const onCancel = () => {
    onClose();
  };

  const onFormSubmit = async () => {
    const { files } = form.getValues();
    onClose(files.filter((x) => x.replace).map((x) => x.name));
  };

  useEffect(() => {
    form.reset(formDefaultData);
  }, [open]);

  return (
    <>
      <CustomDialog
        title='Resolve the conflicts'
        onClose={onCancel}
        open={open}
        maxWidth='sm'
        fullWidth
        actions={
          <>
            <div />
            <Button
              type='submit'
              form={formId}
              variant='contained'
              color='secondary'
              className='narrow'
            >
              Done
            </Button>
          </>
        }
      >
        <Alert color='warning' icon={false} className={classes.mb24}>
          The following files have been already uploaded for this project. Please select what you
          want to do with each of them: upload and replace the existing one or skip.
        </Alert>

        <form
          id={formId}
          onSubmit={form.handleSubmit(() => {
            onFormSubmit();
          })}
          noValidate
        >
          {fields.map((item, index) => (
            <Box key={index} className={classes.fileItemContainer} data-testid='file-name'>
              <Typography variant='subtitle2'>{item.name}</Typography>
              <Controller
                name={`files.${index}.replace`}
                control={form.control}
                render={({ field: { onChange, value } }) => (
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Typography variant='caption'>Skip</Typography>
                    <Switch inputProps={{ role: 'switch' }} checked={value} onChange={onChange} />
                    <Typography variant='caption'>Replace</Typography>
                  </Stack>
                )}
              />
            </Box>
          ))}
        </form>
      </CustomDialog>
    </>
  );
};
