import { Box, Typography } from '@mui/material';
import { getProjectFilesNames, uploadProjectFile } from '@services/api';
import { useApi, useLoader, useUpdateEffect } from '@hooks';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { FileTypes } from '@models/enums/FileTypes';
import { IProject } from '@models/interfaces/entities/IProject';
import { Loader } from '@components/Loader';
import { NamesConflictsResolvingDialog } from './components/NamesConflictsResolvingDialog';
import { ProjectFilesCacheActions } from '@reducers/projectFilesCacheReducer';
import ProjectFilesCacheContext from '@contexts/ProjectFilesCacheContext';
import UploadCloudIcon from '@assets/icons/uploader/upload-cloud.svg';
import { UploadingDialog } from '../UploadingDialog';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import useStyles from './styles';

export interface IProps {
  id: string;
  project: IProject;
  fileType: FileTypes;
  maxFileSize: number;
}

export const Uploader = ({ id, project, fileType, maxFileSize }: IProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openNamesCheckingDialog, setOpenNamesCheckingDialog] = useState(false);
  const [filesCount, setFilesCount] = useState(0);
  const [failedFilesCount, setFailedFilesCount] = useState(0);
  const [uploadedFilesCount, setUploadedFilesCount] = useState(0);
  const { classes } = useStyles();

  const [uploadFileLink, setUploadFileLink] = useState<string>();
  const [conflictedNames, setConflictedNames] = useState<string[]>();

  const { cache, dispatch: cacheDispatch } = useContext(ProjectFilesCacheContext);

  const files = useMemo(
    () => cache.find((x) => x.projectId === project.id)?.files || [],
    [cache, project],
  );

  const uploadFiles = (link: string, files: File[], namesOfFilesToReplace: string[] = []) => {
    if (!files.length) {
      toast.warning('Nothing to upload');
      cacheDispatch({
        type: ProjectFilesCacheActions.clearFiles,
        payload: { projectId: project.id },
      });
    } else {
      setFilesCount(files.length);
      setFailedFilesCount(0);
      setUploadedFilesCount(0);

      files.forEach((file) => {
        uploadProjectFileRequest(link, file, namesOfFilesToReplace.includes(file.name));
      });
    }
  };

  const { request: getProjectFilesNamesRequest, loading: getProjectFilesNamesLoading } = useApi(
    getProjectFilesNames,
    null,
    {
      handleErrors: true,
      onCallback: (names) => {
        if (!names) {
          return;
        }
        setConflictedNames(names);
      },
    },
  );

  const checkFilesNamesAvailability = (link: string) => {
    getProjectFilesNamesRequest(
      link,
      files.map((x) => x.content.name),
    );
  };

  const { request: uploadProjectFileRequest, loading: uploadProjectFileLoading } = useApi(
    uploadProjectFile,
    null,
    {
      handleErrors: true,
      onError: () => {
        setFailedFilesCount((c) => c + 1);
      },
      onCallback: () => {
        setUploadedFilesCount((c) => c + 1);
      },
    },
  );

  const onDropFiles = useCallback(
    async (acceptedFiles: File[]) => {
      if (!acceptedFiles.length) return;

      const filteredFiles = files.filter(
        (x) => acceptedFiles.findIndex((f) => f.name === x.content.name) === -1,
      );

      cacheDispatch({
        type: ProjectFilesCacheActions.setFiles,
        payload: {
          projectId: project.id,
          files: [
            ...filteredFiles,
            ...acceptedFiles.map((x) => ({
              content: x,
            })),
          ],
        },
      });
      setOpenDialog(true);
    },
    [files, project],
  );
  const dropzone = useDropzone({
    noDragEventsBubbling: true,
    onDrop: onDropFiles,
    onDropRejected(fileRejections) {
      toast.info(
        `${fileRejections.length} file${
          fileRejections.length > 1 ? 's' : ''
        } skipped due to exceeding max size (${maxFileSize} mb)`,
      );
    },
    maxSize: maxFileSize * 1024 * 1024,
  });

  const onCloseDialog = async (data?: boolean) => {
    if (!data) {
      setOpenDialog(false);
    } else {
      if (fileType === FileTypes.dataFile && !!project.links[Actions.uploadFile]?.href) {
        setUploadFileLink(project.links[Actions.uploadFile].href);
        checkFilesNamesAvailability(project.links[Actions.getFilesNames]?.href);
      } else if (fileType === FileTypes.resultFile && !!project.links[Actions.uploadResult]?.href) {
        setUploadFileLink(project.links[Actions.uploadResult].href);
        checkFilesNamesAvailability(project.links[Actions.getFilesNames]?.href);
      } else {
        toast.error('Unable to upload files to this project.');
      }
    }
  };

  const onCloseNamesCheckingDialog = async (data?: string[]) => {
    if (!data) {
      setOpenNamesCheckingDialog(false);
    } else {
      setOpenNamesCheckingDialog(false);
      if (uploadFileLink && conflictedNames) {
        const namesOfFilesToSkip = conflictedNames.filter((x) => !data.includes(x));

        uploadFiles(
          uploadFileLink,
          files.filter((x) => !namesOfFilesToSkip.includes(x.content.name)).map((x) => x.content),
          data,
        );

        setConflictedNames(undefined);
        setOpenDialog(false);
      }
    }
  };

  useUpdateEffect(() => {
    if (!uploadFileLink || !conflictedNames) {
      return;
    }
    if (!conflictedNames.length) {
      uploadFiles(
        uploadFileLink,
        files.map((x) => x.content),
      );
      setConflictedNames(undefined);
      setOpenDialog(false);
    } else {
      setOpenNamesCheckingDialog(true);
    }
  }, [conflictedNames, uploadFileLink]);

  useEffect(() => {
    if (filesCount && filesCount === failedFilesCount + uploadedFilesCount) {
      if (!uploadedFilesCount) {
        toast.error('Files uploading failed.');
      } else if (!failedFilesCount) {
        toast.info('Files successfully uploaded.');
      } else {
        toast.warning(
          `Files uploading completed with errors. ${failedFilesCount} file${
            failedFilesCount === 1 ? ' has' : 's have'
          } been not uploaded.`,
        );
      }

      setFilesCount(0);
      cacheDispatch({
        type: ProjectFilesCacheActions.clearFiles,
        payload: { projectId: project.id },
      });
    }
  }, [uploadedFilesCount, failedFilesCount, filesCount, project]);

  const uploadingLoaderLabel = useMemo(
    () =>
      filesCount
        ? `${Math.round(((uploadedFilesCount + failedFilesCount) * 100) / filesCount) || 0}%`
        : '',
    [uploadedFilesCount, failedFilesCount, filesCount],
  );

  const showLoader = useLoader(
    uploadProjectFileLoading,
    getProjectFilesNamesLoading,
    !!uploadingLoaderLabel,
  );

  return (
    <>
      <Box id={id} className={classes.root}>
        <Box
          {...dropzone.getRootProps()}
          className={clsx([classes.dropzone, dropzone.isDragActive && 'active'])}
          data-testid='dropzone'
        >
          <input data-testid='dropzone-input' {...dropzone.getInputProps()} />
          <img className={classes.dropzoneControlsIcon} alt='upload' src={UploadCloudIcon} />
          <Box className={classes.dropzoneControlsTextContent}>
            <Typography className={classes.textWhite} variant='subtitle2'>
              Click to upload
            </Typography>
            <Typography className={classes.textWhite80} variant='body2'>
              or drag & drop
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.textWhite60} variant='overline'>
              csv, pdf, doc, xlsx. (Max. file size {maxFileSize} MB.)
            </Typography>
          </Box>
        </Box>
      </Box>
      <Loader show={showLoader} label={uploadingLoaderLabel} />
      <UploadingDialog
        open={openDialog}
        onClose={onCloseDialog}
        project={project}
        fileType={fileType}
      />
      {conflictedNames?.length && (
        <NamesConflictsResolvingDialog
          names={conflictedNames}
          open={openNamesCheckingDialog}
          onClose={onCloseNamesCheckingDialog}
        />
      )}
    </>
  );
};

Uploader.defaultProps = {
  maxFileSize: 600,
};
