export enum ProjectFilesCacheActions {
  clearFiles = 'CLEAR_FILES',
  setFiles = 'SET_FILES',
}

export interface IProjectFile {
  content: File;
}

export interface IProjectFilesCacheItem {
  projectId: string;
  files: IProjectFile[];
}

export interface IProjectFilesCacheAction {
  type: ProjectFilesCacheActions;
  payload: {
    projectId: string;
    files?: IProjectFile[];
  };
}

export const projectFilesCacheReducer = (
  state: IProjectFilesCacheItem[],
  action: IProjectFilesCacheAction,
) => {
  switch (action.type) {
    case ProjectFilesCacheActions.clearFiles:
      return state.filter((x) => x.projectId !== action.payload.projectId);
    case ProjectFilesCacheActions.setFiles: {
      const index = state.findIndex((x) => x.projectId === action.payload.projectId);
      if (index !== -1) {
        return state.map((x, i) =>
          i !== index
            ? x
            : {
                ...x,
                files: action.payload.files || [],
              },
        );
      } else {
        return [
          ...state,
          {
            projectId: action.payload.projectId,
            files: action.payload.files || [],
          },
        ];
      }
    }
    default:
      return state;
  }
};
