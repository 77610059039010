import { Box, IconButton, Tooltip } from '@mui/material';
import {
  selectGeneralCompletedTours,
  selectGeneralCurrentTours,
  selectGeneralMarkedTours,
  setCompletedTours,
} from '@reducers/generalSlice';
import { useAppDispatch, useAppSelector } from '@hooks';

import HelpIcon from '@assets/icons/tour/help-circle.svg';
import useStyles from './styles';

export const GuideTourHelpButton = () => {
  const { classes } = useStyles();

  const currentTours = useAppSelector(selectGeneralCurrentTours);
  const completedTours = useAppSelector(selectGeneralCompletedTours);
  const markedTours = useAppSelector(selectGeneralMarkedTours);
  const dispatch = useAppDispatch();

  console.debug({ currentTours, completedTours, markedTours });

  const onToggleCurrentTours = () => {
    const uncompletedCurrentTours = currentTours.filter((x) => !completedTours.includes(x));
    const uncompletedMarkedTours = markedTours.filter((x) => !completedTours.includes(x));
    if (uncompletedCurrentTours.length) {
      dispatch(
        setCompletedTours(
          Array.from(
            new Set([...completedTours, ...uncompletedCurrentTours, ...uncompletedMarkedTours]),
          ),
        ),
      );
    } else {
      dispatch(
        setCompletedTours(
          completedTours.filter((x) => !currentTours.includes(x) && !markedTours.includes(x)),
        ),
      );
    }
  };

  return currentTours.length > 0 ? (
    <Box className={classes.helpButtonContainer}>
      <Tooltip title='Click here to toggle the tours'>
        <IconButton onClick={onToggleCurrentTours} id='guide-tour-help-button'>
          <img className={classes.helpButtonIcon} src={HelpIcon} alt='help' />
        </IconButton>
      </Tooltip>
    </Box>
  ) : null;
};
