import * as t from 'io-ts';

export interface IJsonDMAccountAdjustmentData {
  sSource: string;
  sID: string;
  sDescription: string;
  dBalance: number;
  dFees: number;
}

export const IJsonDMAccountAdjustmentDataSchema = t.type({
  sSource: t.string,
  sID: t.string,
  sDescription: t.string,
  dBalance: t.number,
  dFees: t.number,
});
