import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    backgroundColor: t.palette.custom.dark[5],
    paddingTop: 8,
    paddingBottom: 8,

    '&.disabled': {
      backgroundColor: t.palette.custom.error[15],
    },

    '&.invalid': {
      animation: `${keyframes`
      0% {
        box-shadow: inset 0 0 0 0 ${t.palette.custom.error[80]};
      }
      70% {
        box-shadow: inset 0 0 10px 5px ${t.palette.custom.error[60]};
      }
      100% {
        box-shadow: inset 0 0 0 0 ${t.palette.custom.error[60]};
      }
      `} 2s infinite ease-in-out`,
    },
  },
  card: {
    padding: 8,
  },
  filesList: {
    display: 'flex',
    gap: 8,
    flexDirection: 'column',
  },
  title: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  grid: {
    display: 'grid',
    gap: 8,
  },
  contentGrid: {
    display: 'grid',
    gap: 0,
    transition: '300ms',
    gridTemplateColumns: '1fr 0fr',
  },
  twoColumnsGrid: {
    gridTemplateColumns: '2fr 3fr',
    gap: 8,
  },
  mappingGrid: {
    display: 'grid',
    gap: 8,
    gridTemplateColumns: '2fr 32px 3fr',
  },
  disabledMappingGrid: {
    borderRadius: 10,
    backgroundColor: t.palette.custom.error[20],
    cursor: 'not-allowed',
  },
  subCategoryCard: {
    minHeight: 50,
    gap: 16,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 10,
    border: `1px dashed ${t.palette.custom.dark[20]}`,
    backgroundColor: t.palette.custom.dark[5],
    justifyContent: 'space-between',
  },
  previousProjectLink: {
    color: t.palette.custom.info[100],
  },
  dropStatusIcon: {
    height: 30,
  },
  accordionSummaryContent: {
    minHeight: 48,
    margin: 0,

    '&.Mui-expanded': {
      marginTop: 8,
      marginBottom: 8,
    },
  },
}));

export default useStyles;
