import { getPiiIncidentCategories, getStandardPiiIncidentCategories } from '@services/api';
import {
  selectAuthGetPiiIncidentCategoriesActionLink,
  selectAuthGetStandardPiiIncidentCategoriesActionLink,
} from '@reducers/authSlice';
import { useApi, useAppSelector, useLoader } from '@hooks';
import { useCallback, useEffect, useMemo } from 'react';

import { Actions } from '@models/enums/Actions';
import { Box } from '@mui/material';
import { CategoriesMenu } from './components/CategoriesMenu';
import { CustomCategoryView } from './components/CustomCategoryView';
import { Loader } from '@components/Loader';
import { StandardCategoryView } from './components/StandardCategoryView';
import { useParams } from 'react-router-dom';
import useStyles from './styles';

enum PiiCategoryType {
  custom,
  standard,
}

export const PiiCategoriesPage = () => {
  const { classes } = useStyles();
  const getCustomCategoriesActionLink = useAppSelector(
    selectAuthGetPiiIncidentCategoriesActionLink,
  );
  const getStandardCategoriesActionLink = useAppSelector(
    selectAuthGetStandardPiiIncidentCategoriesActionLink,
  );
  const { categoryId } = useParams();

  const {
    request: getCustomCategoriesRequest,
    data: getCustomCategoriesData,
    loading: getCustomCategoriesLoading,
  } = useApi(getPiiIncidentCategories, { items: [], links: {} }, { handleErrors: true });

  const {
    request: getStandardCategoriesRequest,
    data: getStandardCategoriesData,
    loading: getStandardCategoriesLoading,
  } = useApi(getStandardPiiIncidentCategories, { items: [], links: {} }, { handleErrors: true });

  useEffect(() => {
    if (getCustomCategoriesActionLink)
      getCustomCategoriesRequest(getCustomCategoriesActionLink.href);
  }, [getCustomCategoriesActionLink]);

  useEffect(() => {
    if (getStandardCategoriesActionLink)
      getStandardCategoriesRequest(getStandardCategoriesActionLink.href);
  }, [getStandardCategoriesActionLink]);

  const onCustomCategoryChanged = useCallback(() => {
    if (getCustomCategoriesActionLink)
      getCustomCategoriesRequest(getCustomCategoriesActionLink.href);
  }, [getCustomCategoriesActionLink]);

  const onStandardCategoryChanged = useCallback(() => {
    if (getStandardCategoriesActionLink)
      getStandardCategoriesRequest(getStandardCategoriesActionLink.href);
  }, [getStandardCategoriesActionLink]);

  const categoryType = useMemo(() => {
    if (getStandardCategoriesData.items.findIndex((x) => x.id === categoryId) !== -1) {
      return PiiCategoryType.standard;
    }
    if (getCustomCategoriesData.items.findIndex((x) => x.id === categoryId) !== -1) {
      return PiiCategoryType.custom;
    }
    return undefined;
  }, [getStandardCategoriesData.items, getCustomCategoriesData.items, categoryId]);

  const showLoader = useLoader(getCustomCategoriesLoading, getStandardCategoriesLoading);

  return (
    <Box className={classes.container}>
      <CategoriesMenu
        onCustomCategoryAdded={onCustomCategoryChanged}
        customCategories={getCustomCategoriesData.items}
        standardCategories={getStandardCategoriesData.items}
        createCustomCategoryActionLink={getCustomCategoriesData.links[Actions.createCategory]}
        activeCategoryId={categoryId}
      />
      {categoryId && (
        <>
          {categoryType === PiiCategoryType.custom && (
            <CustomCategoryView
              onCategoryChanged={onCustomCategoryChanged}
              categoryId={categoryId}
            />
          )}
          {categoryType === PiiCategoryType.standard && (
            <StandardCategoryView
              onCategoryChanged={onStandardCategoryChanged}
              categoryId={categoryId}
            />
          )}
        </>
      )}
      <Loader show={showLoader} />
    </Box>
  );
};
