import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 10,
  },
  unprocessed: {
    border: `1px solid ${t.palette.divider}`,
  },
  processed: {
    backgroundColor: t.palette.custom.primary[5],
    border: `1px solid ${t.palette.custom.primary[5]}`,
  },
  withError: {
    backgroundColor: t.palette.custom.error[10],
    border: `1px solid ${t.palette.custom.error[100]}`,
  },
  w100: { width: '100%' },
  gap4: {
    gap: 4,
  },
  gap16: {
    gap: 16,
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  flexDirectionColumn: {
    flexDirection: 'column',
  },
  textBold: {
    fontWeight: 600,
  },
  title: {
    wordBreak: 'break-word',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    visibility: 'visible',
    overflow: 'hidden',
  },
  filesList: {
    marginTop: 8,
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  disabledIconButton: {
    filter: 'grayscale(1)',
  },
  statusIcon: {
    height: 30,
    cursor: 'pointer',

    '&.animated': {
      animation: `${keyframes`
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(90deg);
      }
      `} 2s infinite ease-in-out`,
    },
  },
  draggable: {
    cursor: 'grab',
  },
}));

export default useStyles;
