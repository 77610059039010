import { Box, Button, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';

import { CustomDialog } from '@components/CustomDialog';
import { ICreateProductData } from '@models/interfaces/additional/ICreateProductData';
import { ImageUploader } from '@components/ImageUploader';
import PlusFilledIcon from '@assets/icons/dialog/plus-filled.svg';
import { toast } from 'react-toastify';
import useStyles from './styles';

interface IProps {
  open: boolean;
  onClose: (data?: ICreateProductData, file?: File) => void;
}

type IFormData = ICreateProductData;

export const CreateProductDialog = ({ open, onClose }: IProps) => {
  const { classes } = useStyles();
  const [file, setFile] = useState<File | null>(null);
  const formDefaultData = useMemo(
    () => ({
      name: '',
      url: '',
    }),
    [],
  );

  const form = useForm<IFormData>({
    defaultValues: formDefaultData,
  });

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    if (!file) {
      toast.error('Product image is required');
      return;
    }

    const data = form.getValues();

    onClose(data, file);
  };

  const onImageUploaded = (file: File) => {
    setFile(file);
  };

  const onImageRemoved = () => {
    setFile(null);
  };

  const previewUrl = useMemo<string | undefined>(
    () => (file ? URL.createObjectURL(file) : undefined),
    [file],
  );

  useEffect(() => {
    form.reset(formDefaultData);
    setFile(null);
  }, [open]);

  return (
    <CustomDialog
      title='Add New Product'
      onClose={onCancel}
      open={open}
      maxWidth='xs'
      fullWidth
      actions={
        <>
          <div />
          <Button
            type='submit'
            form='form'
            variant='contained'
            color='secondary'
            size='large'
            startIcon={<img alt='add' src={PlusFilledIcon} />}
          >
            Add Product
          </Button>
          <div />
        </>
      }
    >
      <form
        id='form'
        onSubmit={form.handleSubmit(() => {
          onSave();
        })}
        noValidate
      >
        <Box>
          <Controller
            name={'name'}
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Please enter the name',
              },
            }}
            render={({ field: { onChange, value }, formState }) => (
              <TextField
                fullWidth
                label='Name'
                variant='standard'
                error={!!formState.errors.name}
                helperText={formState.errors.name?.message}
                onChange={onChange}
                value={value}
                autoComplete='off'
              />
            )}
          />
        </Box>
        <Box className={classes.mt24}>
          <Controller
            name={'url'}
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Please enter the url',
              },
            }}
            render={({ field: { onChange, value }, formState }) => (
              <TextField
                fullWidth
                label='Url'
                variant='standard'
                error={!!formState.errors.url}
                helperText={formState.errors.url?.message}
                onChange={onChange}
                value={value}
                autoComplete='off'
              />
            )}
          />
        </Box>
        <Box className={classes.mt24}>
          <ImageUploader
            imageUrl={previewUrl}
            onImageUploaded={onImageUploaded}
            onImageRemoved={onImageRemoved}
          />
        </Box>
      </form>
    </CustomDialog>
  );
};
