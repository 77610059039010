import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { getAccounts, getExistingTypeCodes, getNewTypeCodes } from '@services/api';
import { useApi, useLoader, useUpdateEffect } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import ChevronDownIcon from '@assets/icons/accordion/chevron-down.svg';
import DownloadIcon from '@assets/icons/dashboard/download.svg';
import { ExistingTypeCodesGroup } from '../ExistingTypeCodesGroup';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IBalance } from '@models/interfaces/entities/IBalance';
import { IFile } from '@models/interfaces/entities/IFile';
import { IFileCategory } from '@models/interfaces/entities/IFileCategory';
import { IFileSubCategory } from '@models/interfaces/entities/IFileSubCategory';
import { INewTypeCode } from '@models/interfaces/entities/INewTypeCode';
import { IProject } from '@models/interfaces/entities/IProject';
import { ITypeCode } from '@models/interfaces/entities/ITypeCode';
import { Loader } from '@components/Loader';
import { NewTypeCodeStatuses } from '@models/enums/NewTypeCodeStatuses';
import { NewTypeCodesGroup } from '../NewTypeCodesGroup';
import { getBalance } from '@services/api/balances';
import { getDataContent } from '@services/api/dataRecords';
import saveAs from 'file-saver';
import useStyles from './styles';

export interface IProps {
  id?: string;
  project: IProject;
  item: IFileCategory;
  readonly?: boolean;
  subCategories: IFileSubCategory[];
  files: IFile[];
  oldFiles: IFile[];
  balance?: IBalance;
}

export const FileCategoryTypeCodes = ({
  id,
  project,
  item,
  readonly,
  subCategories,
  files,
  oldFiles,
  balance,
}: IProps) => {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [ignoredTypeCodes, setIgnoredTypeCodes] = useState<ITypeCode[]>([]);
  const [existingTypeCodes, setExistingTypeCodes] = useState<ITypeCode[]>([]);
  const [newTypeCodes, setNewTypeCodes] = useState<INewTypeCode[]>([]);
  const [accounts, setAccounts] = useState<IAccount[]>([]);
  const [detailedBalance, setDetailedBalance] = useState<IBalance>();

  const isInvestmentsCategory = useMemo(() => item.name === 'Investments', [item.name]);

  const {
    request: getAccountsRequest,
    data: getAccountsData,
    loading: getAccountsLoading,
  } = useApi(getAccounts, null, { handleErrors: true });

  const {
    request: getExistingTypeCodesRequest,
    data: getExistingTypeCodesData,
    loading: getExistingTypeCodesLoading,
  } = useApi(getExistingTypeCodes, null, { handleErrors: true });

  const {
    request: getNewTypeCodesRequest,
    data: getNewTypeCodesData,
    loading: getNewTypeCodesLoading,
  } = useApi(getNewTypeCodes, null, { handleErrors: true });

  const {
    request: getBalanceRequest,
    data: getBalanceData,
    loading: getBalanceLoading,
  } = useApi(getBalance, null, { handleErrors: true });

  const { request: getDataContentRequest, loading: getDataContentLoading } = useApi(
    getDataContent,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) saveAs(data.content);
      },
    },
  );

  const mappedSubCategories = useMemo(() => {
    if (item.subCategoriesDisabled) return [];
    const result = subCategories.map((category) => {
      const file = files.find((x) => x.fileSubCategoryId === category.id);
      const oldFile = oldFiles.find((x) => x.fileSubCategoryId === category.id);
      return {
        category,
        file,
        oldFile,
      };
    });
    result.sort((a, b) => {
      const aNameLowercased = a.oldFile?.name.toLowerCase();
      const bNameLowercased = b.oldFile?.name.toLowerCase();
      if (!aNameLowercased) {
        return 1;
      }
      if (!bNameLowercased) {
        return -1;
      }
      return aNameLowercased > bNameLowercased ? 1 : bNameLowercased > aNameLowercased ? -1 : 0;
    });
    return result.filter(({ oldFile, file }) => oldFile || file);
  }, [subCategories, files, oldFiles, item.subCategoriesDisabled]);

  const enabledMappedSubCategoriesCount = useMemo(
    () => mappedSubCategories.filter(({ category, file }) => !category.disabled || file).length,
    [mappedSubCategories],
  );

  const formatBalance = useCallback((value: number) => {
    return `$${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value)}`;
  }, []);

  const onDownload: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (project.links[Actions.getData]) {
      getDataContentRequest(project.links[Actions.getData].href, item.id);
    }
  };

  const onLoadTypeCodes = (ignored: boolean) => {
    if (project.links[Actions.getExistingTypeCodes]) {
      getExistingTypeCodesRequest(
        project.links[Actions.getExistingTypeCodes].href,
        item.id,
        ignored,
      );
    }
  };

  const onNewTypeCodeUpdated = (typeCode: INewTypeCode) => {
    setNewTypeCodes((prev) => prev.map((x) => (x.id !== typeCode.id ? x : typeCode)));
    if (
      typeCode.status === NewTypeCodeStatuses.mappedToNewAccount &&
      project.links[Actions.getAccounts]
    ) {
      getAccountsRequest(project.links[Actions.getAccounts].href);
    }
    if (project.links[Actions.getNewTypeCodes]) {
      getNewTypeCodesRequest(project.links[Actions.getNewTypeCodes].href, item.id);
    }
  };

  useEffect(() => {
    if (expanded && balance?.links[Actions.self]) {
      getBalanceRequest(balance.links[Actions.self].href);
    }
  }, [balance, expanded]);

  useEffect(() => {
    if (!isInvestmentsCategory && expanded && project.links[Actions.getNewTypeCodes]) {
      getNewTypeCodesRequest(project.links[Actions.getNewTypeCodes].href, item.id);
    }
  }, [project, item, expanded, isInvestmentsCategory]);

  useEffect(() => {
    if (!isInvestmentsCategory && expanded && project.links[Actions.getAccounts]) {
      getAccountsRequest(project.links[Actions.getAccounts].href);
    }
  }, [project, expanded, isInvestmentsCategory]);

  useUpdateEffect(() => {
    if (getExistingTypeCodesData) {
      if (getExistingTypeCodesData.ignored) {
        setIgnoredTypeCodes(getExistingTypeCodesData.items);
      } else {
        setExistingTypeCodes(getExistingTypeCodesData.items);
      }
    }
  }, [getExistingTypeCodesData]);

  useUpdateEffect(() => {
    if (getAccountsData) {
      setAccounts(getAccountsData.items);
    }
  }, [getAccountsData]);

  useUpdateEffect(() => {
    if (getNewTypeCodesData) {
      setNewTypeCodes(getNewTypeCodesData.items);
    }
  }, [getNewTypeCodesData]);

  useUpdateEffect(() => {
    if (getBalanceData) {
      setDetailedBalance(getBalanceData);
    }
  }, [getBalanceData]);

  const totalBalance = useMemo(() => {
    const bal = detailedBalance || balance;
    if (!bal) {
      return 0;
    }
    return bal.totalBalance - (bal.ignoredBalance || 0);
  }, [detailedBalance, balance]);

  const showLoader = useLoader(
    getAccountsLoading,
    getExistingTypeCodesLoading,
    getNewTypeCodesLoading,
    getBalanceLoading,
    getDataContentLoading,
  );

  return (
    <Accordion
      id={id}
      className={classes.root}
      expanded={expanded}
      onChange={(e, isExpanded) => setExpanded(isExpanded)}
      data-testid='file-category-type-codes'
    >
      <AccordionSummary
        expandIcon={<img alt='arrow down' data-testid='expand-icon' src={ChevronDownIcon} />}
        classes={{
          content: classes.accordionSummaryContent,
        }}
      >
        <Box className={classes.title}>
          {project.links[Actions.getData] && (
            <Tooltip title='Download data'>
              <IconButton onClick={onDownload}>
                <img src={DownloadIcon} alt='download' />
              </IconButton>
            </Tooltip>
          )}
          <Typography variant='h6'>{item.name}</Typography>
          <Typography component='div' variant='caption'>
            ({files.length}
            {!item.subCategoriesDisabled && !readonly
              ? ` of ${enabledMappedSubCategoriesCount}`
              : ''}
            {` file${files.length !== 1 ? 's' : ''}`})
          </Typography>
        </Box>
        <Box className={classes.balanceLabel}>
          <Typography variant='subtitle2'>Total Balance (Used and New Type Codes)</Typography>
          <Typography variant='subtitle2'>{formatBalance(totalBalance)}</Typography>
        </Box>
        {!expanded && <Loader size={20} show={showLoader} fixed={false} />}
      </AccordionSummary>
      <AccordionDetails>
        <Card className={classes.card} data-testid='content-section'>
          {isInvestmentsCategory ? (
            <Box className={classes.messageContainer}>
              <Typography variant='h6'>
                c. myers will handle your investment mapping and will reach out with any questions
              </Typography>
            </Box>
          ) : (
            <Box className={classes.groupsList}>
              <NewTypeCodesGroup
                categoryId={item.id}
                accounts={accounts}
                typeCodes={newTypeCodes}
                onUpdated={onNewTypeCodeUpdated}
                updateLink={getNewTypeCodesData?.links[Actions.updateNewTypeCode]?.href}
                getSamplesLink={project.links[Actions.getTypeCodeSamples]?.href}
                isGL={item.name.includes('GL')}
              />
              <ExistingTypeCodesGroup
                title='Existing Type Codes'
                expandable
                typeCodes={existingTypeCodes}
                onToggle={(val) => val && onLoadTypeCodes(false)}
                gridColumns={['Type Code', 'Type Code Description', 'Model Account']}
                totalBalance={detailedBalance?.usedBalance || 0}
              />
              <ExistingTypeCodesGroup
                title='Unused Type Codes'
                expandable
                typeCodes={ignoredTypeCodes}
                onToggle={(val) => val && onLoadTypeCodes(true)}
                gridColumns={['Type Code', 'Type Code Description', 'Reason']}
                totalBalance={detailedBalance?.ignoredBalance || 0}
              />
            </Box>
          )}
        </Card>
      </AccordionDetails>
      {expanded && <Loader size={expanded ? 100 : 20} show={showLoader} fixed={false} />}
    </Accordion>
  );
};
