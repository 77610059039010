import { ISectionMenuItem, SectionMenu } from '@components/SectionMenu';
import { useApi, useLoader } from '@hooks';
import { useEffect, useMemo, useState } from 'react';

import { CreateProductDialog } from './components/CreateProductDialog';
import { ICreateProductData } from '@models/interfaces/additional/ICreateProductData';
import { ILink } from '@models/interfaces/entities/ILink';
import { IProduct } from '@models/interfaces/entities/IProduct';
import { Loader } from '@components/Loader';
import { createProduct } from '@services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface IProps {
  products: IProduct[];
  activeProductId?: string;
  onProductAdded: () => void;
  createProductActionLink?: ILink;
}

export const ProductsMenu = ({
  products,
  activeProductId,
  onProductAdded,
  createProductActionLink,
}: IProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const {
    request: createProductRequest,
    loading: createProductLoading,
    data: createProductData,
  } = useApi(createProduct, null, { handleErrors: true });

  const onAddNewProduct = () => {
    setOpenDialog(true);
  };

  const onCloseCreateDialog = async (item?: ICreateProductData, file?: File) => {
    if (!item || !file) {
      setOpenDialog(false);
    } else {
      const url = createProductActionLink?.href;
      if (url) {
        createProductRequest(url, item, file);
      }
    }
  };

  const onProductSelected = (id: string) => {
    navigate(`/admin/products/${id}`);
  };

  useEffect(() => {
    if (createProductData) {
      setOpenDialog(false);
      toast.info('Product successfully created');
      onProductAdded();
    }
  }, [createProductData]);

  const showLoader = useLoader(createProductLoading);

  const menuItems = useMemo<ISectionMenuItem[]>(() => {
    return products.map((x) => ({
      id: x.id,
      title: x.name,
    }));
  }, [products]);

  return (
    <>
      <SectionMenu
        title='Products'
        activeItemId={activeProductId}
        items={menuItems}
        showAddButton={!!createProductActionLink}
        onAddItem={onAddNewProduct}
        onItemSelected={onProductSelected}
      />
      <CreateProductDialog open={openDialog} onClose={onCloseCreateDialog} />
      <Loader show={showLoader} />
    </>
  );
};
