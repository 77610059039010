import {
  DateRangePicker,
  DateRangePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers-pro';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import useStyles from './styles';

export const CustomDateRangePicker = <TInputDate, TDate = TInputDate>(
  props: DateRangePickerProps<TInputDate, TDate>,
) => {
  const { classes } = useStyles();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateRangePicker
        {...props}
        PaperProps={{ ...props.DialogProps, className: classes.dateRangePickerPaper }}
      />
    </LocalizationProvider>
  );
};
