import BalancesProvider from '@providers/BalancesProvider';
import { IBalance } from '@models/interfaces/entities/IBalance';
import { IBalanceDto } from '@models/interfaces/dtos/IBalanceDto';
import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import mapper from '@models/mapper';

const balancesProvider = new BalancesProvider();

export const getProjectBalances = async (url: string) => {
  const result = await balancesProvider.getProjectBalances(url);
  return {
    items: mapper.mapArray<IBalanceDto, IBalance>(result.items, 'IBalanceDto', 'IBalance'),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const getBalance = async (url: string) => {
  const result = await balancesProvider.getBalance(url);
  return mapper.map<IBalanceDto, IBalance>(result, 'IBalanceDto', 'IBalance');
};
