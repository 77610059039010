import { Box, IconButton, Tooltip, Typography } from '@mui/material';

import HelpCircleIcon from '@assets/icons/dashboard/help-circle.svg';
import { IFileSubCategory } from '@models/interfaces/entities/IFileSubCategory';
import useStyles from './styles';

interface IProps {
  id?: string;
  item: IFileSubCategory;
  onDrop: () => void;
}

export const FileSubCategoryCard = ({ id, item, onDrop }: IProps) => {
  const { classes } = useStyles();

  return (
    <Box
      id={id}
      className={classes.root}
      onDrop={item.disabled ? undefined : onDrop}
      data-testid='file-sub-category-card'
    >
      <Typography variant='caption'>Pair an uploaded file</Typography>
      <Tooltip
        title={`Drag an accepted uploaded file to the adjacent drop zone to pair it with this file file.
          Pairing a file indicates that the new file is similar to the old file in both content and format and helps with determining how to map the contained data.
          Toggling off this pairing will indicate that you are no longer providing this file for our analysis.`}
      >
        <IconButton data-testid='help-button'>
          <img src={HelpCircleIcon} alt='help' />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
