import { ISectionMenuItem, SectionMenu } from '@components/SectionMenu';
import { useApi, useLoader } from '@hooks';
import { useEffect, useMemo, useState } from 'react';

import { CreateClientDialog } from './components/CreateClientDialog';
import { IClient } from '@models/interfaces/entities/IClient';
import { ICreateClientData } from '@models/interfaces/additional/ICreateClientData';
import { ILink } from '@models/interfaces/entities/ILink';
import { Loader } from '@components/Loader';
import { createClient } from '@services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface IProps {
  clients: IClient[];
  activeClientId?: string;
  onClientAdded: () => void;
  createClientActionLink?: ILink;
}

export const ClientsMenu = ({
  clients,
  activeClientId,
  onClientAdded,
  createClientActionLink,
}: IProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const {
    request: createClientRequest,
    loading: createClientLoading,
    data: createClientData,
  } = useApi(createClient, null, { handleErrors: true });

  const onAddNewClient = () => {
    setOpenDialog(true);
  };

  const onCloseCreateDialog = async (item?: ICreateClientData) => {
    if (!item) {
      setOpenDialog(false);
    } else {
      const url = createClientActionLink?.href;
      if (url) {
        createClientRequest(url, item);
      }
    }
  };

  const onClientSelected = (id: string) => {
    navigate(`/admin/clients/${id}`);
  };

  useEffect(() => {
    if (createClientData) {
      setOpenDialog(false);
      toast.info('Client successfully created');
      onClientAdded();
    }
  }, [createClientData]);

  const showLoader = useLoader(createClientLoading);

  const menuItems = useMemo<ISectionMenuItem[]>(() => {
    return clients.map((x) => ({
      id: x.id,
      title: x.name,
      subtitle: x.accountNumber,
      additional: x.addressState,
    }));
  }, [clients]);

  return (
    <>
      <SectionMenu
        title='Clients'
        activeItemId={activeClientId}
        items={menuItems}
        showAddButton={!!createClientActionLink}
        onAddItem={onAddNewClient}
        onItemSelected={onClientSelected}
      />
      <CreateClientDialog open={openDialog} onClose={onCloseCreateDialog} />
      <Loader show={showLoader} />
    </>
  );
};
