import BaseProvider from './BaseProvider';
import { ICreateProductRequest } from '@models/interfaces/requests/ICreateProductRequest';
import { ICreateProductResponse } from '@models/interfaces/responses/ICreateProductResponse';
import { IGetProductResponse } from '@models/interfaces/responses/IGetProductResponse';
import { IGetProductsResponse } from '@models/interfaces/responses/IGetProductsResponse';
import { IUpdateProductRequest } from '@models/interfaces/requests/IUpdateProductRequest';
import { IUpdateProductResponse } from '@models/interfaces/responses/IUpdateProductResponse';

export default class ProductsProvider extends BaseProvider {
  public getProducts = (url: string) => {
    return this.get<IGetProductsResponse>(url);
  };

  public getProduct = (url: string) => {
    return this.get<IGetProductResponse>(url);
  };

  public createProduct = (url: string, data: ICreateProductRequest, file: File) => {
    const formData = new FormData();
    formData.set('file', file);
    const entries = Object.entries(data);
    entries.forEach(([key, value]) => {
      formData.set(key, value);
    });
    return this.post<FormData, ICreateProductResponse>(url, formData);
  };

  public deleteProduct = (url: string) => {
    return this.delete<void>(url);
  };

  public updateProduct = (url: string, data: IUpdateProductRequest, file?: File) => {
    const formData = new FormData();
    if (file) {
      formData.set('file', file);
    }
    const entries = Object.entries(data);
    entries.forEach(([key, value]) => {
      formData.set(key, value);
    });
    return this.patch<FormData, IUpdateProductResponse>(url, formData);
  };
}
