import { Mapper, createMap } from '@automapper/core';

import { IPiiIncidentDetailsPage } from '@models/interfaces/entities/IPiiIncidentDetailsPage';
import { IPiiIncidentDetailsPageDto } from '@models/interfaces/dtos/IPiiIncidentDetailsPageDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createPiiIncidentDetailsPageMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IPiiIncidentDetailsPageDto>('IPiiIncidentDetailsPageDto', {
    pageTitle: String,
    pii: [Object],
  });

  PojosMetadataMap.create<IPiiIncidentDetailsPage>('IPiiIncidentDetailsPage', {
    pageTitle: String,
    pii: [Object],
  });

  createMap<IPiiIncidentDetailsPageDto, IPiiIncidentDetailsPage>(
    mapper,
    'IPiiIncidentDetailsPageDto',
    'IPiiIncidentDetailsPage',
  );
};
