import { Box, Typography } from '@mui/material';

import { ActivityEventCard } from '../ActivityEventCard';
import { IActivityEvent } from '@models/interfaces/entities/IActivityEvent';
import moment from 'moment';
import { useMemo } from 'react';
import useStyles from './styles';

interface IProps {
  date: string;
  events: IActivityEvent[];
}

export const ActivityEventsBlock = ({ date, events }: IProps) => {
  const { classes } = useStyles();

  const label = useMemo(() => {
    const momentDate = moment(date, 'YYYY-MM-DD');

    const config = {
      // when the date is closer, specify custom values
      lastWeek: 'MM.DD.YYYY',
      lastDay: '[Yesterday]',
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: 'MM.DD.YYYY',
      // when the date is further away, use from-now functionality
      sameElse: function () {
        return 'MM.DD.YYYY';
      },
    };
    return momentDate.calendar(null, config);
  }, [date]);

  return (
    <Box className={classes.container}>
      <Typography variant='caption' component='div' className={classes.date}>
        {label}
      </Typography>

      <Box className={classes.list}>
        {events.map((item) => (
          <ActivityEventCard key={item.id} item={item} />
        ))}
      </Box>
    </Box>
  );
};
