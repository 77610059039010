import { selectAuthGetFeaturesActionLink, setFeatures } from '@reducers/authSlice';
import { useApi, useAppDispatch, useAppSelector, useLoader, useUpdateEffect } from '@hooks';
import { useCallback, useEffect } from 'react';

import { Box } from '@mui/material';
import { FeatureView } from './components/FeatureView';
import { FeaturesMenu } from './components/FeaturesMenu';
import { Loader } from '@components/Loader';
import { getFeatures } from '@services/api';
import { useParams } from 'react-router-dom';
import useStyles from './styles';

export const FeaturesPage = () => {
  const { classes } = useStyles();
  const getFeaturesActionLink = useAppSelector(selectAuthGetFeaturesActionLink);
  const { featureId } = useParams();
  const dispatch = useAppDispatch();

  const {
    request: getFeaturesRequest,
    data: getFeaturesData,
    loading: getFeaturesLoading,
  } = useApi(getFeatures, null, { handleErrors: true });

  useEffect(() => {
    if (getFeaturesActionLink) getFeaturesRequest(getFeaturesActionLink.href);
  }, [getFeaturesActionLink]);

  const onChanged = useCallback(() => {
    if (getFeaturesActionLink) getFeaturesRequest(getFeaturesActionLink.href);
  }, [getFeaturesActionLink]);

  useUpdateEffect(() => {
    if (getFeaturesData) dispatch(setFeatures(getFeaturesData.items));
  }, [getFeaturesData]);

  const showLoader = useLoader(getFeaturesLoading);

  return (
    <Box className={classes.container}>
      <FeaturesMenu features={getFeaturesData?.items || []} activeFeatureId={featureId} />
      {featureId && <FeatureView onFeatureChanged={onChanged} featureId={featureId} />}
      <Loader show={showLoader} />
    </Box>
  );
};
