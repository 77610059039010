import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  h100: {
    height: '100%',
  },
  w100: {
    width: '100%',
  },
  textGray: {
    color: t.palette.custom.dark[60],
  },
  fw600: {
    fontWeight: 600,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
  flexGap8: {
    gap: 8,
  },
  flexGap4: {
    gap: 4,
  },
  flexGap60: {
    gap: 60,
  },
  justifyContentSpaceBetween: {
    justifyContent: 'space-between',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexGrow1: {
    flexGrow: 1,
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',

    '&.loaded::after': {
      display: 'none',
    },

    '&::after': {
      backgroundColor: t.palette.custom.white[100],
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      position: 'absolute',
      top: 40,
      left: 0,
      right: 0,
      bottom: 0,
      content: '""',
    },
  },
  container: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  hiddenSection: {
    marginTop: 107,
  },
  topSection: {
    minHeight: 40,
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: 4,
    paddingTop: 4,
    width: '100%',
    position: 'relative',
  },
  mainSection: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: t.palette.custom.white[100],
  },
  projectsSection: {
    overflow: 'auto',
    height: '100%',
  },
  paginationSection: {
    height: 65,
    backgroundColor: t.palette.custom.white[100],
  },
  topSectionContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    gap: 16,

    '&>nav': {
      maxWidth: '40%',
    },
  },
  title: {
    paddingTop: 10,
    paddingBottom: 10,
    height: 65,
    borderBottom: `1px solid ${t.palette.divider}`,
  },
  projectsList: {
    paddingTop: 24,
    paddingBottom: 24,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: 16,
    overflowY: 'auto',
    height: '100%',
    [t.breakpoints.down('md')]: { gridTemplateColumns: '1fr 1fr' },

    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: t.palette.custom.dark[67],
      borderRadius: 4,
    },
  },
  projectItem: {
    cursor: 'pointer',
    gap: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    padding: 8,
    borderRadius: 10,
    backgroundColor: t.palette.custom.primary[5],
  },
  dateRangePickerInput: {
    width: 280,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
  paginationDivider: {
    width: '100%',
    marginBottom: 16,
  },
}));

export default useStyles;
