import { Box, IconButton, Switch, Tooltip, Typography } from '@mui/material';

import { Actions } from '@models/enums/Actions';
import { IClientFeature } from '@models/interfaces/entities/IClientFeature';
import { IFeature } from '@models/interfaces/entities/IFeature';
import InfoIcon from '@assets/icons/item-view/info.svg';
import clsx from 'clsx';
import { useMemo } from 'react';
import useStyles from './styles';

export interface IProps {
  feature: IFeature & {
    clientFeature?: IClientFeature;
  };
  onActivate: () => void;
  onDeactivate: () => void;
  isActivationEnabled: boolean;
  isDeactivationEnabled: boolean;
  info?: string;
}

export const FeatureCard = ({
  feature,
  onActivate,
  onDeactivate,
  isActivationEnabled,
  isDeactivationEnabled,
  info,
}: IProps) => {
  const { classes } = useStyles();

  const switchChecked = useMemo(() => !!feature.clientFeature, [feature.clientFeature]);
  const switchEnabled = useMemo(
    () =>
      (!!feature.clientFeature &&
        feature.clientFeature.links[Actions.delete] &&
        isDeactivationEnabled) ||
      (!feature.clientFeature && isActivationEnabled),
    [feature.clientFeature, isActivationEnabled],
  );

  return (
    <Box
      className={clsx([classes.root, feature.clientFeature ? classes.active : classes.inactive])}
    >
      <Box className={classes.controls}>
        <Switch
          className={classes.switch}
          disabled={!switchEnabled}
          checked={switchChecked}
          inputProps={{ role: 'switch' }}
          onChange={(_, value) => {
            if (value) {
              onActivate();
            } else {
              onDeactivate();
            }
          }}
        />
        <Tooltip title={<Box className={classes.tooltip}>{info || feature.description}</Box>}>
          <IconButton>
            <img src={InfoIcon} alt='info' />
          </IconButton>
        </Tooltip>
      </Box>
      <Typography variant='subtitle1'>{feature.name}</Typography>
    </Box>
  );
};
