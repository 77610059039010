import FeaturesProvider from '@providers/FeaturesProvider';
import { IFeature } from '@models/interfaces/entities/IFeature';
import { IFeatureDto } from '@models/interfaces/dtos/IFeatureDto';
import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import { IUpdateFeatureData } from '@models/interfaces/additional/IUpdateFeatureData';
import { IUpdateFeatureRequest } from '@models/interfaces/requests/IUpdateFeatureRequest';
import mapper from '@models/mapper';

const featuresProvider = new FeaturesProvider();

export const getFeatures = async (url: string) => {
  const result = await featuresProvider.getFeatures(url);
  return {
    items: mapper.mapArray<IFeatureDto, IFeature>(result.items, 'IFeatureDto', 'IFeature'),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const updateFeature = async (url: string, data: IUpdateFeatureData) => {
  const requestData = mapper.map<IUpdateFeatureData, IUpdateFeatureRequest>(
    data,
    'IUpdateFeatureData',
    'IUpdateFeatureRequest',
  );
  const result = await featuresProvider.updateFeature(url, requestData);
  return mapper.map<IFeatureDto, IFeature>(result, 'IFeatureDto', 'IFeature');
};

export const getFeature = async (url: string) => {
  const result = await featuresProvider.getFeature(url);
  return mapper.map<IFeatureDto, IFeature>(result, 'IFeatureDto', 'IFeature');
};
