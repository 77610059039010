import EmailNotificationsProvider from '@providers/EmailNotificationsProvider';
import { ICreateProjectResultsUploadedNotificationData } from '@models/interfaces/additional/ICreateProjectResultsUploadedNotificationData';
import { ICreateProjectResultsUploadedNotificationRequest } from '@models/interfaces/requests/ICreateProjectResultsUploadedNotificationRequest';
import mapper from '@models/mapper';

const emailNotificationsProvider = new EmailNotificationsProvider();

export const createProjectResultsUploadedNotification = async (
  url: string,
  data: ICreateProjectResultsUploadedNotificationData,
) => {
  const requestData = mapper.map<
    ICreateProjectResultsUploadedNotificationData,
    ICreateProjectResultsUploadedNotificationRequest
  >(
    data,
    'ICreateProjectResultsUploadedNotificationData',
    'ICreateProjectResultsUploadedNotificationRequest',
  );
  await emailNotificationsProvider.createProjectResultsUploadedNotification(url, requestData);
  return true;
};
