import BaseProvider from './BaseProvider';
import { IGetBalanceResponse } from '@models/interfaces/responses/IGetBalanceResponse';
import { IGetProjectBalancesResponse } from '@models/interfaces/responses/IGetProjectBalancesResponse';

export default class BalancesProvider extends BaseProvider {
  public getProjectBalances = (url: string) => {
    return this.get<IGetProjectBalancesResponse>(url);
  };

  public getBalance = (url: string) => {
    return this.get<IGetBalanceResponse>(url);
  };
}
