import UploaderBackground from '../../assets/uploader/uploader-background.png';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    backgroundImage: `url(${UploaderBackground})`,
    backgroundSize: '100% auto',
    backgroundPosition: 'center top',
  },
  mainSection: {
    flexGrow: 1,
    position: 'relative',
    overflow: 'hidden',
  },
  backdrop: {
    backgroundColor: t.palette.custom.white[100],
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    position: 'absolute',
    top: 40,
    left: 0,
    right: 0,
    bottom: 0,
  },
  container: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

export default useStyles;
