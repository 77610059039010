import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  breadcrumbsItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    color: t.palette.custom.white[100],
    fontWeight: t.typography.subtitle2.fontWeight,
    fontSize: t.typography.subtitle2.fontSize,
    lineHeight: t.typography.subtitle2.lineHeight,
    letterSpacing: t.typography.subtitle2.letterSpacing,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    borderRadius: '50%',
    backgroundColor: t.palette.custom.white[100],
  },
}));

export default useStyles;
