import { Badge, Box, Button, IconButton, Typography } from '@mui/material';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  createFileCategory,
  deleteProduct,
  getFileCategories,
  getProduct,
  updateFileCategoriesOrder,
  updateProduct,
} from '@services/api';
import { useApi, useConfirm, useLoader, useUpdateEffect } from '@hooks';

import { Actions } from '@models/enums/Actions';
import { CreateFileCategoryDialog } from './components/CreateFileCategoryDialog';
import EditFilledIcon from '@assets/icons/item-view/edit-3-filled.svg';
import { EditProductDialog } from './components/EditProductDialog';
import { FileCategoryCard } from './components/FileCategoryCard';
import { ICreateFileCategoryData } from '@models/interfaces/additional/ICreateFileCategoryData';
import { IFileCategory } from '@models/interfaces/entities/IFileCategory';
import { IProduct } from '@models/interfaces/entities/IProduct';
import { IUpdateProductData } from '@models/interfaces/additional/IUpdateProductData';
import { Loader } from '@components/Loader';
import PlusIcon from '@assets/icons/item-view/plus-filled.svg';
import TrashFilledIcon from '@assets/icons/item-view/trash-filled.svg';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';

interface IProps {
  productId: string;
  onProductChanged: () => void;
}

export const ProductView = ({ onProductChanged, productId }: IProps) => {
  const { classes } = useStyles();
  const confirm = useConfirm();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openCreateCategoryDialog, setOpenCreateCategoryDialog] = useState(false);
  const [product, setProduct] = useState<IProduct | null>(null);
  const [fileCategories, setFileCategories] = useState<IFileCategory[]>([]);
  const navigate = useNavigate();

  const {
    request: getProductRequest,
    loading: getProductLoading,
    data: getProductData,
  } = useApi(getProduct, null, { handleErrors: true });

  const { request: updateProductRequest, loading: updateProductLoading } = useApi(
    updateProduct,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        setProduct(data);
        setOpenEditDialog(false);
        toast.info('Successfully updated the product');
        onProductChanged();
      },
    },
  );

  const { request: deleteProductRequest, loading: deleteProductLoading } = useApi(
    deleteProduct,
    null,
    {
      handleErrors: true,
      onCallback: () => {
        toast.info('Successfully deleted the product');
        onProductChanged();
        navigate('/admin/products');
      },
    },
  );

  const {
    request: getFileCategoriesRequest,
    data: getFileCategoriesData,
    loading: getFileCategoriesLoading,
  } = useApi(getFileCategories, null, { handleErrors: true });

  const {
    request: updateFileCategoriesOrderRequest,
    data: updateFileCategoriesOrderData,
    loading: updateFileCategoriesOrderLoading,
  } = useApi(updateFileCategoriesOrder, null, { handleErrors: true });

  const {
    request: createFileCategoryRequest,
    loading: createFileCategoryLoading,
    data: createFileCategoryData,
  } = useApi(createFileCategory, null, { handleErrors: true });

  const reloadFileCategories = () => {
    if (product?.links[Actions.getFileCategories]?.href) {
      getFileCategoriesRequest(product.links[Actions.getFileCategories].href);
    }
  };

  const onEdit = () => {
    setOpenEditDialog(true);
  };

  const onCreateCategory = () => {
    setOpenCreateCategoryDialog(true);
  };

  const onCloseCreateCategoryDialog = async (data?: ICreateFileCategoryData) => {
    if (!data) {
      setOpenCreateCategoryDialog(false);
    } else {
      const url = getFileCategoriesData?.links[Actions.createCategory]?.href;
      if (url) {
        createFileCategoryRequest(url, { ...data, position: fileCategories.length + 1 });
      }
    }
  };

  const onCloseEditDialog = async (item?: IUpdateProductData, file?: File) => {
    if (!item) {
      setOpenEditDialog(false);
    } else {
      const url = getProductData?.links[Actions.update]?.href;
      if (url) {
        updateProductRequest(url, item, file);
      }
    }
  };

  const onDelete = async () => {
    if (product?.links[Actions.delete]?.href) {
      await confirm({
        title: 'Delete Product',
        description: 'Are you sure that you want to delete this product?',
        confirmationText: 'Delete',
      });
      deleteProductRequest(product.links[Actions.delete].href);
    }
  };

  const onCategoryChanged = async () => {
    reloadFileCategories();
  };

  useUpdateEffect(() => {
    if (getProductData) {
      setProduct(getProductData);
    }
  }, [getProductData]);

  useUpdateEffect(() => {
    if (createFileCategoryData) {
      setOpenCreateCategoryDialog(false);
      toast.info('Successfully created the file category');
      reloadFileCategories();
    }
  }, [createFileCategoryData]);

  useEffect(() => {
    if (getFileCategoriesData) {
      setFileCategories(getFileCategoriesData.items);
    }
  }, [getFileCategoriesData]);

  useEffect(() => {
    if (updateFileCategoriesOrderData) {
      setFileCategories(updateFileCategoriesOrderData.items);
    }
  }, [updateFileCategoriesOrderData]);

  useEffect(() => {
    if (productId) {
      getProductRequest(`/products/${productId}`);
    }
  }, [productId]);

  useEffect(() => {
    reloadFileCategories();
  }, [product]);

  const showLoader = useLoader(
    getProductLoading,
    deleteProductLoading,
    updateProductLoading,
    getFileCategoriesLoading,
    createFileCategoryLoading,
    updateFileCategoriesOrderLoading,
  );

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    const items = [...fileCategories];
    const [removed] = items.splice(startIndex, 1);
    items.splice(endIndex, 0, removed);

    setFileCategories(items);

    const changes = items
      .map(({ id, position }, i) => ({ id, position, newPosition: i + 1 }))
      .filter((x) => x.position !== x.newPosition)
      .map(({ id, newPosition }) => ({ fileCategoryId: id, position: newPosition }));

    if (changes.length && getFileCategoriesData?.links[Actions.updateOrder]) {
      updateFileCategoriesOrderRequest(getFileCategoriesData.links[Actions.updateOrder].href, {
        records: changes,
      });
    }
  };

  const infoItems = useMemo<
    { value: string | JSX.Element; label: string; action?: ReactNode }[]
  >(() => {
    return [
      {
        label: 'Url',
        value: product?.url ? (
          <a target='_blank' rel='noreferrer' href={product?.url}>
            {product?.url}
          </a>
        ) : (
          '-'
        ),
      },
    ];
  }, [product]);

  return (
    <Box className={classes.container}>
      {product && (
        <>
          <Box className={classes.infoSection}>
            <Box className={classes.title}>
              <Typography variant='h6'>{product.name}</Typography>
              {!!product?.links[Actions.update]?.href && (
                <IconButton onClick={onEdit}>
                  <img src={EditFilledIcon} alt='Edit' />
                </IconButton>
              )}
              {!!product?.links[Actions.delete]?.href && (
                <IconButton onClick={onDelete}>
                  <img src={TrashFilledIcon} alt='Delete' />
                </IconButton>
              )}
            </Box>
            <Box className={classes.infoContent}>
              {infoItems.map((item) => (
                <Box key={item.label} className={classes.infoItem}>
                  <Typography className='label' variant='caption'>
                    {item.label}
                  </Typography>
                  <Box className={classes.flexRow}>
                    <Typography variant='subtitle1'>{item.value}</Typography>
                    {item.action}
                  </Box>
                </Box>
              ))}
            </Box>
            <Box className={classes.categories}>
              <Box className={classes.categoriesCountContainer}>
                <Typography variant='subtitle1'>File categories:</Typography>
                <Badge
                  className={classes.categoriesCount}
                  badgeContent={fileCategories.length}
                  color='secondary'
                />
              </Box>
              {!!getFileCategoriesData?.links[Actions.createCategory]?.href && (
                <Button
                  className={classes.addButton}
                  variant='text'
                  color='info'
                  startIcon={<img src={PlusIcon} alt='Add' />}
                  onClick={onCreateCategory}
                >
                  Add category
                </Button>
              )}
            </Box>
          </Box>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable'>
              {(provided) => (
                <Box
                  className={classes.categoriesList}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {fileCategories.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <FileCategoryCard category={item} onCategoryChanged={onCategoryChanged} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>

          <EditProductDialog product={product} open={openEditDialog} onClose={onCloseEditDialog} />

          {!!getFileCategoriesData?.links[Actions.createCategory] && (
            <CreateFileCategoryDialog
              open={openCreateCategoryDialog}
              onClose={onCloseCreateCategoryDialog}
              position={getFileCategoriesData.items.length + 1}
            />
          )}
        </>
      )}
      <Loader show={showLoader} />
    </Box>
  );
};
