import { Badge, Box, Button, IconButton, Typography } from '@mui/material';
import {
  addGroupMember,
  deleteGroup,
  getGroup,
  getGroupMembers,
  removeGroupMember,
} from '@services/api';
import { useApi, useConfirm, useLoader, useUpdateEffect } from '@hooks';
import { useCallback, useEffect, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { AddGroupMemberDialog } from './components/AddGroupMemberDialog';
import EditFilledIcon from '@assets/icons/item-view/edit-3-filled.svg';
import { IAddGroupMemberData } from '@models/interfaces/additional/IAddGroupMemberData';
import { IGroup } from '@models/interfaces/entities/IGroup';
import { IUser } from '@models/interfaces/entities/IUser';
import { Loader } from '@components/Loader';
import { MemberCard } from './components/MemberCard';
import PlusIcon from '@assets/icons/item-view/plus-filled.svg';
import TrashFilledIcon from '@assets/icons/item-view/trash-filled.svg';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';

interface IProps {
  groupId: string;
  onGroupChanged: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const GroupView = ({ onGroupChanged, groupId }: IProps) => {
  const { classes } = useStyles();
  const confirm = useConfirm();
  const [openDialog, setOpenDialog] = useState(false);
  const [group, setGroup] = useState<IGroup | null>(null);
  const [members, setMembers] = useState<IUser[]>([]);
  const navigate = useNavigate();

  const {
    request: getGroupRequest,
    loading: getGroupLoading,
    data: getGroupData,
  } = useApi(getGroup, null, { handleErrors: true });

  const { request: deleteGroupRequest, loading: deleteGroupLoading } = useApi(deleteGroup, null, {
    handleErrors: true,
    onCallback: () => {
      toast.info('Successfully deleted the group');
      onGroupChanged();
      navigate('/admin/groups');
    },
  });

  const {
    request: getGroupMembersRequest,
    data: getGroupMembersData,
    loading: getGroupMembersLoading,
  } = useApi(getGroupMembers, null, { handleErrors: true });

  const {
    request: removeGroupMemberRequest,
    loading: removeGroupMemberLoading,
    data: removeGroupMemberData,
  } = useApi(removeGroupMember, null, { handleErrors: true });

  const {
    request: addGroupMemberRequest,
    loading: addGroupMemberLoading,
    data: addGroupMemberData,
  } = useApi(addGroupMember, null, { handleErrors: true });

  const onEdit = () => {
    toast.info('Not implemented yet');
  };

  const onAddMember = () => {
    setOpenDialog(true);
  };

  const onDelete = useCallback(async () => {
    if (group?.links[Actions.delete]?.href) {
      await confirm({
        title: 'Delete Group',
        description: 'Are you sure that you want to delete this group?',
        confirmationText: 'Delete',
      });
      deleteGroupRequest(group.links[Actions.delete].href);
    }
  }, [group]);

  const onRemoveMember = async (item: IUser) => {
    if (item.links[Actions.removeMembership]?.href) {
      await confirm({
        title: 'Remove member',
        description: `Are you sure that you want to remove ${item.fullName} from ${group?.name}?`,
        confirmationText: 'Remove',
      });
      removeGroupMemberRequest(item.links[Actions.removeMembership].href);
    }
  };

  const onCloseAddMemberDialog = async (data?: IAddGroupMemberData) => {
    if (!data) {
      setOpenDialog(false);
    } else {
      const url = group?.links[Actions.addUser]?.href;
      if (url) {
        addGroupMemberRequest(url, data);
      }
    }
  };

  useUpdateEffect(() => {
    if (getGroupData) {
      setGroup(getGroupData);
    }
  }, [getGroupData]);

  useEffect(() => {
    if (getGroupMembersData) {
      setMembers(getGroupMembersData);
    }
  }, [getGroupMembersData]);

  useUpdateEffect(() => {
    if (addGroupMemberData) {
      setOpenDialog(false);
      toast.info('Member successfully added');
      setMembers(addGroupMemberData);
    }
  }, [addGroupMemberData]);

  useUpdateEffect(() => {
    if (removeGroupMemberData) {
      toast.info('Member successfully removed');
      setMembers(removeGroupMemberData);
    }
  }, [removeGroupMemberData]);

  useEffect(() => {
    getGroupRequest(`/groups/${groupId}`);
  }, [groupId]);

  useUpdateEffect(() => {
    if (group?.links[Actions.getMembers]?.href) {
      getGroupMembersRequest(group.links[Actions.getMembers].href);
    }
  }, [group]);

  const showLoader = useLoader(
    getGroupLoading,
    getGroupMembersLoading,
    removeGroupMemberLoading,
    addGroupMemberLoading,
    deleteGroupLoading,
  );

  return (
    <Box className={classes.container}>
      {group && (
        <>
          <Box className={classes.infoSection}>
            <Box className={classes.title}>
              <Typography variant='h6'>{group.name}</Typography>
              <IconButton onClick={onEdit}>
                <img src={EditFilledIcon} alt='Edit' />
              </IconButton>
              {!!group.links[Actions.delete]?.href && (
                <IconButton onClick={() => onDelete()}>
                  <img src={TrashFilledIcon} alt='Delete' />
                </IconButton>
              )}
            </Box>
            <Box className={classes.members}>
              <Box className={classes.membersCountContainer}>
                <Typography variant='subtitle1'>All members:</Typography>
                <Badge
                  className={classes.membersCount}
                  badgeContent={members.length}
                  color='secondary'
                />
              </Box>
              {!!group.links[Actions.addUser]?.href && (
                <Button
                  className={classes.addButton}
                  variant='text'
                  color='info'
                  startIcon={<img src={PlusIcon} alt='Add' />}
                  onClick={onAddMember}
                >
                  Add Member
                </Button>
              )}
            </Box>
          </Box>
          <Box className={classes.membersList}>
            {members.map((item) => (
              <MemberCard key={item.id} member={item} onRemove={() => onRemoveMember(item)} />
            ))}
          </Box>
        </>
      )}
      <AddGroupMemberDialog open={openDialog} onClose={onCloseAddMemberDialog} members={members} />
      <Loader show={showLoader} />
    </Box>
  );
};
