import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  container: {
    marginTop: 57,
    display: 'flex',
    height: 'calc(100vh - 57px)',
    paddingLeft: 24,
    paddingRight: 24,
  },
  sideSection: {
    borderRight: `1px solid ${t.palette.divider}`,
  },
  mainSection: {
    flexGrow: 1,
  },
}));

export default useStyles;
